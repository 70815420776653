import MDTypography from 'components/core/MDTypography'
import React, {
  forwardRef,
  useEffect,
  useState,
} from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp'
import MDButton from 'components/core/MDButton'
import _ from 'lodash'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { wizardSelector } from 'layouts/admin/document-template-flow/Wizard/store/wizardSlice'
import LookUpValues
  from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/PartSelection/TablePartDefinition/KeyValue/LookUpValues'
// import { getTextWidth } from 'utils/util'
import SaveIcon from '@mui/icons-material/Save'
import { createLookup } from 'layouts/admin/document-template-flow/Wizard/store/saga'

export default forwardRef(({ title, type, lookupName, tableLookupKeyValueContent, setTableLookupKeyValueContent, typeOfLookUpValues }, ref) => {
  const dispatch = useDispatch()
  const docTemplateFlow = useSelector(wizardSelector)

  const [rowsData, setRowsData] = useState([])
  useEffect(() => {
    if(!_.isEmpty(tableLookupKeyValueContent)) {
      if(!_.isArray(tableLookupKeyValueContent) && _.isString(tableLookupKeyValueContent)) {
        setRowsData(JSON.parse(tableLookupKeyValueContent))
      } else {
        setRowsData(tableLookupKeyValueContent)
      }
    } else {
      setRowsData([])
    }
  }, [tableLookupKeyValueContent])

  const saveLookup = () => {
    const lookupSaveData = {
      name: lookupName,
    }
    let lookupSaveItems = []
    if(typeOfLookUpValues === 'keyValue') {
      lookupSaveItems = {}
    }
    _.forEach(rowsData, content => {
      if(typeOfLookUpValues === 'keyValue') {
        if(_.isArray(content.value)) {
          lookupSaveItems[content.key] = content.value
        } else {
          lookupSaveItems[content.key] = content && content.value ? content.value.split(',') : []
        }
      } else if(typeOfLookUpValues === 'array') {
        if(_.isArray(content.value)) {
          lookupSaveItems = [...lookupSaveItems, ...content.value]
        } else {
          const valArray = content && content.value ? content.value.split(',') : []
          lookupSaveItems = [...lookupSaveItems, ...valArray]
        }
      }

    })
    lookupSaveData['items'] = lookupSaveItems
    dispatch(createLookup({ data: lookupSaveData }))
  }
  const addTableRows = () => {
    const rowsInput = {
      id: rowsData.length,
      key: typeOfLookUpValues === 'array' ? '' : 'key',
      value: '',
    }
    setRowsData([...rowsData, rowsInput])
  }
  const deleteTableRows = (index) => {
    const rows = _.cloneDeep(rowsData)
    rows.splice(index, 1)
    setRowsData(rows)
    setTableLookupKeyValueContent(rows)
  }

  const handleKeyChange = (index, e) => {
    const { name, value } = e.target
    const rowsInput = _.cloneDeep(rowsData)
    rowsInput[index]['key'] = value
    setRowsData(rowsInput)
    setTableLookupKeyValueContent(rowsInput)
  }

  const handleValueChange = (index, e) => {
    const { name, value } = e.target
    const rowsInput = _.cloneDeep(rowsData)
    rowsInput[index]['value'] = value
    setRowsData(rowsInput)
    setTableLookupKeyValueContent(rowsInput)
  }

  return (

    <Box sx={{ width: '90%', justifyContent: 'center' }}>
      <Box>
        <MDTypography p={2} variant={'h6'}>
          {title}
        </MDTypography>
      </Box>
      <Box
        sx={{ width: '100%', border: 1, justifyContent: 'center', minHeight: 100, maxHeight: 500, overflow: 'hidden', overflowY: 'scroll' }}>
        <Stack direction='column' sx={{ justifyContent: 'center' }}>
          <LookUpValues rowsData={rowsData} deleteTableRows={deleteTableRows} handleValueChange={handleValueChange}
                        handleKeyChange={handleKeyChange} />
        </Stack>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Stack direction='row' sx={{ justifyContent: 'center', paddingTop: '3%' }} spacing={4}>
          <MDButton size='large'
                    variant={'outlined'}
                    color={'dark'}
                    onClick={addTableRows}
                    disabled={docTemplateFlow.wizardType === 'view'}
          >
            <AddCircleSharpIcon />
          </MDButton>
          <MDButton size='large'
                    variant={'outlined'}
                    color={'dark'}
                    onClick={saveLookup}
                    disabled={docTemplateFlow.wizardType === 'view'}
          >
            <SaveIcon />
          </MDButton>
        </Stack>
      </Box>
    </Box>

  )
})