import { Box, CardContent, CardHeader, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ReactFlowProvider } from 'reactflow'
import SidePanel from './SidePanel'
import React, { useRef, useState } from 'react'
import Designer from './Designer'
import store from 'utils/local-storage'
import { useCurrentEffect } from 'use-current-effect'
import ActionBar from 'components/extended/HardwareSetup/Designer/ActionBar'
import { DataGrid } from '@mui/x-data-grid'
import Card from '@mui/material/Card'
import MDBox from '../../core/MDBox'
import AutoComplete from '../AutoComplete'
import { SUBSYSTEM_BLOCK_SECTION } from '../../../utils/util'
import Table from '../Table'
import { dummyTableDef } from '../../../layouts/document-management/document/data/dummyTableDef'
import { useSelector } from 'react-redux'
import { documentSelector } from '../../../layouts/document-management/document-store/documentSlice'

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    flexShrink: 0,
  },
  tabs: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}))

export default ({
  type,
  subSystemData,
  handleOnTreeNodeSelect,
  onCreateSubSystemNode,
  actionTemplate,
  heading,
  workflowContent,
  setReactFlowInstance,
  reactFlowInstance,
  onDeleteSubSystemNode,
  onChangeOfInputNodeInputValue,
  onChangeOfOutputNodeOutputValue,
  getInputNumberValue,
  getOutputNumberValue,
  setDialogBody,
  setDialogTitle,
  setDialogOpen,
  setIsDeleteDialog,
}) => {
  
  const [validWorkflow, setValidWorkflow] = useState(false)
  const [loading, setLoading] = useState(true)
  const [reload, setReload] = useState(false)
  const [tableNodes, setTableNodes] = useState([])
  const [workflowData, setWorkflowData] = useState([])
  const [nodeToDelete, setNodeToDelete] = useState()
  const subSystems = subSystemData ? subSystemData.subSystems : []
  const subSystemMap = subSystemData ? subSystemData.subSystemMap : {}
  const [dataMap, setDataMap] = useState([])
  
  const mainDoc = useSelector(documentSelector)
  
  const getColumns = () => {
    return [
      {
        editable: false,
        field: 'id',
        hide: true,
        show: false,
        headerName: 'Id',
        minWidth: 50,
        required: true,
        width: 50,
      },
      {
        editable: false,
        field: 'attributeName',
        headerName: 'Attribute Name',
        minWidth: 140,
        required: true,
        width: 300,
      },
      {
        editable: true,
        field: 'attributeValue',
        headerName: 'Attribute Value',
        minWidth: 140,
        renderCell: createRenderer('Attribute Value'),
        required: true,
        width: 150,
      },
    ]
  }
  
  const updateRow = (params, value) => {
    const node = _.find(tableNodes, { id: params.row.nodeId })
    if (node) {
      const { executionInfo } = node.data
      executionInfo.paramObject[params.row.attributeKey] = value
    }
    setTableNodes([...tableNodes])
  }
  
  const createRenderer = (placeholder) => {
    return (params) => {
      const options = params.row['options']
      if (options) {
        const selectedValue = { name: params.value ? params.value : '', label: params.value ? params.value : '' }
        const lOptions = _.map(options, option => ({ name: option, label: option }))
        return (
          <AutoComplete
            value={selectedValue}
            
            onValueChange={(val) => updateRow(params, val)}
            rowId={params.row.id}
            options={lOptions}
          />
        )
      }
      return (
        <TextField
          variant='standard'
          size='small'
          InputProps={{
            disableUnderline: true,
          }}
          placeholder={placeholder}
          value={params.value ? params.value : ''}
        />
      )
    }
  }
  
  const getUnitName = (actionId) => {
    switch (actionId) {
      case 'powerUnit' :
        return 'Power Unit'
      case 'commsUnit' :
        return 'Comm Unit'
      case 'cpuUnit' :
        return 'CPU Unit'
    }
  }
  const prepareTable = (nodes) => {
    const lmap = []
    _.map(nodes, ({ data }, index) => {
      const { executionInfo, nodeId } = data
      const rows = _.map(executionInfo.attributes, (attribute, index) => {
        return {
          id: index,
          attributeKey: attribute.key,
          nodeId: nodeId,
          attributeName: !_.isEmpty(attribute.unit) ? `${attribute.label}  (${attribute.unit})` : attribute.label,
          attributeValue: executionInfo.paramObject[attribute.key],
          options: attribute.options,
        }
      })
      if (index % 2 === 0) {
        lmap.push({ lRows: rows, lName: executionInfo.headerText || getUnitName(executionInfo.actionId) })
      }
      if (index % 2 === 1) {
        const obj = lmap.pop()
        obj['rRows'] = rows
        obj['rName'] = executionInfo.headerText || getUnitName(executionInfo.actionId)
        lmap.push(obj)
      }
    })
    setDataMap(lmap)
  }
  useCurrentEffect(isCurrent => {
    (async () => {
      if(type === SUBSYSTEM_BLOCK_SECTION && workflowContent && workflowContent.workflowInstance) {
        const { workflowInstance: { nodes } } = workflowContent
        setTableNodes(nodes)
      }
      setLoading(true)
      store.set('WORKFLOW_ACTIONS', actionTemplate)
      setLoading(false)
    })()
  }, [])
  useCurrentEffect(isCurrent => {
    prepareTable(tableNodes)
  }, [tableNodes])
  
  const valueEditor = (params, event) => {
    updateRow(params, event.target.value)
  }
  const reactFlowWrapper = useRef(null)
  return (
    <>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='stretch'
        height='100%'
        border={`3px solid`}
        borderColor={'black'}
      >
        <Box
          display='flex'
          flexDirection='column'
          borderColor={'black'}
          alignItems='stretch'
          height='100%'
        >
          <Box
            display='flex'
            borderColor={'black'}
          >
            <ActionBar
              workflowContent={workflowContent}
              heading={heading}
            />
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            alignItems='stretch'
            height='100%'
            borderColor={'black'}
          >
            <ReactFlowProvider>
              <Box mb={3} zIndex={1200} width='20%'>
                <SidePanel
                  type={type}
                  subSystems={subSystems}
                  onCreateSubSystemNode={onCreateSubSystemNode}
                  setLoading={setLoading}
                  setNodeToDelete={setNodeToDelete}
                  workflowData={workflowData}
                  actionSet={actionTemplate}
                  onDeleteSubSystemNode={onDeleteSubSystemNode}
                />
              </Box>
              <Designer
                reactFlowWrapper={reactFlowWrapper}
                reactFlowInstance={reactFlowInstance}
                setReactFlowInstance={setReactFlowInstance}
                subSystemMap={subSystemMap}
                setTableNodes={setTableNodes}
                nodeToDelete={nodeToDelete}
                handleOnTreeNodeSelect={handleOnTreeNodeSelect}
                workflowContent={workflowContent}
                loading={loading}
                setValidWorkflow={setValidWorkflow}
                actionSet={actionTemplate}
                onChangeOfInputNodeInputValue={onChangeOfInputNodeInputValue}
                onChangeOfOutputNodeOutputValue={onChangeOfOutputNodeOutputValue}
                getInputNumberValue={getInputNumberValue}
                getOutputNumberValue={getOutputNumberValue}
                setDialogBody={setDialogBody}
                setDialogTitle={setDialogTitle}
                setDialogOpen={setDialogOpen}
                setIsDeleteDialog={setIsDeleteDialog}
              />
            </ReactFlowProvider>
          </Box>
        </Box>
        
        {type === SUBSYSTEM_BLOCK_SECTION && _.map(dataMap, (entry) => {
            return (
              <table sx={{ width: '100%' }}>
                <tbody>
                <tr>
                  <td>
                    <Card sx={{ marginTop: 5, marginLeft: 1, marginRight: 1 }}>
                      <CardHeader title={entry.lName} />
                      <CardContent>
                        <MDBox>
                          <DataGrid
                            editMode={'row'}
                            columns={getColumns()}
                            rows={entry.lRows}
                            hideFooterRowCount={true}
                            disableRowSelectionOnClick
                            getRowHeight={() => '20'}
                            pageSizeOptions={[5, 10, 25]}
                            onRowEditStop={valueEditor}
                            initialState={{
                              pagination: {
                                paginationModel: { pageSize: 5, page: 0 },
                              },
                              columns: {
                                columnVisibilityModel: {
                                  id: false,
                                },
                              },
                            }}
                          />
                        </MDBox></CardContent></Card></td>
                  <td>
                    {entry.rRows ?
                      (<Card sx={{ marginTop: 5, marginLeft: 1, marginRight: 1 }}>
                        <CardHeader title={entry.rName} />
                        <CardContent>
                          <MDBox>
                            <DataGrid
                              editMode={'row'}
                              columns={getColumns()}
                              rows={entry.rRows || []}
                              hideFooterRowCount={true}
                              disableRowSelectionOnClick
                              getRowHeight={() => '20'}
                              onRowEditStop={valueEditor}
                              pageSizeOptions={[5, 10, 25]}
                              initialState={{
                                pagination: {
                                  paginationModel: { pageSize: 5, page: 0 },
                                },
                                columns: {
                                  columnVisibilityModel: {
                                    id: false,
                                  },
                                },
                              }}
                            />
                          </MDBox></CardContent></Card>) :
                      <Card sx={{ width: 600 }}>
                      </Card>}
                  </td>
                </tr>
                </tbody>
              </table>)
          },
        )}
      </Box></>
  )
}