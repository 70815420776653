import React, { useState } from 'react'
import FormatColorTextIcon from '@mui/icons-material/FormatColorText'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import BackupTableSharpIcon from '@mui/icons-material/BackupTableSharp'
import FolderIcon from '@mui/icons-material/Folder'
import makeStyles from '@mui/styles/makeStyles'
import TreeView, { flattenTree } from 'react-accessible-treeview'
import 'components/extended/Tree/styles.css'

const useStyles = makeStyles({
  treeNode: {
    paddingLeft: level => 20 * (level - 1),
    marginTop: 10,
    marginBottom: 10,
    cursor: 'pointer',
  },
})

export default ({ data, selectedIds, defaultExpandedIds, handleOnTreeNodeSelect, parentMap, setDocumentBreadCrumb }) => {
  
  const getBreadcrumbs = (parentMap, element) => {
    const breadCrumbArray = []
    if (parentMap && parentMap.parent) {
      const { parent, nodeName } = parentMap
      const currentSelectedName = element.name
      const parentId = element.parent
      breadCrumbArray.push(currentSelectedName)
      getHierarchyIds(parent, nodeName, parentId, breadCrumbArray)
    }
    return breadCrumbArray.reverse()
  }
  
  const getHierarchyIds = (parent, nodeName, id, breadCrumbArray) => {
    breadCrumbArray.push(nodeName[id])
    if (parent[id]) {
      getHierarchyIds(parent, nodeName, parent[id], breadCrumbArray)
    }
  }
  return (
    <div>
      <div className='directory'>
        <TreeView
          data={flattenTree(data)}
          aria-label='directory tree'
          defaultExpandedIds={defaultExpandedIds ? defaultExpandedIds : []}
          selectedIds={selectedIds}
          propagateSelectUpwards={true}
          onNodeSelect={({ element }) => {
            const breadcrumbs = getBreadcrumbs(parentMap, element)
            if (setDocumentBreadCrumb) setDocumentBreadCrumb(breadcrumbs)
            handleOnTreeNodeSelect({ id: element.id })
          }}
          nodeRenderer={({ element, isBranch, isExpanded, getNodeProps, level }) => (
            <div {...getNodeProps()} className={useStyles(level).treeNode}>
              {isBranch ? (<SectionHeaderIcon isOpen={isExpanded} />) : (<SectionIcon filename={element.name} />)}
              {`${element.name === 'TextArea' ? '    Description' : (element.name === 'Table' ? '    Requirements' : (element.name === 'Image' ? '    Image' : (element.name === 'SystemBlockDiagram' ? '    Draw Sheet' : '    ' + element.name)))}`}
            </div>
          )}
        />
      </div>
    </div>
  )
}

const SectionHeaderIcon = ({ isOpen }) => (isOpen ? <FolderOpenIcon /> : <FolderIcon />)

const SectionIcon = ({ filename }) => {
  switch (filename) {
    case 'TextArea':
      return <FormatColorTextIcon />
    case 'Image':
      return <CloudUploadIcon />
    case 'Table':
      return <BackupTableSharpIcon />
    case 'SystemBlockDiagram':
      return <BackupTableSharpIcon />
    default:
      return null
  }
}
