export const readProjects = (projects, domains) => {
  const treeArray = []
  // create folder structure based on domain value
  const createDomainFolder = (domain) => {
    return {
      name: domain.value,
      id: domain.id,
      children: [],
    }
  }

  _.forEach(projects, (project) => {
    const typeSplit = project.type.split('.')
    let currentPath = ''
    let currentChildren = treeArray

    typeSplit.forEach((folder) => {
      currentPath += `${folder}/`
      const existingFolder = _.find(currentChildren, { name: folder })

      if(existingFolder) {
        currentChildren = existingFolder.children
      } else {
        const newFolder = {
          name: folder,
          id: `${folder}`,
          children: [],
          path: currentPath.slice(0, -1),
        }
        currentChildren.push(newFolder)
        currentChildren = newFolder.children
      }
    })

    currentChildren.push({
      name: project.name,
      id: `${project.id}`,
      children: null,
      path: currentPath + project.name,
    })
  })

  // Add domain folders if they don't exist
  _.forEach(domains, (domain) => {
    const domainFolder = createDomainFolder(domain)
    const existingDomainFolder = _.find(treeArray, { name: domain.value })

    if(!existingDomainFolder) {
      treeArray.push(domainFolder)
    }
  })

  return treeArray
}


export const readDomains = (domains) => {
  const treeArray = []
  _.forEach(domains, (domain) => {
    treeArray.push({
      name: domain.value,
      id: domain.id,
    })
  })
  return treeArray
}

export const mapProjectToDomain = (projects) => {
  const projectDomainMap = {}
  _.forEach(projects, (project) => {
    projectDomainMap[project.id] = project.type
  })
  return projectDomainMap
}
