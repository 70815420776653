import {
  Box,
  InputLabel,
  TextField,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import MDButton from 'components/core/MDButton'
import React, {
  useEffect,
  useState,
} from 'react'
import _ from 'lodash'

export default ({
  setPartContentAttributes,
  paramsDefAttributes,
  setParamsDefAttributes,
  selectedAddedUnitAttributes,
  setOptions,
  options,
  defaultValue,
  setDefaultValue,
  setName,
  name,
  setUnit,
  unit,
}) => {
  const [attrs, setAttrs] = useState([])
  const [defaultOption, setDefaultOption] = useState({})
  const [key, setKey] = useState('')

  useEffect(() => {
    const attribute = _.filter(paramsDefAttributes, (item) => item.key === selectedAddedUnitAttributes)
    if(!_.isEmpty(attribute) && attribute.length > 0) {
      const { key, label, unit, options, defaultValue } = attribute[0]
      setUnit(unit ? unit : '')
      setName(label ? label : '')
      setOptions(!_.isEmpty(options) ? options.toString() : '')
      setDefaultValue(defaultValue ? defaultValue : '')
    }
  }, [selectedAddedUnitAttributes])

  const addNew = () => {
    const tempParams = _.cloneDeep(paramsDefAttributes)
    const attribute = _.find(paramsDefAttributes, { label: name })
    if(_.isEmpty(attribute) && !_.isEmpty(name) && !_.find(paramsDefAttributes, { label: name })) {
      tempParams.push({
        label: name,
        key: name,
        options,
        defaultValue,
        unit,
      })
      setPartContentAttributes({ paramsDefAttributes: [...tempParams] })
      setParamsDefAttributes([...tempParams])
    } else if(!_.isEmpty(attribute)) {
      const attrs = _.filter(paramsDefAttributes, (item) => item.label !== name)
      attrs.push({
        label: name,
        key: name,
        options,
        defaultValue,
        unit,
      })
      setPartContentAttributes({ paramsDefAttributes: [...attrs] })
      setParamsDefAttributes([...attrs])
    }
    setUnit('')
    setName('')
    setOptions('')
    setDefaultValue('')
  }

  return (

    <Box sx={{ width: '80%', paddingRight: 5, border: 'thin', borderColor: 'gray' }}>
      <InputLabel sx={{ textAlign: 'left', paddingBottom: '20px' }}>
        Hardware Unit Attributes
      </InputLabel>
      <table style={{ width: '100%', outline: 'auto' }}>
        <tbody>
        <tr style={{ textAlign: 'center' }}>
          <td><Typography style={{ fontSize: '15px', textAlign: 'left', paddingLeft: '10px' }}> Name </Typography></td>
          <td><TextField value={name} style={{ width: '100%' }} onChange={({ target }) =>
            setName(target.value)} /></td>
        </tr>
        <tr style={{ textAlign: 'center' }}>
          <td><Typography style={{ fontSize: '15px', textAlign: 'left', paddingLeft: '10px' }}> Unit </Typography></td>
          <td><TextField value={unit} onChange={({ target }) =>
            setUnit(target.value)} style={{ width: '100%' }} /></td>
        </tr>
        <tr style={{ textAlign: 'center' }}>
          <td><Typography style={{ fontSize: '15px', textAlign: 'left', paddingLeft: '10px' }}> Options </Typography>
          </td>
          <td><TextField value={options} onChange={({ target }) =>
            setOptions(_.split(target.value, ','))} style={{ width: '100%' }} /></td>
        </tr>
        <tr style={{ textAlign: 'center' }}>
          <td><Typography style={{ fontSize: '15px', textAlign: 'left', paddingLeft: '10px' }}> Default
            Value </Typography></td>
          <td><TextField onChange={({ target }) =>
            setDefaultValue(target.value)} value={defaultValue} style={{ width: '100%' }} /></td>
        </tr>
        </tbody>
      </table>
      {_.isEmpty(key) &&
        <MDButton style={{ marginTop: '10px', alignText: 'right' }}
                  onClick={addNew}
                  size={'small'} variant={'outlined'} color={'black'}>Add New</MDButton>
      }
    </Box>
  )
}