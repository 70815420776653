import {
  useEffect,
  useState,
} from 'react'
import {
  Link,
  useNavigate,
} from 'react-router-dom'
import Card from '@mui/material/Card'
import Switch from '@mui/material/Switch'
import MDBox from 'components/core/MDBox'
import MDTypography from 'components/core/MDTypography'
import MDInput from 'components/core/MDInput'
import MDButton from 'components/core/MDButton'
import BasicLayout from 'layouts/authentication/components/BasicLayout'
import bgImage from 'assets/images/bg-sign-in-basic.jpeg'
import {
  getUser,
  loginUser,
} from '../store/saga'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { loginSelector } from '../store/userSlice'
import boschLogo from '../../../assets/images/bosch_logo.png'

function Basic () {
  const [rememberMe, setRememberMe] = useState(true)
  const navigate = useNavigate()
  const [userName, setUserName] = useState('')
  const [passWord, setPassWord] = useState('')

  const handleSetRememberMe = () => setRememberMe(!rememberMe)
  const dispatch = useDispatch()
  const user = useSelector(loginSelector)
  useEffect(() => {
    if(user.user_id) {
      navigate('/dashboard')
    } else {
      dispatch(getUser())
    }
  }, [user])
  return (
    <BasicLayout image={bgImage}>
     
      <Card >
        <img style={{marginTop : 10}} width={350} src={boschLogo} />
        <MDBox
          variant='gradient'
          bgColor='info'
          borderRadius='lg'
          coloredShadow='info'
          mx={2}
          mt={4}
          p={2}
          mb={1}
          textAlign='center'
        >
          <MDTypography variant='h4' fontWeight='medium' color='white' mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component='form' role='form'>
            <MDBox mb={2}>
              <MDInput type='text' label='UserName' fullWidth value={userName}
                       onKeyPress={(e) => {
                         if (e.key === "Enter") {
                           dispatch(loginUser({ username: userName, password: passWord }))
                         }
                       }}
                       onChange={(evt) => {setUserName(evt.target.value)}} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type='password' label='Password' fullWidth
                       value={passWord}
                       onKeyPress={(e) => {
                         if (e.key === "Enter") {
                           dispatch(loginUser({ username: userName, password: passWord }))
                         }
                       }}
                       onChange={(evt) => {setPassWord(evt.target.value)}} />
            </MDBox>
            <MDBox display='flex' alignItems='center' ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant='button'
                fontWeight='regular'
                color='text'
                onClick={handleSetRememberMe}
                sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant='gradient'
                color='info'
                fullWidth
                onClick={() => {
                  dispatch(loginUser({ username: userName, password: passWord }))
                }}
              >
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign='center'>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  )
}

export default Basic
