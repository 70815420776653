/*
 *  Copyright (C) 2007 Free Software Foundation, Inc. <https://fsf.org/>
 *  This file (index.js) is part of LiteFarm.
 *
 *  LiteFarm is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  LiteFarm is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 *  GNU General Public License for more details, see <https://www.gnu.org/licenses/>.
 */

import { combineReducers } from 'redux'
import baseReducer from '../layouts/baseReducer'
import userReducer from '../layouts/authentication/store/userSlice'
import mainReducer from '../layouts/dashboard/store/mainSlice'
import documentReducer from '../layouts/document-management/document-store/documentSlice'
import subSystemReducer from 'layouts/document-management/subsystem-store/subSystemSlice'
import wizardReducer from 'layouts/admin/document-template-flow/Wizard/store/wizardSlice'
import { PURGE } from 'redux-persist'

const layoutsReducer = combineReducers({
  baseReducer,
  userReducer,
  mainReducer,
  documentReducer,
  subSystemReducer,
  wizardReducer,
})

// combine all reducers here and pass it to application
const appReducer = combineReducers({
  layoutsReducer,
})

const rootReducer = (state, action) => {

  if(action.type === PURGE) {
    // clear redux state
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
