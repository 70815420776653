import React, {
  useEffect,
  useState,
} from 'react'
import MDButton from '../../components/core/MDButton'
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux'
import { loginSelector } from '../authentication/store/userSlice'
import { documentSelector } from '../document-management/document-store/documentSlice'
import Select from 'react-select'

const baseUri = process.env.REACT_APP_API_BASE

export default ({ documentId }) => {
  const [url, setUrl] = useState('')
  const [loading, setLoading] = useState(true)
  const [docOptions, setDocOptions] = useState()
  const [docLoaded, setDocLoaded] = useState(false)
  const user = useSelector(loginSelector)
  const mainDoc = useSelector(documentSelector)
  const buildSpecificDocs = (sections) => {
    const base = `${baseUri}/dms/download-document?docId=${documentId}`
    let sysOverviewUrl = `${base}&secIds=`, testingUrl = `${base}&secIds=`, softwareUrl = `${base}&secIds=`
    _.map(sections, section => {
      if (section.title === 'System Overview') {
        sysOverviewUrl = sysOverviewUrl + section.id + ','
      }
      if (section.title === 'Assumptions') {
        sysOverviewUrl = sysOverviewUrl + section.id + ','
        testingUrl = testingUrl + section.id + ','
        softwareUrl = softwareUrl + section.id + ','
      }
      if (section.title === 'Risks') {
        sysOverviewUrl = sysOverviewUrl + section.id + ','
        testingUrl = testingUrl + section.id + ','
        softwareUrl = softwareUrl + section.id + ','
      }
      if (section.title === 'Software Requirements') {
        softwareUrl = softwareUrl + section.id + ','
      }
      if (section.title === 'Test Requirements') {
        testingUrl = testingUrl + section.id + ','
      }
      if (section.title === 'Acceptance Tests') {
        testingUrl = testingUrl + section.id + ','
      }
    })
    setDocOptions([
      { value: `${sysOverviewUrl}&type=hw`, label: 'System Hardware Document' },
      { value: `${testingUrl}&type=test`, label: 'Testing Document' },
      { value: `${softwareUrl}&type=sw`, label: 'Software Specification Document' },
    
    ])
    
  }
  
  useEffect(() => {
    const timeSpan = new Date().getTime()
    buildSpecificDocs(mainDoc.parts.sections)
    setUrl(`${baseUri}/dms/download-document?docId=${documentId}&tspan=${timeSpan}&validId=${user.session_id}__${user.user_id}`)
    window.__loading_frame = false
    const timeRef = setInterval(() => {
      if (!window.__loading_frame) {
        const timeSpan = new Date().getTime()
        setUrl(`${baseUri}/dms/download-document?docId=${documentId}&tspan=${timeSpan}&validId=${user.session_id}__${user.user_id}`)
      } else {
        clearInterval(timeRef)
        setLoading(false)
      }
    }, 10000)
  }, [documentId])
  return (
    <>
      <a
        href={url}
        download='Requirement-document.docx'
        target='_blank'
        rel='noreferrer'
      >
        <MDButton color='dark' variant='outlined' sx={{ marginRight: '40px', marginBottom: '10px' }}>Download</MDButton>
      </a>
      <Select
        placeholder={'Download Specific Document'}
        options={docOptions}
        onChange={ev => {
          window.open(ev.value, '_blank', 'rel=noreferrer')
        }
        }
      />
      {loading && <Typography>Loading... Please Wait..</Typography>}
      
      <iframe
        id='id12321'
        title='Docx'
        onLoad={() => {
          parent.__loading_frame = true
        }}
        width='100%'
        height='800'
        frameBorder='0'
        src={`https://docs.google.com/viewer?url=${url}&embedded=true`}
      />
    
    </>
  )
}
