import React, {
  Fragment,
  useEffect,
  useState,
} from 'react'
import steps from './wizardPages'
import Stepper from './Stepper'
import {
  Box,
  Stack,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import {
  documentTypeValidation,
  sectionsValidation,
} from 'layouts/admin/document-template-flow/Wizard/validations'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { wizardSelector } from 'layouts/admin/document-template-flow/Wizard/store/wizardSlice'
import { createDocumentTemplate } from 'layouts/admin/document-template-flow/Wizard/store/saga'
import { useNavigate } from 'react-router-dom'
import {
  getPartsSuccess,
  getPartSuccess,
} from 'layouts/document-management/document-store/documentSlice'
import { getDocumentSuccess } from 'layouts/dashboard/store/mainSlice'
import DocumentPath from 'components/extended/DocumentPath'
import { mainSelector } from 'layouts/dashboard/store/mainSlice'

const useStyles = makeStyles(({}) => ({
  container: {
    height: '300px',
    width: '100%',
    justifyContent: 'center',
  },
  wizard: {
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    paddingTop: '10px',
  },
  wizardFooter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

function Wizard ({ status, onFinish }) {
  const dashData = useSelector(mainSelector)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const docTemplateFlow = useSelector(wizardSelector)
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [previousResult, setPreviousResult] = useState()
  
  const stepConfig = steps && steps[activeStep]
  const isStepOptional = (step) => {
    return step === 1
  }
  
  const isStepSkipped = (step) => {
    return skipped.has(step)
  }
  
  const handleNext = () => {
    if (activeStep === 0) {
      dispatch(getPartsSuccess(undefined))
      dispatch(getPartSuccess(undefined))
      dispatch(getDocumentSuccess(undefined))
      if (!documentTypeValidation(docTemplateFlow.templateWorkflow)) {
        return
      }
    }
    if (activeStep === 1) {
      if (!sectionsValidation(docTemplateFlow.templateWorkflow, docTemplateFlow.sectionTreeData)) {
        return
      } else {
        const { document, sections, hardwareTestsDefs } = docTemplateFlow.templateWorkflow
        const data = {}
        data['type'] = document.documentType.value
        data['domain'] = document.domain.value
        data['subdomain'] = document.subdomain.value
        data['sections'] = sections
        data['hardwareTestsDefs'] = hardwareTestsDefs
        dispatch(createDocumentTemplate({ data }))
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  
  const handleBack = () => {
    if (activeStep === 0) {
      navigate('/administration')
      return
    }
    if (activeStep === 2) {
      dispatch(getPartsSuccess(undefined))
      dispatch(getPartSuccess(undefined))
      dispatch(getDocumentSuccess(undefined))
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  
  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error('You can\'t skip a step that isn\'t optional.')
    }
    
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }
  
  const handleCancel = () => {
    navigate('/administration')
  }
  
  const stepUp = step => {
    let isInitialized = true, stepIndex = step
    while (stepIndex < steps.length && isInitialized === true) {
      if (!status[steps[stepIndex].id]) {
        isInitialized = false
      } else {
        stepIndex++
      }
    }
    setActiveStep(stepIndex)
    if (stepIndex === steps.length) {
      onFinish()
    }
  }
  useEffect(() => {
    stepUp(activeStep)
  }, [])
  const handleStepComplete = (completionResult = {}) => {
    setPreviousResult(completionResult.result)
    stepUp(activeStep + 1)
  }
  return (
    <div>
      {!_.isEmpty(docTemplateFlow.templateWorkflow.document) && (<DocumentPath
        domain={docTemplateFlow.templateWorkflow.document.domain ? docTemplateFlow.templateWorkflow.document.domain.value : ''}
        subdomain={docTemplateFlow.templateWorkflow.document.subdomain ? docTemplateFlow.templateWorkflow.document.subdomain.value : ''}
        project={!_.isEmpty(dashData.selected_project) ? dashData.selected_project.name : ''}
        documentName={docTemplateFlow.templateWorkflow.document.documentType ? docTemplateFlow.templateWorkflow.document.documentType.value : ''}
      />)}
      <Stack direction='column'>
        <Stack direction='column' sx={{ justifyContent: 'center' }} spacing={2}>
          <Stepper steps={steps} activeStep={activeStep} className={classes.wizard} />
          <Box sx={{ flexGrow: 1, overflowY: 'auto', height: '100%', paddingTop: '3%' }} mx={1}>
            {stepConfig && !!stepConfig.component && (
              <stepConfig.component
                onStepComplete={handleStepComplete} previousResult={previousResult} handleNext={handleNext}
              />)}
          </Box>
        </Stack>
        
        <Stack direction='row' sx={{ justifyContent: 'right' }} spacing={2}>
          <Fragment>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                onClick={handleBack}
                sx={{ mr: 1 }}
                variant='outlined'
                size={'large'}
                component='label' style={{ color: 'black' }}
                color={'secondary'}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              
              <Button
                onClick={docTemplateFlow.wizardType === 'view'
                && activeStep === steps.length - 2
                || activeStep === steps.length - 1
                  ? handleCancel
                  : handleNext}
                variant='outlined'
                size={'large'}
                component='label'
                style={{ color: 'black' }}
                color={'secondary'}
              >
                {docTemplateFlow.wizardType === 'view'
                && activeStep === steps.length - 2
                || activeStep === steps.length - 1
                  ? 'Cancel'
                  : 'Next'}
              </Button>
            </Box>
          </Fragment>
        </Stack>
      </Stack>
    </div>
  
  )
}

export default Wizard