import React, {
  memo,
  useEffect,
  useState,
} from 'react'
import { Handle } from 'reactflow'
import { CloseCircleOutlined } from '@ant-design/icons'
import store from 'utils/local-storage'
import MDTypography from 'components/core/MDTypography'
import {
  checkOfMandatoryFields,
  validateProps,
} from 'components/extended/HardwareSetup/validator'

export default memo(({ data, isConnectable }) => {
  const [showingProps, setShowingProps] = useState(false)
  const [actionObject, setActionObject] = useState()

  useEffect(() => {
    async function fetchData () {
      const actions = store.get('WORKFLOW_ACTIONS')
      setActionObject(_.find(actions, { actionId: data.type }))
    }

    fetchData()
  }, [])

  const handleToggle = () => {
    if(showingProps) {
      const requiredFields = validateProps(data)
      if(_.isEmpty(requiredFields)) {
        data.setSelectedNode()
        setShowingProps(false)
      }
      checkOfMandatoryFields(requiredFields, data)
    } else {
      data.setSelectedNode(data)
      setShowingProps(true)
    }
  }

  return (actionObject && (
    <div style={{ minHeight: 100 , minWidth : 140 }}>
      <Handle
        type='target'
        position='left'
        style={{ background: '#555', zIndex: 2000, width: '5px', height: '5px' }}
        isConnectable={isConnectable}
      />
      <MDTypography
        variant='body1'
        align='center'
        onClick={handleToggle}
        style={{ cursor: 'pointer', fontSize: '12px' }}
      >
        {data.executionInfo.headerText || actionObject.displayName}
      </MDTypography>

      <Handle
        type='source'
        position='right'
        style={{ background: '#555', width: '5px', height: '5px' }}
        isConnectable={isConnectable}
      />
      {data.hideDelete && <div style={{ position: 'relative', top: '90px', left: '-90px' }}>
        <CloseCircleOutlined onClick={(event) => {
          event.preventDefault()
          data.onDelete(data.nodeId)
        }} />
      </div>}
    </div>
  ) || <></>)
})
