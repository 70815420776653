import React, {
  memo, useEffect, useState,
} from 'react'
import { Handle } from 'reactflow'
import { CloseCircleOutlined } from '@ant-design/icons'
import MDTypography from 'components/core/MDTypography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import MDButton from '../../../../core/MDButton'
import TreeView from '@mui/lab/TreeView'
import TreeItem from '@mui/lab/TreeItem'
import Button from '@mui/material/Button'
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import _ from 'lodash'
import { getDocument } from '../../../../../layouts/document-management/document-store/saga'

export default memo(({ data, isConnectable }) => {
  const [showSpecs, setShowSpecs] = useState(false)
  const [expanded, setExpanded] = useState([])
  const handleCancel = () => {
    setShowSpecs(false)
  }
  const handleSelect = (node , nodeId) => {
    const id = _.first(nodeId.split("_"))
    
    data.handleOnTreeNodeSelect({ id
      , sectionId: nodeId.split("_").length > 1 ? _.last(nodeId.split("_")) : ''  })
    setShowSpecs(false)
  }
  useEffect(() => {
    const exp = []
    _.map(data.subSystemMap, entry => {
      exp.push(entry.id)
      _.map(entry.requirementSections, req => exp.push(entry.id +'_' + req))
    })
    setExpanded(exp)
  }, [])
  
  return (
    <div style={{ minHeight: 40, minWidth: 70 }}>
      <Handle
        type='source'
        position='left'
        style={{ background: '#555', zIndex: 2000, width: '5px', height: '5px' }}
        isConnectable={isConnectable}
      />
      <MDTypography
        variant='body1'
        align='center'
        style={{ fontSize: '12px' }}>
        {data.executionInfo.headerText}
      </MDTypography>
      <Handle
        type='target'
        position='right'
        style={{ background: '#555', width: '5px', height: '5px' }}
        isConnectable={isConnectable}
      />
      {data.hideDelete && <div style={{ position: 'relative', top: '50px', left: '50px' }}>
        <CloseCircleOutlined onClick={(event) => {
          event.preventDefault()
          data.onDelete(data.nodeId)
        }} />
      </div>}
      {data.hideDelete && <div style={{ position: 'relative', top: '-10px' }}>
        <Button variant={'outlined'} size={'small'} style={{ color: 'black' }} onClick={(event) => {
          event.preventDefault()
          setShowSpecs(true)
        }}>Specs</Button>
      </div>}
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth='xs'
        open={showSpecs}
      >
        <DialogTitle>Specifications</DialogTitle>
        <DialogContent dividers>
          <TreeView
            aria-label='controlled'
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            onNodeSelect={handleSelect}
          >
            {_.map(data.subSystemMap, entry => (
              <TreeItem nodeId={entry.id} label={entry.title}>
                {_.map(entry.requirementSections, req => (
                  <TreeItem nodeId={entry.id +'_' + req} label={req} />
                ))
                }
              </TreeItem>
            ))
            }
          </TreeView>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
})
