import React, { useState } from 'react'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import NodePalette from './NodePalette'
import { SYSTEM_BLOCK_SECTION } from '../../../../utils/util'
import MDButton from '../../../core/MDButton'
import Button from '@mui/material/Button'
import makeStyles from '@mui/styles/makeStyles'
import _ from 'lodash'

const useStyles = makeStyles(({ spacing }) => ({
  addButton: {
    fontFamily: 'sans-serif',
    border: '1px solid #1a192b',
    color: '#1a192b',
    margin: '10px',
    '&:hover': {
      border: '1px solid #1a192b',
    },
  },
}))

export default ({
  type,
  subSystems,
  setNodeToDelete,
  onCreateSubSystemNode,
  actionSet,
  onDeleteSubSystemNode,
}) => {
  const classes = useStyles()
  const [dialogTitle, setDialogTitle] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [subSystemName, setSubSystemName] = useState('')
  const [isDelete, setIsDelete] = useState(false)

  const handleCancel = () => {
    setDialogOpen(false)
  }
  const handleFinalRemoval = () => {
    setIsDelete(false)
    setDialogOpen(false)
    setNodeToDelete(subSystemName)
    onDeleteSubSystemNode(subSystemName)
    setDialogOpen(false)
  }

  const handleOk = () => {
    setDialogOpen(false)
    if(_.isEmpty(subSystemName)) return
    !isDelete ? onCreateSubSystemNode({
      subSystem: subSystemName,
      onSuccess: () => {setDialogOpen(false)},
    }) : handleFinalRemoval()
  }

  const handleChange = (event) => {
    setSubSystemName(event.target.value)
  }
  const handleDelete = (subSystem) => {
    setSubSystemName(subSystem)
    setIsDelete(true)
    setDialogTitle('Delete SubSystem')
    setDialogOpen(true)
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='stretch'
      height='100%'
      border={`3px solid`}
      borderColor={'black'}
    >
      {type === SYSTEM_BLOCK_SECTION
        && <MDButton onClick={() => {
          setDialogTitle('Create SubSystem')
          setDialogOpen(true)
        }} className={classes.addButton}> Add Subsystem</MDButton>
      }
      <NodePalette subSystems={subSystems} actionSet={actionSet} onDeleteSubSystemNode={handleDelete} />
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth='xs'
        open={dialogOpen}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent dividers>
          {!isDelete ? <TextField fullWidth helperText={'SubSystem Name'} onChange={handleChange} />
            : <Typography>Are you sure to delete {subSystemName} ? </Typography>}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleOk}>Ok</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
