import { Stack } from '@mui/material'
import DescriptionPartDefinition
  from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/PartSelection/DescriptionPartDefinition'
import ImagePartDefinition
  from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/PartSelection/ImagePartDefinition'
import TablePartDefinition
  from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/PartSelection/TablePartDefinition'
import * as React from 'react'
import SystemBlockDiagramPartDefinition
  from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/PartSelection/SystemBlockDiagramPartDefinition'

export default ({
  availableFields,
  availableLookups,
  setAvailableLookups,
  lookupName,
  setLookupName,
  partSelected,
  partSelectedType,
  partContent,
  setPartContent,
  tableLookupKeyValueContent,
  setTableLookupKeyValueContent,
  dependFieldsLookupKeyValueContent,
  setDependFieldsLookupKeyValueContent,
  inputSectionKeyValueContent,
  setInputSectionKeyValueContent,
  tableColumnDependentsMap,
  tableDef,
  setTableDef,
  paramsDef,
  setParamsDef,
  partObjectSelected,
  setContent,
  content,
  setNewContent,
  newContent,
}) => {
  return (
    <Stack direction='column' sx={{ justifyContent: 'left', paddingTop: 5, paddingLeft: '5px', paddingRight: '5px' }}
           spacing={4}>
      {
        partSelectedType === 'text' && (
          <DescriptionPartDefinition partContent={partContent} setPartContent={setPartContent}
                                     partObjectSelected={partObjectSelected} setContent={setContent}
                                     content={content} setNewContent={setNewContent}
                                     newContent={newContent}/>
        )
      }
      {
        partSelectedType === 'images' && (
          <ImagePartDefinition partContent={partContent} setPartContent={setPartContent}
                               partObjectSelected={partObjectSelected} setContent={setContent}
                               content={content} setNewContent={setNewContent}
                               newContent={newContent}/>
        )
      }
      {
        partSelectedType === 'table' && (
          <TablePartDefinition availableFields={availableFields} availableLookups={availableLookups}
                               setAvailableLookups={setAvailableLookups}
                               dependFieldsLookupKeyValueContent={dependFieldsLookupKeyValueContent}
                               setDependFieldsLookupKeyValueContent={setDependFieldsLookupKeyValueContent}
                               lookupName={lookupName} partContent={partContent} setPartContent={setPartContent}
                               setLookupName={setLookupName}
                               tableLookupKeyValueContent={tableLookupKeyValueContent}
                               setTableLookupKeyValueContent={setTableLookupKeyValueContent}
                               tableColumnDependentsMap={tableColumnDependentsMap}
                               tableDef={tableDef}
                               setTableDef={setTableDef}
                               partContent={partContent}
                               setContent={setContent}
                               content={content}
                               setNewContent={setNewContent}
                               newContent={newContent}
                               partObjectSelected={partObjectSelected}
          />
        )
      }
      {
        partSelectedType === 'subSystemBlockDiagram' && (
          <SystemBlockDiagramPartDefinition
            partContent={partContent}
            setPartContent={setPartContent}
            paramsDef={paramsDef}
            setParamsDef={setParamsDef}
            partObjectSelected={partObjectSelected}
            setContent={setContent}
            content={content}
            setNewContent={setNewContent}
            newContent={newContent}
          />
        )
      }
    </Stack>
  )
}