import React, {
  useEffect,
  useState,
} from 'react'
import _ from 'lodash'
import { useFormik } from 'formik'
import * as yup from 'yup'
import MDButton from 'components/core/MDButton'
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from '@mui/material'
import { loginSelector } from '../../authentication/store/userSlice'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {
  projectDomains,
  projectSubDomains,
} from './constants/project-domain'
import { projectFields } from './constants/project-fields'
import { createProject } from '../../dashboard/store/saga'
import { useNavigate } from 'react-router-dom'
import CircularProgressStyled from 'components/extended/progress/CircularProgressStyled'
import { mainSelector } from 'layouts/dashboard/store/mainSlice'

const validationSchema = yup.object({
  name: yup
    .string('Enter Project Name')
    .required('Project Name is required'),
  type: yup
    .string()
    .required(),
  manager: yup.string('Enter Project Manager Email').email('Email is not valid')
    .required('Project Manager Email is required'),
  testLead: yup.string('Enter Test Lead Email').email('Email is not valid')
    .required('Test Lead Email is required'),
  devLead: yup.string('Enter Dev Lead Email').email('Email is not valid')
    .required('Dev Lead Email is required'),
  tags: yup.string('Enter Tags')
    .required('Tags are required'),

})

export default ({}) => {
  const animatedComponents = makeAnimated()
  const [subDomainList, setSubDomainList] = useState([])
  const [subDomain, setSubDomain] = useState()
  const [domain, setDomain] = useState()
  const TYPE = 'type'
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector(loginSelector)
  const dashData = useSelector(mainSelector)
  const indicatorSize = 80
  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    isValid,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: {
      name: '',
      type: '',
      manager: '',
      testLead: '',
      devLead: '',
      tags: '',
    },
    onSubmit: async (values) => {
      handleSave(values)
    },
    validationSchema: validationSchema,
  })

  useEffect(() => {
    if(!subDomain || !domain) return
    setFieldValue(TYPE, `${domain}.${subDomain}`)
  }, [domain, subDomain])

  const handleSave = (data) => {
    dispatch(createProject({ data, navigate }))
    return true
  }

  return (
    <div style={{ background: '#fefefe' }}>

      <Grid container style={{ width: '60%', margin: 'auto' }}>
        <Card>
          <CardHeader title={'Add Project'} />
          <CardContent>
            <CircularProgressStyled
              size={indicatorSize}
              sx={{
                display: dashData.loading ? 'block' : 'none',
                position: 'relative',
                top: '30%',
                left: '50%',
                zIndex: '9999',
                marginTop: `${-indicatorSize/2}px`,
                marginLeft: `${-indicatorSize/2}px`,
                background: 'transparent',
                color: 'gray',
              }}
            />
          </CardContent>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Select
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 100,
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    marginBottom: 10,
                  }),
                }}
                name='Project Domain'
                placeholder='Select Project Domain'
                components={animatedComponents}
                options={projectDomains}
                onChange={(option) => {
                  setSubDomainList(projectSubDomains[option.value])
                  setDomain(option.value)
                }}
              />
              <Select
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 100,
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    marginBottom: 10,
                  }),
                }}
                name='Project Type'
                placeholder='Select Project Type'
                components={animatedComponents}
                options={subDomainList}
                onChange={(option) => {
                  setSubDomain(option.value)
                }}
              />
              {_.map(projectFields, (field) =>
                <TextField
                  key={field.name}
                  sx={{ mb: 2 }}
                  fullWidth
                  id={field.name}
                  name={field.name}
                  label={field.label}
                  value={values[field.name]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched[field.name] && Boolean(errors[field.name])}
                  helperText={touched[field.name] && errors[field.name]}
                />,
              )}

              <MDButton color='primary' disabled={_.isEmpty(touched) || !isValid} variant='contained' fullWidth
                        type='submit'>
                Submit
              </MDButton>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </div>
  )
}

