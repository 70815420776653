import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

export function onLoadingStart (state) {
  state.loading = true
}

export function onLoadingFail (state, { payload: error }) {
  state.loading = false
  state.error = error
  state.loaded = true
}

export function onLoadingSuccess (state) {
  state.loading = false
  state.error = null
  state.loaded = true
}

export const initialState = {
  loading: false,
  error: null,
  loaded: true,
  selected_project: undefined,
  projects: undefined,
  selected_document: undefined,
  documents: undefined,
  selected_section: 'SystemBlockDiagram',
  projectToDomainMap: {}
}

const addProjects = (state, { payload: projects }) => {
  state.loading = false
  state.error = null
  state.projects = projects
}

const addProject = (state, { payload: project }) => {
  state.loading = false
  state.error = null
  state.selected_project = project
}

const addProjectToDomainMap = (state, { payload: projectToDomainMap }) => {
  state.loading = false
  state.error = null
  state.projectToDomainMap = projectToDomainMap
}

const addDocuments = (state, { payload: { documents, projectId } }) => {
  state.loading = false
  state.error = null
  state.documents = state.documents || {}
  state.documents[projectId] = documents
}
const addDocument = (state, { payload: document }) => {
  state.loading = false
  state.error = null
  state.selected_document = document
}
const addSection = (state, { payload: section }) => {
  state.loading = false
  state.error = null
  state.selected_section = section
}

const cleanUp = (state) => {
    state.loading =  false
    state.error = null
    state.loaded = true
    state.selected_project = undefined
    state.projects = undefined
    state.selected_document = undefined
    state.documents = undefined
    state.selected_section = 'SystemBlockDiagram'
    state.projectToDomainMap = {}
}

const mainSlice = createSlice({
  name: 'mainReducer',
  initialState,
  reducers: {
    onLoadingUserStart: onLoadingStart,
    onLoadingUserEnd: (state) => {
      state.loading = false
      state.error = null
    },
    onLoadingUserFail: onLoadingFail,
    getProjectsSuccess: addProjects,
    getProjectSuccess: addProject,
    getProjectToDomainMapSuccess: addProjectToDomainMap,
    createProjectSuccess: (state, { payload: project }) => {
      state.loading = false
      state.error = null
      state.projects = state.projects || []
      state.projects.push(project)
    },
    createDocumentSuccess: (state, { payload: { document, projectId } }) => {
      state.loading = false
      state.error = null
      state.documents = state.documents || {}
      state.documents[projectId] = state.documents[projectId] || []
      state.documents[projectId].push(document)
    },
    getDocumentsSuccess: addDocuments,
    getDocumentSuccess: addDocument,
    getSectionSuccess: addSection,
    cleanUpDocs : cleanUp,
  },
})

export const {
  onLoadingUserStart,
  onLoadingUserEnd,
  onLoadingUserFail,
  createProjectSuccess,
  createDocumentSuccess,
  getProjectsSuccess,
  getProjectSuccess,
  getDocumentsSuccess,
  getDocumentSuccess,
  getSectionSuccess,
  getProjectToDomainMapSuccess,
  cleanUpDocs,

} = mainSlice.actions
export default mainSlice.reducer

export const mainReducerSelector = (state) => state.layoutsReducer[mainSlice.name]
export const mainSelector = createSelector(mainReducerSelector,
  ({ loading, error, documents, projects, selected_document, selected_section, selected_project, projectToDomainMap }) => ({
    loading, error, documents, projects, selected_document, selected_section, selected_project, projectToDomainMap,
  }))
