import React, { useEffect, useState } from 'react'
import MDTypography from 'components/core/MDTypography'
import {
  TextareaAutosize,
  TextField,
} from '@mui/material'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { documentSelector } from 'layouts/document-management/document-store/documentSlice'
import MDBox from 'components/core/MDBox'
import Suggestion from '../Suggestion'

export default ({
  content,
  title,
  newContent,
  setNewContent,
  isSuggestion,
  selectedChip,
  suggestions,
  setSuggestions,
  setDirtyFlag
}) => {
  const mainDoc = useSelector(documentSelector)
  const [data, setData] = useState('')
  const [textData, setTextData] = useState('')

  useEffect(() => {
    if(!content) return
    setTextData(content)
    setNewContent(content)
  }, [content])

  useEffect(() => {
    if(!data) return
    setTextData(data)
    setNewContent(data)
  }, [data])

  useEffect(() => {
    if(!_.isEmpty(mainDoc.selected_search_part)) {
      const documentPart = mainDoc.selected_search_part
      const sectionToDisplay = documentPart
      const { text, type } = sectionToDisplay || ''
      if(type === 'text') {
        if(data === text) {
          setData('')
          setTimeout(() => {
            setData(text)
          }, 10)
          return
        }
        setData(text)
      }
    }
  }, [mainDoc.selected_search_part])
  const onChange = (e) => {
    if(!e) return
    setDirtyFlag(true)
    setTextData(e.target.value)
    setNewContent(e.target.value)
  }

  return (
    <div>
      <MDTypography p={ 2 } variant={ 'h6' }>
        { title }
      </MDTypography>
      <MDBox display='flex'>
        <TextareaAutosize
          minRows={ 20 }
          style={ {
            width: '100%',
            resize: 'none',
            margin: 10,
            padding: 10,
            fontFamily: 'sans-serif',
            fontSize: 15
          } }
          placeholder='Add Description'
          onChange={ onChange }
          value={ textData }
        />

        { (isSuggestion && !_.isEmpty(suggestions)) && <MDBox
          sx={ {
            width: '20%',
            resize: 'none',
            fontFamily: 'sans-serif',
            fontSize: 15,
            border: 1,
            my: 1,
            mr: 1,
            padding: 2
          } }>
          <Suggestion
            isSuggestion={ isSuggestion }
            selectedChip={ selectedChip }
            suggestions={ suggestions }
            setSuggestions={ setSuggestions }
            sx={ {
              my: 1,
              py: 1,
              width: '100%',
              height: 'auto',
              justifyContent: 'space-between',
              '& .MuiChip-label': {
                display: 'block',
                whiteSpace: 'normal',
              },
            } }
          />
        </MDBox> }
      </MDBox>
      {/*<Editor
        apiKey='tm0c7fptqjdtuhuzzh4161raq2h2ao9bjshx91ul6cu5ing4'
        value={newContent ? newContent : ''}
        init={{
          branding: false,
          formats: 'text',
          height: 400,
          menubar: 'insert view format table tools help',
          plugins:
            'print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern',
          toolbar:
            'formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat',
          image_advtab: true,
        }}
        onEditorChange={(e) => onChange(e)}
      />*/}
    </div>
  )
}
