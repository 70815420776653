import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import _ from 'lodash'

export function onLoadingStart (state) {
  state.loading = true
}

export function onLoadingFail (state, { payload: error }) {
  state.loading = false
  state.error = error
  state.loaded = true
}

export function onLoadingSuccess (state) {
  state.loading = false
  state.error = null
  state.loaded = true
}

export const initialState = {
  loading: false,
  error: null,
  loaded: true,
  subSystems: [],
  subSystemMap: {},
}

const handleAddSubSystem = (state, { payload: { subSystemName } }) => {
  if(!state.subSystems.includes(subSystemName)) state.subSystems.push(subSystemName)
}

const handleAddSubSystems = (state, { payload: { subSystemNames } }) => {
  state.subSystems = subSystemNames
}

const handleAddSubSystemMap = (state, { payload: { subSystemMap } }) => {
 _.map(_.keys(subSystemMap) , key => {
   state.subSystemMap[key] = subSystemMap[key]
 })
}
const handleSubSystemRemove = (state, { payload: { subSystemName } }) => {
  state.subSystems = _.filter(state.subSystems , sub => sub !== subSystemName)
  delete state.subSystemMap[subSystemName]
}

const subSystemSlice = createSlice({
  name: 'subSystemReducer',
  initialState,
  reducers: {
    onLoadingUserStart: onLoadingStart,
    onLoadingUserEnd: (state) => {
      state.loading = false
      state.error = null
    },
    addSubSystem : handleAddSubSystem,
    addSubSystems : handleAddSubSystems,
    removeSubSystem : handleSubSystemRemove,
    addSubSystemMap : handleAddSubSystemMap,
    onLoadingUserFail: (state) => {
      state.error='ERROR'
    },
  },
})

export const {
  onLoadingUserStart,
  onLoadingUserEnd,
  addSubSystem,
  removeSubSystem,
  addSubSystems,
  onLoadingUserFail,
  addSubSystemMap,
} = subSystemSlice.actions
export default subSystemSlice.reducer

export const subSystemReducerSelector = (state) => state.layoutsReducer[subSystemSlice.name]
export const subSystemSelector = createSelector(subSystemReducerSelector,
  ({ loading, error, subSystems, subSystemMap  }) => ({
    loading, error, subSystems, subSystemMap
  }))
