import {
  Box, InputLabel,
  TextField,
} from '@mui/material'
import MDTypography from 'components/core/MDTypography'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Select from 'react-select'
import DependentFieldsLookUpValues
  from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/PartSelection/TablePartDefinition/DependentFields/DependentFieldsLookUpValues'
import MDButton from 'components/core/MDButton'
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp'
import React from 'react'
import OptionValues
  from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/PartSelection/SystemBlockDiagramPartDefinition/ParamsValue/OptionValues'
import uuid from 'react-uuid'
import _ from 'lodash'
import {
  useEffect,
  useState,
} from 'react'
import { getSubdomains } from '../../../../../store/saga'
import { updateTemplateWorkflowDocumentTypeSuccess } from '../../../../../store/wizardSlice'
import Button from '@mui/material/Button'

export default ({
  attributes,
  selectedAddedUnit,
  setPartContent,
  paramsDef,
  setParamsDef,
  setProcedureView,
}) => {
  const [attrs, setAttrs] = useState([])
  const [name, setName] = useState('')
  const [unit, setUnit] = useState('')
  const [defaultOption, setDefaultOption] = useState({})
  const [key, setKey] = useState('')
  const [options, setOptions] = useState([])
  const [defaultValue, setDefaultValue] = useState('')
  
  const handleUnitValueChange = (e) => {
  
  }
  
  const handleLabelValueChange = (e) => {
  
  }
  
  useEffect(() => {
    const selectOptions = _.map(attributes, (attr) => ({
      value: attr.key,
      label: attr.label,
    }))
    setAttrs(selectOptions)
    setDefaultOption({})
    setName('')
    setUnit('')
    setOptions([])
    setDefaultValue('')
    setKey('')
  }, [selectedAddedUnit])
  
  useEffect(() => {
    if (_.isEmpty(name) || _.isEmpty(key)) return
    const tempParams = _.cloneDeep(paramsDef)
    const item = _.find(tempParams, { actionId: selectedAddedUnit })
    const attribute = _.find(item.attributes, { key: key })
    attribute.label = name
    attribute.options = options
    attribute.defaultValue = defaultValue
    attribute.unit = unit
    setPartContent({ paramsDef: [...tempParams] })
    setParamsDef([...tempParams])
  }, [name, options, defaultValue, unit, key])
  
  const addNew = () => {
    const tempParams = _.cloneDeep(paramsDef)
    const item = _.find(tempParams, { actionId: selectedAddedUnit })
    if (!_.isEmpty(name) && !_.find(item.attributes, { label: name })) {
      item.attributes.push({
        label: name,
        key: name,
        options,
        defaultValue,
        unit,
      })
      const selectOptions = _.map(item.attributes, (attr) => ({
        value: attr.key,
        label: attr.label,
      }))
      setAttrs(selectOptions)
      setPartContent({ paramsDef: [...tempParams] })
      setParamsDef([...tempParams])
    }
  }
  
  return (
    
    <Box sx={{ width: '45%', paddingRight: 5, border: 'thin', borderColor: 'gray' }}>
      <InputLabel sx={{ textAlign: 'left', paddingBottom: '20px' }}>
        Hardware Unit Attributes
      </InputLabel>
      <Select
        styles={{
          menu: base => ({
            ...base,
            zIndex: 100,
          }),
          control: (provided, state) => ({
            ...provided,
            marginBottom: 10,
          }),
        }}
        options={attrs}
        name='Select Attribute'
        placeholder='Select Attribute'
        value={{ label: name, value: key }}
        onChange={(option) => {
          const entry = _.find(attributes, { key: option.value })
          setName(entry.label)
          setUnit(entry.unit)
          setOptions(entry.options)
          setDefaultValue(entry.defaultValue)
          setKey(entry.key)
        }}
      />
      <table style={{ width: '100%', outline: 'auto' }}>
        <tbody>
        <tr style={{ textAlign: 'center' }}>
          <td><Typography style={{ fontSize: '15px', textAlign: 'left', paddingLeft: '10px' }}> Name </Typography></td>
          <td><TextField value={name} style={{ width: '100%' }} onChange={({ target }) =>
            setName(target.value)} /></td>
        </tr>
        <tr style={{ textAlign: 'center' }}>
          <td><Typography style={{ fontSize: '15px', textAlign: 'left', paddingLeft: '10px' }}> Unit </Typography></td>
          <td><TextField value={unit} onChange={({ target }) =>
            setUnit(target.value)} style={{ width: '100%' }} /></td>
        </tr>
        <tr style={{ textAlign: 'center' }}>
          <td><Typography style={{ fontSize: '15px', textAlign: 'left', paddingLeft: '10px' }}> Options </Typography>
          </td>
          <td><TextField value={options} onChange={({ target }) =>
            setOptions(_.split(target.value, ','))} style={{ width: '100%' }} /></td>
        </tr>
        <tr style={{ textAlign: 'center' }}>
          <td><Typography style={{ fontSize: '15px', textAlign: 'left', paddingLeft: '10px' }}> Default
            Value </Typography></td>
          <td><TextField onChange={({ target }) =>
            setDefaultValue(target.value)} value={defaultValue} style={{ width: '100%' }} /></td>
        </tr>
        </tbody>
      </table>
      {_.isEmpty(key) &&
      <MDButton style={{ marginTop: '10px',  alignText: 'right' }}
                onClick={addNew}
                size={'small'} variant={'outlined'} color={'black'}>Add New</MDButton>
      }
      {setProcedureView && <MDButton style={{ marginTop: '10px',marginLeft : '10px', alignText: 'right' }}
                onClick={() => {setProcedureView(true)}}
                size={'small'} variant={'outlined'} color={'black'}>
        Test Procedures</MDButton> }
    </Box>
  )
}