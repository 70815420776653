import Select from 'react-select'
import Stack from '@mui/material/Stack'
import React, {
  useEffect,
  useState,
} from 'react'
import _ from 'lodash'
import {
  Box,
  Grid,
} from '@mui/material'
import MDTypography from 'components/core/MDTypography'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import DependentFieldsLookUpValues
  from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/PartSelection/TablePartDefinition/DependentFields/DependentFieldsLookUpValues'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { wizardSelector } from 'layouts/admin/document-template-flow/Wizard/store/wizardSlice'
import uuid from 'react-uuid'
import MDButton from 'components/core/MDButton'
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp'
import { getLookupByName } from 'layouts/admin/document-template-flow/Wizard/store/saga'

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    flexShrink: 0,
  },
  tabs: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}))

export default ({
  selectedField,
  title,
  partContent,
  setPartContent,
  dependAvailableFields,
  dependentOn,
  dependFieldsLookupKeyValueContent,
  setDependFieldsLookupKeyValueContent,
  tableColumnDependentsMap,
  availableLookups,
  availableLookupOption,
  setAvailableLookupOption,
  tableDef,
  setTableDef,
  setTypeOfPredifinedValues,
  typeOfPredifinedValues,
}) => {
  
  const classes = useStyles()
  
  const dispatch = useDispatch()
  const docTemplateFlow = useSelector(wizardSelector)
  
  const [rowsData, setRowsData] = useState([])
  const [dependFieldOptions, setDependFieldOptions] = useState([])
  const [dependFieldOption, setDependFieldOption] = useState()
  const [availableLookupOptions, setAvailableLookupOptions] = useState([])
  
  useEffect(() => {
    if (!_.isEmpty(dependFieldOption) && (dependFieldOption.value !== 'None')) {
      setTypeOfPredifinedValues('keyValue')
    } else {
      setTypeOfPredifinedValues('array')
    }
  }, [dependFieldOption])
  
  useEffect(() => {
    setDependFieldOption({ value: 'None', label: 'None' })
    setAvailableLookupOption({ value: 'None', label: 'Select Lookups' })
    // debugger
    if (tableDef && tableDef.columns) {
      _.forEach(tableDef.columns, column => {
        if (column.field === selectedField.id) {
          const predefinedValues = convertPredefinedValuesToRowsFormat(column.predefinedValues)
          setRowsData(predefinedValues)
          if (column.dependField) {
            const headerName = getColumnHeaderName(column.dependField)
            setDependFieldOption({ value: column.dependField, label: headerName })
          }
          
        }
      })
    } else {
      setRowsData([])
    }
  }, [selectedField])
  
  const getColumnHeaderName = (id) => {
    const headerArray = _.filter(tableDef.columns, column => column.field == id).map(column => column.headerName)
    return _.findLast(headerArray)
  }
  
  useEffect(() => {
    const availableLookupObjs = _.map(availableLookups, availableLookup => {
      return {
        label: availableLookup.name,
        value: availableLookup.name,
      }
    })
    setAvailableLookupOptions(availableLookupObjs)
  }, [availableLookups])
  
  const updateTableDefRows = (rows) => {
    let isKeyEmpty = true
    _.forEach(rows, row => {
      if (row?.key !== '') {
        isKeyEmpty = false
        
      }
    })
    if (isKeyEmpty) {
      setTypeOfPredifinedValues('array')
    } else {
      setTypeOfPredifinedValues('keyValue')
    }
    
    const tableDefObj = _.cloneDeep(tableDef)
    let foundColumn = false
    if (tableDefObj && tableDefObj.columns) {
      _.forEach(tableDefObj.columns, column => {
        if (column.field == selectedField.id) {
          const modRows = convertRowsToTablePredefinedValuesFormat(rows)
          column.predefinedValues = modRows
          foundColumn = true
          if (tableColumnDependentsMap && tableColumnDependentsMap[selectedField.id]) {
            const dependentFieldObj = tableColumnDependentsMap[selectedField.id]
            dependentFieldObj['predefinedValues'] = modRows
          }
          
        }
      })
    }
    setTableDef(tableDefObj)
    setPartContent({ table: (!_.isEmpty(partContent) ? partContent.table : {}), tableDef: tableDefObj })
  }
  
  const convertRowsToTablePredefinedValuesFormat = (rows) => {
    let isKeyEmpty = true
    _.forEach(rows, row => {
      if (row?.key !== '') {
        isKeyEmpty = false
      }
    })
    let modRows = []
    if (isKeyEmpty) {
      _.forEach(rows, row => {
        modRows.push(row.value)
      })
    } else {
      modRows = {}
      _.forEach(rows, row => {
        if (_.isArray(row?.value)) {
          modRows[row.key] = row?.value
        } else {
          modRows[row.key] = row?.value?.split(',')
        }
      })
    }
    return modRows
  }
  
  const convertPredefinedValuesToRowsFormat = (predefinedValues) => {
    let rowsDataObj = []
    if (_.isArray(predefinedValues) && !_.isEmpty(predefinedValues)) {
      setTypeOfPredifinedValues('array')
      rowsDataObj = _.map(predefinedValues, predefinedValue => {
        return {
          id: uuid(),
          key: '',
          value: predefinedValue,
        }
      })
    } else if (_.isObject(predefinedValues) && !_.isEmpty(predefinedValues)) {
      setTypeOfPredifinedValues('keyValue')
      _.forOwn(predefinedValues, function (value, key) {
        rowsDataObj.push({
          id: uuid(),
          key: key,
          value: value,
        })
      })
    }
    return rowsDataObj
  }
  
  const updateTableDefDependField = (dependField) => {
    if (dependField !== 'None') {
      setTypeOfPredifinedValues('keyValue')
    }
    const tableDefObj = _.cloneDeep(tableDef)
    _.forEach(tableDefObj.columns, column => {
      if (column.field == selectedField.id) {
        column.dependField = dependField === 'None' ? undefined : dependField
      }
    })
    setTableDef(tableDefObj)
    setPartContent({ table: (!_.isEmpty(partContent) ? partContent.table : {}), tableDef: tableDefObj })
  }
  
  useEffect(() => {
    setDependFieldOptions([{ value: 'None', label: 'None' }, ...(dependAvailableFields || [])])
  }, [dependAvailableFields])
  
  useEffect(() => {
    if (!_.isEmpty(dependFieldsLookupKeyValueContent)) {
      if (!_.isArray(dependFieldsLookupKeyValueContent) && _.isString(dependFieldsLookupKeyValueContent)) {
        const parsedData = JSON.parse(dependFieldsLookupKeyValueContent)
        setRowsData(parsedData)
        if (!_.isEmpty(parsedData)) {
          updateTableDefRows(parsedData)
        }
      } else {
        setRowsData(dependFieldsLookupKeyValueContent)
        if (!_.isEmpty(dependFieldsLookupKeyValueContent)) {
          updateTableDefRows(dependFieldsLookupKeyValueContent)
        }
      }
    } else {
      setRowsData([])
    }
  }, [dependFieldsLookupKeyValueContent])
  
  const handleDependentFieldChange = ({ value, label }) => {
    setDependFieldOption({ value, label })
    updateTableDefDependField(value)
  }
  
  const handleAvalilableLookupOptionChange = (e) => {
    setAvailableLookupOption({ value: e.value, label: e.value })
    dispatch(getLookupByName({ name: e.value, source: 'depend' }))
  }
  
  const addTableRows = () => {
    const rowsInput = {
      id: uuid(),
      key: typeOfPredifinedValues === 'array' ? '' : 'key',
      value: '',
    }
    setRowsData([...rowsData, rowsInput])
    
  }
  const deleteTableRows = (index) => {
    const rows = _.cloneDeep(rowsData)
    rows.splice(index, 1)
    setRowsData(rows)
    setDependFieldsLookupKeyValueContent(rows)
    updateTableDefRows(rows)
  }
  
  const handleKeyChange = (index, e) => {
    const { name, value } = e.target
    const rowsInput = _.cloneDeep(rowsData)
    rowsInput[index]['key'] = value
    setRowsData(rowsInput)
    setDependFieldsLookupKeyValueContent(rowsInput)
    updateTableDefRows(rowsInput)
  }
  
  const handleValueChange = (index, e) => {
    const { name, value } = e.target
    const rowsInput = _.cloneDeep(rowsData)
    if (typeOfPredifinedValues === 'array' || _.isArray(value)) {
      rowsInput[index]['value'] = value
    } else {
      rowsInput[index]['value'] = value ? value.split(',') : []
    }
    setRowsData(rowsInput)
    setDependFieldsLookupKeyValueContent(rowsInput)
    updateTableDefRows(rowsInput)
  }
  
  return (
    <Box sx={{ width: '70%' }}>
      <Box>
        <MDTypography p={2} variant={'h6'}>
          {title}
        </MDTypography>
      </Box>
      <Box sx={{ width: '100%', border: 1, justifyContent: 'center' }}>
        
        <Stack direction='column' sx={{ justifyContent: 'center', padding: 3 }}
               spacing={2}>
          <Typography
            variant='h6'
            className={classes.title}
            align='left'
          >
            {dependentOn?.name}
          </Typography>
          {dependFieldOption && dependFieldOption.value !== 'None' && <Typography
            variant='body'
            className={classes.title}
            align='left'
          >
            This field depends on values from other field - {dependFieldOption.label}
          </Typography>}
          <Select
            options={availableLookupOptions}
            value={availableLookupOption}
            isDisabled={docTemplateFlow.wizardType === 'view'}
            placeholder={'Select Lookup'}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
              }),
            }}
            onChange={handleAvalilableLookupOptionChange}
          />
          
          <Box sx={{ width: '100%', border: 1, height: 300, overflow: 'hidden', overflowY: 'scroll' }}>
            <Stack direction='column' sx={{ justifyContent: 'center', paddingTop: 3, paddingBottom: 3 }} spacing={2}>
              <Typography
                variant='h6'
                className={classes.title}
                align='center'
              >
                {`These are the options exists for the field ${(dependentOn && dependentOn.name ? dependentOn.name : 'To be Selected Field')}. Edit if you would like to customize further.`}
              </Typography>
              
              <DependentFieldsLookUpValues rowsData={rowsData} deleteTableRows={deleteTableRows}
                                           handleValueChange={handleValueChange}
                                           handleKeyChange={handleKeyChange} />
            </Stack>
          </Box>
        
        </Stack>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Stack direction='row' sx={{ justifyContent: 'center', paddingTop: '3%' }}>
          <MDButton size='large'
                    variant={'outlined'}
                    color={'dark'}
                    onClick={addTableRows}
                    disabled={docTemplateFlow.wizardType === 'view'}
          >
            <AddCircleSharpIcon />
          </MDButton>
        </Stack>
      </Box>
    </Box>
  )
}