import Card from '@mui/material/Card'
import MDBox from 'components/core/MDBox'
import MDTypography from 'components/core/MDTypography'
import DataTable from 'components/extended/Table/DataTable'
import MDButton from 'components/core/MDButton'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { mainSelector } from '../../../layouts/dashboard/store/mainSlice'
import { useEffect } from 'react'

export default ({ rows, columns }) => {
  const navigate = useNavigate()
  const mainDash = useSelector(mainSelector)
  
  return (
    <Card>
      <MDBox display='flex' justifyContent='space-between' alignItems='center' p={3}>
        <MDBox>
          <MDTypography variant='h6' gutterBottom>
            Documents
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
      </MDBox>
    </Card>
  )
}
