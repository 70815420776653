import React from 'react'
import _ from 'lodash'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  InputLabel,
} from '@mui/material'
import { CloseCircleOutlined } from '@ant-design/icons'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles(({ spacing }) => ({
  dndnode: {
    height: '25px',
    padding: '5px',
    fontSize: '15px',
    fontFamily: 'sans-serif',
    border: '1px solid #1a192b',
    borderRadius: '5px',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'grab',
  },
}))

export default function SimpleAccordion ({ actionSet, subSystems, onDeleteSubSystemNode }) {
  const classes = useStyles()
  const onDragStart = (event, nodeType, type, displayName) => {
    event.dataTransfer.setData('application/reactflow', nodeType)
    event.dataTransfer.setData('application/reactflow/inputType', type)
    event.dataTransfer.setData('application/reactflow/displayName', displayName)
    event.dataTransfer.effectAllowed = 'move'
  }
  
  return (
    <Box sx={{overflowY : 'auto'}}>
      <Accordion expanded={true}>
        <AccordionDetails>
          {!_.isEmpty(subSystems) && <InputLabel sx={{ mt: 2, mb: 3 }} >Drag and Drop </InputLabel>}
          {subSystems && _.map(subSystems, (subsystem, index) => (
            <Box>
              <Box key={index} className={classes.dndnode}
                   onDragStart={(event) => onDragStart(event,
                     `subsystemNode_${subsystem}`)}
                   draggable>
                {subsystem}
              </Box>
              <div style={{ position: 'relative', bottom: '30px', left: '190px' }}>
                <CloseCircleOutlined onClick={(event) => {
                  event.preventDefault()
                  onDeleteSubSystemNode(subsystem)
                }} />
              </div>
            </Box>
          ))}
          {!_.isEmpty(actionSet) && <InputLabel sx={{ mt: 2, mb: 3 }} >Main units </InputLabel>}
          {_.map(_.filter(actionSet, { actionType: 'mainUnit' }), (action, index) => (
            <Box>
              <Box key={index} className={classes.dndnode}
                   onDragStart={(event) => onDragStart(
                     event,
                     action.actionId,
                     action.actionType,
                     action.displayName,
                      )}
                   draggable>
                {action.displayName}
              </Box>
            </Box>
          ))}
          <Divider />
          {!_.isEmpty(actionSet) && <InputLabel sx={{ mt: 2, mb: 3 }} >Input units </InputLabel>}
          {_.map(_.filter(actionSet, { actionType: 'inputUnit' }), (action, index) => (
            <Box>
              <Box key={index} className={classes.dndnode}
                   onDragStart={(event) => onDragStart(
                     event,
                     action.actionId,
                     action.actionType,
                     action.displayName,
                   )}
                   draggable>
                {action.displayName}
              
              </Box>
            
            </Box>
          ))}
          <Divider />
          {!_.isEmpty(actionSet) && <InputLabel sx={{ mt: 2, mb: 3 }} >Output units </InputLabel>}
          {_.map(_.filter(actionSet, { actionType: 'outputUnit' }), (action, index) => (
            <Box>
              <Box key={index} className={classes.dndnode}
                   onDragStart={(event) => onDragStart(
                     event,
                     action.actionId,
                     action.actionType,
                     action.displayName,
                   )}
                   draggable>
                {action.displayName}
      
              </Box>
    
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}