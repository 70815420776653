import MDButton from 'components/core/MDButton'
import MDBox from 'components/core/MDBox'
import React from 'react'
import {
  Grid,
  Stack,
  TextareaAutosize,
  TextField,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from '@mui/material/Button'
import _ from 'lodash'

export default ({ rowsData, deleteTableRows, handleChange, handleDescriptionChange, handleCaptionChange }) => {
  return (
    rowsData && !_.isEmpty(rowsData) && rowsData.map((element, index) => {
      return (
        <Stack direction='row' sx={{ justifyContent: 'left' }} spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={6}>
              <TextareaAutosize
                variant='outlined'
                fullWidth
                style={{ width: '100%' }}
                minRows={6}
                placeholder={'Description'}
                defaultValue={rowsData[index]['text'] ? rowsData[index]['text'] : ''}
                onChange={(e) => handleDescriptionChange(index, e)}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={1}>
              <input
                accept='image/*'
                type='file'
                id={`select-image-${index}`}
                style={{ display: 'none' }}
                onChange={(e) => handleChange(index, e)}
              />
              <label htmlFor={`select-image-${index}`}>
                <MDButton variant='contained' color='primary' fullWidth='100%' component='span'>
                  Upload Image
                </MDButton>
              </label>
            </Grid>
            <Grid item xs={12} md={4} lg={1}>
              <label>
                <Button variant='outlined' size={'medium'} component='label' style={{ color: 'black' }}
                        color={'secondary'}
                        startIcon={<DeleteIcon />} onClick={() => (deleteTableRows(index))}>
                  Delete
                </Button>
              </label>
            </Grid>
            {rowsData[index]['image'] && (
              <>
                <Grid item xs={12} md={4} lg={2}>
                  <MDBox mt={2} textAlign='center'>
                    <img src={rowsData[index]['image'] ? rowsData[index]['image'] : ''} width='80%' height='80%' />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                  <TextField
                    placeholder={'Caption'}
                    variant='outlined'
                    defaultValue={rowsData[index]['caption'] ? rowsData[index]['caption'] : ''}
                    onChange={(e) => handleCaptionChange(index, e)}
                    fullWidth
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Stack>
      )
    })

  )

}