import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material'
import MDTypography from 'components/core/MDTypography'
import React, {
  useEffect,
  useState,
} from 'react'
import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import uuid from 'react-uuid'
import Image from 'components/extended/Diagrams/Image'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { documentSelector } from 'layouts/document-management/document-store/documentSlice'
import MDBox from 'components/core/MDBox'
import Suggestion from '../Suggestion'

export default ({
  heading,
  content,
  newContent,
  setNewContent,
  imageOrientation,
  setImageOrientation,
  isSuggestion,
  selectedChip,
  suggestions,
  setSuggestions,
  setDirtyFlag }) => {
  const mainDoc = useSelector(documentSelector)
  const [rowsData, setRowsData] = useState([])
  const [data, setData] = useState('')

  useEffect(() => {
    if(!_.isArray(content) && _.isString(content) && !_.isEmpty(content)) {
      setRowsData(JSON.parse(content))
      setNewContent(JSON.parse(content))
    } else {
      setRowsData(content)
      setNewContent(content)
    }
  }, [content])

  useEffect(() => {
    if(!_.isArray(data) && _.isString(data) && !_.isEmpty(data)) {
      setRowsData(JSON.parse(data))
      setNewContent(JSON.parse(data))
    } else if(!_.isEmpty(data)) {
      setRowsData(data)
      setNewContent(data)
    }
  }, [data])

  useEffect(() => {
    if(!_.isEmpty(mainDoc.selected_search_part)) {
      const documentPart = mainDoc.selected_search_part
      const sectionToDisplay = documentPart
      const { images, title, type, orientation } = sectionToDisplay || ''
      if(type === 'images') {
        setData(images)
        setImageOrientation(orientation)
      }
    }
  }, [mainDoc.selected_search_part])

  const addTableRows = () => {
    const rowsInput = {
      id: uuid(),
      image: '',
      text: '',
      caption: '',
    }
    setRowsData([...(rowsData || []), rowsInput])
  }

  const deleteTableRows = (index) => {
    const rows = _.cloneDeep(rowsData)
    rows.splice(index, 1)
    setRowsData(rows)
    setNewContent(rows)
  }

  const handleOrientationChange = (evt) => {
    setImageOrientation(evt.target.value)
  }

  const handleChange = (index, e) => {
    const rowsInput = _.cloneDeep(rowsData)
    if(!e.target.files || !e.target.files[0]) return
    const FR = new FileReader()
    FR.addEventListener('load', function(evt) {
      rowsInput[index]['image'] = (evt.target.result)
      setRowsData(rowsInput)
      setNewContent(rowsInput)
      setDirtyFlag(true)
    })
    FR.readAsDataURL(e.target.files[0])
  }

  const handleCaptionChange = (index, e) => {
    const { name, value } = e.target
    const rowsInput = _.cloneDeep(rowsData)
    rowsInput[index]['caption'] = value
    setRowsData(rowsInput)
    setNewContent(rowsInput)
    setDirtyFlag(true)
  }

  const handleDescriptionChange = (index, e) => {
    const { name, value } = e.target
    const rowsInput = _.cloneDeep(rowsData)
    rowsInput[index]['text'] = value
    setRowsData(rowsInput)
    setNewContent(rowsInput)
    setDirtyFlag(true)
  }
  return (
    <Stack direction='column' sx={ { justifyContent: 'center' } } spacing={ 4 }>
      <Box>
        <MDTypography p={ 2 } variant={ 'h6' }>
          { heading }
        </MDTypography>
      </Box>
      <Stack direction='row' sx={ { justifyContent: 'left' } } spacing={ 4 }>
        <MDTypography p={ 1 } variant={ 'h5' }>Orientation</MDTypography>
        <RadioGroup
          row
          value={ imageOrientation }
          onChange={ handleOrientationChange }
        >
          <FormControlLabel value='horizontal' control={ <Radio /> } label='Horizontal' />
          <FormControlLabel value='vertical' control={ <Radio /> } label='Vertical' />
        </RadioGroup>
      </Stack>
      <MDBox sx={ { display: 'flex', gap: 2 } }>
        <MDBox sx={ { display: 'flex', gap: 4, flexDirection: 'column' } }>
          <Button variant='outlined' size={ 'large' } component='label' style={ { color: 'black', display: 'flex' } } color={ 'secondary' }
            startIcon={ <AddIcon /> } onClick={ addTableRows }>
            Add
          </Button>
          <Stack direction='column' spacing={ 2 }>
            <Image rowsData={ rowsData } deleteTableRows={ deleteTableRows } handleCaptionChange={ handleCaptionChange }
              handleChange={ handleChange }
              handleDescriptionChange={ handleDescriptionChange } />
          </Stack>
        </MDBox>
        { (isSuggestion && !_.isEmpty(suggestions)) && <MDBox
          sx={ {
            width: '20%',
            resize: 'none',
            fontFamily: 'sans-serif',
            fontSize: 15,
            border: 1,
            mr: 1,
            padding: 2
          } }>
          <Suggestion
            isSuggestion={ isSuggestion }
            selectedChip={ selectedChip }
            suggestions={ suggestions }
            setSuggestions={ setSuggestions }
            sx={ {
              my: 1,
              py: 1,
              width: '100%',
              height: 'auto',
              justifyContent: 'space-between',
              '& .MuiChip-label': {
                display: 'block',
                whiteSpace: 'normal',
              },
            } }
          />
        </MDBox> }
      </MDBox>
    </Stack>
  )
}