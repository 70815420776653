import React, { useState } from 'react'
import { Box } from '@mui/material'
import { useCurrentEffect } from 'use-current-effect'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    flexShrink: 0,
  },
  tabs: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}))
export default ({
  workflowContent,
  heading,
}) => {
  const classes = useStyles()
  const [workflowName, setWorkflowName] = useState('HardwareSetup-1')
  useCurrentEffect(() => {
    if(workflowContent) setWorkflowName(workflowContent.name)
  }, [workflowContent])

  return (
    <Box pl={3} mb={3} zIndex={1200} mt={2} minWidth={'100%'} alignItems={'center'}>
      <Typography
        variant='h6'
        className={classes.title}
        align='center'
      >
        {heading}
      </Typography>
    </Box>
  )
}