import _ from 'lodash'
import { DateTime } from 'luxon'
import prettyMs from 'pretty-ms'

export const getPrettyTime = ({
  dateTime,
  tz,
  withTZ = false,
}) => {
  const dt = getLuxonDateTime(dateTime, { tz })
  const timeSimple = dt.toLocaleString(DateTime.TIME_SIMPLE)
  return withTZ ? `${timeSimple} ${dt.toFormat('ZZZZ')}` : timeSimple
}

export const getDateISOString = ({
  dateTime,
  tz,
  withTZ = false,
}) => getLuxonDateTime(dateTime, { tz }).toFormat(withTZ ? 'yyyy-LL-dd ZZZZ' : 'yyyy-LL-dd HH:MM:ss')

/**
 * Return a luxon DateTime instance from either a js Date, object, ISO string, or milliseconds
 *
 * @param {*} val
 * @param {object=} opts
 * @param {string=} opts.tz - if set, setZone with it
 * @param {boolean=} opts.utc - if true, convert to UTC
 * @param {boolean=} opts.iso - if true, convert to ISO string
 * @param {boolean=} opts.isoDate - if true, convert to ISO date-only string
 * @return {DateTime|string}
 */
export function getLuxonDateTime (val, opts = {}) {
  const { utc = false, iso = false, isoDate = false, tz } = opts
  let dt
  if(val instanceof DateTime) {
    dt = val
  } else if(val instanceof Date) {
    dt = DateTime.fromJSDate(val)
  } else if(_.isPlainObject(val)) {
    dt = DateTime.fromObject(val)
  } else if(_.isString(val)) {
    dt = DateTime.fromISO(val)
  } else if(_.isFinite(val)) {
    dt = DateTime.fromMillis(val)
  } else {
    dt = DateTime.local()
  }
  if(tz) dt = dt.setZone(tz)
  if(utc) dt = dt.toUTC()
  if(iso) dt = dt.toISO()
  if(isoDate) dt = dt.toISODate()
  return dt
}

/**
 *
 * @param {*} dateTime - representation of a timestamp
 * @param {boolean} compact - if true, returns "7d" instead of "7 days"
 * @return {string}
 */
export const getLocalTimeAgo = ({ dateTime, compact = false }) => {
  const from = getLuxonDateTime(dateTime)
  const to = DateTime.now()
  return prettyMs(getDateDiff(from, to, 'seconds') * 1000, { compact })
}

/**
 * This method is used to covert chrome, safari , firefox all browsers to one time
 *
 * @param date
 * @returns {DateTime}
 */
export const convertToStandardDate = ( date ) => {
  let currentDateTime = new Date()
  if(date){
    if (typeof(date) === 'string') {
      date = DateTime.fromISO(date)
    }
    // always keep the standard time whether firefox/chrome/safari
    date = date.set({ hour: 15 }).set({ minutes: 25 }).set({seconds : 0})
  }
  return date
}
