import _ from 'lodash'
import {
  Stack,
  TextField,
} from '@mui/material'
import Button from '@mui/material/Button'
import DeleteIcon from '@mui/icons-material/Delete'
import React from 'react'
import { useSelector } from 'react-redux'
import { wizardSelector } from 'layouts/admin/document-template-flow/Wizard/store/wizardSlice'

export default ({ rowsData, deleteTableRows, handleValueChange, handleKeyChange }) => {
  const docTemplateFlow = useSelector(wizardSelector)
  return (
    rowsData && rowsData.map((element, index) => {
      return (
        <Stack direction='row' key={`lookup_stack_${index}`} sx={{ justifyContent: 'left' }} spacing={2}
               paddingLeft={'2px'} paddingRight={'2px'} paddingTop={'2px'} paddingBottom={'2px'}>
          {!_.isEmpty(element.key) && (
            <TextField
              variant='outlined'
              size='medium'
              placeholder={'Key'}
              key={`lookup_key_${element.id}`}
              defaultValue={element.key ? element.key : ''}
              onChange={(e) => handleKeyChange(index, e)}
            />
          )}
          <TextField
            variant='outlined'
            size='medium'
            placeholder={'Value'}
            key={`lookup_val_${element.id}`}
            defaultValue={element.value ? element.value : ''}
            onChange={(e) => handleValueChange(index, e)}
          />
          <label>
            <Button size={'medium'} component='label' style={{ color: 'black', width: '10px' }} color={'secondary'}
                    disabled={docTemplateFlow.wizardType === 'view'}
                    startIcon={<DeleteIcon />} key={`lookup_del_${element.id}`}
                    onClick={() => (deleteTableRows(index))}>
            </Button>
          </label>
        </Stack>
      )
    })
  )
}