import Grid from '@mui/material/Grid'
import MDBox from 'components/core/MDBox'
import DashboardLayout from 'components/core/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/core/Navbars/DashboardNavbar'
import Footer from 'components/core/Footer'
import { documentData } from 'layouts/dashboard/data/documentData'
import Document from 'components/extended/Document'
import {
  useEffect,
  useState,
} from 'react'
import _ from 'lodash'
import Tree from 'components/extended/Tree'
import {
  mapProjectToDomain,
  readProjects,
} from 'layouts/dashboard/project-reader'
import MDTypography from 'components/core/MDTypography'
import MDProgress from 'components/core/MDProgress'
import team1 from 'assets/images/section.png'
import team2 from 'assets/images/section.png'
import team3 from 'assets/images/section.png'
import team4 from 'assets/images/section.png'
import Tooltip from '@mui/material/Tooltip'
import MDAvatar from 'components/core/MDAvatar'
import {
  Box, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab, Stack,
  Typography,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import ShareIcon from '@mui/icons-material/Share'
import DeleteIcon from '@mui/icons-material/Delete'
import DownloadIcon from '@mui/icons-material/Download'
import { useNavigate } from 'react-router-dom'
import { changeWorkArea, loginSelector } from '../authentication/store/userSlice'
import {
  getDocumentSuccess,
  getProjectsSuccess,
  getProjectSuccess,
  getProjectToDomainMapSuccess,
  getSectionSuccess,
  mainSelector,
} from './store/mainSlice'
import {
  getDocuments,
  getProjects,
} from './store/saga'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import MDButton from '../../components/core/MDButton'
import { getDateISOString } from 'utils/date-time'
import Button from '@mui/material/Button'
import {
  changeDocumentTemplateVersion,
  deleteDocument,
  getPublishedDocumentTemplates,
} from 'layouts/document-management/document-store/saga'
import CircularProgressStyled from 'components/extended/progress/CircularProgressStyled'
import React from 'react'
import {
  documentSelector,
  getPartsSuccess,
  getPartSuccess,
} from 'layouts/document-management/document-store/documentSlice'
import { wizardSelector } from 'layouts/admin/document-template-flow/Wizard/store/wizardSlice'
import { getAvailableSections } from 'layouts/admin/document-template-flow/Wizard/store/saga'
import { getAvailableParts } from 'layouts/admin/document-template-flow/Wizard/store/saga'
import { getDomains } from 'layouts/admin/document-template-flow/Wizard/store/saga'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Select from 'react-select'
import KeyboardDoubleArrowUpOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowUpOutlined'
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined'

const appBarButtonStyle = {
  padding: '5px',
  borderRadius: '5px',
  border: '2px solid',
  color: 'white',
  fontSize: 'medium',
  marginRight: '10px',
  cursor: 'pointer',
}

export default () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const docTemplateFlow = useSelector(wizardSelector)
  const [rows, setRows] = useState([])
  const [loadingDoc, setLoadingDoc] = useState('')
  const [templateVersions, setTemplateVersions] = useState([])
  const [columns, setColumns] = useState([])
  const [availableDocs, setAvailableDocs] = useState([])
  const [loadedProjects, setLoadedProjects] = useState([])
  const [directoryTreeFolder, setDirectoryTreeFolder] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [defaultExpandedIds, setDefaultExpandedIds] = useState([])
  const [dialogTitle, setDialogTitle] = useState('')
  const [dialogBody, setDialogBody] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [isDeleteDocumentDialog, setIsDeleteDocumentDialog] = useState(false)
  const [documentIdToDelete, setDocumentIdToDelete] = useState('')
  const indicatorSize = 80
  const baseUri = process.env.REACT_APP_API_BASE
  const timeSpan = new Date().getTime()
  
  useEffect(() => {
    dispatch(getPartsSuccess(undefined))
    dispatch(getPartSuccess(undefined))
    dispatch(changeWorkArea({ workArea: 'projects' }))
    if (_.isEmpty(docTemplateFlow.availableSections)) {
      const { domain, subdomain, documentType } = docTemplateFlow.templateWorkflow.document
      if (domain && subdomain && documentType) {
        dispatch(getAvailableSections({ domain: domain.value, subdomain: subdomain.value, doctype: documentType.value }))
      }
    }
  }, [])
  
  useEffect(() => {
    if (_.isEmpty(docTemplateFlow.domains)) {
      dispatch(getDomains())
    }
  }, [])
  useEffect(() => {
    updateDocuments()
  }, [loadingDoc])
  
  const handleCancel = () => {
    setDialogOpen(false)
  }
  
  const onSuccessfulDeleteDocument = () => {
    setDialogOpen(false)
    setIsDeleteDocumentDialog(false)
    dispatch(getDocuments(dashData.selected_project.id))
  }
  
  const handleDeleteDocumentOk = () => {
    const document = _.find(dashData.documents[dashData.selected_project.id], { 'id': documentIdToDelete })
    dispatch(deleteDocument({ docId: document.id, onSuccess: () => onSuccessfulDeleteDocument() }))
  }
  
  const handleDownload = (url) => {
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', true)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  
  const docSelector = useSelector(documentSelector)
  const dashData = useSelector(mainSelector)
  const user = useSelector(loginSelector)
  docSelector.selected_part = undefined
  docSelector.parts = undefined
  const avatars = (sections) =>
    sections.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder='bottom'>
        <MDAvatar
          src={image}
          alt='name'
          onClick={() => {
            dispatch(getSectionSuccess(''))
            navigate(`/section`)
          }}
          size='xs'
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: 'pointer',
            position: 'relative',
            
            '&:not(:first-of-type)': {
              ml: -1.25,
            },
            
            '&:hover, &:focus': {
              zIndex: '10',
            },
          }}
        />
      </Tooltip>
    ))
  
  const getRowButtons = (id) => (
    <MDBox width='8rem' textAlign='left'>
      <Fab
        size='small'
        color='secondary'
        aria-label='edit'
        onClick={() => {
          const document = _.find(dashData.documents[dashData.selected_project.id], { 'id': id })
          dispatch(getDocumentSuccess(document))
          navigate(`/section`)
        }}
      >
        <EditIcon />
      </Fab>
      <Fab size='small' aria-label='download'
           onClick={() => handleDownload(`${baseUri}/dms/download-document?docId=${id}&tspan=${timeSpan}&validId=${user.session_id}__${user.user_id}`)}>
        <DownloadIcon />
      </Fab>
      {/*<Fab size='small' aria-label='share'>
        <ShareIcon />
      </Fab>*/}
      <Fab size='small'
           aria-label='delete'
           onClick={() => {
             setDialogTitle('Delete document')
             setDialogBody('Are you sure you want to delete document ?')
             setDialogOpen(true)
             setIsDeleteDocumentDialog(true)
             setDocumentIdToDelete(id)
           }}
      >
        <DeleteIcon />
      </Fab>
    </MDBox>
  )
  useEffect(() => {
    if (_.isEmpty(dashData.projects)) {
      dispatch(getProjects())
    }
  }, [])
  useEffect(() => {
    if (!user.user_id) {
      navigate('/authentication/sign-in')
    }
  }, [user])
  useEffect(() => {
    if (!_.isEmpty(templateVersions)) {
      updateDocuments()
    }
  }, [templateVersions])
  useEffect(() => {
    if (_.isEmpty(docSelector.publishedDocumentTemplates)) return
    const versionList = _.map(docSelector.publishedDocumentTemplates, ({ id, version }) => ({
      value: id,
      label: `Version - ${version}`,
    }))
    setTemplateVersions(versionList)
  }, [docSelector.publishedDocumentTemplates])
  useEffect(() => {
    if (!_.isEmpty(dashData.projects)) updateProjects()
    if (!_.isEmpty(dashData.selected_project) &&
      (_.isEmpty(dashData.documents) || !dashData.documents[dashData.selected_project.id])) {
      dispatch(getDocuments(dashData.selected_project.id))
      const domain = _.first(dashData.selected_project.type.split('.'))
      const subdomain = _.last(dashData.selected_project.type.split('.'))
      dispatch(getPublishedDocumentTemplates({ domain, subdomain }))
    }
    if (!_.isEmpty(dashData.selected_project)) updateDocuments()
    
    if (!_.isEmpty(dashData.projects) && _.isEmpty(dashData.selected_project)) {
      setSelectedIds(_.first(dashData.projects).id)
      handleOnTreeNodeSelect({ id: _.first(dashData.projects).id })
    }
    
  }, [dashData])
  
  useEffect(() => {
    if (!_.isEmpty(dashData.selected_project)) {
      const domain = _.first(dashData.selected_project.type.split('.'))
      const subdomain = _.last(dashData.selected_project.type.split('.'))
      dispatch(getDocuments(dashData.selected_project.id))
      dispatch(getPublishedDocumentTemplates({ domain, subdomain }))
      updateProjects()
    }
  }, [])
  
  const updateDocuments = () => {
    const data = dashData.selected_project && dashData.documents ? dashData.documents[dashData.selected_project.id] : []
    const docMetadata = documentData
    const { tableDef } = docMetadata
    setColumns(tableDef.columns)
    const docList = []
    const modRows = _.map(data, (row) => {
      const { id, name, templateId, templateVersion, owner, type, tags, editPercentage, createdDate, lastModifiedDate, lastModifiedSection } = row
      docList.push({
        label: `${name} - Template Version - ${templateVersion}`,
        value: id,
      })
      return {
        id: (
          <MDTypography variant='caption' color='text' fontWeight='medium'>
            {id}
          </MDTypography>
        ),
        name: (
          <MDTypography
            variant='caption'
            color='text'
            fontWeight='medium'
            style={{
              cursor: 'pointer',
              transition: 'color 0.7s',
            }}
            onClick={() => {
              const document = _.find(dashData.documents[dashData.selected_project.id], { 'id': id })
              dispatch(getDocumentSuccess(document))
              navigate(`/section`)
            }}
          >
            {name}
          </MDTypography>
        ),
        tags: (
          <MDTypography variant='caption' color='text' fontWeight='medium'>
            {tags}
          </MDTypography>
        ),
        version: (<Stack direction={'row'}><Select
            placeholder={'Change Template'}
            options={templateVersions}
            menuPosition={'fixed'}
            value={{ label: `Version - ${templateVersion}`, value: templateId }}
            styles={{
              menu: base => ({
                ...base,
                maxWidth: '220px',
                fontSize: '12px',
              }),
              valueContainer: base => ({
                ...base,
                position: 'relative',
                maxWidth: '220px',
                fontSize: '12px',
              }),
              control: (provided, state) => ({
                ...provided,
                position: 'relative',
                maxWidth: '220px',
                fontSize: '12px',
              }),
            }}
            onChange={ev => {
              if (ev.value !== templateId) {
                setLoadingDoc(id)
                dispatch(changeDocumentTemplateVersion({
                  docId: id,
                  newTemplateId: ev.value,
                  onSuccess: (data) => {
                    setLoadingDoc('')
                    dispatch(getDocuments(dashData.selected_project.id))
                  },
                }))
              }
              
              //setLinkOptions([])
              //handleOnTreeNodeSelect({ id: ev.value })
            }
            }
          /> <Box sx={{ display: loadingDoc === id ? 'flex' : 'none', marginLeft: '10px' }}>
            <CircularProgress color='success' />
          </Box> </Stack>
        
        ),
        editPercentage: (
          <MDBox width='8rem' textAlign='left'>
            <MDProgress value={editPercentage} color='info' variant='gradient' label={false} />
          </MDBox>
        ),
        
        sections: (
          <MDBox display='flex' py={1}>
            {avatars([
              [team1, 'Section 1'],
              [team2, 'Section 2'],
              [team3, 'Section 3'],
              [team4, 'Section 4'],
            ])}
          </MDBox>
        ),
        actions: getRowButtons(id),
        lastModifiedSection: (
          <MDTypography variant='caption' color='text' fontWeight='medium'>
            {lastModifiedSection}
          </MDTypography>
        ),
        createdDate: (
          <MDTypography variant='caption' color='text' fontWeight='medium'>
            {getDateISOString({ dateTime: createdDate, withTZ: false })}
          </MDTypography>
        ),
        lastModifiedDate: (
          <MDTypography variant='caption' color='text' fontWeight='medium'>
            {getDateISOString({ dateTime: lastModifiedDate, withTZ: true })}
          </MDTypography>
        ),
      }
    })
    setRows(modRows)
    setAvailableDocs(docList)
  }
  
  const updateProjects = () => {
    const projects = dashData.projects
    setLoadedProjects(_.map(projects, ({ id, name }) => ({
      label: name,
      value: id,
    })))
    
    const domains = docTemplateFlow.domains
    const treeArray = readProjects(projects, domains)
    const directoryTreeArray = {
      name: '',
      children: [
        {
          id: '0',
          name: 'Projects',
          children: treeArray,
        },
      ],
    }
    let defaultId
    if (dashData.selected_project) {
      defaultId = dashData.selected_project.id
    } else {
      defaultId = (projects && projects.length > 0 ? projects[0].id : '')
    }
    const defaultSelectedIds = defaultId
    /*let xExpandedIds = ['0', defaultId]
    if (dashData.selected_project) {
      xExpandedIds = ['0', _.first(dashData.selected_project.type.split('.')), _.last(dashData.selected_project.type.split('.')), defaultId]
    }*/
    let xExpandedIds = ['0', defaultId]
    _.map(projects, ({ id, type }) => {
      const domain = _.first(type.split('.'))
      const subdomain = _.last(type.split('.'))
      if(!xExpandedIds.includes(domain)) xExpandedIds.push(domain)
      if(!xExpandedIds.includes(subdomain)) xExpandedIds.push(subdomain)
      if(!xExpandedIds.includes(id)) xExpandedIds.push(id)
    })
    
    setSelectedIds([defaultSelectedIds])
    setDefaultExpandedIds(xExpandedIds)
    setDirectoryTreeFolder(directoryTreeArray)
  }
  
  const handleOnTreeNodeSelect = ({ id }) => {
    const project = _.find(dashData.projects, { 'id': id })
    dispatch(getProjectSuccess(project))
  }
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox sx={{ flexGrow: 1 }}>
        <AppBar position='static' sx={{ bgcolor: '#00A3EE', width: '100%' }}>
          <Toolbar>
            
            <Box sx={{ flexGrow: 1, maxWidth: '220px' }}>
              {<Select
                placeholder={'Go To Project'}
                options={loadedProjects}
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 10000,
                    maxWidth: '220px',
                    fontSize: '12px',
                  }),
                  valueContainer: base => ({
                    ...base,
                    zIndex: 10000,
                    maxWidth: '220px',
                    fontSize: '12px',
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    maxWidth: '220px',
                    fontSize: '12px',
                  }),
                }}
                onChange={ev => {
                  handleOnTreeNodeSelect({ id: ev.value })
                }
                }
              />}
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Typography style={{ ...appBarButtonStyle, maxWidth: '110px', marginLeft: '20px' }}
                          onClick={() => navigate('/create-project')}>Create Project</Typography>
            </Box>
            <Box sx={{ marginRight: '50px' }}>
              {<Select
                placeholder={'Go To Document'}
                options={availableDocs}
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 10000,
                    minWidth: '420px',
                  }),
                  valueContainer: base => ({
                    ...base,
                    zIndex: 10000,
                    minWidth: '420px',
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    minWidth: '420px',
                  }),
                }}
                onChange={ev => {
                  const document = _.find(dashData.documents[dashData.selected_project.id], { 'id': ev.value })
                  dispatch(getDocumentSuccess(document))
                  navigate(`/section`)
                }
                }
              />}
            </Box>
            {!_.isEmpty(dashData.projects) &&
            <Typography style={appBarButtonStyle}
                        onClick={() => navigate('/create-document')}>Create Document</Typography>}
          </Toolbar>
        </AppBar>
      </MDBox>
      <MDBox sx={{ flexGrow: 1 }}>
        <Grid container border={`2px solid`}>
          
          <Grid item xs={12} md={3} lg={2} border={`1px solid`}>
            {selectedIds.length > 0
            && defaultExpandedIds.length > 0
            && !_.isEmpty(directoryTreeFolder)
            && (<Tree
              data={directoryTreeFolder}
              selectedIds={selectedIds}
              defaultExpandedIds={defaultExpandedIds}
              handleOnTreeNodeSelect={handleOnTreeNodeSelect}
            />)
            }
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <CircularProgressStyled
              size={indicatorSize}
              sx={{
                display: docSelector.loading ? 'block' : 'none',
                position: 'relative',
                top: '30%',
                left: '50%',
                zIndex: '9999',
                marginTop: `${-indicatorSize / 2}px`,
                marginLeft: `${-indicatorSize / 2}px`,
                background: 'transparent',
                color: 'gray',
              }}
            />
            <Document rows={rows} columns={columns} />
          </Grid>
        </Grid>
        <Dialog
          sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
          maxWidth='xs'
          open={dialogOpen}
        >
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent dividers>
            <Typography>{dialogBody}</Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCancel}>
              Cancel
            </Button>
            {isDeleteDocumentDialog && (<Button onClick={handleDeleteDocumentOk}>Ok</Button>)}
          </DialogActions>
        </Dialog>
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}