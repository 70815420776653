export const documentData = {
  tableDef: {
    columns: [
      {
        accessor: 'id',
        Header: 'ID',
        colWidth: 60,
        isVisible: false,
      },
      {
        accessor: 'name',
        Header: 'Document Name',
        colWidth: 240,
        isVisible: true,
      },
      {
        accessor: 'tags',
        Header: 'Tags',
        colWidth: 140,
        isVisible: true,
      },
      {
        accessor: 'version',
        Header: 'Template Version',
        colWidth: 250,
        isVisible: true,
      },
      {
        accessor: 'editPercentage',
        Header: 'Completion',
        colWidth: 200,
        isVisible: true,
      },
      {
        accessor: 'sections',
        Header: 'Sections',
        colWidth: 110,
        isVisible: false,
      },
      {
        accessor: 'createdDate',
        Header: 'Created Date',
        colWidth: 110,
        isVisible: true,
      },
      {
        accessor: 'lastModifiedDate',
        Header: 'Last Modified Date',
        colWidth: 110,
        isVisible: false,
      },
      {
        accessor: 'actions',
        Header: 'Actions',
        colWidth: 110,
        isVisible: true,
      },
    ],
  },
}

