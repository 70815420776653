import React from 'react'
import PropTypes from 'prop-types'
import { Chip, Typography } from '@mui/material'
import _ from 'lodash'
import MDTypography from 'components/core/MDTypography'
import MDBox from 'components/core/MDBox'

const Suggestion = ({
  chipContainerStyle,
  containerStyle,
  heading,
  headingShown,
  isSuggestion,
  selectedChip,
  setSuggestions,
  suggestions,
  ...rest
}) => {

  const handleDelete = (deletedLabel) => {
    const updatedSuggestions = suggestions.filter(
      (suggestion) => suggestion !== deletedLabel
    )
    setSuggestions(updatedSuggestions)
  }

  const handleChipClick = (clickedLabel) => {
    selectedChip(clickedLabel)
  }

  return (
    <div>
      <MDBox sx={ containerStyle }>
        { headingShown && (
          <MDTypography variant={ 'h6' }>
            { heading }
          </MDTypography>
        ) }
        <MDBox sx={ chipContainerStyle }>
          { suggestions.map((suggestion, index) => (
            <Chip
              key={ index }
              variant='outlined'
              onClick={ () => handleChipClick(suggestion) }
              onDelete={ () => handleDelete(suggestion) }
              { ...rest }
              label={
                <Typography sx={ { fontFamily: 'sans-serif', fontSize: 8 } }>
                  Project: { suggestion.label.split(',')[0].split(': ')[1] } <br />
                  Part: { suggestion.label.split(',')[3].split(': ')[1] } <br />
                  Section: { suggestion.label.split(',')[2].split(': ')[1] } <br />
                  Document: { suggestion.label.split(',')[1].split(': ')[1] }
                </Typography>
              }
            />
          )) }
        </MDBox>
      </MDBox>
    </div>
  )
}

Suggestion.defaultProps = {
  heading: 'Suggestions',
  headingShown: true,
  isSuggestion: true,
}

Suggestion.propTypes = {
  containerStyle: PropTypes.object,
  chipContainerStyle: PropTypes.object,
  heading: PropTypes.string,
  headingShown: PropTypes.bool,
  isSuggestion: PropTypes.bool,
  selectedChip: PropTypes.func.isRequired,
  setSuggestions: PropTypes.func.isRequired,
  suggestions: PropTypes.array.isRequired,
}

export default Suggestion
