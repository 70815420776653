import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { loginSelector } from 'layouts/authentication/store/userSlice'
import { logoutUser } from 'layouts/authentication/store/saga'

function Basic () {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector(loginSelector)
  useEffect(() => {
    if(user.user_id) {
      dispatch(logoutUser({ navigate }))
    } else {
      navigate('/authentication/sign-in')
    }
  }, [user])
  return (
    <></>
  )
}

export default Basic
