import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

export function onLoadingStart (state) {
  state.loading = true
}

export function onLoadingFail (state, { payload: error }) {
  state.loading = false
  state.error = error
  state.loaded = true
}

export function onLoadingSuccess (state) {
  state.loading = false
  state.error = null
  state.loaded = true
}

export const initialState = {
  loading: false,
  error: null,
  work_area : 'projects',
  loaded: true,
  user_id: undefined,
  session_id: undefined,
  user_display_name: undefined,
  user_role: undefined,
}

const addUserDetails = (state, { payload: user }) => {
  state.loading = false
  state.error = null
  state.user_id = user.user_id
  state.user_display_name = user.user_display_name
  state.user_role = user.user_role
  state.session_id = user.session_id
}

const userSlice = createSlice({
  name: 'userReducer',
  initialState,
  reducers: {
    onLoadingUserStart: onLoadingStart,
    onLoadingUserEnd: (state) => {
      state.loading = false
      state.error = null
    },
    onLoadingUserFail: onLoadingFail,
    loginSuccess: addUserDetails,
    logoutSuccess: (state) => {
      state.loading = false
      state.error = null
      state.user_id = undefined
      state.user_display_name = undefined
      state.user_role = undefined
    },
    changeWorkArea : (state,{ payload: { workArea } }) => {
      state.work_area = workArea
    },
  },
})

export const {
  onLoadingUserStart,
  onLoadingUserEnd,
  onLoadingUserFail,
  loginSuccess,
  logoutSuccess,
  changeWorkArea,
} = userSlice.actions
export default userSlice.reducer

export const userReducerSelector = (state) => state.layoutsReducer[userSlice.name]
export const loginSelector = createSelector(userReducerSelector, ({ user_role, user_display_name, user_id, session_id , work_area }) => ({
  user_role,
  user_display_name,
  user_id,
  session_id,
  work_area,
}))
