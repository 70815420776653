export const camelCaseConvert = (string) => string.replace(/\W+(.)/g, (match, chr) => chr.toUpperCase())

export const camelCaseToTextConvert = (str) => {
  let out = str.replace(/^\s*/, '') // strip leading spaces
  out = out.replace(/^[a-z]|[^\s][A-Z]/g, function (str, offset) {
    if(offset == 0) {
      return (str.toUpperCase())
    } else {
      return (str.substr(0, 1) + ' ' + str.substr(1).toUpperCase())
    }
  })
  return out
}

export const getTextWidth = (text, font) => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  context.font = font || getComputedStyle(document.body).font

  return context.measureText(text).width
}

export const SYSTEM_BLOCK_SECTION = 'SYSTEM_BLOCK_SECTION'
export const SUBSYSTEM_BLOCK_SECTION = 'SUBSYSTEM_BLOCK_SECTION'
