/**
 All of the routes for the HWAI V1 2 React are added here,
 You can add a new route, customize the routes and delete the routes here.
 
 Once you add a new route on this file it will be visible automatically on
 the Sidenav.
 
 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `type` key with the `title` value is used for a title inside the Sidenav.
 3. The `type` key with the `divider` value is used for a divider between Sidenav items.
 4. The `name` key is used for the name of the route on the Sidenav.
 5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
 7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 8. The `route` key is used to store the route location which is used for the react router.
 9. The `href` key is used to store the external links location.
 10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
 10. The `component` key is used to store the component of its route.
 */

import Dashboard from 'layouts/dashboard'
import Section from 'layouts/document-management/document'
import SignIn from 'layouts/authentication/sign-in'
import SignOut from 'layouts/authentication/sign-out'
import SignUp from 'layouts/authentication/sign-up'
import CreateDocument from 'layouts/document-management/Form'
import CreateProject from 'layouts/ProjectManagement/Form'
import CreateDocumentTemplate from 'layouts/admin/document-template-flow'

// @mui icons
import Icon from '@mui/material/Icon'
import NewDocument from './layouts/new-document'
import Administration from 'layouts/admin/administration'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'

const routes = [
  {
    type: 'collapse',
    workArea: 'projects',
    name: 'Admin',
    key: 'administration',
    icon: <AdminPanelSettingsOutlinedIcon />,
    route: '/administration',
    component: <Administration />,
  },
  {
    type: 'collapse',
    workArea: 'admin',
    name: 'Projects',
    key: 'dashboard',
    icon: <Icon fontSize='small'>dashboard</Icon>,
    route: '/dashboard',
    component: <Dashboard />,
  },
  {
    type: 'collapse',
    showMenu: false,
    name: 'New',
    key: 'document',
    icon: <Icon fontSize='small'>assignment</Icon>,
    route: '/document',
    component: <NewDocument />,
  },
  
  {
    type: 'collapse',
    showMenu: false,
    name: 'Shared',
    key: 'doc-shared',
    icon: <Icon fontSize='small'>dashboard</Icon>,
    route: '/dashboard',
    component: <Dashboard />,
  },
  {
    type: 'divider',
    showMenu: true,
    name: 'Administration',
    key: 'adminLine',
  },
  {
    type: 'divider',
    showMenu: true,
    name: 'Administration',
    key: 'adminLine',
  },
  {
    type: 'title',
    showMenu: false,
    name: 'Administration',
    textColor: 'primary',
    key: 'admin',
  },
  {
    type: 'collapse',
    showMenu: true,
    name: 'Sign Out',
    key: 'sign-out',
    icon: <Icon fontSize='small'>login</Icon>,
    route: '/authentication/sign-out',
    component: <SignOut />,
  },
  {
    type: 'collapse',
    showMenu: false,
    name: 'Sign In',
    key: 'sign-in',
    icon: <Icon fontSize='small'>login</Icon>,
    route: '/authentication/sign-in',
    component: <SignIn />,
  },
  {
    type: 'collapse',
    showMenu: false,
    name: 'Sign Up',
    key: 'sign-up',
    icon: <Icon fontSize='small'>assignment</Icon>,
    route: '/authentication/sign-up',
    component: <SignUp />,
  },
  ,
  {
    type: 'collapse',
    showMenu: false,
    name: 'Section',
    key: 'section',
    icon: <Icon fontSize='small'>assignment</Icon>,
    route: '/section',
    component: <Section />,
  },
  {
    type: 'collapse',
    showMenu: false,
    name: 'CreateDocument',
    key: 'createDocument',
    icon: <Icon fontSize='small'>assignment</Icon>,
    route: '/create-document',
    component: <CreateDocument />,
  },
  {
    type: 'collapse',
    showMenu: false,
    name: 'CreateProject',
    key: 'createProject',
    icon: <Icon fontSize='small'>assignment</Icon>,
    route: '/create-project',
    component: <CreateProject />,
  },
  
  {
    type: 'collapse',
    showMenu: false,
    name: 'CreateProject',
    key: 'createProject',
    icon: <Icon fontSize='small'>assignment</Icon>,
    route: '/create-document-template',
    component: <CreateDocumentTemplate />,
  },
]

export default routes
