import React, { useState } from 'react'
import { Handle } from 'reactflow'
import { CloseCircleOutlined } from '@ant-design/icons'
import MDTypography from 'components/core/MDTypography'
import {
  checkOfMandatoryFields,
  validateProps,
} from 'components/extended/HardwareSetup/validator'

export default ({ data, isConnectable }) => {
  const [showingProps, setShowingProps] = useState(false)
  const handleToggle = () => {
    if(showingProps) {
      const requiredFields = validateProps(data)
      if(_.isEmpty(requiredFields)) {
        data.setSelectedNode()
        setShowingProps(false)
      }
      checkOfMandatoryFields(requiredFields, data)
    } else {
      data.setSelectedNode(data)
      setShowingProps(true)
    }
  }
  
  return (
    <div style={{ maxHeight: 20, minWidth: 100 }}>
      
      <MDTypography
        variant='body1'
        align='center'
        onClick={handleToggle}
        style={{ fontSize: '12px', cursor: 'pointer' }}>
        {data.executionInfo.headerText}
      </MDTypography>
      <Handle
        type='target'
        position='left'
        style={{ background: '#555', width: '5px', height: '5px' }}
        isConnectable={isConnectable}
      />
      {data.hideDelete && <div style={{ position: 'right', marginTop: '10px', marginLeft: '-150px' }}>
        <CloseCircleOutlined onClick={(event) => {
          event.preventDefault()
          data.onDelete(data.nodeId)
        }} />
      </div>}
    </div>
  )
}
