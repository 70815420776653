import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import { createAction } from '@reduxjs/toolkit'
import axiosWithoutInterceptors from 'axios'
import {
  getAddPublishedDocumentTemplatesSuccess,
  getDeleteSelectedPart,
  getPartsSuccess,
  getPartSuccess,
  getSearchPartSuccess,
  onLoadingUserEnd,
  onLoadingUserFail,
  onLoadingUserStart,
} from './documentSlice'
import { getDocumentTypesSuccess } from 'layouts/admin/document-template-flow/Wizard/store/wizardSlice'
import { takeEvery } from '@redux-saga/core/effects'

const axios = axiosWithoutInterceptors
const baseUri = process.env.REACT_APP_API_BASE

export const getDocument = createAction('getDocumentSaga')

export function * getDocumentSaga ({ payload }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.get, `/dms/document?docId=${payload}`, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const parts = result.data
    yield put(getPartsSuccess(parts))
    
  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export const deleteDocument = createAction('deleteDocumentSaga')

export function * deleteDocumentSaga ({ payload }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.delete, `/dms/document?docId=${payload.docId}`, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const parts = result.data
    payload.onSuccess()
    yield put(getDeleteSelectedPart())
    yield put(getPartsSuccess(parts))
    
  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export const getDocumentPart = createAction('getDocumentPartSaga')

export function * getDocumentPartSaga ({ payload: { documentId, partId, fromTree = true, onDataSuccess } }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.get, `/dms/part?docId=${documentId}&partId=${partId}`, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const part = result.data
    if (onDataSuccess) {
      onDataSuccess(part)
    } else {
      part.fromTree = fromTree
      yield put(getPartSuccess(part))
    }
  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export const getSearchDocumentPart = createAction('getSearchDocumentPartSaga')

export function * getSearchDocumentPartSaga ({ payload: { documentId, partId } }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.get, `/dms/part?docId=${documentId}&partId=${partId}`, {
      withCredentials: true,
    })
    // yield put(onLoadingUserEnd())
    const part = result.data
    yield put(getSearchPartSuccess(part))
    
  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export const saveDocumentPart = createAction('saveDocumentPartSaga')

export function * saveDocumentPartSaga ({ payload: { data, onSuccess, onFailure } }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.put, `/dms/part`, data, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const part = result.data
    onSuccess(part)
    
  } catch (error) {
    onFailure()
    yield put(onLoadingUserFail())
  }
}

export const getPublishedDocumentTemplates = createAction('getPublishedDocumentTemplatesSaga')

export function * getPublishedDocumentTemplatesSaga ({ payload: { domain, subdomain } }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.get, `/dms/admin/published-document-templates?domain=${domain}&subdomain=${subdomain}`, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const publishedDocumentTemplates = result.data
    
    yield put(getAddPublishedDocumentTemplatesSuccess(publishedDocumentTemplates))
    
  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export const getGeneratedTestCases = createAction('getGeneratedTestCasesSaga')

export function * getGeneratedTestCasesSaga ({ payload: { docId, blockDiagramPartId, onSuccess } }) {
  try {
    const result = yield call(axios.get, `/dms/test-specifications?docId=${docId}&partId=${blockDiagramPartId}`, {
      withCredentials: true,
    })
    onSuccess(result.data)
    
  } catch (error) {
    yield put(onLoadingUserFail())
  }
}


export const changeDocumentTemplateVersion = createAction('changeDocumentTemplateVersionSaga')

export function * changeDocumentTemplateVersionSaga ({ payload: { docId, newTemplateId , onSuccess } }) {
  try {
    const result = yield call(axios.put, `/dms/document/version?docId=${docId}&destDtId=${newTemplateId}`, {} , {
      withCredentials: true,
    })
    onSuccess(result.data)
    
  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export default function * documentSaga () {
  yield takeLatest(getDocument.type, getDocumentSaga)
  yield takeLatest(deleteDocument.type, deleteDocumentSaga)
  yield takeEvery(getDocumentPart.type, getDocumentPartSaga)
  yield takeLatest(getSearchDocumentPart.type, getSearchDocumentPartSaga)
  yield takeLatest(saveDocumentPart.type, saveDocumentPartSaga)
  yield takeLatest(getPublishedDocumentTemplates.type, getPublishedDocumentTemplatesSaga)
  yield takeLatest(getGeneratedTestCases.type, getGeneratedTestCasesSaga)
  yield takeLatest(changeDocumentTemplateVersion.type, changeDocumentTemplateVersionSaga)
  
}