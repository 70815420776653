import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import MDBox from 'components/core/MDBox'
import DefaultNavbar from 'components/core/Navbars/DefaultNavbar'
import PageLayout from 'components/core/LayoutContainers/PageLayout'

// Authentication pages components
import Footer from 'layouts/authentication/components/Footer'

function BasicLayout ({ image, children }) {
  return (
    <PageLayout>
      <DefaultNavbar
        action={{
          type: 'external',
          route: 'https://bosch.com',
          label: 'Learn More',
          color: 'dark',
        }}
      />
      <MDBox
        position='absolute'
        width='100%'
        minHeight='100vh'
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6),
            )}, url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <MDBox px={1} width='100%' height='100vh' mx='auto'>
        <Grid container spacing={1} justifyContent='center' alignItems='center' height='100%'>
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
      <Footer light />
    </PageLayout>
  )
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default BasicLayout
