import _ from 'lodash'
import { Grid } from '@mui/material'
import React from 'react'
import MDButton from 'components/core/MDButton'
import Select from 'react-select'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { getLookupByName } from 'layouts/admin/document-template-flow/Wizard/store/saga'
import { wizardSelector } from 'layouts/admin/document-template-flow/Wizard/store/wizardSlice'
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp'

export default ({ rowsData, deleteTableRows, handleLookupFields, selected, classes, setDialogTitle, setDialogOpen }) => {
  const dispatch = useDispatch()
  const docTemplateFlow = useSelector(wizardSelector)
  const options = _.map(rowsData, row => ({
    label: row.name,
    value: row.name,
  }))

  return (

    <Grid container spacing={1} style={{ width: '100%', margin: 'auto' }}>
      <Grid item xs={12} md={10} lg={10}>

        <Select
          styles={{
            menu: base => ({
              ...base,
              zIndex: 100,
            }),
            control: (provided, state) => ({
              ...provided,
              marginBottom: 10,
            }),
          }}
          isDisabled={docTemplateFlow.wizardType === 'view'}
          options={options}
          name='Select Lookup'
          placeholder='Select Lookup'
          onChange={(option) => {
            dispatch(getLookupByName({ name: option.value, source: 'lookup' }))
            //const element = _.find(rowsData, { name: option.value })
            handleLookupFields(option.value)
          }}
        />
      </Grid>
      <Grid item xs={12} md={2} lg={2}>
        <MDButton size='small' variant={'outlined'}
                  disabled={docTemplateFlow.wizardType === 'view'}
                  color={'dark'}
                  onClick={() => {
                    setDialogTitle('Create Lookup')
                    setDialogOpen(true)
                  }}
        >
          <AddCircleSharpIcon />
        </MDButton>
      </Grid>
    </Grid>

  )

  /* return (
     rowsData && !_.isEmpty(rowsData) && rowsData.map((element, index) => {
       return (
         <Stack direction='row' key={`stack_${index}`} sx={{ justifyContent: 'left' , maxHeight :'100px' }} spacing={2} paddingLeft={'10%'} paddingRight={'5%'} paddingTop={'2%'} paddingBottom={'2%'}>
           <MDButton size={'small'} variant={element.name === selected ? 'contained' : 'outlined'} color='dark'  className={element.name === selected ? classes.selectButton : classes.unselectButton}
                     key={`lookup_${index}`} onClick={(e) => handleLookupFields(e, element)}  sx={{ whiteSpace: 'nowrap' }}>
            <Typography fontSize={'10px'}> {`Lookup ${index}`} </Typography>
           </MDButton>
           <MDButton sx={{ marginLeft: '10px', height: 20 }} size='small' variant={'outlined'} color={'dark'}
                     key={`delete_${index}`} onClick={() => deleteTableRows(index, element.name)}>
             <DeleteSharpIcon />
           </MDButton>
         </Stack>
       )
     })
   )*/
}