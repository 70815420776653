import Card from '@mui/material/Card'
import MDBox from 'components/core/MDBox'
import MDTypography from 'components/core/MDTypography'
import DataTable from 'components/extended/Table/DataTable'
import MDButton from 'components/core/MDButton'
import { useNavigate } from 'react-router-dom'

export default ({ rows, columns }) => {
  const navigate = useNavigate()
  return (
    <Card>
      <MDBox display='flex' justifyContent='space-between' alignItems='center' p={3}>
        <MDBox>
          <MDTypography variant='h6' gutterBottom>
            Documents Templates
          </MDTypography>
        </MDBox>
        <MDBox>

          <MDButton variant={'outlined'} color={'dark'} onClick={() => navigate('/create-document-template')}>
            Create Document Template
          </MDButton>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
      </MDBox>
    </Card>
  )
}
