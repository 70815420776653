import MDTypography from 'components/core/MDTypography'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import MDButton from 'components/core/MDButton'
import _ from 'lodash'
import { readColumns } from 'components/extended/Table/table-reader'
import { getType } from 'layouts/document-management/validator'
import { object } from 'yup'
import { useSelector } from 'react-redux'
import { documentSelector } from 'layouts/document-management/document-store/documentSlice'
import MDBox from 'components/core/MDBox'
import Suggestion from '../Suggestion'
import TablePlotter from './TablePlotter'
// import { getTextWidth } from 'utils/util'

export default forwardRef(({
  content,
  title,
  hwUnits,
  testRows,
  newContent,
  tableDef,
  setContent,
  setNewContent,
  defaultSection,
  setDialogBody,
  setDialogTitle,
  setDialogOpen,
  setValidationColumns,
  setIsDeleteDialog,
  isAdminTable,
  isSuggestion,
  selectedChip,
  suggestions,
  setSuggestions,
  setDirtyFlag,
}, ref) => {
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const [newSubRow, setNewSubRow] = useState({})
  const [rows, setRows] = useState([])
  const [deletedRows, setDeletedRows] = useState([])
  const [newRow, setNewRow] = useState({})
  const [rowModesModel, setRowModesModel] = useState({})
  const [columns, setColumns] = useState([])
  const [sectionOptions, setSectionOptions] = useState([])
  const [sectionOption, setSectionOption] = useState()
  const [testRowsAdded, setTestRowsAdded] = useState(false)
  
  const [columnVisible, setColumnVisible] = useState({})
  const [suggestionDisplay, setSuggestionDisplay] = useState('none')
  const mainDoc = useSelector(documentSelector)
  const [data, setData] = useState('')
  const [dataTableDef, setDataTableDef] = useState(tableDef)
  const stateRef = useRef()
  stateRef.rows = rows
  useEffect(() => {
    if (!_.isEmpty(testRows)) {
      const currentContent = !_.isEmpty(newContent) ? newContent : content
      let maxCount = 0
      if (!_.isEmpty(currentContent)) {
        maxCount = _.max(_.map(currentContent, entry => entry.id))
        maxCount += 1
      }
      
      const qualifiedRows = _.filter(testRows, entry => {
        const cRow = _.find(stateRef.rows, enx =>
          enx.section === entry.section && enx.parameter === entry.parameter
          && enx.name === entry.name
          && (enx.value || (enx.ub && enx.lb)))
        return !cRow
      })
      
      const nRows = _.map(qualifiedRows, (row, index) => ({ ...row, id: (maxCount + index) }))
      setNewContent([...(newContent || []), ...nRows])
      setTimeout(() => {
        setTestRowsAdded(true)
      }, 10)
      
    }
  }, [testRows])
  useEffect(() => {
    if (!testRowsAdded) return
    if (!_.isEmpty(newContent)) {
      const nRows = getFullRows()
      setRows(nRows)
      setDirtyFlag(true)
      setSectionOption({})
      setTestRowsAdded(false)
    }
  }, [testRowsAdded])
  useEffect(() => {
    setRows(() =>
      rows.map((row) => {
        if (row.id === newSubRow.parentId) {
          const newMappedSubRow = {}
          const newMappedSubRowObj = {}
          let mainRowKey = ''
          _.forOwn(newSubRow, (value, key) => {
            if (key !== 'id' && key !== 'parentId') {
              newMappedSubRow[key] = value
              newMappedSubRowObj[key.split('.')[1]] = value
              mainRowKey = key.split('.')[0]
            }
          })
          row[mainRowKey] = newMappedSubRowObj
          const newMappedRow = { ...row, ...newMappedSubRow }
          return newMappedRow
        } else {
          return row
        }
      }),
    )
  }, [newSubRow])
  
  useEffect(() => {
    setRows(() =>
      rows.map((row) => {
        if (row.id === newRow.id) {
          return newRow
        } else {
          return row
        }
      }),
    )
  }, [newRow])
  
  useEffect(() => {
    const section = _.find(tableDef.columns, { field: 'section' })
    const options = _.map(section.predefinedValues, value => {
      return {
        value,
        label: value,
      }
    })
    setSectionOptions(options)
  }, [tableDef])
  
  useEffect(() => {
    const hiddenColumns = {}
    let columns = readColumns({
      processRowUpdate,
      updateRow,
      hwUnits,
      columnArray: tableDef.columns,
      hiddenColumns,
    })
    
    // Remove the "Sl No." column
    const filteredColumns = columns.filter(column => column.field !== 'id')
    
    //TODO : Fix this properly
    
    setTimeout(() => {
      if (!_.isEmpty(defaultSection)) {
        handleSectionChange({ value: defaultSection })
      } else {
        const nRows = getFullRows()
        setRows(nRows)
      }
      setValidationColumns(filteredColumns)
      setColumns(filteredColumns)
      setColumnVisible(hiddenColumns)
    }, 200)
  }, [tableDef, content, hwUnits])
  
  useEffect(() => {
    if (isAdminTable) {
      const hiddenColumns = {}
      const columns = readColumns({
        processRowUpdate,
        updateRow,
        hwUnits,
        columnArray: dataTableDef.columns,
        hiddenColumns,
      })
      //TODO : Fix this properly
      setTimeout(() => {
        if (!_.isEmpty(defaultSection)) {
          handleSectionChange({ value: defaultSection })
        } else {
          setRows(content ? content : [])
        }
        setValidationColumns(columns)
        setColumns(columns)
        setColumnVisible(hiddenColumns)
      }, 200)
      setRows([])
    }
  }, [dataTableDef, data, hwUnits])
  
  useEffect(() => {
    if (isAdminTable) {
      if (!_.isEmpty(mainDoc.selected_search_part)) {
        const documentPart = mainDoc.selected_search_part
        const sectionToDisplay = documentPart
        const { table, heading, tableDef, type } = sectionToDisplay || ''
        if (type === 'table') {
          setData(table)
          setDataTableDef(tableDef)
        }
      }
    }
  }, [mainDoc.selected_search_part])
  
  const getRowIndex = (rowId, rows) => {
    let selectedRowIndex
    _.forEach(rows, (row, index) => {
      if (row.id == rowId) {
        selectedRowIndex = index
      }
    })
    return selectedRowIndex
  }
  useImperativeHandle(ref, () => ({
    handleRowDelete () {
      if (!_.isEmpty(rowSelectionModel)) {
        setRows((prevRows) => {
          const delRows = _.map(rowSelectionModel, (rowId) => ({ id: rowId }))
          const newRows = _.reject(rows, ({ id }) => _.find(delRows, { id }))
          setDeletedRows(delRows)
          setDirtyFlag(true)
          setIsDeleteDialog(false)
          setDialogOpen(false)
          setDialogTitle('')
          setDialogBody('')
          return [...newRows]
        })
      }
    },
    validateSystemSpecifications (validationColumns, newContent) {
      return validateRows(validationColumns, newContent)
    },
    validateAndRemoveNumberFields (validationColumns, newContent) {
      const newModifiedContent = []
      const columnNumberType = {}
      _.forEach(validationColumns, column => {
        if (column.validator && column.validator.type && column.validator.type === 'number') {
          columnNumberType[column.field] = 'number'
        }
      })
      
      _.forEach(newContent, content => {
        const newContentObj = {}
        _.forOwn(content, (value, key) => {
          if (columnNumberType[key] && columnNumberType[key] === 'number') {
            if (value !== '') newContentObj[key] = value
          } else {
            newContentObj[key] = value
          }
        })
        newModifiedContent.push(newContentObj)
      })
      const finalRows = _.concat(_.filter(content,
          entry => !_.find(newModifiedContent, { id: entry.id })),
        _.map(newModifiedContent, entry => _.omit(entry, ['filled' , 'isCustom'])))
      return _.filter(finalRows, entry => {
        const dRow = _.find(deletedRows, { id: entry.id })
        if (dRow) return false
        return true
      })
    },
  }))
  
  const validateRows = (validationColumns, newContent) => {
    const errorMessage = []
    let validationErrors = []
    const schemaObj = iterateColumnsForSchemaValidator(tableDef.columns)
    _.forEach(newContent, (row) => {
      _.filter(validationColumns, (column) => {
        if (column.required) {
          if (!row[column.field] || row[column.field] === '') {
            errorMessage.push(`In Sl number : ${row['id']}, Column name :  ${column['headerName']} is Mandatory`)
          }
        }
      })
      
    })
    const finalErrors = [...errorMessage, ...validationErrors]
    if (finalErrors && finalErrors.length > 0) {
      const erMsg = finalErrors.join('\n')
      setIsDeleteDialog(false)
      setDialogBody(erMsg)
      setDialogTitle('Error Message')
      setDialogOpen(true)
      return false
    }
    return true
  }
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }
  
  const processRowUpdate = useCallback((newRow, oldRow) => {
    const updatedRow = { ...newRow }
    setNewSubRow(updatedRow)
    return updatedRow
  }, [])
  
  const changeRow = (rows, rowId, columnName, columnValue) => {
    const modifiedRows = []
    _.forEach(rows, (row) => {
      const newRow = { ...row }
      if (row.id === rowId) {
        newRow[columnName] = columnValue
        if (columnName.indexOf('.') >= 0) {
          const columnNameArray = columnName.split('.')
          newRow[columnNameArray[0]][columnNameArray[1]] = columnValue
        }
      }
      modifiedRows.push(newRow)
    })
    if (!_.isEmpty(modifiedRows)) {
      setRows(modifiedRows)
      setDirtyFlag(true)
    }
  }
  
  const removeIndex = (rows, index) => {
    return [...rows.slice(0, index), ...rows.slice(index + 1)]
  }
  const handleDeleteRow = () => {
    if (!_.isEmpty(rowSelectionModel)) {
      setIsDeleteDialog(true)
      setDialogBody('Are you sure you want to delete row ?')
      setDialogTitle('Delete Row')
      setDialogOpen(true)
    } else {
      setIsDeleteDialog(false)
      setDialogBody('Please select row to delete.')
      setDialogTitle('Delete Row')
      setDialogOpen(true)
    }
  }
  
  const iterateColumnsForSchemaValidator = (columns) => {
    const schemaObj = {}
    _.forEach(columns, (column) => {
      if (column.columns) {
        iterateColumnsForSchemaValidator(column.columns)
      } else if (column.validator) {
        const { type, min, max, required } = column.validator
        schemaObj[column.field] = getType({ type, minValue: min, maxValue: max, required, fieldName: column.headerName })
      }
    })
    return object(schemaObj)
  }
  
  const iterateColumns = (columns) => {
    const rowObj = {}
    _.forEach(columns, (column) => {
      if (column.columns) {
        rowObj[column.field] = iterateColumns(column.columns)
      } else {
        rowObj[column.field] = ''
      }
    })
    return rowObj
  }
  
  const createAllRow = (content) => {
    const newRows = []
    let maxCount = 0
    if (!_.isEmpty(content)) {
      maxCount = _.max(_.map(content, entry => entry.id))
    }
    tableDef.columns.forEach((sectionColumn) => {
      if (sectionColumn.field === 'section' && sectionColumn.predefinedValues) {
        sectionColumn.predefinedValues.forEach((sectionValue) => {
          const parameterColumn = tableDef.columns.find(
            (col) => col.field === 'parameter',
          )
          
          if (parameterColumn && parameterColumn.predefinedValues) {
            const parameterValues = parameterColumn.predefinedValues[sectionValue] || []
            if (parameterValues.length === 0) {
              // Create a row even if "Parameter" values are empty
              maxCount += 1
              const rowObj = {
                id: maxCount,
                type: 'Must-have',
                lb: '',
                ub: '',
                unit: '',
                remarks: '',
                section: sectionValue,
                parameter: '',
                name: '',
              }
              if (_.find(tableDef.columns, { field: 'hwUnit' })) {
                rowObj['hwUnit'] = ''
                rowObj['hwInstanceId'] = ''
                rowObj['isCustom'] = true
              }
              newRows.push(rowObj)
            } else {
              parameterValues.forEach((parameterValue) => {
                const nameColumn = tableDef.columns.find(
                  (col) => col.field === 'name',
                )
                if (nameColumn && nameColumn.predefinedValues) {
                  const nameValues = nameColumn.predefinedValues[parameterValue] || []
                  if (nameValues.length === 0) {
                    // Create a row even if "Name" values are empty
                    maxCount += 1
                    const rowObj = {
                      id: maxCount,
                      type: 'Must-have',
                      lb: '',
                      ub: '',
                      unit: '',
                      remarks: '',
                      section: sectionValue,
                      parameter: parameterValue,
                      name: '',
                    }
                    if (_.find(tableDef.columns, { field: 'hwUnit' })) {
                      rowObj['hwUnit'] = ''
                      rowObj['hwInstanceId'] = ''
                      rowObj['isCustom'] = true
                    }
                    newRows.push(rowObj)
                  } else {
                    nameValues.forEach((nameValue) => {
                      // Create a row object with all columns from tableDef
                      maxCount += 1
                      const rowObj = {
                        id: maxCount,
                        type: 'Must-have',
                        lb: '',
                        ub: '',
                        unit: '',
                        remarks: '',
                        section: sectionValue,
                        parameter: parameterValue,
                        name: nameValue,
                      }
                      if (_.find(tableDef.columns, { field: 'hwUnit' })) {
                        rowObj['hwUnit'] = ''
                        rowObj['hwInstanceId'] = ''
                        rowObj['isCustom'] = true
                      }
                      newRows.push(rowObj)
                    })
                  }
                }
              })
            }
          }
        })
      }
    })
    return newRows
  }
  
  const createRow = ({
    hwUnit,
    hwInstanceId,
    section,
  }) => {
    const rowObj = iterateColumns(tableDef.columns)
    let currentId = 1
    if (rows.length > 0) {
      const lastRow = rows[rows.length - 1]
      currentId = lastRow.id + 1
    }
    rowObj['id'] = currentId
    if (sectionOption && sectionOption.value) {
      rowObj['section'] = sectionOption.value
    } else if (section) {
      rowObj['section'] = section
    }
    if (hwUnit && hwInstanceId) {
      rowObj['hwUnit'] = hwUnit
      rowObj['hwInstanceId'] = hwInstanceId
    }
    return rowObj
  }
  
  const handleAddRow = ({
    hwUnit,
    hwInstanceId,
    section,
  }) => {
    if (validateRows(columns, rows)) {
      setRows((prevRows) => [createRow({
        hwUnit,
        hwInstanceId,
        section,
      }), ...prevRows])
    }
  }
  
  const getFullRows = () => {
    const allRow = createAllRow(_.isEmpty(newContent) ? content : newContent)
    const pendingRows = _.filter(allRow, entry => {
      const cRow = _.find(content, enx =>
        enx.section === entry.section && enx.parameter === entry.parameter
        && enx.name === entry.name)
      if (cRow) return false
      const cXRow = _.find(newContent, enx =>
        enx.section === entry.section && enx.parameter === entry.parameter
        && enx.name === entry.name)
      if (cXRow) return false
      return true
    })
    const finalRows = _.concat(_.map(content, entry => ({ filled: true, ...entry }))
      , _.map(newContent, entry => ({ filled: true, ...entry })), pendingRows)
    return _.filter(finalRows, entry => {
      const dRow = _.find(deletedRows, { id: entry.id })
      if (dRow) return false
      return true
    })
  }
  
  const handleSectionChange = ({ value }) => {
    const nRows = getFullRows()
    setRows([..._.filter(nRows, { section: value })])
    setSectionOption({ value, label: value })
  }
  
  const handleClearSection = () => {
    const nRows = getFullRows()
    setRows(nRows)
    setSectionOption({})
  }
  
  useEffect(() => {
    if (_.isEmpty(defaultSection)) {
      handleClearSection()
      return
    }
    handleSectionChange({ value: defaultSection })
  }, [defaultSection])
  
  const valueEditor = (params, event) => {
    if (event.target.value) updateRow(params, event.target.value)
  }
  
  const updateRow = (params, value) => {
    
    if ((params.field === 'ub' || params.field === 'lb') && !isNaN(value)) {
      value = value * 1
    }
    
    const lRows = _.cloneDeep(stateRef.rows)
    const row = _.find(lRows, { id: params.id })
    let xRow = _.find(newContent, { id: params.id })
    if (row) {
      if (params.field === 'hwUnit') {
        const hwUnit = _.first(value.split('-'))
        const instanceId = _.last(value.split('-'))
        row['hwUnit'] = hwUnit
        row['hwInstanceId'] = instanceId
      } else {
        row[params.field] = value
      }
      const ub = _.get(row, 'ub')
      const lb = _.get(row, 'lb')
      const rowVal = _.get(row, 'value')
      if (
        (typeof rowVal === 'string' && !_.isEmpty(rowVal) || (typeof rowVal === 'number')) ||
        ((typeof ub === 'string' && !_.isEmpty(ub) || (typeof ub === 'number')) &&
          (typeof lb === 'string' && !_.isEmpty(lb) || (typeof lb === 'number')))) {
        row['filled'] = true
        if (xRow) xRow['filled'] = true
        if (xRow) {
          if (params.field === 'hwUnit') {
            const hwUnit = _.first(value.split('-'))
            const instanceId = _.last(value.split('-'))
            xRow['hwUnit'] = hwUnit
            xRow['hwInstanceId'] = instanceId
          } else {
            xRow[params.field] = value
          }
        }
        setDirtyFlag(true)
        if (!xRow) newContent.push(_.cloneDeep(row))
        setNewContent([...newContent])
      }
      setRows([...lRows])
    }
  }
  
  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ paddingBottom: '10px' }}>
          <MDTypography p={2} variant={'h6'}>
            {title}
          </MDTypography>
        </Box>
        <MDBox sx={{
          gap: 1,
          overflow: 'auto',
          display: 'flex',
          width: '100%',
        }}>
          <MDBox sx={{ gap: 4, flexDirection: 'column', weight: 1, overflow: 'auto', width: '100%' }}>
            {(isSuggestion && !_.isEmpty(suggestions)) &&
            <Stack direction='row' mt={2} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
              
              <MDButton size='small'
                        onClick={() => {
                          suggestionDisplay === 'none'
                            ? setSuggestionDisplay('block') :
                            setSuggestionDisplay('none')
                        }}
                        variant={'outlined'}
                        color={'dark'}>{suggestionDisplay === 'none'
                ? 'Show Suggestions' :
                'Hide Suggestions'}</MDButton>
            </Stack>}
            <Box sx={{ height: 1000, mt: 1, width: 'auto' }}>
              <TablePlotter hwUnits={hwUnits} columns={columns} rows={rows}
                            level={_.find(columns, { field: 'hwUnit' }) ? 2 : 1}
                            rowSelectionModel={rowSelectionModel}
                            setRowSelectionModel={setRowSelectionModel}
                            columnVisible={columnVisible}
                            handleRowModesModelChange={handleRowModesModelChange}
                            valueEditor={valueEditor}
                            handleAddRow={handleAddRow}
                            handleDeleteRow={handleDeleteRow}
              />
              {/*<GridTable
                columns={columns}
                rows={rows}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
                columnVisible={columnVisible}
                handleRowModesModelChange={handleRowModesModelChange}
                valueEditor={valueEditor}
              />*/}
            </Box>
          </MDBox>
          {(isSuggestion && !_.isEmpty(suggestions)) && <MDBox sx={{
            display: suggestionDisplay, width: '20%', resize: 'none', fontFamily: 'sans-serif', fontSize: 15,
            border: 1, mr: 1, padding: 2, weight: 1,
          }}>
            
            <Suggestion
              isSuggestion={isSuggestion}
              selectedChip={selectedChip}
              suggestions={suggestions}
              setSuggestions={setSuggestions}
              sx={{
                my: 1,
                py: 1,
                
                width: '100%',
                height: 'auto',
                justifyContent: 'space-between',
                '& .MuiChip-label': {
                  display: 'block',
                  whiteSpace: 'normal',
                },
              }}
            />
          </MDBox>}
        </MDBox>
      </Box>
    </div>
  )
})