import {
  Box,
  Stack,
  TextField,
} from '@mui/material'
import React, { useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import {
  searchParts,
} from 'layouts/admin/document-template-flow/Wizard/store/saga'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { wizardSelector } from 'layouts/admin/document-template-flow/Wizard/store/wizardSlice'
import { useEffect } from 'react'
import _ from 'lodash'
import { documentSelector } from 'layouts/document-management/document-store/documentSlice'
import {
  getDocumentPart,
  getSearchDocumentPart,
} from 'layouts/document-management/document-store/saga'
import { useCallback } from 'react'

export default ({
  type,
  setTableDef,
  setNewContent,
  setContent,
  setRowsData,
  setImageOrientation,
  domain,
  subdomain,
  docId,
}) => {
  const dispatch = useDispatch()
  const docTemplateFlow = useSelector(wizardSelector)
  const mainDoc = useSelector(documentSelector)
  const [options, setOptions] = useState([])

  useEffect(() => {
    const updatedOptions = _.map((docTemplateFlow.searchedParts),(part) => {
      return {
        label: `Project: ${part.projectName}, Document: ${part.documentName}, Section: ${part.sectionName}, Part: ${part.partName}`,
        key: `${part.projectId} ${part.documentId} ${part.sectionId} ${part.partId} `,
        documentId: part.documentId,
        partId: part.partId,
      }
    })
    setOptions(updatedOptions)
  }, [docTemplateFlow.searchedParts])

  const updateDocumentPart = () => {
    const documentPart = mainDoc.selected_search_part
    const sectionToDisplay = documentPart
    if(type === 'table'){
      const documentPart = mainDoc.selected_search_part
      const sectionToDisplay = documentPart
      const { table, heading, tableDef } = sectionToDisplay || ''
      if(table) {
        setTimeout(() => {
          setContent(table)
          setNewContent(table)
          setTableDef(tableDef)
        }, 1)
      }
     
      //setNewContent(table)
      //setTableDef(tableDef)
    }
  }

  useEffect(() => {
    if(!_.isEmpty(mainDoc.selected_search_part)) updateDocumentPart()
  }, [mainDoc.selected_search_part])

  const getData = (searchTerm) => {
    dispatch(searchParts({ domain: domain, subdomain: subdomain, keyword: searchTerm, type:type, excludeDoc: docId }))
  }

  /*
  const onInputChange = (event, value, reason) => {
    if (value) {
      getData(value)
    } else {
      setOptions([])
    }
  }

   */

  const handleSearchParts = (searchTerm, domain, subdomain, docId) => {
    dispatch(searchParts({ domain: domain, subdomain: subdomain, keyword: searchTerm, type: type === 'RTF' ? 'text' : type, excludeDoc: docId }))
  }

  const callSearchParts = useCallback(_.debounce(handleSearchParts, 300), [])

  const onInputChange = (event, value, reason) => {
    if (value) {
      callSearchParts(value, domain, subdomain, docId)
    } else {
      setOptions([])
    }
  }

  const getPart = (value) => {
    if(value) {
    dispatch(getSearchDocumentPart({
      documentId: value.documentId,
      partId: value.partId,
    }))
    }
  }
  return (
    <Box sx={{ justifyContent: 'left' }}>
      <Stack direction='column' sx={{ justifyContent: 'left' }} spacing={4}>
        <Autocomplete
          id='combo-box-demo'
          sx={{width : '400px'}}
          options={options}
          onInputChange={onInputChange}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} label='Search existing document for prefilled data ....' variant='outlined' />
          )}
          onChange={(event, value) => {
            getPart(value)
          }}
        />
      </Stack>
    </Box>
  )
  
}