import {
  Box,
  Grid,
  Stack,
  TextField,
} from '@mui/material'
import InputSelection from './InputSelection'
import _ from 'lodash'
import * as React from 'react'
import { useState } from 'react'
import Button from '@mui/material/Button'
import ParamsValue from './ParamsValue'
import Select from 'react-select'
import uuid from 'react-uuid'
import { createHardwareUnit } from 'layouts/admin/document-template-flow/Wizard/store/saga'
import { useDispatch } from 'react-redux'

const availableInputAtt = [
  {
    'key': 'measureFreq',
    'label': 'Measurement Frequency (s)',
    'unit': 'Sec',
  },
  {
    'key': 'workingVoltage',
    'label': 'Working Voltage',
    'options': [
      '3.3',
      '5.0',
      '9.0',
      '12.0',
    ],
    'unit': 'V',
  },
  {
    'key': 'maxCurrent',
    'label': 'Max Current',
    'options': [
      '0.3',
      '0.5',
      '1.0',
      '2.0',
    ],
    'unit': 'A',
  },
  {
    'key': 'make',
    'label': 'Make',
    'options': [
      'STM',
      'Microtech',
      'Hilink',
    ],
  },
]

export const actionTypes = [
  { value: 'mainUnit', label: 'Main Unit' },
  { value: 'inputUnit', label: 'Input Unit' },
  { value: 'outputUnit', label: 'Output Unit' },
]

export default ({
  setAvailableInputSectionsState,
  availableInputSectionsState,
  setAvailableInputAttributes,
  availableInputAttributes,
}) => {
  const [addHardwareUnit, setAddHardwareUnit] = React.useState(false)
  const [hardwareUnitName, setHardwareUnitName] = React.useState('')
  const [selectedAvailableUnitAttributes, setSelectedAvailableUnitAttributes] = React.useState('')
  const [selectedAddedUnitAttributes, setSelectedAddedUnitAttributes] = React.useState('')
  const [actionType, setActionType] = React.useState('')
  const [name, setName] = useState('')
  const [unit, setUnit] = useState('')
  const [options, setOptions] = useState([])
  const [defaultValue, setDefaultValue] = useState('')
  const dispatch = useDispatch()

  const [paramsDefAttributes, setParamsDefAttributes] = React.useState([])
  const [partContentAttributes, setPartContentAttributes] = React.useState('')

  const addToAddedInputAttributesSections = () => {
    if(_.isEmpty(selectedAvailableUnitAttributes)) return
    const unit = _.find(availableInputAttributes, { key: selectedAvailableUnitAttributes })
    setPartContentAttributes({ paramsDefAttributes: [unit, ...(paramsDefAttributes || [])] })
    setParamsDefAttributes([unit, ...(paramsDefAttributes || [])])
    setSelectedAvailableUnitAttributes('')
  }

  const removeFromAddedInputAttributesSections = () => {
    if(_.isEmpty(selectedAddedUnitAttributes)) return
    const units = _.filter(paramsDefAttributes, (item) => item.key !== selectedAddedUnitAttributes)
    const availableUnit = _.filter(availableInputAttributes, (item) => item.key === selectedAddedUnitAttributes)
    if(availableUnit.length === 0) {
      const tempParams = _.cloneDeep(availableInputAttributes)
      const item = _.find(paramsDefAttributes, { key: selectedAddedUnitAttributes })
      tempParams.push(item)
      setAvailableInputAttributes(tempParams)
    }
    setPartContentAttributes({ paramsDefAttributes: [...units] })
    setParamsDefAttributes([...units])
    setSelectedAddedUnitAttributes('')
  }

  const camelize = (str) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    }).replace(/\s+/g, '')
  }

  const addNewHardwareUnit = () => {
    const tempParams = _.cloneDeep(availableInputSectionsState)
    const actionId = camelize(hardwareUnitName)
    const defAttributes = _.map(paramsDefAttributes, (attributes) => {
      return {
        ...attributes,
        key: `${actionId}.${_.last(attributes.key.split('.'))}`,
        parameter: _.last(attributes.key.split('.')) === 'rangeHigh'
          || _.last(attributes.key.split('.')) === 'rangeLow' ? `${actionId}Parameter` : attributes['parameter']
      }
    })
    const hardwareUnit = {
      _id: uuid(),
      actionId: actionId,
      actionType: actionType,
      attributes: defAttributes,
      displayName: hardwareUnitName,
      returnSet: {
        'id': actionType,
      },
    }
    tempParams.push(hardwareUnit)
    dispatch(createHardwareUnit({data: hardwareUnit}))
    setAvailableInputSectionsState(tempParams)
    setPartContentAttributes({ paramsDefAttributes: [] })
    setParamsDefAttributes([])
    setAvailableInputAttributes(availableInputAtt)
    setUnit('')
    setName('')
    setOptions('')
    setDefaultValue('')
  }

  const onChangeOfHardwareUnitName = (name) => {
    setHardwareUnitName(name)
  }
  return (
    <>
      <Box>
        <Button variant={'outlined'} size={'small'} style={{ color: 'black' }} onClick={(event) => {
          event.preventDefault()
          setAddHardwareUnit(true)
        }}>Add Harware Unit</Button>
      </Box>
      <Box sx={{ justifyContent : 'space-evenly' }}>
        {addHardwareUnit && (
          <>
            <TextField
              placeholder={'Hardware Unit Name'}
              variant='outlined'
              onChange={ev => {
                onChangeOfHardwareUnitName(ev.target.value)
              }}
              sx={{ width: '30ch', paddingBottom: '20px' }}
            />
            <Select
              styles={{
                menu: base => ({
                  ...base,
                  zIndex: 100,
                  width: '30ch',
                }),
                control: (provided, state) => ({
                  ...provided,
                  marginBottom: 10,
                  width: '30ch',
                }),
              }}
              name='Action Id'
              placeholder='Select Unit Type'
              options={actionTypes}
              onChange={(option) => {
                setActionType(option.value)
              }}
            />

            <Stack direction='row' sx={{ paddingTop: 5, paddingBottom: 3 }} spacing={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={7} lg={7}>
                  <InputSelection
                    available={availableInputAttributes}
                    added={paramsDefAttributes}
                    addToAdded={addToAddedInputAttributesSections}
                    removeFromAdded={removeFromAddedInputAttributesSections}
                    setSelectedAvailable={setSelectedAvailableUnitAttributes}
                    setSelectedAdded={setSelectedAddedUnitAttributes}
                    availableTitle={`Available Hardware Unit Attributes`}
                    addedTitle={`Added Hardware Unit Attributes`}
                  />
                </Grid>
                <Grid item xs={12} md={5} lg={5}>
                  <ParamsValue
                    setPartContentAttributes={setPartContentAttributes}
                    paramsDefAttributes={paramsDefAttributes}
                    setParamsDefAttributes={setParamsDefAttributes}
                    selectedAddedUnitAttributes={selectedAddedUnitAttributes}
                    setOptions={setOptions}
                    options={options}
                    defaultValue={defaultValue}
                    setDefaultValue={setDefaultValue}
                    setName={setName}
                    name={name}
                    setUnit={setUnit}
                    unit={unit}
                  />
                </Grid>
              </Grid>
            </Stack>
            <Button variant={'outlined'} size={'small'} style={{ color: 'black' }} onClick={addNewHardwareUnit}>Save
              Harware Unit</Button>
          </>
        )}
      </Box>
    </>
  )
}