import * as React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Box } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'

export default ({
  rows,
  columns,
  rowSelectionModel,
  setRowSelectionModel,
  columnVisible,
  handleRowModesModelChange,
  valueEditor,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        fontSize: '12px',
        '& .filled': {
          backgroundColor: '#E8F5E9',
          fontSize: '12px',
        },
        '& .header-style': {
          fontSize: '12px',
        },
        '& .unfilled': {
          backgroundColor: '#FBE9E7',
          fontSize: '12px',
        },
      }}
    >
      <DataGrid
        editMode={'cell'}
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel)
        }}
        columns={columns}
        rows={rows}
        getRowClassName={(params) => params.row.filled ? `filled` : 'unfilled'}
        hideFooterRowCount={true}
        onCellEditStop={valueEditor}
        getRowHeight={() => '20'}
        columnVisibilityModel={columnVisible}
        onRowModesModelChange={handleRowModesModelChange}
      />
    </Box>
  )
}
