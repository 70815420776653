import PropTypes from 'prop-types'
import Container from '@mui/material/Container'
import MDBox from 'components/core/MDBox'
import typography from 'assets/theme/base/typography'

function Footer ({ light }) {
  const { size } = typography

  return (
    <MDBox position='absolute' width='100%' bottom={0} py={4}>
      <Container>
        <MDBox
          width='100%'
          display='flex'
          flexDirection={{ xs: 'column', lg: 'row' }}
          justifyContent='space-between'
          alignItems='center'
          px={1.5}
        >
          <MDBox
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexWrap='wrap'
            color={light ? 'white' : 'text'}
            fontSize={size.sm}
          >
            <MDBox fontSize={size.md} color={light ? 'white' : 'dark'} mb={-0.5} mx={0.25} />
          </MDBox>
          <MDBox
            component='ul'
            sx={({ breakpoints }) => ({
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'center',
              listStyle: 'none',
              mt: 3,
              mb: 0,
              p: 0,

              [breakpoints.up('lg')]: {
                mt: 0,
              },
            })}
          >
            <MDBox component='li' pr={2} lineHeight={1} />
            <MDBox component='li' px={2} lineHeight={1} />
            <MDBox component='li' px={2} lineHeight={1} />
            <MDBox component='li' pl={2} lineHeight={1} />
          </MDBox>
        </MDBox>
      </Container>
    </MDBox>
  )
}

// Setting default props for the Footer
Footer.defaultProps = {
  light: false,
}

// Typechecking props for the Footer
Footer.propTypes = {
  light: PropTypes.bool,
}

export default Footer
