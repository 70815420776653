import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import _ from 'lodash'

export function onLoadingStart (state) {
  state.loading = true
}

export function onLoadingFail (state, { payload: error }) {
  state.loading = false
  state.error = error
  state.loaded = true
}

export function onLoadingSuccess (state) {
  state.loading = false
  state.error = null
  state.loaded = true
}

export const initialState = {
  loading: false,
  error: null,
  loaded: true,
  selected_domain: undefined,
  section_tree: undefined,
  show_section_view: false,
  show_part_view: false,
  show_subsystem_view: false,
  availableSections: [],
  addedSections: [],
  availableParts: [],
  addedParts: [],
  availableSubsystems: [],
  addedSubsystems: [],
  domains: [],
  subdomains: [],
  documentTypes: [],
  documentsByDomain: [],
  sectionTreeData: {},
  availableFields: [],
  availableLookups: [],
  templateWorkflow: {
    document: {},
    sections: [],
    hardwareTestsDefs : {
      hardwareTestParametersDefs : [],
      hardwareTestProceduresDefs : [],
    },
  },
  createdTemplate: {},
  lookupDataForDependFields: undefined,
  lookupDataForLookups: undefined,
  wizardType: 'edit',
  searchedParts: [],
  hardwareUnitData: undefined,
  hardwareUnits: undefined,
}

const updateWizardType = (state, { payload: wizardType }) => {
  state.loading = false
  state.error = null
  state.wizardType = wizardType
}

const addTemplateWorkflow = (state, { payload: templateWorkflow }) => {
  state.loading = false
  state.error = null
  //debugger
  let tWFlow = _.cloneDeep(templateWorkflow)
  if(!tWFlow.hardwareTestsDefs) {
    tWFlow.hardwareTestsDefs = {}
  }
  if(!tWFlow.hardwareTestsDefs.hardwareTestParametersDefs) {
    tWFlow.hardwareTestsDefs.hardwareTestParametersDefs = []
  }
  if(!tWFlow.hardwareTestsDefs.hardwareTestProceduresDefs) {
    tWFlow.hardwareTestsDefs.hardwareTestProceduresDefs = []
  }
  state.templateWorkflow = tWFlow
}

const updateTemplateWorkflowDocumentType = (state, { payload: document }) => {
  state.loading = false
  state.error = null
  state.templateWorkflow.document = document
}

const updateTemplateWorkflowSections = (state, { payload: sections }) => {
  state.loading = false
  state.error = null
  state.templateWorkflow.sections = sections
}

const updateTemplateWorkflowHwTestProcedures = (state, { payload: testProcedures }) => {
  state.loading = false
  state.error = null
  state.templateWorkflow.hardwareTestsDefs = testProcedures
    || { hardwareTestParametersDefs : [] , hardwareTestProceduresDefs : [] }
}

const clearTemplateWorkflow = (state, {}) => {
  state.loading = false
  state.error = null
  state.templateWorkflow = {
    document: {},
    sections: [],
    hardwareTestsDefs : { hardwareTestParametersDefs : [] , hardwareTestProceduresDefs : [] },
  }
}

const updateTreeData = (state, { payload: sectiontree }) => {
  state.loading = false
  state.error = null
  state.section_tree = sectiontree
}

const updateSectionTreeData = (state, { payload: sectionTreeData }) => {
  state.loading = false
  state.error = null
  state.sectionTreeData = sectionTreeData
}

const addSelectedDomain = (state, { payload: domain }) => {
  state.loading = false
  state.error = null
  state.selected_domain = domain
}

const showHideSection = (state, { payload: showHideSection }) => {
  state.loading = false
  state.error = null
  state.show_section_view = showHideSection
}

const addAvailableSections = (state, { payload: availableSections }) => {
  state.loading = false
  state.error = null
  state.availableSections = availableSections
}

const addAvailableParts = (state, { payload: availableParts }) => {
  state.loading = false
  state.error = null
  state.availableParts = availableParts
}

const searchedParts = (state, { payload: searchedParts }) => {
  state.loading = false
  state.error = null
  state.searchedParts = searchedParts
}

const addAvailableSubsystems = (state, { payload: availableSubsystems }) => {
  state.loading = false
  state.error = null
  state.availableSubsystems = availableSubsystems
}

const addDomains = (state, { payload: domains }) => {
  state.loading = false
  state.error = null
  state.domains = domains
}

const getHardwareUnits = (state, { payload: hardwareUnits }) => {
  state.loading = false
  state.error = null
  state.hardwareUnits = hardwareUnits
}

const addSubdomains = (state, { payload: subdomains }) => {
  state.loading = false
  state.error = null
  state.subdomains = subdomains
}

const addDocumentTypes = (state, { payload: documentTypes }) => {
  state.loading = false
  state.error = null
  state.documentTypes = documentTypes
}

const addDocumentsByDomain = (state, { payload: documentsByDomain }) => {
  state.loading = false
  state.error = null
  state.documentsByDomain = documentsByDomain
}

const addAvailableFields = (state, { payload: availableFields }) => {
  state.loading = false
  state.error = null
  state.availableFields = availableFields
}

const addAvailableLookups = (state, { payload: availableLookups }) => {
  state.loading = false
  state.error = null
  state.availableLookups = availableLookups
}

const addLookupDataForDependFields = (state, { payload: lookupData }) => {
  state.loading = false
  state.error = null
  state.lookupDataForDependFields = lookupData
}

const addLookupDataForLookups = (state, { payload: lookupData }) => {
  state.loading = false
  state.error = null
  state.lookupDataForLookups = lookupData
}

const createHardwareUnit = (state, { payload: hardwareUnitData }) => {
  state.loading = false
  state.error = null
  state.hardwareUnitData = hardwareUnitData
}



const wizardSlice = createSlice({
  name: 'wizardReducer',
  initialState,
  reducers: {
    onLoadingUserStart: onLoadingStart,
    getSelectedDomainSuccess: addSelectedDomain,
    onLoadingUserEnd: (state) => {
      state.loading = false
      state.error = null
    },
    onLoadingUserFail: onLoadingFail,
    onShowHideSection: showHideSection,
    getAvailableSectionsSuccess: addAvailableSections,
    getAvailablePartsSuccess: addAvailableParts,
    getAvailableSubsystemsSuccess: addAvailableSubsystems,
    getDomainsSuccess: addDomains,
    getHardwareUnitsSuccess: getHardwareUnits,
    getSubdomainsSuccess: addSubdomains,
    getDocumentTypesSuccess: addDocumentTypes,
    getDocumentsByDomainSuccess: addDocumentsByDomain,
    updateSectionTreeDataSuccess: updateSectionTreeData,
    getAvailableFieldsSuccess: addAvailableFields,
    getAvailableLookupsSuccess: addAvailableLookups,
    getLookupDataForDependFieldsSuccess: addLookupDataForDependFields,
    getLookupDataForLookupsSuccess: addLookupDataForLookups,
    getAddTemplateWorkflowSuccess: addTemplateWorkflow,
    searchedPartsSuccess: searchedParts,
    updateTemplateWorkflowDocumentTypeSuccess: updateTemplateWorkflowDocumentType,
    updateTemplateWorkflowSectionsSuccess: updateTemplateWorkflowSections,
    updateTemplateWorkflowHwTestProceduresSuccess: updateTemplateWorkflowHwTestProcedures,
    updateTreeDataSuccess: updateTreeData,
    clearTemplateWorkflowSuccess: clearTemplateWorkflow,
    updateWizardTypeSuccess: updateWizardType,
    createDocumentTemplateSuccess: (state, { payload: documentTemplateId }) => {
      state.loading = false
      state.error = null
      state.createdTemplate = state.createdTemplate || {}
      state.createdTemplate = documentTemplateId
    },
    createHardwareUnitSuccess: createHardwareUnit,
  },
})

export const {
  onLoadingUserStart,
  getSelectedDomainSuccess,
  onLoadingUserEnd,
  onLoadingUserFail,
  onShowHideSection,
  getAvailableSectionsSuccess,
  getAvailablePartsSuccess,
  getAvailableSubsystemsSuccess,
  getDomainsSuccess,
  getSubdomainsSuccess,
  getDocumentTypesSuccess,
  getDocumentsByDomainSuccess,
  getAddTemplateWorkflowSuccess,
  updateSectionTreeDataSuccess,
  getAvailableFieldsSuccess,
  getAvailableLookupsSuccess,
  getLookupDataForDependFieldsSuccess,
  getLookupDataForLookupsSuccess,
  searchedPartsSuccess,
  updateTemplateWorkflowDocumentTypeSuccess,
  updateTemplateWorkflowSectionsSuccess,
  updateTemplateWorkflowHwTestProceduresSuccess,
  updateTreeDataSuccess,
  updateWizardTypeSuccess,
  createDocumentTemplateSuccess,
  clearTemplateWorkflowSuccess,
  createHardwareUnitSuccess,
  getHardwareUnitsSuccess,

} = wizardSlice.actions
export default wizardSlice.reducer

export const wizardReducerSelector = (state) => state.layoutsReducer[wizardSlice.name]
export const wizardSelector = createSelector(wizardReducerSelector,
  ({ loading, error, parts, selected_domain, section_tree, show_section_view, show_part_view, show_subsystem_view, availableSections, addedSections, availableParts, addedParts, availableSubsystems, addedSubsystems, domains, subdomains, documentTypes, documentsByDomain, sectionTreeData, availableFields, availableLookups, templateWorkflow, createdTemplate, lookupDataForDependFields, lookupDataForLookups, wizardType, searchedParts, hardwareUnitData, hardwareUnits }) => ({
    loading, error, parts, selected_domain, section_tree, show_section_view, show_part_view, show_subsystem_view, availableSections, addedSections, availableParts, addedParts, availableSubsystems, addedSubsystems, domains, subdomains, documentTypes, documentsByDomain, sectionTreeData, availableFields, availableLookups, templateWorkflow, createdTemplate, lookupDataForDependFields, lookupDataForLookups, wizardType, searchedParts, hardwareUnitData, hardwareUnits,
  }))
