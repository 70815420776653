import {
  InputLabel,
  Stack,
} from '@mui/material'
import _ from 'lodash'
import * as React from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import {
  getLookupDataForDependFieldsSuccess,
  getLookupDataForLookupsSuccess,
  wizardSelector,
} from 'layouts/admin/document-template-flow/Wizard/store/wizardSlice'
import 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/wizard.css'

const typeLabel = {
  text: 'Text',
  table: 'Table',
  systemBlockDiagram: 'System Diagram',
  subSystemBlockDiagram: 'SubSystem Hardware Diagram',
  images: 'Images',
}

export default ({
  sections,
  label,
  setSelectedSections,
  setPartSelected,
  setPartSelectedType,
  setPartObjectSelected,
  setSelectedArea,
  setPartContent,
  setTableDef,
  setParamsDef,
  setDependFieldsLookupKeyValueContent,
  setTableLookupKeyValueContent,
  setTableColumnDependentsMap,
  partObjectSelected,
}) => {
  const dispatch = useDispatch()
  const docTemplateFlow = useSelector(wizardSelector)
  const handlePartSelect = (event, section) => {
    debugger
    if (setSelectedArea) {
      setSelectedArea(event.target.value)
    }
    if (setPartSelected) {
      setDependFieldsLookupKeyValueContent([])
      setTableLookupKeyValueContent([])
      dispatch(getLookupDataForLookupsSuccess([]))
      dispatch(getLookupDataForDependFieldsSuccess([]))
      setPartSelected(event.target.value)
      setPartSelectedType(section.type)
      setPartObjectSelected(section)
      if (section.type === 'table') {
        let columObjs = []
        if(section.tableDef) {
          columObjs = section.tableDef?.columns
          setTableDef(section.tableDef)
        } else {
          const avblFields = docTemplateFlow.availableFields
          const columns = []
          _.forEach(avblFields, avblField => {
            columns.push({
              colWidth: 100,
              field: avblField?.id,
              headerName: avblField?.name,
            })
          })
          columObjs = columns
          setTableDef({ columns: columns })
        }
        const tableColumnDependentsMap = {}
        _.forEach(columObjs, column => {
          const { field, headerName, dependField, predefinedValues } = column
          tableColumnDependentsMap[field] = {
            name: headerName,
            id: field,
            dependField: dependField ? dependField : '',
            predefinedValues: predefinedValues ? predefinedValues : [],
          }
        })
        setTableColumnDependentsMap(tableColumnDependentsMap)
      }
      if (section.type === 'subSystemBlockDiagram') {
        setParamsDef(section.paramsDef)
      }
      setPartContent('')
    }
  }
  const handleChangeMultiple = (event) => {
    debugger
    let optionsData
    if (event.target && event.target.options) {
      const { options } = event.target
      optionsData = options
    } else if (event.currentTarget) {
      optionsData = event.currentTarget
    }
    const value = []
    for (let i = 0, l = optionsData.length; i < l; i += 1) {
      if (optionsData[i].selected) {
        value.push(optionsData[i].value)
      }
    }
    setSelectedSections(value)
    setTimeout(() => {
    
    })
    const section = _.find(sections , { title : event.target.value })
    if(section) {
      handlePartSelect(event, section)
    }
   
  }

  return (
    <Stack direction='column' sx={{ justifyContent: 'center' }} spacing={2}>
      <InputLabel sx={{ textAlign: 'center' }}>
        {label}
      </InputLabel>
      <select
        multiple
        style={{ minHeight: 300, height: 300, width: 400, padding: '5px' }}
        //onChange={handleChangeMultiple}
        //onSelect={handleChangeMultiple}
        onClick={handleChangeMultiple}
      >
        {_.map(sections, (section, index) => (
          <>
            <option key={`option_${label}_${index}`} value={section.title} selected={setPartSelected && partObjectSelected && partObjectSelected.type === section.type ? true : false}
                    style={{ fontSize: '15px', paddingBottom: '5px', fontFamily: 'sans-serif' }}
                    /*onClick={(event) => handlePartSelect(event, section)}*/>
              {section.type ? `${section.title} (${typeLabel[section.type]})` : `${section.title}`}
            </option>
          </>
        ))}
      </select>

    </Stack>

  )

}