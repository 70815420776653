import { getRectOfNodes, getTransformForBounds } from 'reactflow'
import { toPng } from 'html-to-image'
import HardwareSetup from '../../../components/extended/HardwareSetup'
import { SUBSYSTEM_BLOCK_SECTION, SYSTEM_BLOCK_SECTION } from '../../../utils/util'
import Search from '../document/Search'
import ImageUpload from '../../../components/extended/ImageUpload'
import Diagrams from '../../../components/extended/Diagrams'
import RTF from '../../../components/extended/RTF'
import Table from '../../../components/extended/Table'
import Grid from '@mui/material/Grid'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { subSystemSelector } from '../subsystem-store/subSystemSlice'
import { getDocumentPart, getGeneratedTestCases, getSearchDocumentPart } from '../document-store/saga'
import _ from 'lodash'
import { validateProps } from '../../../components/extended/HardwareSetup/validator'
import { wizardSelector } from '../../admin/document-template-flow/Wizard/store/wizardSlice'
import { searchParts } from '../../admin/document-template-flow/Wizard/store/saga'
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material'
import NewLineToBr from '../../../components/extended/NewLineToBr'
import Button from '@mui/material/Button'
import Warning from '@mui/icons-material/Warning'
import MDButton from '../../../components/core/MDButton'

export default ({
  isAdmin,
  selectedPart,
  selectedBlockDiagram,
  createSubSystemNode,
  onDeleteSubSystemNode,
  handleOnTreeNodeSelect,
  domain,
  subdomain,
  docId,
  sectionId,
  setAlertMsg,
  saveData,
  saveEnable,
  setDocumentDirtyFlag,
  saveDialogContent,
}) => {
  const subSystemData = useSelector(subSystemSelector)
  const docTemplateFlow = useSelector(wizardSelector)
  const dispatch = useDispatch()
  const [type, setType] = useState('RTF')
  const [title, setTitle] = useState()
  const [tableDef, setTableDef] = useState({})
  const [content, setContent] = useState([])
  const [newContent, setNewContent] = useState([])
  const [imageOrientation, setImageOrientation] = useState('horizontal')
  const [actionSet, setActionSet] = useState({})
  const [heading, setHeading] = useState('')
  const [reactFlowInstance, setReactFlowInstance] = useState(null)
  const [workflowContent, setWorkflowContent] = useState({})
  const [validationColumns, setValidationColumns] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [dirtyFlag, setDirtyFlag] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('')
  const [dialogBody, setDialogBody] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [isDeleteDialog, setIsDeleteDialog] = useState(false)
  const [testRows, setTestRows] = useState([])
  const [hwUnits, setHwUnits] = useState([])
  const [testRowLoading, setTestRowLoading] = useState(false)
  
  const tableChildRef = useRef()
  
  const getRemarks = (remark) => {
    if (_.isEmpty(remark)) return 'Ref Measure'
    switch (remark) {
      case 'powerUnit' :
        return 'Ref Power Measure'
      case 'cpuUnit' :
        return 'Ref Measure'
      case 'commsUnit' :
        return 'Ref Communication Measure'
      case 'pressureInput' :
        return 'Ref Pressure Measure'
      case 'tempInput' :
        return 'Refer Temperature Measure'
      case  'accelInput' :
        return 'Ref Accelerometer Measure'
      case 'rpmInput' :
        return 'Ref RPM  Measure'
      case 'loadInput' :
        return 'Ref Load Measure'
    }
  }
  
  useEffect(() => {
    updateDocumentPart()
    setTestRows([])
  }, [selectedPart])
  
  useEffect(() => {
    setDialogOpen(saveDialogContent.dialogOpen)
    setDialogTitle(saveDialogContent.title)
    setDialogBody(saveDialogContent.description)
    setIsDeleteDialog(true)
  }, [saveDialogContent])
  
  useEffect(() => {
    if (saveEnable) handleSave()
  }, [saveEnable])
  
  useEffect(() => {
    setDocumentDirtyFlag(dirtyFlag)
  }, [dirtyFlag])
  
  useEffect(() => {
    dispatch(searchParts({
      domain: domain,
      subdomain: subdomain,
      keyword: selectedPart?.title,
      type: type === 'RTF' ? 'text' : type,
      excludeDoc: docId,
    }))
    
  }, [domain, subdomain, type, docId, title])
  useEffect(() => {
    if (!selectedBlockDiagram || !selectedBlockDiagram.id || !docId) return
    dispatch(getDocumentPart({
      documentId: docId,
      partId: selectedBlockDiagram.id,
      onDataSuccess: (part) => {
        //debugger
        const { workFlowContent: { workflowInstance: { nodes } } } = part
        const units = _.map(nodes, ({ data: { executionInfo } }) => {
          return {
            hwUnit: executionInfo.actionId,
            label: executionInfo.displayName,
            instanceId: executionInfo.paramObject.instanceId,
          }
        })
        setHwUnits(units)
      },
    }))
    
  }, [selectedBlockDiagram])
  
  const handleGenerateTestCase = () => {
    setTestRowLoading(true)
    dispatch(
      getGeneratedTestCases({
        docId: docId,
        blockDiagramPartId: selectedBlockDiagram.id,
        onSuccess: (data) => {
          const tRows = _.map(data, (entry, index) => {
            return {
              id: index,
              hwUnit: entry['hwUnit'],
              hwInstanceId: `${entry['hwInstanceId']}`,
              type: 'Critical',
              lb: entry['Lower Bound'] ? entry['Lower Bound'] * 1 : entry['Lower Bound'],
              ub: entry['Upper Bound'] ? entry['Upper Bound'] * 1 : entry['Upper Bound'],
              remarks: getRemarks(entry['HwUnit']),
              name: entry['Name'],
              parameter: entry['Parameter'],
              value: entry['Value'],
              section: entry['Section'],
              unit: entry['Unit'],
            }
          })
          setTestRows(tRows)
          setTestRowLoading(false)
        },
      }),
    )
  }
  const handleCancel = () => {
    setIsDeleteDialog(false)
    setDialogOpen(false)
    setDialogTitle('')
    setDialogBody('')
    if (saveDialogContent.dialogOpen) {
      setDirtyFlag(false)
      saveDialogContent.handleAction()
    }
  }
  
  const handleSave = () => {
    //debugger
    const saveObj = {
      partId: selectedPart.id,
      docId,
    }
    if (type === 'text') {
      saveObj['type'] = 'text'
      saveObj['text'] = dirtyFlag ? newContent : content
      setAlertMsg(dirtyFlag ? 'Saved Successfully !!' : 'Nothing to Save...')
      setDirtyFlag(false)
    } else if (type === 'table') {
      //debugger
      const newModifiedContent =
        tableChildRef.current.validateAndRemoveNumberFields(
          validationColumns,
          newContent,
        )
      if (
        tableChildRef.current.validateSystemSpecifications(
          validationColumns,
          newContent,
        )
      ) {
        saveObj['type'] = 'table'
        saveObj['table'] = newModifiedContent
        setAlertMsg(dirtyFlag ? 'Saved Successfully !!' : 'Nothing to Save...')
        setDirtyFlag(false)
      } else {
        return false
      }
    } else if (type === 'image') {
      saveObj['type'] = 'image'
      saveObj['image'] = newContent
      setAlertMsg(dirtyFlag ? 'Saved Successfully !!' : 'Nothing to Save...')
      setDirtyFlag(false)
    } else if (type === 'images') {
      saveObj['type'] = 'images'
      saveObj['orientation'] = imageOrientation
      saveObj['images'] = newContent
      setAlertMsg(dirtyFlag ? 'Saved Successfully !!' : 'Nothing to Save...')
      setDirtyFlag(false)
    } else if (type === 'subSystemBlockDiagram') {
      const { nodes } = reactFlowInstance.toObject()
      let validationFields = []
      _.forEach(nodes, node => {
        const requiredFields = validateProps(node?.data)
        validationFields = [...validationFields, ...requiredFields]
      })
      if (!_.isEmpty(validationFields)) {
        const erMsg = validationFields.join('\n')
        setIsDeleteDialog(false)
        setDialogBody(erMsg)
        setDialogTitle('Error Message')
        setDialogOpen(true)
        return false
      }
      saveObj['type'] = 'subSystemBlockDiagram'
      saveObj['workFlowContent'] = {
        workflowInstance: reactFlowInstance.toObject(),
      }
      const dataParams = {}
      _.forEach(nodes, node => {
        const { actionId, paramObject } = node?.data?.executionInfo
        if (!dataParams[actionId]) {
          dataParams[actionId] = []
        }
        const paramObj = {}
        _.forEach(paramObject, function (value, key) {
          paramObj[_.last(key.split('.'))] = value
        })
        dataParams[actionId].push(paramObj)
      })
      saveObj['params'] = dataParams
      generateImageFromFlow(saveObj, 'subSystemBlockDiagram')
      setAlertMsg('Saved Successfully !!')
    } else if (type === 'systemBlockDiagram') {
      saveObj['type'] = 'systemBlockDiagram'
      saveObj['workFlowContent'] = {
        workflowInstance: reactFlowInstance.toObject(),
      }
      generateImageFromFlow(saveObj, 'systemBlockDiagram')
      setAlertMsg('Saved Successfully !!')
    }
    if (type !== 'subSystemBlockDiagram' && type !== 'systemBlockDiagram') {
      saveData(saveObj)
      // setAlertMsg('Saved Successfully !!')
    }
    
  }
  useEffect(() => {
    const updatedOptions = _.map(docTemplateFlow.searchedParts, part => {
      return {
        label: `Project: ${part.projectName}, Document: ${part.documentName}, Section: ${part.sectionName}, Part: ${part.partName}`,
        key: `${part.projectId} ${part.documentId} ${part.sectionId} ${part.partId} `,
        documentId: part.documentId,
        partId: part.partId,
      }
    })
    setSuggestions(updatedOptions)
  }, [docTemplateFlow.searchedParts])
  
  const updateDocumentPart = () => {
    setContent([])
    setHeading('')
    setNewContent([])
    setTitle(selectedPart?.title)
    const documentPart = selectedPart
    const { type } = documentPart
    const sectionToDisplay = documentPart
    setType(type)
    if (type === 'table') {
      const { table, heading, tableDef } = sectionToDisplay || ''
      setContent(table)
      setHeading(heading)
      setTableDef(tableDef)
    } else if (type === 'text') {
      const { text, heading } = sectionToDisplay || ''
      setContent(text)
      setHeading(heading)
    } else if (type === 'image') {
      const { image, heading } = sectionToDisplay || ''
      setContent(image)
      setHeading(heading)
    } else if (type === 'images') {
      const { images, title } = sectionToDisplay || ''
      setContent(images)
      setHeading(title)
    } else if (type === 'subSystemBlockDiagram') {
      const { workFlowContent, paramsDef, subSystemBlockDiagram, heading } =
      sectionToDisplay || ''
      setActionSet(paramsDef)
      setContent(subSystemBlockDiagram)
      setHeading(heading)
      setWorkflowContent(workFlowContent)
    } else if (type === 'systemBlockDiagram') {
      const { workFlowContent } = sectionToDisplay || ''
      setWorkflowContent(workFlowContent)
    }
  }
  
  const downloadSystemBlockDiagram = (data, saveObj, partType) => {
    saveObj[partType] = data
    setNewContent(data)
    saveData(saveObj).then(r => console.log('success'))
  }
  const onChangeOfInputNodeInputValue = ({
    nodeId,
    inputValueCurrentIndex,
    value,
  }) => {
    let newInputData = []
    let inputData // = hardwareSetupData.inputParameters[nodeId]?.inputData
    if (inputData) {
      newInputData = [...inputData]
    }
    newInputData[inputValueCurrentIndex] = value
  }
  const onChangeOfOutputNodeOutputValue = ({
    nodeId,
    outputValueCurrentIndex,
    value,
  }) => {
    let newOutputData = []
    let outputData // =hardwareSetupData.outputParameters[nodeId]?.outputData
    if (outputData) {
      newOutputData = [...outputData]
    }
    newOutputData[outputValueCurrentIndex] = value
  }
  const generateImageFromFlow = (saveObj, partType) => {
    const imageWidth = 1024
    const imageHeight = 768
    const nodesBounds = getRectOfNodes(reactFlowInstance.getNodes())
    const transform = getTransformForBounds(
      nodesBounds,
      imageWidth,
      imageHeight,
      0.5,
      2,
    )
    toPng(document.querySelector('.react-flow__viewport'), {
      // backgroundColor: '#1a365d',
      width: imageWidth,
      height: imageHeight,
      style: {
        width: imageWidth,
        height: imageHeight,
        transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
      },
    }).then(data => downloadSystemBlockDiagram(data, saveObj, partType))
  }
  const getInputNumberValue = ({ nodeId }) => {
    // return (hardwareSetupData.inputParameters[nodeId] && hardwareSetupData.inputParameters[nodeId].inputNumber ? hardwareSetupData.inputParameters[nodeId].inputNumber : 0)
  }
  const getOutputNumberValue = ({ nodeId }) => {
    //  return (hardwareSetupData.outputParameters[nodeId] && hardwareSetupData.outputParameters[nodeId].outputNumber ? hardwareSetupData.outputParameters[nodeId].outputNumber : 0)
  }
  const handleDeleteOk = () => {
    if (saveDialogContent.dialogOpen) {
      handleSave()
      setIsDeleteDialog(false)
      setDialogOpen(false)
      setDialogTitle('')
      setDialogBody('')
      setDirtyFlag(false)
      saveDialogContent.handleAction()
    } else {
      tableChildRef.current.handleRowDelete()
    }
  }
  const handleSelectedChip = selectedValue => {
    if (selectedValue) {
      dispatch(
        getSearchDocumentPart({
          documentId: selectedValue.documentId,
          partId: selectedValue.partId,
        }),
      )
    }
  }
  return (
    <Grid item sx={{ marginTop: '10px' }}>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 435 } }}
        maxWidth='xs'
        open={dialogOpen}
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {saveDialogContent.dialogOpen
            && <Warning sx={{ marginRight: '10px' }} />
            }
            {dialogTitle}
          </Box>
        </DialogTitle>
        <DialogContent>
          <NewLineToBr>{dialogBody}</NewLineToBr>
        </DialogContent>
        <DialogActions>
          {!isDeleteDialog && (
            <Button autoFocus onClick={handleCancel}>
              Ok
            </Button>
          )}
          {isDeleteDialog && (
            <>
              <Button autoFocus onClick={handleCancel}>
                Cancel
              </Button>
              <Button onClick={handleDeleteOk}>Ok</Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      {type === 'systemBlockDiagram' && (
        <HardwareSetup
          type={SYSTEM_BLOCK_SECTION}
          subSystemData={subSystemData}
          handleOnTreeNodeSelect={handleOnTreeNodeSelect}
          onCreateSubSystemNode={createSubSystemNode}
          workflowContent={workflowContent}
          setReactFlowInstance={setReactFlowInstance}
          reactFlowInstance={reactFlowInstance}
          onDeleteSubSystemNode={onDeleteSubSystemNode}
          setDialogBody={setDialogBody}
          setDialogTitle={setDialogTitle}
          setDialogOpen={setDialogOpen}
          setIsDeleteDialog={setIsDeleteDialog}
        />
      )}
      {type === 'subSystemBlockDiagram' && (
        <HardwareSetup
          type={SUBSYSTEM_BLOCK_SECTION}
          actionTemplate={actionSet}
          heading={heading}
          workflowContent={workflowContent}
          setReactFlowInstance={setReactFlowInstance}
          reactFlowInstance={reactFlowInstance}
          onChangeOfInputNodeInputValue={
            onChangeOfInputNodeInputValue
          }
          onChangeOfOutputNodeOutputValue={
            onChangeOfOutputNodeOutputValue
          }
          getInputNumberValue={getInputNumberValue}
          getOutputNumberValue={getOutputNumberValue}
          setDialogBody={setDialogBody}
          setDialogTitle={setDialogTitle}
          setDialogOpen={setDialogOpen}
          setIsDeleteDialog={setIsDeleteDialog}
        />
      )}
      {type === 'image' && (
        <>
          <Search
            type={type}
            setNewContent={setNewContent}
            setTableDef={setTableDef}
            setImageOrientation={setImageOrientation}
            domain={domain}
            subdomain={subdomain}
            docId={docId}
          />
          <ImageUpload
            content={content}
            newContent={newContent}
            setNewContent={setNewContent}
            setDirtyFlag={setDirtyFlag}
          />
        </>
      )}
      {type === 'images' && (
        <>
          <Search
            type={type}
            setNewContent={setNewContent}
            setTableDef={setTableDef}
            setImageOrientation={setImageOrientation}
            domain={domain}
            subdomain={subdomain}
            docId={docId}
          />
          <Diagrams
            content={content}
            imageOrientation={imageOrientation}
            setImageOrientation={setImageOrientation}
            newContent={newContent}
            setNewContent={setNewContent}
            isSuggestion={true}
            selectedChip={handleSelectedChip}
            suggestions={suggestions}
            setSuggestions={setSuggestions}
            setDirtyFlag={setDirtyFlag}
          />
        </>
      )}
      {type === 'text' && (
        <>
          <Search
            type={type}
            setNewContent={setNewContent}
            setTableDef={setTableDef}
            setImageOrientation={setImageOrientation}
            domain={domain}
            subdomain={subdomain}
            docId={docId}
          />
          <RTF
            content={content}
            title={''}
            newContent={newContent}
            setNewContent={setNewContent}
            isSuggestion={true}
            selectedChip={handleSelectedChip}
            suggestions={suggestions}
            setSuggestions={setSuggestions}
            setDirtyFlag={setDirtyFlag}
          />
        </>
      )}
      {type === 'table' && (
        <>
          <Stack direction={'row'} sx={{ justifyContent: 'end', mt: -10 }}>
            <Search
              type={type}
              setNewContent={setNewContent}
              setTableDef={setTableDef}
              setContent={setContent}
              setImageOrientation={setImageOrientation}
              domain={domain}
              subdomain={subdomain}
              docId={docId}
            />
            {selectedBlockDiagram && (<><MDButton color='dark' variant='outlined'
                                                  onClick={handleGenerateTestCase}
                                                  sx={{ margin: 1 }}>
              Generate Test Cases
            </MDButton>
              {testRowLoading &&
              (<CircularProgress color={'black'} sx={{ verticalAlign: 'middle' }} />)}
            </>)
            }
          </Stack>
          <Table
            ref={tableChildRef}
            content={content}
            hwUnits={hwUnits}
            testRows={testRows}
            newContent={newContent}
            title={''}
            tableDef={tableDef}
            setContent={setContent}
            setNewContent={setNewContent}
            setDialogBody={setDialogBody}
            setDialogTitle={setDialogTitle}
            setDialogOpen={setDialogOpen}
            defaultSection={sectionId}
            setValidationColumns={setValidationColumns}
            setIsDeleteDialog={setIsDeleteDialog}
            isAdminTable={false}
            isSuggestion={true}
            selectedChip={handleSelectedChip}
            suggestions={suggestions}
            setSuggestions={setSuggestions}
            setDirtyFlag={setDirtyFlag}
          />
        </>
      )}
    </Grid>
  )
}