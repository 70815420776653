import {
  useEffect,
  useState,
} from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Container from '@mui/material/Container'
import Icon from '@mui/material/Icon'
import MDBox from 'components/core/MDBox'
import MDTypography from 'components/core/MDTypography'
import MDButton from 'components/core/MDButton'
import DefaultNavbarMobile from 'components/core/Navbars/DefaultNavbar/DefaultNavbarMobile'
import breakpoints from 'assets/theme/base/breakpoints'
import { useMaterialUIController } from 'context'
import { Typography } from '@mui/material'

function DefaultNavbar ({ transparent, light, action }) {
  const [controller] = useMaterialUIController()
  const { darkMode } = controller

  const [mobileNavbar, setMobileNavbar] = useState(false)
  const [mobileView, setMobileView] = useState(false)

  const openMobileNavbar = ({ currentTarget }) => setMobileNavbar(currentTarget.parentNode)
  const closeMobileNavbar = () => setMobileNavbar(false)

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar () {
      if(window.innerWidth < breakpoints.values.lg) {
        setMobileView(true)
        setMobileNavbar(false)
      } else {
        setMobileView(false)
        setMobileNavbar(false)
      }
    }

    /**
     The event listener that's calling the displayMobileNavbar function when
     resizing the window.
     */
    window.addEventListener('resize', displayMobileNavbar)

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', displayMobileNavbar)
  }, [])

  return (
    <Container>
      {mobileView && <DefaultNavbarMobile open={mobileNavbar} close={closeMobileNavbar} />}
    </Container>
  )
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
  action: false,
}

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(['external', 'internal']).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'error',
        'dark',
        'light',
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
}

export default DefaultNavbar
