import MDButton from 'components/core/MDButton'
import {
  Box,
  Stack,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import MDTypography from 'components/core/MDTypography'
import _ from 'lodash'

const useStyles = makeStyles(({ spacing }) => ({
  unselectButton: {
    fontFamily: 'sans-serif',
    border: '1px solid #1a192b',
    color: '#1a192b',
    '&:hover': {
      border: '1px solid #1a192b',
    },
    width: '150px',
  },
  selectButton: {
    fontFamily: 'sans-serif',
    border: '1px solid #1a192b',
    color: '#ffffff',
    '&:hover': {
      border: '1px solid #1a192b',
    },
    width: '150px',
  },
}))

export default ({ setSelectedField, title, fields, setDependentOn, tableColumnDependentsMap, setDependAvailableFields, setAvailableLookupOption }) => {
  const classes = useStyles()
  const [selected, setSelected] = useState('0')
  
  useEffect(() => {
    _.map(fields , field => {
      if(field.id === 'type') {
        handleDependentFields(undefined , field)
      }
    })
  },[fields])
  
  
  const handleDependentFields = (e, field) => {
    setSelectedField(field)
    setSelected(field.id)
    if(!_.isEmpty(tableColumnDependentsMap) && tableColumnDependentsMap[field.id]){
      setDependentOn(tableColumnDependentsMap[field.id])
    }else {
      setDependentOn(field)
    }
    const filteredAvailableFields = _.filter(fields, availableField => field.id !== availableField.id)
    const options = _.map(filteredAvailableFields, filteredAvailableField => {
      return {
        value: filteredAvailableField.id,
        label: filteredAvailableField.name,
      }
    })
    setDependAvailableFields(options)
    setAvailableLookupOption({})
  }
  return (
    <>
      <Box sx={{ width: '20%' }}>
        <Box>
          <MDTypography p={2} variant={'h6'}>
            {title}
          </MDTypography>
        </Box>
        <Box
          sx={{ width: '100%', border: 1, justifyContent: 'center', height: 500, overflow: 'hidden', overflowY: 'scroll' }}>
          <Stack direction='column' sx={{ justifyContent: 'center', paddingTop: 3, paddingBottom: 3, paddingLeft: 1 }}
                 spacing={2}>
            {
              _.map(fields, (field, index) => {
                return (
                  <>
                    <MDButton variant={field.id === selected ? 'contained' : 'outlined'} color={'dark'}
                              size={'small'}
                              className={field.id === selected ? classes.selectButton : classes.unselectButton}
                              key={index} onClick={(e) => handleDependentFields(e, field)}>{field.name}</MDButton>
                  </>
                )
              })
            }
          </Stack>
        </Box>
      </Box>
    </>
  )
}