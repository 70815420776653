import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { validateProps } from '../../../components/extended/HardwareSetup/validator'
import { getDocumentPart, getGeneratedTestCases, saveDocumentPart } from '../document-store/saga'
import { useDispatch } from 'react-redux'
import Section from '../section'
import { Breadcrumbs, CardContent, CardHeader } from '@mui/material'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import KeyboardDoubleArrowUpOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowUpOutlined'
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined'
import MDButton from '../../../components/core/MDButton'
import Select from 'react-select'

export default ({
  isAdmin,
  upDocDone,
  setUpDocDone,
  downDocDone,
  setDownDocDone,
  linkOptions,
  setLinkOptions,
  allPartLinkOptions,
  setAllPartLinkOptions,
  createSubSystemNode,
  onDeleteSubSystemNode,
  handleOnTreeNodeSelect,
  domain,
  subdomain,
  docId,
  sectionId,
  setAlertMsg,
  setFailSaveDone,
  setSaveDone,
  dashData,
  mainDoc,
  sectionParts,
  saveEnable,
  setSaveEnable,
  setDirtyFlag,
  saveDialogContent,
}) => {
  const dispatch = useDispatch()
  const [loadedParts, setLoadedParts] = useState([])
  
  const [selectedPartId, setSelectedPartId] = useState()
  const [selectedBlockDiagram, setSelectedBlockDiagram] = useState()
  const [subsystemBlock, setSubsystemBlock] = useState()
  
  const saveData = async saveObj => {
    dispatch(
      saveDocumentPart({
        data: saveObj,
        onSuccess: refreshPartInStore,
        onFailure: refreshFailedPartInStore,
      }),
    )
  }
  const refreshFailedPartInStore = () => {
    setFailSaveDone(true)
    setSaveEnable(false)
    dispatch(
      getDocumentPart({
        documentId: dashData.selected_document.id,
        partId: mainDoc.selected_part.id,
      }),
    )
  }
  const refreshPartInStore = part => {
    setSaveDone(true)
    setSaveEnable(false)
    dispatch(
      getDocumentPart({
        documentId: dashData.selected_document.id,
        partId: mainDoc.selected_part.id,
      }),
    )
  }
  useEffect(() => {
    const nParts = []
    const allOptions = []
    _.map(sectionParts, ({ part, tag, sectionTitle, path }, index) => {
      nParts.push({
        index,
        part: part.id === selectedPartId ? mainDoc.selected_part : part,
        id: part.id,
        prevPartId: (index > 0) ? sectionParts[index - 1].part.id : -1,
        nextPartId: (index < sectionParts.length - 1) ? sectionParts[index + 1].part.id : -1,
        isVisible: part.id === selectedPartId,
        tag,
        sectionTitle,
        path,
      })
      
    })
    nParts.sort((a, b) => {
      return a.index > b.index ? 1 : -1
    })
    _.map(nParts, ({ part, path }) => {
      allOptions.push({
        value: part.id,
        label: path,
      })
    })
    setAllPartLinkOptions(allOptions)
    setLoadedParts(nParts)
    setSelectedBlockDiagram()
    setSubsystemBlock()
    const options = []
    _.map(nParts, ({ part, path, tag, sectionTitle }, index) => {
      const selectedSectionPart = _.find(nParts, { id: selectedPartId })
      if (selectedSectionPart) {
        if (tag === 'SubSystem' && sectionTitle === selectedSectionPart.sectionTitle
          && selectedSectionPart.tag === 'SubSystem' && part.type === 'subSystemBlockDiagram'
          && selectedSectionPart.part.type === 'table'
          && selectedSectionPart.part.title.toLowerCase().includes('test')) {
          setSelectedBlockDiagram(part)
        }
        if (tag === 'SubSystem' && sectionTitle === selectedSectionPart.sectionTitle
          && selectedSectionPart.tag === 'SubSystem' && part.type === 'subSystemBlockDiagram') {
          setSubsystemBlock(part)
        }
        if (tag === 'SubSystem' && selectedSectionPart.tag === 'SubSystem'
          && sectionTitle === selectedSectionPart.sectionTitle) {
          options.push({
            value: part.id,
            label: path,
          })
        }
      }
    })
    setLinkOptions(options)
  }, [sectionParts])
  
  useEffect(() => {
    if (mainDoc.selected_part) setSelectedPartId(mainDoc.selected_part.id)
  }, [mainDoc])
  
  useEffect(() => {
    if (!upDocDone) return
    const { prevPartId } = _.find(loadedParts, { isVisible: true })
    handleOnTreeNodeSelect({ id: prevPartId })
    setUpDocDone(false)
  }, [upDocDone])
  
  useEffect(() => {
    if (!downDocDone) return
    const { nextPartId } = _.find(loadedParts, { isVisible: true })
    handleOnTreeNodeSelect({ id: nextPartId })
    setDownDocDone(false)
  }, [downDocDone])
  
  return (<>{_.map(_.filter(loadedParts, { isVisible: true }),
    ({ part, nextPartId, prevPartId, path }, index) => (
      <>
        <Grid container justifyContent='flex-start' sx={{ mt: 2 }}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Typography sx={{ cursor: subsystemBlock ? 'pointer' : 'auto' }} variant={'caption'} color='text.primary'
                        onClick={() => {if (subsystemBlock) handleOnTreeNodeSelect({ id: subsystemBlock.id })}}
            >
              {path}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Card>
          <CardHeader title={part.title} />
          <CardContent>
            
            <Section
              isAdmin={isAdmin}
              selectedBlockDiagram={selectedBlockDiagram}
              selectedPart={part}
              createSubSystemNode={createSubSystemNode}
              onDeleteSubSystemNode={onDeleteSubSystemNode}
              handleOnTreeNodeSelect={handleOnTreeNodeSelect}
              domain={domain}
              subdomain={subdomain}
              docId={docId}
              saveData={saveData}
              sectionId={sectionId}
              setAlertMsg={setAlertMsg}
              saveEnable={saveEnable}
              setDocumentDirtyFlag={setDirtyFlag}
              saveDialogContent={saveDialogContent}
            /></CardContent></Card>
      </>
    ))
  }</>)
  
}