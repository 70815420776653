import Grid from '@mui/material/Grid'
import MDBox from 'components/core/MDBox'
import DashboardLayout from 'components/core/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/core/Navbars/DashboardNavbar'
import Footer from 'components/core/Footer'
import Sections from '../section-holder'
import React, { useEffect, useRef, useState } from 'react'
import Tree from 'components/extended/Tree'
import MDButton from 'components/core/MDButton'
import DocumentPreview from 'layouts/document-preview'
import _ from 'lodash'
import {
  getParentTree,
  getSelectedSectionIds,
  readSections,
} from 'layouts/document-management/document/document-reader'
import {
  Alert, Box,
  Snackbar,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { mainSelector } from '../../dashboard/store/mainSlice'
import {
  documentSelector,
} from '../document-store/documentSlice'
import {
  getDocument,
  getDocumentPart,
} from '../document-store/saga'
import { useNavigate } from 'react-router-dom'
import {
  addSubSystemMap,
  addSubSystems,
  subSystemSelector,
} from 'layouts/document-management/subsystem-store/subSystemSlice'
import {
  createSubsystem,
  deleteSubsystem,
} from 'layouts/document-management/subsystem-store/saga'
import 'layouts/document-management/css/react-confirm.css'
import CircularProgressStyled from 'components/extended/progress/CircularProgressStyled'
import DocumentPath from 'components/extended/DocumentPath'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import KeyboardDoubleArrowUpOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowUpOutlined'
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined'
import Select from 'react-select'

const appBarButtonStyle = {
  padding: '5px',
  borderRadius: '5px',
  border: '2px solid',
  color: 'white',
  fontSize: 'medium',
  marginRight: '10px',
  cursor: 'pointer',
}

export default ({ isAdmin }) => {
  const dispatch = useDispatch()
  const dashData = useSelector(mainSelector)
  const navigate = useNavigate()
  const mainDoc = useSelector(documentSelector)
  const subSystemData = useSelector(subSystemSelector)
  
  const [preview, setPreview] = useState(false)
  const [saveDone, setSaveDone] = useState(false)
  const [upDocDone, setUpDocDone] = useState(false)
  const [downDocDone, setDownDocDone] = useState(false)
  const [linkOptions, setLinkOptions] = useState([])
  const [allPartLinkOptions, setAllPartLinkOptions] = useState([])
  const [failSaveDone, setFailSaveDone] = useState(false)
  const [localData, setLocalData] = useState(null)
  const [domain, setDomain] = useState()
  
  const [subdomain, setSubdomain] = useState()
  const [project, setProject] = useState()
  const [documentName, setDocumentName] = useState()
  const [selectedIds, setSelectedIds] = useState([])
  const [defaultExpandedIds, setDefaultExpandedIds] = useState([])
  const [directoryTreeFolder, setDirectoryTreeFolder] = useState([])
  const [partId, setPartId] = useState(null)
  const [docId, setDocId] = useState(null)
  const [sectionId, setSectionId] = useState()
  const [defaultSelectedId, setDefaultSelectedId] = useState(null)
  
  const [parentMap, setParentMap] = useState(null)
  const [sectionParts, setSectionParts] = useState([])
  const [documentBreadCrumb, setDocumentBreadCrumb] = useState(null)
  const [alertMsg, setAlertMsg] = useState('Nothing to Save...')
  const [saveEnable, setSaveEnable] = useState(false)
  const [dirtyFlag, setDirtyFlag] = useState(false)
  const [saveDialogContent, setSaveDialogContent] = useState({})
  const indicatorSize = 80
  let documentId
  
  const defaultSelectedPart = partId => {
    setDefaultSelectedId(partId)
  }
  
  const updateDocumentData = async () => {
    const data = mainDoc.parts
    setDocId(dashData.selected_document.id)
    setLocalData(data)
    setDomain(data?.domain)
    setSubdomain(data?.subdomain)
    setProject(!_.isEmpty(dashData.selected_project) ? dashData.selected_project.name : '')
    setDocumentName(mainDoc.parts?.name)
    const { sections, name, id } = data
    const subSystems = []
    const idExpanded = []
    const subSystemMap = {}
    const treeArray = readSections({
      sections,
      subSystems,
      subSystemMap,
      idExpanded,
      defaultSelectedPart,
      selectedSection: dashData.selected_section,
    })
    setDefaultExpandedIds([id, ...idExpanded])
    
    dispatch(addSubSystems({ subSystemNames: subSystems }))
    dispatch(addSubSystemMap({ subSystemMap }))
    const directoryTreeArray = {
      name: '0',
      children: [
        {
          id,
          name,
          children: treeArray,
        },
      ],
    }
    const sectionPartList = []
    setDirectoryTreeFolder(directoryTreeArray)
    _.map(sections, section => {
      handleSectionPush(section, sectionPartList, '')
    })
    setSectionParts(sectionPartList)
  }
  
  const handleSectionPush = (section, sectionPartList, parentPath) => {
    _.map(section.parts, part => {
      sectionPartList.push({ part, tag: section.tag, sectionTitle: section.title, path: `${parentPath} / ${section.title} / ${part.title}` })
    })
    _.map(section.sections, sx => {
      handleSectionPush(sx, sectionPartList, `${parentPath} / ${section.title}`)
    })
  }
  
  useEffect(() => {
    if (_.isEmpty(mainDoc.parts)) return
    const data = mainDoc.parts
    const { sections, name, id, lastModifiedPart } = data
    const subSystems = []
    const subSystemMap = {}
    const treeArray = readSections({
      sections,
      subSystems,
      subSystemMap,
      defaultSelectedPart,
      selectedSection: dashData.selected_section,
    })
    dispatch(addSubSystems({ subSystemNames: subSystems }))
    dispatch(addSubSystemMap({ subSystemMap }))
    const selectedNodes = getSelectedSectionIds({
      id,
      treeArray,
      lastModifiedPart,
      defaultSelectedId,
    })
    const parentHierarchy = getParentTree(treeArray)
    setParentMap(parentHierarchy)
    if (!mainDoc.selected_part) {
      setSelectedIds(selectedNodes[0])
      handleOnTreeNodeSelect({ id: _.first(selectedNodes[0]) })
    } else {
      setPartId(mainDoc.selected_part.id)
      setSelectedIds([mainDoc.selected_part.id])
    }
  }, [directoryTreeFolder, defaultSelectedId])
  
  const handleOnTreeNodeSelect = ({ id, sectionId }) => {
    if (dirtyFlag) {
      setSaveDialogContent({
        dialogOpen: true,
        title: 'Save Changes',
        description: 'Changes are not yet saved. Do you want to save?',
        handleAction: () => {
          setPartId(id)
          setSelectedIds([id])
          setTimeout(() => {
            dispatch(
              getDocumentPart({
                documentId: dashData.selected_document.id,
                partId: id,
              }),
            )
          }, 100)
          setSectionId(sectionId)
        },
      })
    } else {
      setPartId(id)
      setSelectedIds([id])
      setTimeout(() => {
        dispatch(
          getDocumentPart({
            documentId: dashData.selected_document.id,
            partId: id,
          }),
        )
      }, 100)
      setSectionId(sectionId)
    }
  }
  
  useEffect(() => {
    if (!dashData.selected_document) {
      navigate('/dashboard')
      return
    }
    documentId = dashData.selected_document.id
    if (_.isEmpty(mainDoc.parts)) dispatch(getDocument(documentId))
  }, [])
  
  useEffect(() => {
    if (!_.isEmpty(mainDoc.parts)) updateDocumentData()
  }, [mainDoc])
  
  const getValue = (row, key, actualRow, actualKey) => {
    const keyArray = key.split('.')
    const val = row[keyArray[0]]
    if (keyArray.length > 1) {
      const newKey = keyArray.slice(1, keyArray.length).join('-')
      return getValue(val, newKey, actualRow, actualKey)
    } else {
      return val
    }
  }
  
  const createSubSystemNode = ({ subSystem, onSuccess }) => {
    dispatch(
      createSubsystem({
        data: {
          docId: docId,
          subSystemName: subSystem,
        },
        onSuccess: () => {
          onSuccess()
          refreshDocumentTree()
        },
      }),
    )
  }
  
  const handleSubSystemDelete = () => {
    // handleSave()
    refreshDocumentTree()
  }
  const onDeleteSubSystemNode = subSystemName => {
    dispatch(
      deleteSubsystem({
        data: {
          docId: docId,
          subSystemName: subSystemName,
        },
        onSuccess: handleSubSystemDelete,
      }),
    )
  }
  
  const refreshDocumentTree = () => {
    dispatch(getDocument(docId))
  }
  
  const handlePreview = () => {
    // localData
    setPreview(preview => !preview)
    if (preview) {
      dispatch(
        getDocumentPart({
          documentId: dashData.selected_document.id,
          partId,
        }),
      )
    }
  }
  
  const handlePreviewInReview = () => {
    // localData
    setPreview(preview => !preview)
  }
  
  return (
    <>
      <div className='container'>
        <Snackbar
          open={saveDone}
          autoHideDuration={2000}
          onClose={() => setSaveDone(false)}
        >
          <Alert
            onClose={() => setSaveDone(false)}
            severity='success'
            sx={{ width: '100%' }}
          >
            {alertMsg}
          </Alert>
        </Snackbar>
        <Snackbar
          open={failSaveDone}
          autoHideDuration={2000}
          onClose={() => setFailSaveDone(false)}
        >
          <Alert
            onClose={() => setFailSaveDone(false)}
            severity='error'
            sx={{ width: '100%' }}
          >
            Problem in Saving !!
          </Alert>
        </Snackbar>
        
        {!preview && (
          <DashboardLayout>
            <DashboardNavbar />
            <DocumentPath
              domain={domain}
              subdomain={subdomain}
              project={project}
              documentName={documentName}
            />
            <MDBox sx={{ flexGrow: 1 }}>
              <AppBar position='static' sx={{ bgcolor: '#03a9f4', width: '100%' }}>
                <Toolbar>
                  <Box sx={{ flexGrow: 1 }}>
                    {!_.isEmpty(allPartLinkOptions) && <Select
                      placeholder={'Go To Section'}
                      options={allPartLinkOptions}
                      styles={{
                        menu: base => ({
                          ...base,
                          zIndex: 10000,
                          maxWidth: '220px',
                          fontSize: '12px',
                        }),
                        valueContainer: base => ({
                          ...base,
                          zIndex: 10000,
                          maxWidth: '220px',
                          fontSize: '12px',
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          maxWidth: '220px',
                          fontSize: '12px',
                        }),
                      }}
                      onChange={ev => {
                        setLinkOptions([])
                        handleOnTreeNodeSelect({ id: ev.value })
                      }
                      }
                    />}
                  </Box>
                  
                  <Box sx={{ marginRight: '50px' }}>
                    {!_.isEmpty(linkOptions) && <Select
                      placeholder={'Go To Subsystem Section'}
                      options={linkOptions}
                      styles={{
                        menu: base => ({
                          ...base,
                          zIndex: 10000,
                          minWidth: '420px',
                        }),
                        valueContainer: base => ({
                          ...base,
                          zIndex: 10000,
                          minWidth: '420px',
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          minWidth: '420px',
                        }),
                      }}
                      onChange={ev => {
                        handleOnTreeNodeSelect({ id: ev.value })
                      }
                      }
                    />}
                  </Box>
                  <Typography style={appBarButtonStyle}
                              onClick={() => {setUpDocDone(true)}}><KeyboardDoubleArrowUpOutlinedIcon /></Typography>
                  <Typography style={appBarButtonStyle}
                              onClick={() => {setDownDocDone(true)}}><KeyboardDoubleArrowDownOutlinedIcon /></Typography>
                  <Typography style={appBarButtonStyle} onClick={handlePreview}>Preview</Typography>
                  <Typography style={{ ...appBarButtonStyle, color: dirtyFlag ? 'red' : 'white' }}
                              onClick={() => { setSaveEnable(true)}}>Save</Typography>
                </Toolbar>
              </AppBar>
            </MDBox>
            <MDBox sx={{ flexGrow: 1 }}>
              <Grid
                container
                
                sx={{ height: '100%' }}
                border={`2px solid`}
              >
                <Grid item xs={12} md={5} lg={2} borderRight={`2px solid`}>
                  {!_.isEmpty(directoryTreeFolder) && (
                    <Tree
                      data={directoryTreeFolder}
                      selectedIds={selectedIds}
                      defaultExpandedIds={defaultExpandedIds}
                      handleOnTreeNodeSelect={handleOnTreeNodeSelect}
                      parentMap={parentMap}
                      setDocumentBreadCrumb={setDocumentBreadCrumb}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={7} lg={10}>
                  {mainDoc.loading ||
                  (subSystemData.loading && (
                    <Grid item sx={{ height: '150px', marginTop: '30px' }}>
                      <CircularProgressStyled
                        size={indicatorSize}
                        sx={{
                          display:
                            mainDoc.loading || subSystemData.loading
                              ? 'block'
                              : 'none',
                          position: 'relative',
                          top: '30%',
                          left: '50%',
                          marginTop: `${-indicatorSize / 2}px`,
                          marginLeft: `${-indicatorSize / 2}px`,
                          background: 'transparent',
                          color: 'gray',
                        }}
                      />
                    </Grid>
                  ))}
                  < Sections
                    upDocDone={upDocDone}
                    setUpDocDone={setUpDocDone}
                    downDocDone={downDocDone}
                    setDownDocDone={setDownDocDone}
                    linkOptions={linkOptions}
                    setLinkOptions={setLinkOptions}
                    allPartLinkOptions={allPartLinkOptions}
                    setAllPartLinkOptions={setAllPartLinkOptions}
                    isAdmin={isAdmin}
                    createSubSystemNode={createSubSystemNode}
                    onDeleteSubSystemNode={onDeleteSubSystemNode}
                    handleOnTreeNodeSelect={handleOnTreeNodeSelect}
                    domain={domain}
                    subdomain={subdomain}
                    docId={docId}
                    sectionId={sectionId}
                    setAlertMsg={setAlertMsg}
                    setFailSaveDone={setFailSaveDone}
                    setSaveDone={setSaveDone}
                    dashData={dashData}
                    mainDoc={mainDoc}
                    sectionParts={sectionParts}
                    saveEnable={saveEnable}
                    setSaveEnable={setSaveEnable}
                    setDirtyFlag={setDirtyFlag}
                    saveDialogContent={saveDialogContent}
                  />
                </Grid>
              </Grid>
            </MDBox>
            <Footer />
          </DashboardLayout>
        )}
        {preview && (
          <DashboardLayout>
            <DashboardNavbar />
            <MDBox sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} border={`2px solid`}>
                <Grid item xs={12} md={5} lg={3} border={`1px solid`}>
                  {!_.isEmpty(directoryTreeFolder) && (
                    <Tree
                      data={directoryTreeFolder}
                      selectedIds={selectedIds}
                      defaultExpandedIds={defaultExpandedIds}
                      handleOnTreeNodeSelect={handleOnTreeNodeSelect}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={7} lg={9}>
                  <Grid container justifyContent='flex-end'>
                    <MDButton
                      color='dark'
                      variant='outlined'
                      onClick={handlePreview}
                      sx={{ marginRight: '40px' }}
                    >
                      Back
                    </MDButton>
                  </Grid>
                  <Grid item>
                    <DocumentPreview documentId={docId} />
                  </Grid>
                </Grid>
              </Grid>
            </MDBox>
            <Footer />
          </DashboardLayout>
        )}
      </div>
    </>
  )
}
