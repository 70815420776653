import * as React from 'react'
import { TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

export default ({ value, label, required, options, onValueChange, rowId }) => {
  return (
    <Autocomplete
      fullWidth
      disableClearable
      defaultValue={value}
      ListboxProps={{ style: { maxHeight: 280 } }}
      options={options}
      getOptionLabel={(option) => option.label}
      onChange={(evt, val) => onValueChange(val.name, rowId)}
      renderInput={(value) => (
        <TextField {...value} label={label} variant='standard' size='small' />
      )}
    />
  )
}
