import {
  Stack,
  TextField,
} from '@mui/material'
import SectionsList from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/SectionsList'
import * as React from 'react'
import Button from '@mui/material/Button'
import { useSelector } from 'react-redux'
import { wizardSelector } from 'layouts/admin/document-template-flow/Wizard/store/wizardSlice'

export default ({
  customNamePlaceHolder,
  available,
  added,
  addToAdded,
  removeFromAdded,
  setSelectedAvailable,
  setSelectedAdded,
  availableTitle,
  addedTitle,
  setPartSelected,
  setSubsystemPartSelected,
  subsystemPartSelected,
  setPartSelectedType,
  setPartObjectSelected,
  setSelectedArea,
  setPartContent,
  setCustomSection,
  addCustomSection,
  setTableDef,
  setParamsDef,
  partSelected,
  modifyPartName,
  setDependFieldsLookupKeyValueContent,
  setTableLookupKeyValueContent,
  setTableColumnDependentsMap,
  saveSubsystemName,
  partObjectSelected,
}) => {
  const docTemplateFlow = useSelector(wizardSelector)
  return (
    <Stack direction='column' sx={{ justifyContent: 'left' }} spacing={4}>
      <Stack direction='row' sx={{ justifyContent: 'center' }} spacing={2}>
        <Stack direction='column' sx={{ justifyContent: 'center', paddingTop: 8, paddingRight: 8 }} spacing={2}>
          <SectionsList sections={available} label={availableTitle}
                        setSelectedSections={setSelectedAvailable} />
        </Stack>
        <Stack direction='column' sx={{ justifyContent: 'center' }} spacing={2}>
          {docTemplateFlow.wizardType !== 'view' && (
            <>
              <Button style={{ color: 'black' }} variant={'outlined'} onClick={addToAdded}> {'>> Add >>'} </Button>
              <Button style={{ color: 'black' }} variant={'outlined'}
                      onClick={removeFromAdded}> {'<< Remove <<'} </Button>
            </>
          )
          }

        </Stack>

        <Stack direction='column' sx={{ justifyContent: 'center', paddingLeft: 8 }} spacing={2}>
          {!setPartSelected && docTemplateFlow.wizardType !== 'view' && (
            <Stack direction='row' sx={{ justifyContent: 'right' }} spacing={2}>
              <TextField
                placeholder={customNamePlaceHolder}
                name={'inputText'}
                variant='outlined'
                onChange={(e) => setCustomSection(e.target.value)}
              />
              <Button style={{ color: 'black' }} variant={'outlined'} onClick={addCustomSection}>Add</Button>
            </Stack>
          )}
          {setPartSelected && !setSubsystemPartSelected && docTemplateFlow.wizardType !== 'view' && (
            <Stack direction='row' sx={{ justifyContent: 'right' }} spacing={2}>
              <TextField
                placeholder={'Change Part Name'}
                name={'inputText'}
                variant='outlined'
                value={partSelected}
                onChange={(e) => setPartSelected(e.target.value)}
              />
              <Button style={{ color: 'black' }} variant={'outlined'} onClick={modifyPartName}>Rename</Button>
            </Stack>
          )}
          {setPartSelected && setSubsystemPartSelected && docTemplateFlow.wizardType !== 'view' && (
            <Stack direction='row' sx={{ justifyContent: 'right' }} spacing={2}>
              <TextField
                placeholder={'Change Subsystem Name'}
                name={'inputText'}
                variant='outlined'
                sx={{ width: 300 }}
                value={subsystemPartSelected}
                onChange={(e) => setSubsystemPartSelected(e.target.value)}
              />
              <Button style={{ color: 'black' }} variant={'outlined'} onClick={saveSubsystemName}>Save</Button>
            </Stack>
          )}
          {docTemplateFlow.wizardType === 'view' && (
            <Stack direction='row' sx={{ justifyContent: 'right', paddingTop:5 }} spacing={2}>
            </Stack>
          )}
          <SectionsList sections={added} label={addedTitle}
                        setSelectedSections={setSelectedAdded}
                        setPartSelected={setPartSelected}
                        setPartSelectedType={setPartSelectedType}
                        setPartObjectSelected={setPartObjectSelected}
                        setSelectedArea={setSelectedArea}
                        setPartContent={setPartContent}
                        setTableDef={setTableDef}
                        setParamsDef={setParamsDef}
                        setDependFieldsLookupKeyValueContent={setDependFieldsLookupKeyValueContent}
                        setTableLookupKeyValueContent={setTableLookupKeyValueContent}
                        setTableColumnDependentsMap={setTableColumnDependentsMap}
                        partObjectSelected={partObjectSelected}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}