import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import { createAction } from '@reduxjs/toolkit'
import axiosWithoutInterceptors from 'axios'
import {
  createDocumentTemplateSuccess,
  createHardwareUnitSuccess,
  getAvailableFieldsSuccess,
  getAvailableLookupsSuccess,
  getAvailablePartsSuccess,
  getAvailableSectionsSuccess,
  getAvailableSubsystemsSuccess,
  getDocumentsByDomainSuccess,
  getDocumentTypesSuccess,
  getDomainsSuccess,
  getHardwareUnitsSuccess,
  getLookupDataForDependFieldsSuccess,
  getLookupDataForLookupsSuccess,
  getSubdomainsSuccess,
  onLoadingUserEnd,
  onLoadingUserFail,
  onLoadingUserStart,
  searchedPartsSuccess,
} from './wizardSlice'
import _ from 'lodash'
import { getDocumentSuccess } from 'layouts/dashboard/store/mainSlice'

const axios = axiosWithoutInterceptors
const baseUri = process.env.REACT_APP_API_BASE

export const getAvailableSections = createAction('getAvailableSectionsSaga')

export function * getAvailableSectionsSaga ({ payload: { domain, subdomain, doctype } }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.get, `/dms/admin/available-sections?domain=${domain}&subdomain=${subdomain}&doctype=${doctype} `, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const availableSections = result.data
    yield put(getAvailableSectionsSuccess(availableSections))

  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export const getAvailableParts = createAction('getAvailablePartsSaga')

export function * getAvailablePartsSaga () {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.get, `/dms/admin/available-parts`, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const availableParts = result.data
    yield put(getAvailablePartsSuccess(availableParts))

  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export const getAvailableSubsystems = createAction('getAvailableSubsystemsSaga')

export function * getAvailableSubsystemsSaga ({ payload }) {
  try {
    yield put(onLoadingUserStart())
    /*
    const result = yield call(axios.get, `/dms/document?docId=${payload}`, {
      withCredentials: true,
    })
    */
    yield put(onLoadingUserEnd())
    // const availableSubsystems = result.data
    const availableSubsystems = [{ name: 'Test Subsystem1' }, { name: 'Test Subsystem2' }, { name: 'Test Subsystem3' }, { name: 'Test Subsystem4' }]
    yield put(getAvailableSubsystemsSuccess(availableSubsystems))

  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export const getDomains = createAction('getDomainsSaga')

export function * getDomainsSaga () {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.get, `/dms/admin/domains`, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const domains = result.data
    const domainsDropDown = _.map(domains, (domain) => {
      return {
        id: domain,
        value: domain,
        label: domain,
      }
    })
    yield put(getDomainsSuccess(domainsDropDown))

  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export const getSubdomains = createAction('getSubdomainsSaga')

export function * getSubdomainsSaga ({ payload: { domain } }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.get, `/dms/admin/subdomains?domain=${domain}`, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const subdomains = result.data
    const subDomainsDropDown = _.map(subdomains, (subdomain) => {
      return {
        value: subdomain,
        label: subdomain,
      }
    })
    yield put(getSubdomainsSuccess(subDomainsDropDown))

  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export const getDocumentTypes = createAction('getDocumentTypesSaga')

export function * getDocumentTypesSaga ({ payload: { domain, subdomain } }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.get, `/dms/admin/documenttypes?domain=${domain}&subdomain=${subdomain}`, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const documentTypes = result.data
    const documentTypesDropDown = _.map(documentTypes, (documentType) => {
      return {
        value: documentType,
        label: documentType,
      }
    })
    yield put(getDocumentTypesSuccess(documentTypesDropDown))

  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export const createDocumentTemplate = createAction('createDocumentTemplateSaga')

export function * createDocumentTemplateSaga ({ payload: { data, navigate } }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.post, `/dms/admin/document-template`, data, {
      withCredentials: true,
    })
    const documentTemplateId = result.data
    const createDocumentData = {
      name: data.type,
      projectId: 'temporary-project',
      tags: '',
      docTemplateId: documentTemplateId.id,
    }
    if(!navigate) {
      const result = yield call(axios.post, `/dms/document`, createDocumentData, {
        withCredentials: true,
      })
      const documentRaw = result.data
      const document = _.pick(documentRaw, ['createdDate', 'id', 'name', 'owner', 'tags', 'type'])
      document.editPercentage = 10
      const { projectId } = createDocumentData
      yield put(getDocumentSuccess(document))
    }
    yield put(onLoadingUserEnd())
    yield put(createDocumentTemplateSuccess(documentTemplateId))
    if(navigate) {
      navigate('/administration')
    }
  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export const publishDocumentTemplate = createAction('publishDocumentTemplateSaga')

export function * publishDocumentTemplateSaga ({ payload: { data, navigate } }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.post, `/dms/admin/publish-document-template`, data, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const documentTemplateId = result.data
    yield put(createDocumentTemplateSuccess(documentTemplateId))
    navigate('/administration')
  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export const getDocumentsByDomain = createAction('getDocumentsByDomainSaga')

export function * getDocumentsByDomainSaga ({ payload: { domain } }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.get, `/dms/admin/document-templates?&domain=${domain}`, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const documentsByDomain = result.data
    yield put(getDocumentsByDomainSuccess(documentsByDomain))
  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export const getAvailableFields = createAction('getAvailableFieldsSaga')

export function * getAvailableFieldsSaga ({ payload }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.get, `/dms/admin/tablefields`, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const availableFields = result.data
    yield put(getAvailableFieldsSuccess(availableFields))

  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export const getAvailableLookups = createAction('getAvailableLookupsSaga')

export function * getAvailableLookupsSaga ({ payload }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.get, `/dms/admin/lookup`, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const availableLookups = result.data
    yield put(getAvailableLookupsSuccess(availableLookups))

  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export const getLookupByName = createAction('getLookupByNameSaga')

export function * getLookupByNameSaga ({ payload: { name, source } }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.get, `/dms/admin/lookup?name=${name}`, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const lookupData = result.data
    if(source === 'depend') {
      yield put(getLookupDataForDependFieldsSuccess(lookupData))
    } else {
      yield put(getLookupDataForLookupsSuccess(lookupData))
    }
  } catch (error) {
    yield put(onLoadingUserFail())
    if(source === 'depend') {
      yield put(getLookupDataForDependFieldsSuccess([]))
    } else {
      yield put(getLookupDataForLookupsSuccess([]))
    }
  }
}

export const createLookup = createAction('createLookupSaga')

export function * createLookupSaga ({ payload: { data } }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.post, `dms/admin/lookup`, data, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const documentTemplateId = result.data
    yield put(createDocumentTemplateSuccess(documentTemplateId))
  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export const deleteLookupByName = createAction('deleteLookupByNameSaga')

export function * deleteLookupByNameSaga ({ payload: { name } }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.delete, `/dms/admin/lookup?name=${name}`, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const lookupData = result.data
    yield put(getLookupDataForLookupsSuccess(lookupData))

  } catch (error) {
    yield put(onLoadingUserFail())
    yield put(getLookupDataForLookupsSuccess([]))
  }
}

export const searchParts = createAction('searchPartsSaga')

export function * searchPartsSaga ({ payload: { domain, subdomain, type, keyword, excludeDoc } }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.get, `/dms/search-parts?domain=${domain}&type=${type}&keywords=${keyword}&excludeDoc=${excludeDoc}`, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const searchedParts = result.data
    yield put(searchedPartsSuccess(searchedParts))

  } catch (error) {
    yield put(onLoadingUserFail())
  }
}


export const getHardwareUnits = createAction('getHardwareUnitsSaga')

export function * getHardwareUnitsSaga ({ payload: { actionId } }) {
  try {
    yield put(onLoadingUserStart())
    const url = actionId ? `/dms/admin/hardware-block?id=${actionId}` : `/dms/admin/hardware-block`
    const result = yield call(axios.get, url, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const hardwareUnits = result.data
    yield put(getHardwareUnitsSuccess(hardwareUnits))

  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export const createHardwareUnit = createAction('createHardwareUnitSaga')

export function * createHardwareUnitSaga ({ payload: { data } }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.post, `dms/admin/hardware-block`, data, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const hardwareUnitData = result.data
    yield put(createHardwareUnitSuccess(hardwareUnitData))
  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export default function * wizardSaga () {
  yield takeLatest(getAvailableSections.type, getAvailableSectionsSaga)
  yield takeLatest(getAvailableParts.type, getAvailablePartsSaga)
  yield takeLatest(getAvailableSubsystems.type, getAvailableSubsystemsSaga)
  yield takeLatest(getDomains.type, getDomainsSaga)
  yield takeLatest(getSubdomains.type, getSubdomainsSaga)
  yield takeLatest(getDocumentTypes.type, getDocumentTypesSaga)
  yield takeLatest(getDocumentsByDomain.type, getDocumentsByDomainSaga)
  yield takeLatest(getAvailableFields.type, getAvailableFieldsSaga)
  yield takeLatest(getAvailableLookups.type, getAvailableLookupsSaga)
  yield takeLatest(createDocumentTemplate.type, createDocumentTemplateSaga)
  yield takeLatest(publishDocumentTemplate.type, publishDocumentTemplateSaga)
  yield takeLatest(createLookup.type, createLookupSaga)
  yield takeLatest(getLookupByName.type, getLookupByNameSaga)
  yield takeLatest(deleteLookupByName.type, deleteLookupByNameSaga)
  yield takeLatest(searchParts.type, searchPartsSaga)
  yield takeLatest(createHardwareUnit.type, createHardwareUnitSaga)
  yield takeLatest(getHardwareUnits.type, getHardwareUnitsSaga)
}