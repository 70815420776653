import _ from 'lodash'

//TODO: fetch from specific lookups
const getSubSystemSpecSections = (title) => {
  
  if(!_.isEmpty(title) && title.toLowerCase().includes('general')) {
    return [
      "General",
      "Communication",
      "Mechanical",
      "System",
      "Compliance",
      "Monitoring",
      "Operating",
      "Electronics",
      "Regulation",
      "Information"
    ]
  }
  if(!_.isEmpty(title) && title.toLowerCase().includes('specification')) {
    return [
      "Sensor Sampling",
      "Power Management",
      "Network Management",
      "Data Security",
      "Data Reliability",
      "Operating System",
      "Test"
    ]
  }
  if(!_.isEmpty(title) && title.toLowerCase().includes('test')) {
    return [
      "Hardware Function",
      "Environmental",
      "Battery",
      "Assembly",
      "Communication",
      "Alerts",
      "IP Rating",
      "Certifications"
    ]
  }
  return []
}



export const readSections = ({ sections, subSystems, subSystemMap, idExpanded, defaultSelectedPart, selectedSection }) => {
  const treeArray = []
  _.forEach(sections, (section) => {
    if (idExpanded && !idExpanded.includes(section.id)) idExpanded.push(section.id)
    if (section.tag
      && section.tag === 'SubSystem'
      && subSystems) {
      let identifier = section.title
      if (!subSystems.includes(identifier)) subSystems.push(identifier)
      if (!subSystemMap[identifier]) subSystemMap[identifier] = []
      _.map(section.parts, ({ id, title, type }) => {
        subSystemMap[identifier].push({
          id,
          title,
          type,
          requirementSections: type === 'table' ?getSubSystemSpecSections(title)  : [],
        })
      })
    }
    if (section.tag
      && section.tag === selectedSection) {
      defaultSelectedPart(section.id)
    }
    treeArray.push({
      name: section.title ? section.title : section.type === 'text' ? 'TextArea' : (section.type === 'image' ? 'Image' : (section.type === 'systemBlockDiagrams' ? 'SystemBlockDiagram' : 'Table')),
      id: section.id,
      children: getChildren({ parts: section.parts, sections: section.sections, subSystems, subSystemMap, idExpanded, defaultSelectedPart, selectedSection }),
    })
  })
  return treeArray
}

const getChildren = ({ parts, sections, subSystems, subSystemMap, idExpanded, defaultSelectedPart, selectedSection }) => {
  const partsArray = (parts ? readSections({ sections: parts, subSystems, subSystemMap, idExpanded, defaultSelectedPart, selectedSection }) : [])
  const sectionsArray = (sections ? readSections({ sections, subSystems, subSystemMap, idExpanded, defaultSelectedPart, selectedSection }) : [])
  return [...partsArray, ...sectionsArray]
}

export const getParentTree = (treeArray) => {
  const nodeById = {}
  const parent = {}
  const nodeName = {}
  _.forEach(treeArray, (node) => {
    nodeById[node.id] = node.id
    nodeName[node.id] = node.name
    if (node.children && node.children.length > 0) {
      getSectionIdsWithParent({ treeArray: node.children, parentNodeId: node.id, nodeById, parent, nodeName })
    }
  })
  return { parent, nodeName }
}

const getSectionIdsWithParent = ({ treeArray, parentNodeId, nodeById, parent, nodeName }) => {
  _.forEach(treeArray, (node) => {
    nodeById[node.id] = node.id
    nodeName[node.id] = node.name
    parent[node.id] = parentNodeId
    if (node.children && node.children.length > 0) {
      getSectionIdsWithParent({ treeArray: node.children, nodeById, parent, nodeName })
    }
  })
}

export const getSelectedSectionIds = ({ id, treeArray, lastModifiedPart, defaultSelectedId }) => {
  const selectedIds = []
  const defaultExpandedIds = ['0', id]
  const selectedNodes = []
  if (!lastModifiedPart) {
    // To make sure if there is no last modified part system block diagram is default select
    lastModifiedPart = defaultSelectedId
  }
  
  if (!lastModifiedPart) {
    getSectionIds({ treeArray, selectedIds, defaultExpandedIds })
  } else {
    getSectionIdsWithId({ treeArray, selectedIds, defaultExpandedIds, lastModifiedPart })
  }
  selectedNodes.push(selectedIds)
  selectedNodes.push(defaultExpandedIds)
  return selectedNodes
}

const getSectionIds = ({ treeArray, selectedIds, defaultExpandedIds }) => {
  const node = _.first(treeArray)
  defaultExpandedIds.push(node.id)
  if (node.children && node.children.length > 0) {
    getSectionIds({ treeArray: node.children, selectedIds, defaultExpandedIds })
  } else {
    selectedIds.push(node.id)
  }
  
}

const getSectionIdsWithId = ({ treeArray, selectedIds, defaultExpandedIds, lastModifiedPart }) => {
  const nodeById = {}
  const parent = {}
  _.forEach(treeArray, (node) => {
    nodeById[node.id] = node.id
    if (node.children && node.children.length > 0) {
      getSectionIdsWithIdParent({ treeArray: node.children, selectedIds, defaultExpandedIds, lastModifiedPart, parentNodeId: node.id, nodeById, parent })
    }
  })
  const expandedIds = []
  getHierarchyIds(nodeById, parent, lastModifiedPart, expandedIds)
  selectedIds.push(lastModifiedPart)
  defaultExpandedIds.push(...expandedIds.reverse())
}

const getHierarchyIds = (nodeById, parent, lastModifiedPart, expandedIds) => {
  expandedIds.push(lastModifiedPart)
  if (parent[lastModifiedPart]) {
    getHierarchyIds(nodeById, parent, parent[lastModifiedPart], expandedIds)
  }
}

const getSectionIdsWithIdParent = ({ treeArray, selectedIds, defaultExpandedIds, lastModifiedPart, parentNodeId, nodeById, parent }) => {
  _.forEach(treeArray, (node) => {
    nodeById[node.id] = node.id
    parent[node.id] = parentNodeId
    if (node.children && node.children.length > 0) {
      getSectionIdsWithIdParent({ treeArray: node.children, selectedIds, defaultExpandedIds, lastModifiedPart, nodeById, parent })
    }
  })
}

