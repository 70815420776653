import {
  number,
  string,
} from 'yup'

export const getString = ({ required, minValue, maxValue, fieldName }) => {
  let str = string()
  if(required){
    str = str.required(`${fieldName} should be required`)
  }
  if(minValue){
    str = str.min(minValue, `${fieldName} should be minimum ${minValue}`)
  }
  if(maxValue){
    str = str.max(maxValue, `${fieldName} should have maximum ${maxValue}`)
  }
  return str
}

export const getEmail = ({ required, email, fieldName }) => {
  let str = string()
  if(required){
    str = str.required(`${fieldName} should be required`)
  }
  if(email){
    str = str.email(`${fieldName} must be email`)
  }
  return str
}

export const getNumber = ({ required, minValue, maxValue, isInteger, fieldName }) => {
  let nbr
  if(isInteger){
    nbr = number().typeError(`${fieldName} must be integer`).integer(`${fieldName} should be of type Integer`)
  }else{
    nbr = number().typeError(`${fieldName} must be number`)
  }
  if(required){
    nbr = nbr.required(`${fieldName} should be required`)
  }
  if(minValue){
    nbr = nbr.min(minValue, `${fieldName} should be minimum ${minValue}`)
  }
  if(maxValue){
    nbr = nbr.max(maxValue, `${fieldName} should have maximum ${maxValue}`)
  }
  return nbr
}

export const getType = ({ type, minValue, maxValue, required, fieldName }) => {
  if(type === 'string') {
    return getString({ required, minValue, maxValue, fieldName })
  } else if(type === 'email') {
    return getEmail({ required, email: true, fieldName })
  }else if(type === 'number') {
    return getNumber({ required, minValue, maxValue, isInteger: false, fieldName })
  } else if(type === 'integer') {
    return getNumber({ required, minValue, maxValue, isInteger: true, fieldName })
  }

  return null
}

