import * as React from 'react'
import { useEffect } from 'react'
import Box from '@mui/material/Box'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { TreeView } from '@mui/x-tree-view/TreeView'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import { Stack } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import {
  getLookupDataForDependFieldsSuccess,
  getLookupDataForLookupsSuccess,
  updateSectionTreeDataSuccess,
  updateTemplateWorkflowSectionsSuccess,
  updateTreeDataSuccess,
  wizardSelector,
} from 'layouts/admin/document-template-flow/Wizard/store/wizardSlice'
import _, { cloneDeep } from 'lodash'
import {
  getAvailableFields,
  getAvailableLookups,
  getAvailableParts,
  getAvailableSections,
  getAvailableSubsystems,
} from 'layouts/admin/document-template-flow/Wizard/store/saga'
import {
  addSectionsAndParts,
  findNodeToPushTheAddedNodes,
  findNodeToRemoveTheRemovedNodes,
  getPartContent,
  getSection,
  getSubsystemPartContent,
  getSubsystemTitle,
  updatePartContent,
  updatePartName,
  updateParts,
  updatePartsForRemoval,
  updateSections,
  updateSectionTreeDataPartTitle,
  updateSectionTreePartTitle,
  updateSubsystemName,
  updateSubsystemPartContent,
  updateSubsystemPartsForRemoval,
  updateSubystemParts,
} from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/Tree/RenderTree'
import Selection from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/Selection'
import PartSelection from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/PartSelection'
import 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/wizard.css'
import { useState } from 'react'

const useStyles = makeStyles(({ spacing }) => ({
  addButton: {
    fontFamily: 'sans-serif',
    border: '1px solid #1a192b',
    color: '#1a192b',
    margin: '10px',
    '&:hover': {
      border: '1px solid #1a192b',
    },
  },
  label: {
    fontSize: '15px !important',
  },
}))

export default ({ onStepComplete }) => {
  const dispatch = useDispatch()
  const docTemplateFlow = useSelector(wizardSelector)
  const workflowObj = _.cloneDeep((docTemplateFlow.templateWorkflow))
  const [expanded, setExpanded] = React.useState([])
  const [selected, setSelected] = React.useState([])
  const [treeNodes, setTreeNodes] = React.useState([])

  const [currentSection, setCurrentSection] = React.useState('')
  const [showSectionsView, setShowSectionsView] = React.useState(true)
  const [selectedAvailableSections, setSelectedAvailableSections] = React.useState([])
  const [selectedAddedSections, setSelectedAddedSections] = React.useState([])
  const [availableSections, setAvailableSections] = React.useState([])
  const [addedSections, setAddedSections] = React.useState([])

  const [currentPart, setCurrentPart] = React.useState('')
  const [showPartsView, setShowPartsView] = React.useState(false)
  const [selectedAvailableParts, setSelectedAvailableParts] = React.useState([])
  const [selectedAvailablePartOptions, setSelectedAvailablePartOptions] = React.useState([])
  const [selectedAddedParts, setSelectedAddedParts] = React.useState([])
  const [availableParts, setAvailableParts] = React.useState([])
  const [defaultAvailableParts, setDefaultAvailableParts] = React.useState([])
  const [addedParts, setAddedParts] = React.useState([])

  const [currentSubsystem, setCurrentSubsystem] = React.useState('')
  const [showSubsystemsView, setShowSubsystemsView] = React.useState(false)
  const [selectedAvailableSubsystems, setSelectedAvailableSubsystems] = React.useState([])
  const [selectedAddedSubsystems, setSelectedAddedSubsystems] = React.useState([])
  const [availableSubsystems, setAvailableSubsystems] = React.useState([])
  const [addedSubsystems, setAddedSubsystems] = React.useState([])

  const [availableFields, setAvailableFields] = React.useState([])
  const [tableColumnDependentsMap, setTableColumnDependentsMap] = React.useState([])
  const [tableDef, setTableDef] = React.useState({})
  const [paramsDef, setParamsDef] = React.useState([])

  const [availableLookups, setAvailableLookups] = React.useState([])
  const [tableLookupKeyValueContent, setTableLookupKeyValueContent] = React.useState([])
  const [dependFieldsLookupKeyValueContent, setDependFieldsLookupKeyValueContent] = React.useState([])
  const [lookupName, setLookupName] = React.useState('')
  const [partSelected, setPartSelected] = React.useState('')
  const [subsystemPartSelected, setSubsystemPartSelected] = React.useState('')
  const [partObjectSelected, setPartObjectSelected] = React.useState('')
  const [partSelectedType, setPartSelectedType] = React.useState('')

  const [selectedArea, setSelectedArea] = React.useState('')
  const [treeSelected, setTreeSelected] = React.useState(['Root__addchildsection'])
  const [currentAreaSelected, setCurrentAreaSelected] = React.useState('')
  const [sectionsToPartsMap, setSectionsToPartsMap] = React.useState('')
  const [sectionsToSubsystemsMap, setSectionsToSubsystemsMap] = React.useState('')
  const [customSection, setCustomSection] = React.useState('')
  const [inputSectionKeyValueContent, setInputSectionKeyValueContent] = React.useState([])
  const [content, setContent] = useState([])
  const [newContent, setNewContent] = useState([])

  const [partContent, setPartContent] = React.useState('')

  const defaultSectionTree = {
    id: 'root',
    title: 'Root',
    children: [
      {
        id: 'Root__addchildsection',
        type: 'childSectionButton',
        title: 'Add Child Section',
      },
    ],
  }
  useEffect(() => {
    if(_.isEmpty(treeNodes) && _.isEmpty(docTemplateFlow.section_tree)) {
      dispatch(updateTreeDataSuccess(defaultSectionTree))
      setTreeNodes(docTemplateFlow.section_tree)
    }
    if(_.isEmpty(treeNodes) && !_.isEmpty(docTemplateFlow.section_tree)) {
      setTreeNodes(docTemplateFlow.section_tree)
    }
    if(_.isEmpty(availableSections) && !_.isEmpty(docTemplateFlow.sectionTreeData) && !_.isEmpty(docTemplateFlow.sectionTreeData['Root']) && !_.isEmpty(docTemplateFlow.sectionTreeData['Root'].availableSections)) {
      setAvailableSections(docTemplateFlow.sectionTreeData['Root'].availableSections)
    } else if(_.isEmpty(availableSections)) {
      const { domain, subdomain, documentType } = docTemplateFlow.templateWorkflow.document
      dispatch(getAvailableSections({ domain: domain.value, subdomain: subdomain.value, doctype: documentType.value }))
    }
    if(_.isEmpty(addedSections) && !_.isEmpty(docTemplateFlow.sectionTreeData) && !_.isEmpty(docTemplateFlow.sectionTreeData['Root']) && !_.isEmpty(docTemplateFlow.sectionTreeData['Root'].addedSections)) {
      setAddedSections(docTemplateFlow.sectionTreeData['Root'].addedSections)
    }
    addSection('Root__addchildsection')
    if(_.isEmpty(availableFields)) dispatch(getAvailableFields())
    if(_.isEmpty(availableParts)) dispatch(getAvailableParts())
    if(_.isEmpty(availableLookups)) dispatch(getAvailableLookups())
    if(_.isEmpty(availableSubsystems)) dispatch(getAvailableSubsystems())
  }, [])

  useEffect(() => {
    //debugger
    if(currentAreaSelected === 'section') {
      setTreeSelected([`${currentSection}_${selectedArea}`])
    } else if(currentAreaSelected === 'part') {
      const treeNodes = `${currentPart}_${selectedArea}`
      const treeNodesArray = treeNodes.split('_')
      const partContentFromStore = getPartContent(workflowObj.sections, treeNodesArray)
      setPartContent(partContentFromStore)
      if(partObjectSelected && partObjectSelected.type === 'table') {
        if(!_.isEmpty(partContentFromStore.tableDef)) {
          setTableDef(partContentFromStore.tableDef)
          // Neeed to make changes here
        }
      }
      if(partObjectSelected && partObjectSelected.type === 'subSystemBlockDiagram') {
        setParamsDef(partContentFromStore.paramsDef)
      }
      setTreeSelected([`${currentPart}_${selectedArea}`])
    } else if(currentAreaSelected === 'subsystem') {
      const treeNodes = `${currentSubsystem}_${selectedArea}`
      const treeNodesArray = treeNodes.split('_')
      const partContentFromStore = getSubsystemPartContent(workflowObj.sections, treeNodesArray)
      setPartContent(partContentFromStore)
      if(partObjectSelected && partObjectSelected.type === 'table') {
        if(!_.isEmpty(partContentFromStore.tableDef))
          setTableDef(partContentFromStore.tableDef)
          // Need to make changes here
      }
      if(partObjectSelected && partObjectSelected.type === 'subSystemBlockDiagram') {
        setParamsDef(partContentFromStore.paramsDef)
      }
      setTreeSelected([`${currentSubsystem}_${selectedArea}`])
    }
  }, [selectedArea])

  useEffect(() => {
    //debugger
    if(_.isEmpty(partContent)) return
    if(currentAreaSelected === 'part') {
      const treeNodes = `${currentPart}_${selectedArea}`
      const treeNodesArray = treeNodes.split('_')
      updatePartContent(workflowObj.sections, partContent, treeNodesArray)
      dispatch(updateTemplateWorkflowSectionsSuccess(workflowObj.sections))
    } else if(currentAreaSelected === 'subsystem') {
      const treeNodes = `${currentSubsystem}_${selectedArea}`
      const treeNodesArray = treeNodes.split('_')
      updateSubsystemPartContent(workflowObj.sections, partContent, treeNodesArray)
      dispatch(updateTemplateWorkflowSectionsSuccess(workflowObj.sections))
    }
  }, [partContent])

  useEffect(() => {
    const activeSection = currentSection || 'Root'
    if(!_.isEmpty(docTemplateFlow.sectionTreeData) && !_.isEmpty(docTemplateFlow.sectionTreeData[activeSection]) && (!_.isEmpty(docTemplateFlow.sectionTreeData[activeSection].availableSections) || !_.isEmpty(docTemplateFlow.sectionTreeData[activeSection].addedSections))) {
      setAvailableSections(docTemplateFlow.sectionTreeData[activeSection].availableSections)
    } else {
      const mapSectionsToParts = createMapOfAvailablePartsForSections(docTemplateFlow.availableSections)
      const mapSectionsToSubsystemParts = createMapOfAvailablePartsForSubsystemsInSections(docTemplateFlow.availableSections)
      setSectionsToPartsMap(mapSectionsToParts)
      setSectionsToSubsystemsMap(mapSectionsToSubsystemParts)
      setAvailableSections(docTemplateFlow.availableSections)
    }
  }, [docTemplateFlow.availableSections])

  useEffect(() => {
    setAvailableParts(docTemplateFlow.availableParts)
    setDefaultAvailableParts(docTemplateFlow.availableParts)
    setAvailableSubsystems(docTemplateFlow.availableParts)
  }, [docTemplateFlow.availableParts])

  /*
  useEffect(() => {
    setAvailableSubsystems(docTemplateFlow.availableSubsystems)
  }, [docTemplateFlow.availableSubsystems])
  */
  useEffect(() => {
    setTreeNodes(docTemplateFlow.section_tree)
  }, [docTemplateFlow.section_tree])

  useEffect(() => {
    setAvailableFields(docTemplateFlow.availableFields)
  }, [docTemplateFlow.availableFields])

  useEffect(() => {
    setAvailableLookups(docTemplateFlow.availableLookups)
  }, [docTemplateFlow.availableLookups])

  useEffect(() => {
    //Get all the Available Fileds
    //
    let columns = []
    if(tableDef && tableDef.columns) {
      columns = tableDef.columns
    } else {
      const avblFields = docTemplateFlow.availableFields
      _.forEach(avblFields, avblField => {
        columns.push({
          //dependField : '',
          colWidth: 100,
          field: avblField?.id,
          headerName: avblField?.name,
          //predefinedValues : {},
        })
      })
    }
    const availableFieldObjs = _.map(columns, (column) => {
      const { field, headerName, dependField } = column
      return {
        name: headerName,
        id: field,
        dependField,
      }
    })
    
    setAvailableFields(availableFieldObjs)

  }, [tableDef])

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds)
  }

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds)
  }

  const addCustomSection = () => {
    //addSection('Root__addchildsection') // TODO
    addToAddedSections(customSection)
  }

  const createMapOfAvailablePartsForSections = (availableSections) => {
    const mapSectionsToParts = {}
    _.forEach(availableSections, availableSection => {
      mapSectionsToParts[availableSection.title] = availableSection.parts
    })
    return mapSectionsToParts
  }

  const createMapOfAvailablePartsForSubsystemsInSections = (availableSections) => {
    const mapSectionsToSubsystemParts = {}
    _.forEach(availableSections, availableSection => {
      if(availableSection.subSystemDefinition && availableSection.subSystemDefinition.parts) {
        mapSectionsToSubsystemParts[availableSection.title] = availableSection.subSystemDefinition.parts
      }
    })
    return mapSectionsToSubsystemParts
  }

  const addSection = (name) => {
    setCurrentAreaSelected('section')
    setTreeSelected([`${name}`])
    docTemplateFlow.show_section_view = true
    docTemplateFlow.show_part_view = false
    docTemplateFlow.show_subsystem_view = false
    setShowSectionsView(true)
    setShowPartsView(false)
    setShowSubsystemsView(false)
    const currentSectionToSet = name.split('__')[0]
    setCurrentSection(currentSectionToSet)
    const sectionTreeData = _.cloneDeep(docTemplateFlow.sectionTreeData)
    if(!sectionTreeData[currentSectionToSet] || !sectionTreeData[currentSectionToSet]['availableSections'] || (_.isEmpty(sectionTreeData[currentSectionToSet]['availableSections']) && _.isEmpty(sectionTreeData[currentSectionToSet]['addedSections']))) {
      sectionTreeData[currentSectionToSet] = {
        ...sectionTreeData[currentSectionToSet],
        availableSections: docTemplateFlow.availableSections,
        addedSections: [],
      }
      dispatch(updateSectionTreeDataSuccess(sectionTreeData))
    }
    setAvailableSections(sectionTreeData[currentSectionToSet].availableSections)
    setAddedSections(sectionTreeData[currentSectionToSet].addedSections)
  }
  const addPart = (name) => {
    setCurrentAreaSelected('part')
    setTreeSelected([`${name}`])
    docTemplateFlow.show_section_view = false
    docTemplateFlow.show_part_view = true
    docTemplateFlow.show_subsystem_view = false
    setShowSectionsView(false)
    setShowPartsView(true)
    setShowSubsystemsView(false)
    const currentPartToSet = name.split('__')[0]
    const section = currentPartToSet.split('_').pop()
    setCurrentPart(currentPartToSet)
    const sectionTreeData = _.cloneDeep(docTemplateFlow.sectionTreeData)
    if(!sectionTreeData[currentPartToSet] || !sectionTreeData[currentPartToSet]['availableParts'] || (_.isEmpty(sectionTreeData[currentPartToSet]['availableParts']) && _.isEmpty(sectionTreeData[currentPartToSet]['addedParts']))) {
      sectionTreeData[currentPartToSet] = {
        ...sectionTreeData[currentPartToSet],
        availableParts: (sectionsToPartsMap[section] ? sectionsToPartsMap[section] : defaultAvailableParts),
        addedParts: [],
      }
    }

    // This code is related to based on added types available parts will change - Start
    const addedPartsArray = sectionTreeData[currentPartToSet].addedParts
    const availablePartsArray = sectionTreeData[currentPartToSet].availableParts
    const addedPartsTypes = _.map(addedPartsArray, addedPart => addedPart.type)
    const remAvblParts = _.filter(availablePartsArray, (availablePart) => {
      if(!addedPartsTypes.includes(availablePart.type)) {
        return availablePart
      }
    })
    sectionTreeData[currentPartToSet].availableParts = remAvblParts
    // This code is related to based on added types available parts will change - end
    dispatch(updateSectionTreeDataSuccess(sectionTreeData))

    setAvailableParts(sectionTreeData[currentPartToSet].availableParts)
    setAddedParts(sectionTreeData[currentPartToSet].addedParts)
  }

  const editPart = (nodes) => {
    const currentPartToSetArray = nodes.id.split('_')
    const currentPartToSet = currentPartToSetArray.slice(0, -1).join('_')
    addPart(`${currentPartToSet}__addchildpart`)
    setCurrentAreaSelected('part')
    setSelectedArea(nodes.title)
    setDependFieldsLookupKeyValueContent([])
    setTableLookupKeyValueContent([])
    dispatch(getLookupDataForLookupsSuccess([]))
    dispatch(getLookupDataForDependFieldsSuccess([]))
    setPartSelected(nodes.title)
    setPartSelectedType(nodes.type)
    setTreeSelected([`${nodes.id}`])
    const section = nodes
    setPartObjectSelected(section)
    setCurrentPart(currentPartToSet)
    if(section.type === 'table') {
      //debugger
      const treeNodes = nodes.id
      const treeNodesArray = treeNodes.split('_')
      const partContentFromStore = getPartContent(workflowObj.sections, treeNodesArray)
      let columObjs = []
      if(section.tableDef) {
        if(partContentFromStore) {
          setTableDef((!_.isEmpty(partContentFromStore) && !_.isEmpty(partContentFromStore.tableDef)) ? partContentFromStore.tableDef : section.tableDef)
          setContent((!_.isEmpty(partContentFromStore) && !_.isEmpty(partContentFromStore.table)) ? partContentFromStore.table : [])
          //setPartContent(partContentFromStore)
          setPartContent({table: ((!_.isEmpty(partContentFromStore) && !_.isEmpty(partContentFromStore.table)) ? partContentFromStore.table : []), tableDef: ((!_.isEmpty(partContentFromStore) && !_.isEmpty(partContentFromStore.tableDef)) ? partContentFromStore.tableDef : section.tableDef)})
        } else {
          columObjs = section.tableDef?.columns
          setTableDef(section.tableDef)
        }
      } else {
        if(partContentFromStore) {
          setTableDef((!_.isEmpty(partContentFromStore) && !_.isEmpty(partContentFromStore.tableDef)) ? partContentFromStore.tableDef : section.tableDef)
          setContent((!_.isEmpty(partContentFromStore) && !_.isEmpty(partContentFromStore.table)) ? partContentFromStore.table : [])
          setPartContent({table: ((!_.isEmpty(partContentFromStore) && !_.isEmpty(partContentFromStore.table)) ? partContentFromStore.table : []), tableDef: ((!_.isEmpty(partContentFromStore) && !_.isEmpty(partContentFromStore.tableDef)) ? partContentFromStore.tableDef : section.tableDef)})
        } else {
          const avblFields = docTemplateFlow.availableFields
          const columns = []
          _.forEach(avblFields, avblField => {
            columns.push({
              colWidth: 100,
              field: avblField?.id,
              headerName: avblField?.name,
            })
          })
          columObjs = columns
          setTableDef({ columns: columns })
        }
      }
      const tableColumnDependentsMap = {}
      _.forEach(columObjs, column => {
        const { field, headerName, dependField, predefinedValues } = column
        tableColumnDependentsMap[field] = {
          name: headerName,
          id: field,
          dependField: dependField ? dependField : '',
          predefinedValues: predefinedValues ? predefinedValues : [],
        }
      })
      setTableColumnDependentsMap(tableColumnDependentsMap)
    }
    if(section.type === 'subSystemBlockDiagram') {
      setParamsDef(section.paramsDef)
    }
    setPartContent('')
  }
  const addSubSystem = (name) => {
    setCurrentAreaSelected('subsystem')
    setTreeSelected([`${name}`])
    docTemplateFlow.show_section_view = false
    docTemplateFlow.show_part_view = false
    docTemplateFlow.show_subsystem_view = true
    setShowSectionsView(false)
    setShowPartsView(false)
    setShowSubsystemsView(true)
    const currentSubsystemToSet = name.split('__')[0]
    const section = currentSubsystemToSet.split('_').pop()
    setCurrentSubsystem(currentSubsystemToSet)
    const sectionTreeData = _.cloneDeep(docTemplateFlow.sectionTreeData)
    if(!sectionTreeData[currentSubsystemToSet] || !sectionTreeData[currentSubsystemToSet]['availableSubsystems'] || (_.isEmpty(sectionTreeData[currentSubsystemToSet]['availableSubsystems']) && _.isEmpty(sectionTreeData[currentSubsystemToSet]['addedSubsystems']))) {
      sectionTreeData[currentSubsystemToSet] = {
        ...sectionTreeData[currentSubsystemToSet],
        availableSubsystems: (sectionsToSubsystemsMap[section] ? sectionsToSubsystemsMap[section] : defaultAvailableParts),
        addedSubsystems: [],
      }

    }
    // code for setting the subsystem name
    const treeNodeLevel = `${currentSubsystemToSet}`
    const treeNodeLevelArray = treeNodeLevel.split('_')
    const subsystemTitle = getSubsystemTitle(workflowObj.sections, treeNodeLevelArray)
    setSubsystemPartSelected(subsystemTitle)

    // This code is related to based on added types available parts will change - Start
    const addedSubsystemsArray = sectionTreeData[currentSubsystemToSet].addedSubsystems
    const availableSubsystemsArray = sectionTreeData[currentSubsystemToSet].availableSubsystems
    const addedSubsystemsTypes = _.map(addedSubsystemsArray, addedSubsystem => addedSubsystem.type)
    const remAvblSubsystems = _.filter(availableSubsystemsArray, (availableSubsystem) => {
      if(!addedSubsystemsTypes.includes(availableSubsystem.type)) {
        return availableSubsystem
      }
    })
    sectionTreeData[currentSubsystemToSet].availableSubsystems = remAvblSubsystems
    // This code is related to based on added types available parts will change - end
    dispatch(updateSectionTreeDataSuccess(sectionTreeData))

    setAvailableSubsystems(sectionTreeData[currentSubsystemToSet].availableSubsystems)
    setAddedSubsystems(sectionTreeData[currentSubsystemToSet].addedSubsystems)
  }

  const renderTree = (nodes) => {
    if(nodes.type && nodes.type === 'childSectionButton') {
      return (
        <TreeItem classes={{ label: classes.label }} key={nodes.id} nodeId={nodes.id} label={nodes.title}
                  onClick={() => addSection(nodes.id)}>
          {Array.isArray(nodes.children)
            ? nodes.children.map((node) => renderTree(node))
            : null}
        </TreeItem>
      )
    } else if(nodes.type && nodes.type === 'childPartButton') {
      return (
        <TreeItem classes={{ label: classes.label }} key={nodes.id} nodeId={nodes.id} label={nodes.title}
                  onClick={() => addPart(nodes.id)}>
          {Array.isArray(nodes.children)
            ? nodes.children.map((node) => renderTree(node))
            : null}
        </TreeItem>
      )
    } else if(nodes.type && nodes.type === 'subsystemButton') {
      return (
        <TreeItem classes={{ label: classes.label }} key={nodes.id} nodeId={nodes.id} label={nodes.title}
                  onClick={() => addSubSystem(nodes.id)}>
          {Array.isArray(nodes.children)
            ? nodes.children.map((node) => renderTree(node))
            : null}
        </TreeItem>
      )
    } else {
      return (
        <TreeItem classes={{ label: classes.label }} key={nodes.id} nodeId={nodes.id} label={nodes.title}
                  onClick={() => editPart(nodes)}>
          {Array.isArray(nodes.children)
            ? nodes.children.map((node) => renderTree(node))
            : null}
        </TreeItem>
      )
    }
  }

  const addToAddedSections = (customSectionTobeAdded) => {
    const addSectionsToAddedSections = []
    if(!_.isString(customSectionTobeAdded)) {
      if(_.isEmpty(selectedAvailableSections)) return
    }
    const avblSections = availableSections
    const remAvblSections = _.filter(avblSections, (avblSection) => {
      if(!selectedAvailableSections.includes(avblSection.title)) {
        return avblSection
      } else {
        addSectionsToAddedSections.push(avblSection)
      }
    })
    if(customSectionTobeAdded && _.isString(customSectionTobeAdded)) {
      addSectionsToAddedSections.push({ title: customSectionTobeAdded })
    }
    setAvailableSections(remAvblSections)
    setAddedSections(() => [...addedSections, ...addSectionsToAddedSections])
    setSelectedAvailableSections([])
    const treeNodes = _.cloneDeep(docTemplateFlow.section_tree)
    const treeNodesTobeAdded = _.map(addSectionsToAddedSections, (addSectionsToAddedSection) => {
      return getSection(currentSection, addSectionsToAddedSection)
    })
    const sectionTreeData = _.cloneDeep(docTemplateFlow.sectionTreeData)
    updateSections(workflowObj.sections, treeNodesTobeAdded)
    addSectionsAndParts(currentSection, addSectionsToAddedSections, treeNodesTobeAdded, sectionTreeData, defaultAvailableParts, workflowObj.sections)
    const treeNodeNameArray = currentSection.split('_')
    findNodeToPushTheAddedNodes([treeNodes], treeNodeNameArray, treeNodesTobeAdded)

    sectionTreeData[currentSection] = {
      ...sectionTreeData[currentSection],
      availableSections: remAvblSections,
      addedSections: [...addedSections, ...addSectionsToAddedSections],
    }

    dispatch(updateTemplateWorkflowSectionsSuccess(workflowObj.sections))
    dispatch(updateSectionTreeDataSuccess(sectionTreeData))
    dispatch(updateTreeDataSuccess(treeNodes))
  }

  const isPartOfAvailableSections = (addSection) => {
    const avbl = _.filter(docTemplateFlow.availableSections, availableSection => (availableSection.title === addSection.title))
    if(!_.isEmpty(avbl)) {
      return true
    }
    return false
  }

  const removeFromAddedSections = () => {
    if(_.isEmpty(selectedAddedSections)) return
    const addSectionsToAvailableSections = []
    const addCustomSectionsToRemove = []
    const addSections = addedSections
    const remAddedSections = _.filter(addSections, (addSection) => {
      if(!selectedAddedSections.includes(addSection.title)) {
        return addSection
      } else {
        if(isPartOfAvailableSections(addSection)) {
          addSectionsToAvailableSections.push(addSection)
        } else {
          addCustomSectionsToRemove.push(addSection)
        }
      }
    })
    setAddedSections(remAddedSections)
    setAvailableSections(() => [...availableSections, ...addSectionsToAvailableSections])
    setSelectedAddedSections([])

    const treeNodes = _.cloneDeep(docTemplateFlow.section_tree)
    let treeNodesTobeRemoved
    if(_.isEmpty(addCustomSectionsToRemove)) {
      treeNodesTobeRemoved = _.map(addSectionsToAvailableSections, (addSectionsToAvailableSection) => {
        return addSectionsToAvailableSection.title
      })
    } else {
      treeNodesTobeRemoved = _.map(addCustomSectionsToRemove, (addSectionsToAvailableSection) => {
        return addSectionsToAvailableSection.title
      })
    }

    const treeNodeNameArray = currentSection.split('_')
    findNodeToRemoveTheRemovedNodes([treeNodes], treeNodeNameArray, treeNodesTobeRemoved)

    const sectionTreeData = _.cloneDeep(docTemplateFlow.sectionTreeData)
    sectionTreeData[currentSection] = {
      ...sectionTreeData[currentSection],
      availableSections: [...availableSections, ...addSectionsToAvailableSections],
      addedSections: remAddedSections,
    }
    // docTemplateFlow.sectionTreeData = sectionTreeData
    dispatch(updateSectionTreeDataSuccess(sectionTreeData))
    dispatch(updateTreeDataSuccess(treeNodes))
  }

  const saveSubsystemName = () => {
    const newNameForSubsystemName = subsystemPartSelected
    const treeHierarchyLevel = `${currentSubsystem}`
    const treeHierarchyLevelArray = treeHierarchyLevel.split('_')
    updateSubsystemName(workflowObj.sections, newNameForSubsystemName, treeHierarchyLevelArray)
    dispatch(updateTemplateWorkflowSectionsSuccess(workflowObj.sections))
  }

  const modifyPartName = () => {
    //debugger
    const treeHierarchyUntilPart = `${currentPart}_${selectedArea}`
    const treeHierarchyUntilPartArray = treeHierarchyUntilPart.split('_')
    const newNameForPart = partSelected

    //Modified Added Parts
    const addedPartsObjs = _.cloneDeep(addedParts)
    _.forEach(addedPartsObjs, addedPartsObj => {
      if(partObjectSelected.type === addedPartsObj.type) {
        addedPartsObj.title = newNameForPart
      }
    })
    setAddedParts(addedPartsObjs)

    //Modified sections
    updatePartName(workflowObj.sections, newNameForPart, treeHierarchyUntilPartArray)
    dispatch(updateTemplateWorkflowSectionsSuccess(workflowObj.sections))

    // Modified sections tree
    const menuTreeNodes = _.cloneDeep(docTemplateFlow.section_tree)
    const treeHierarchyUntilPartForMenuTree = `${currentPart}`
    const treeHierarchyUntilPartForMenuTreeArray = treeHierarchyUntilPartForMenuTree.split('_')
    updateSectionTreePartTitle([menuTreeNodes], newNameForPart, treeHierarchyUntilPartForMenuTreeArray, partObjectSelected?.type)
    dispatch(updateTreeDataSuccess(menuTreeNodes))

    // Modified Section Tree Data
    const sectionTreeData = _.cloneDeep(docTemplateFlow.sectionTreeData)
    const treeHierarchyUntilPartForSectionTreeData = `${currentPart}`
    updateSectionTreeDataPartTitle(sectionTreeData, newNameForPart, treeHierarchyUntilPartForSectionTreeData, partObjectSelected?.type)
    dispatch(updateSectionTreeDataSuccess(sectionTreeData))
    setSelectedArea(newNameForPart)
  }

  const addToAddedParts = () => {
    if(_.isEmpty(selectedAvailableParts)) return
    const addPartsToAddedParts = []
    const section = currentPart.split('_').pop()
    const avblParts = availableParts //TODO
    const remAvblPartsObjs = _.filter(avblParts, (avblPart) => {
      if(!selectedAvailableParts.includes(avblPart.title)) {
        return avblPart
      } else {
        addPartsToAddedParts.push(avblPart)
      }
    })

    // This code is related to based on added types available parts will change - Start
    const addedPartsTypes = _.map(addPartsToAddedParts, addedPart => addedPart.type)
    const remAvblParts = _.filter(remAvblPartsObjs, (availablePart) => {
      if(!addedPartsTypes.includes(availablePart.type)) {
        return availablePart
      }
    })
    // This code is related to based on added types available parts will change - end

    setAvailableParts(remAvblParts)
    setAddedParts(() => [...addedParts, ...addPartsToAddedParts])
    setSelectedAvailableParts([])
    const treeNodes = _.cloneDeep(docTemplateFlow.section_tree)
    const treeNodesTobeAdded = _.map(addPartsToAddedParts, (addPartsToAddedPart) => {
      return {
        id: `${currentPart}_${addPartsToAddedPart.title}`,
        title: addPartsToAddedPart.title,
        type: addPartsToAddedPart.type,
      }
    })
    const treeNodeNameArray = currentPart.split('_')
    findNodeToPushTheAddedNodes([treeNodes], treeNodeNameArray, treeNodesTobeAdded)
    const sectionTreeData = _.cloneDeep(docTemplateFlow.sectionTreeData)
    sectionTreeData[currentPart] = {
      ...sectionTreeData[currentPart],
      availableParts: remAvblParts,
      addedParts: [...addedParts, ...addPartsToAddedParts],
    }
    updateParts(workflowObj.sections, treeNodesTobeAdded)
    dispatch(updateTemplateWorkflowSectionsSuccess(workflowObj.sections))
    dispatch(updateSectionTreeDataSuccess(sectionTreeData))
    dispatch(updateTreeDataSuccess(treeNodes))
  }

  const removeFromAddedParts = () => {
    if(_.isEmpty(selectedAddedParts)) return
    const addPartsToAvailableParts = []
    const addParts = addedParts
    const remAddedParts = _.filter(addParts, (addPart) => {
      if(!selectedAddedParts.includes(addPart.title)) {
        return addPart
      } else {
        addPartsToAvailableParts.push(addPart)
      }
    })

    // TODO - Need to check when removal in available parts should be before name change or not

    setAddedParts(remAddedParts)
    setAvailableParts(() => [...availableParts, ...addPartsToAvailableParts])
    setSelectedAddedParts([])

    const treeNodes = _.cloneDeep(docTemplateFlow.section_tree)
    const treeNodesTobeRemoved = _.map(addPartsToAvailableParts, (addPartsToAvailablePart) => {
      return addPartsToAvailablePart.title
    })
    const treeNodesTobeRemovedFromSectionsStore = _.map(addPartsToAvailableParts, (addPartsToAvailablePart) => {
      return {
        id: `${currentPart}_${addPartsToAvailablePart.title}`,
        title: addPartsToAvailablePart.title,
        type: addPartsToAvailablePart.type,
      }
    })
    const treeNodeNameArray = currentPart.split('_')
    findNodeToRemoveTheRemovedNodes([treeNodes], treeNodeNameArray, treeNodesTobeRemoved)

    const sectionTreeData = _.cloneDeep(docTemplateFlow.sectionTreeData)
    sectionTreeData[currentPart] = {
      ...sectionTreeData[currentPart],
      availableParts: [...availableParts, ...addPartsToAvailableParts],
      addedParts: remAddedParts,
    }
    updatePartsForRemoval(workflowObj.sections, treeNodesTobeRemovedFromSectionsStore)
    dispatch(updateTemplateWorkflowSectionsSuccess(workflowObj.sections))
    dispatch(updateSectionTreeDataSuccess(sectionTreeData))
    dispatch(updateTreeDataSuccess(treeNodes))
  }

  const addToAddedSubsystems = () => {
    if(_.isEmpty(selectedAvailableSubsystems)) return
    const addSubsystemsToAddedSubsystems = []
    const section = currentSubsystem.split('_').pop()
    const avblSubsystems = availableSubsystems
    const remAvblSubsystemObjs = _.filter(avblSubsystems, (avblSubsystem) => {
      if(!selectedAvailableSubsystems.includes(avblSubsystem.title)) {
        return avblSubsystem
      } else {
        addSubsystemsToAddedSubsystems.push(avblSubsystem)
      }
    })

    // This code is related to based on added types available parts will change - Start
    const addedSubsystemTypes = _.map(addSubsystemsToAddedSubsystems, addedSubsystem => addedSubsystem.type)
    const remAvblSubsystems = _.filter(remAvblSubsystemObjs, (avblSubsystem) => {
      if(!addedSubsystemTypes.includes(avblSubsystem.type)) {
        return avblSubsystem
      }
    })
    // This code is related to based on added types available parts will change - end

    setAvailableSubsystems(remAvblSubsystems)
    setAddedSubsystems(() => [...addedSubsystems, ...addSubsystemsToAddedSubsystems])
    setSelectedAvailableSubsystems([])

    const treeNodes = _.cloneDeep(docTemplateFlow.section_tree)
    const treeNodesTobeAdded = _.map(addSubsystemsToAddedSubsystems, (addSubsystemsToAddedSubsystem) => {
      return {
        id: `${currentSubsystem}_${addSubsystemsToAddedSubsystem.title}`,
        title: addSubsystemsToAddedSubsystem.title,
        type: addSubsystemsToAddedSubsystem.type,
      }
    })

    const treeNodeNameArray = currentSubsystem.split('_')
    //findNodeToPushTheAddedNodes([treeNodes], treeNodeNameArray, treeNodesTobeAdded)

    const sectionTreeData = _.cloneDeep(docTemplateFlow.sectionTreeData)
    sectionTreeData[currentSubsystem] = {
      ...sectionTreeData[currentSubsystem],
      availableSubsystems: remAvblSubsystems,
      addedSubsystems: [...addedSubsystems, ...addSubsystemsToAddedSubsystems],
    }
    // TODO need to verify
    updateSubystemParts(workflowObj.sections, treeNodesTobeAdded, subsystemPartSelected)
    dispatch(updateTemplateWorkflowSectionsSuccess(workflowObj.sections))
    dispatch(updateSectionTreeDataSuccess(sectionTreeData))
  }

  const removeFromAddedSubsystems = () => {
    if(_.isEmpty(selectedAddedSubsystems)) return
    const addSubsystemsToAvailableSubsystems = []
    const addSubsystems = addedSubsystems
    const remAddedSubsystems = _.filter(addSubsystems, (addSubsystem) => {
      if(!selectedAddedSubsystems.includes(addSubsystem.title)) {
        return addSubsystem
      } else {
        addSubsystemsToAvailableSubsystems.push(addSubsystem)
      }
    })
    setAddedSubsystems(remAddedSubsystems)
    setAvailableSubsystems(() => [...availableSubsystems, ...addSubsystemsToAvailableSubsystems])
    setSelectedAddedSubsystems([])

    const treeNodes = _.cloneDeep(docTemplateFlow.section_tree)
    const treeNodesTobeRemoved = _.map(addSubsystemsToAvailableSubsystems, (addSubsystemsToAvailableSubsystem) => {
      return addSubsystemsToAvailableSubsystem.title
    })
    const treeNodesTobeRemovedFromSectionsStore = _.map(addSubsystemsToAvailableSubsystems, (addSubsystemsToAvailableSubsystem) => {
      return {
        id: `${currentSubsystem}_${addSubsystemsToAvailableSubsystem.title}`,
        title: addSubsystemsToAvailableSubsystem.title,
        type: addSubsystemsToAvailableSubsystem.type,
      }
    })
    const treeNodeNameArray = currentSubsystem.split('_')
    //findNodeToRemoveTheRemovedNodes([treeNodes], treeNodeNameArray, treeNodesTobeRemoved)

    const sectionTreeData = _.cloneDeep(docTemplateFlow.sectionTreeData)
    sectionTreeData[currentSubsystem] = {
      ...sectionTreeData[currentSubsystem],
      availableSubsystems: [...availableSubsystems, ...addSubsystemsToAvailableSubsystems],
      addedSubsystems: remAddedSubsystems,
    }
    updateSubsystemPartsForRemoval(workflowObj.sections, treeNodesTobeRemovedFromSectionsStore)
    dispatch(updateTemplateWorkflowSectionsSuccess(workflowObj.sections))
    dispatch(updateSectionTreeDataSuccess(sectionTreeData))
  }
  const classes = useStyles()

  return (
    <Stack direction='row' sx={{ justifyContent: 'left' }} spacing={2}>
      <Box sx={{ minHeight: 270, minWidth: 300, maxWidth: 400, border: 1 }}>
        <TreeView
          aria-label='controlled'
          fontSize={10}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
          defaultExpanded={['root']}
          defaultSelected={['Root__addchildsection']}
          selected={treeSelected}
          multiSelect
        >
          {renderTree(treeNodes)}
        </TreeView>
      </Box>
      <Box sx={{ flexGrow: 2, border: 1, maxWidth:'80%' }}>
        <Box sx={{ border: 1, paddingTop: 3, paddingBottom: 3 }}>
          {showSectionsView && (
            <Selection
              customNamePlaceHolder={`Add Section Name`}
              available={availableSections}
              added={addedSections}
              addToAdded={addToAddedSections}
              removeFromAdded={removeFromAddedSections}
              setSelectedAvailable={setSelectedAvailableSections}
              setSelectedAdded={setSelectedAddedSections}
              availableTitle={`Available Sections`}
              addedTitle={`Added Sections`}
              setSelectedArea={setSelectedArea}
              setCustomSection={setCustomSection}
              addCustomSection={addCustomSection}
            />
          )}
          {showPartsView && (
            <Selection
              customNamePlaceHolder={`Add Part Name`}
              available={availableParts}
              added={addedParts}
              addToAdded={addToAddedParts}
              removeFromAdded={removeFromAddedParts}
              setSelectedAvailable={setSelectedAvailableParts}
              setSelectedAdded={setSelectedAddedParts}
              availableTitle={`Available Parts`}
              addedTitle={`Added Parts`}
              setPartSelected={setPartSelected}
              setPartObjectSelected={setPartObjectSelected}
              setPartSelectedType={setPartSelectedType}
              setSelectedArea={setSelectedArea}
              setPartContent={setPartContent}
              setTableDef={setTableDef}
              setParamsDef={setParamsDef}
              modifyPartName={modifyPartName}
              partSelected={partSelected}
              setDependFieldsLookupKeyValueContent={setDependFieldsLookupKeyValueContent}
              setTableLookupKeyValueContent={setTableLookupKeyValueContent}
              setTableColumnDependentsMap={setTableColumnDependentsMap}
              partObjectSelected={partObjectSelected}
              setContent={setContent}
              setNewContent={setNewContent}
              newContent={newContent}
              content={content}
            />
          )}
          {showSubsystemsView && (
            <Selection
              customNamePlaceHolder={`Add Subsystem Name`}
              available={availableSubsystems}
              added={addedSubsystems}
              addToAdded={addToAddedSubsystems}
              removeFromAdded={removeFromAddedSubsystems}
              setSelectedAvailable={setSelectedAvailableSubsystems}
              setSelectedAdded={setSelectedAddedSubsystems}
              availableTitle={`Available Subsystems`}
              addedTitle={`Added Subsystems`}
              setSelectedArea={setSelectedArea}
              setPartSelected={setPartSelected}
              setSubsystemPartSelected={setSubsystemPartSelected}
              subsystemPartSelected={subsystemPartSelected}
              setPartObjectSelected={setPartObjectSelected}
              setPartSelectedType={setPartSelectedType}
              setPartContent={setPartContent}
              setTableDef={setTableDef}
              setParamsDef={setParamsDef}
              modifyPartName={modifyPartName}
              partSelected={partSelected}
              setDependFieldsLookupKeyValueContent={setDependFieldsLookupKeyValueContent}
              setTableLookupKeyValueContent={setTableLookupKeyValueContent}
              setTableColumnDependentsMap={setTableColumnDependentsMap}
              saveSubsystemName={saveSubsystemName}
              setContent={setContent}
              setNewContent={setNewContent}
              newContent={newContent}
              content={content}
            />
          )}
        </Box>
        <Box sx={{ border: 1, paddingTop: 2, paddingBottom: 3 }}>
          <PartSelection setPartContent={setPartContent} partContent={partContent} availableFields={availableFields}
                         setAvailableLookups={setAvailableLookups}
                         dependFieldsLookupKeyValueContent={dependFieldsLookupKeyValueContent}
                         setDependFieldsLookupKeyValueContent={setDependFieldsLookupKeyValueContent}
                         availableLookups={availableLookups} lookupName={lookupName} partSelected={partSelected}
                         partSelectedType={partSelectedType}
                         setLookupName={setLookupName} tableLookupKeyValueContent={tableLookupKeyValueContent}
                         setTableLookupKeyValueContent={setTableLookupKeyValueContent}
                         inputSectionKeyValueContent={inputSectionKeyValueContent}
                         setInputSectionKeyValueContent={setInputSectionKeyValueContent}
                         tableColumnDependentsMap={tableColumnDependentsMap}
                         tableDef={tableDef}
                         setTableDef={setTableDef}
                         paramsDef={paramsDef}
                         setParamsDef={setParamsDef}
                         partObjectSelected={partObjectSelected}
                         setContent={setContent}
                         setNewContent={setNewContent}
                         newContent={newContent}
                         content={content}
          />
        </Box>
      </Box>
    </Stack>

  )
}