import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import axiosWithoutInterceptors from 'axios'
import { createAction } from '@reduxjs/toolkit'
import {
  loginSuccess,
  logoutSuccess,
  onLoadingUserEnd,
  onLoadingUserFail,
  onLoadingUserStart,
} from './userSlice'
import { cleanUpDocs } from '../../dashboard/store/mainSlice'

const axios = axiosWithoutInterceptors
const baseUri = process.env.REACT_APP_API_BASE

export const loginUser = createAction('loginUserSaga')

export function * loginUserSaga ({ payload: { username, password } }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.post, '/dms/login', { username, password }, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const check = result.data
    if(check.result === 'OK') {
      const { userId: user_id, displayName: user_display_name, role: user_role } = check.user
      yield put(loginSuccess({ user_id, user_display_name, user_role }))
    } else {
      yield put(onLoadingUserFail())
    }

  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export const logoutUser = createAction('logoutUserSaga')

export function * logoutUserSaga ({ payload: { navigate } }) {
  try {
    yield put(onLoadingUserStart())
    yield call(axios.post, '/dms/logout', {}, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    yield put(logoutSuccess())
    yield put(cleanUpDocs())
    navigate('/authentication/sign-in')

  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export const getUser = createAction('getUserSaga')

export function * getUserSaga () {
  try {
    const result = yield call(axios.get, '/dms/user', {
      withCredentials: true,
    })
    const check = result.data
    if(check.result === 'OK') {
      const { userId: user_id, displayName: user_display_name, role: user_role , sessionId : session_id } = check.user
      yield put(loginSuccess({ user_id, user_display_name, user_role , session_id }))
    } else {
      yield put(onLoadingUserFail())
    }
  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export default function * userSaga () {
  yield takeLatest(loginUser.type, loginUserSaga)
  yield takeLatest(getUser.type, getUserSaga)
  yield takeLatest(logoutUser.type, logoutUserSaga)
}
