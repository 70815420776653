import * as React from 'react'
import { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import GridTable from '../GridTable'
import MDButton from '../../../core/MDButton'
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp'
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp'
import MDBox from '../../../core/MDBox'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  svgIcon: {
    minWidth: '35px',
    '& svg': {
      fontSize: '20px !important',
    },
  },
}))

const Accordion = styled((props) => (
  <MuiAccordion slotProps={{ transition: { unmountOnExit: true } }} disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  minHeight : '35px',
  maxHeight : '35px',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
 
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0.5),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

export default function TablePlotter ({
  columns,
  rows,
  level,
  hwUnits,
  rowSelectionModel,
  setRowSelectionModel,
  columnVisible,
  handleRowModesModelChange,
  valueEditor,
  handleAddRow,
  handleDeleteRow,
}) {
  const [expandedLevelOne, setExpandedLevelOne] = React.useState()
  const [expandedLevelTwo, setExpandedLevelTwo] = React.useState()
  const [expandedLevelOneAnchors, setExpandedLevelOneAnchors] = React.useState([])
  
  const getHwName = (hwUnit, instanceId) => {
    const unit = _.find(hwUnits, { hwUnit })
    if (unit) {
      return `${unit.label}-${instanceId}`
    } else {
      return '-'
    }
  }
  useEffect(() => {
    getAccordionAnchors()
  }, [rows])
  
  const getAccordionAnchors = () => {
    if (level === 2) {
      const levelOneAnchors = []
      _.map(rows, row => {
        const identifier = getHwName(row['hwUnit'], row['hwInstanceId'])
        if (!_.find(levelOneAnchors, { identifier })) {
          const secondLevelRows = _.uniqBy(_.map(_.filter(rows, {
            hwUnit: row['hwUnit']
            , hwInstanceId: row['hwInstanceId'],
          }), entry => ({
            identifier: entry['section'],
          })), 'identifier')
          levelOneAnchors.push({
            identifier,
            hwUnit: row['hwUnit'],
            hwInstanceId: row['hwInstanceId'],
            secondLevelRows,
          })
        }
      })
      setExpandedLevelOneAnchors(levelOneAnchors)
    } else {
      const levelOneAnchors = _.uniqBy(_.map(rows, entry => ({
        identifier: entry['section'],
      })), 'identifier')
      setExpandedLevelOneAnchors(levelOneAnchors)
    }
    
  }
  
  const handleChangeLevelOne = (panel) => (event, newExpanded) => {
    setExpandedLevelOne(newExpanded ? panel : false)
  }
  const handleChangeLevelTwo = (panel) => (event, newExpanded) => {
    setExpandedLevelTwo(newExpanded ? panel : false)
  }
  const classes = useStyles()
  return (
    <div>
      {level === 2 ? _.map(expandedLevelOneAnchors, (row, index) => (
        <Accordion key={`ac${index}`} expanded={expandedLevelOne === row.identifier}
                   onChange={handleChangeLevelOne(row.identifier)}>
          <AccordionSummary  key={`acs${index}`} aria-controls='panel1d-content' id='panel1d-header'>
            <Typography sx={{fontSize : '14px'}} key={`ty${index}`}>{row.identifier === '-' ? 'Add Custom' : row.identifier}</Typography>
          </AccordionSummary>
          <AccordionDetails key={`acd${index}`}>
            {_.map(row.secondLevelRows, (rowX, indexX) => (
              <Accordion key={`acc${index}-${indexX}`} expanded={expandedLevelTwo === rowX.identifier}
                         onChange={handleChangeLevelTwo(rowX.identifier)}>
                <AccordionSummary key={`acs${index}-${indexX}`} aria-controls='panel1d-content' id='panel1d-header'>
                  <Typography sx={{fontSize : '14px'}} key={`ty${index}-${indexX}`}>{rowX.identifier}</Typography>
                </AccordionSummary>
                <AccordionDetails key={`acd${index}-${indexX}`}>
                  <MDBox key={`mdb${index}-${indexX}`} sx={{ textAlign: 'end' }}>
                    <MDButton key={`mdbu${index}-${indexX}`} className={classes.svgIcon} size='small'
                              variant={'outlined'} color={'dark'}
                              onClick={() => {
                                handleAddRow({
                                  hwUnit: row.hwUnit,
                                  hwInstanceId: row.hwInstanceId,
                                  section: rowX.identifier,
                                })
                              }}>
                      <AddCircleSharpIcon />
                    </MDButton>
                    <MDButton key={`mdbut${index}-${indexX}`} className={classes.svgIcon} sx={{ marginLeft: '2px' }}
                              size='small' variant={'outlined'}
                              color={'dark'}
                              onClick={handleDeleteRow}>
                      <DeleteSharpIcon />
                    </MDButton>
                  </MDBox>
                  {expandedLevelTwo === rowX.identifier
                  && <GridTable
                    key={`gt${index}-${indexX}`}
                    columns={columns}
                    rows={_.filter(rows, lRow => {
                        return (row.identifier === '-' && lRow.isCustom && lRow.section === rowX.identifier)
                          || (lRow.hwUnit === row.hwUnit
                            && lRow.hwInstanceId === row.hwInstanceId
                            && lRow.section === rowX.identifier)
                      },
                    )}
                    rowSelectionModel={rowSelectionModel}
                    setRowSelectionModel={setRowSelectionModel}
                    columnVisible={columnVisible}
                    handleRowModesModelChange={handleRowModesModelChange}
                    valueEditor={valueEditor}
                  />}
                </AccordionDetails>
              </Accordion>
            ))}
          </AccordionDetails>
        </Accordion>
      )) : _.map(expandedLevelOneAnchors, row => (
        <Accordion expanded={expandedLevelOne === row.identifier} onChange={handleChangeLevelOne(row.identifier)}>
          <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
            <Typography sx={{fontSize : '14px'}} >{row.identifier}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <GridTable
              columns={columns}
              rows={_.filter(rows, {
                section: row.identifier,
              })}
              rowSelectionModel={rowSelectionModel}
              setRowSelectionModel={setRowSelectionModel}
              columnVisible={columnVisible}
              handleRowModesModelChange={handleRowModesModelChange}
              valueEditor={valueEditor}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}