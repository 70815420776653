import { TreeItem } from '@mui/x-tree-view/TreeItem'
import * as React from 'react'
import MDButton from 'components/core/MDButton'
import _ from 'lodash'
import { updateSectionTreeDataSuccess } from 'layouts/admin/document-template-flow/Wizard/store/wizardSlice'

export const addChildNodes = (nodes) => {
  return _.map(nodes, (node) => {
    if (node.type === 'button') {
      return (
        <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.title}>
          <MDButton> {node.title} </MDButton>
        </TreeItem>
      )
    }
  })
}

export const findNodeToPushTheAddedNodes = (treeNodes, treeNodeNameArray, treeNodesTobeAdded) => {
  if (_.isEmpty(treeNodeNameArray)) {
    treeNodes.push(...treeNodesTobeAdded)
  }
  _.forEach(treeNodeNameArray, treeNodeName => {
    _.forEach(treeNodes, node => {
      if (node.title === treeNodeName) {
        var index = treeNodeNameArray.indexOf(treeNodeName)
        if (index !== -1) {
          treeNodeNameArray.splice(index, 1)
        }
        findNodeToPushTheAddedNodes(node.children, treeNodeNameArray, treeNodesTobeAdded)
      }
    })
    
  })
}

export const findNodeToRemoveTheRemovedNodes = (treeNodes, treeNodeNameArray, treeNodesTobeRemoved) => {
  if (_.isEmpty(treeNodeNameArray)) {
    let indexArray = []
    _.forEach(treeNodes, (treeNode, ind) => {
      if (treeNodesTobeRemoved.includes(treeNode.title)) {
        indexArray.push(ind)
      }
    })
    for (var i = indexArray.length - 1; i >= 0; i--)
      treeNodes.splice(indexArray[i], 1)
    
  }
  _.forEach(treeNodeNameArray, treeNodeName => {
    _.forEach(treeNodes, node => {
      if (node.title === treeNodeName) {
        var index = treeNodeNameArray.indexOf(treeNodeName)
        if (index !== -1) {
          treeNodeNameArray.splice(index, 1)
        }
        findNodeToRemoveTheRemovedNodes(node.children, treeNodeNameArray, treeNodesTobeRemoved)
      }
    })
  })
}

export const updateSections = (sections, treeNodesTobeAdded) => {
  _.forEach(treeNodesTobeAdded, treeNodeTobeAdded => {
    const id = treeNodeTobeAdded.id
    const idArray = id.split('_')
    idArray.pop()
    const toBeAdded = { title: treeNodeTobeAdded.title }
    idArray.splice(0, 1)
    updateSection(sections, idArray, toBeAdded)
  })
}

export const updateSection = (sections, idArray, treeNodeTobeAdded) => {
  if (_.isEmpty(idArray)) {
    sections.push(treeNodeTobeAdded)
    return
  }
  _.forEach(sections, section => {
    if (section['title'] === idArray[0]) {
      if (!section['sections']) {
        section['sections'] = []
      }
      idArray.splice(0, 1)
      updateSection(section['sections'], idArray, treeNodeTobeAdded)
    }
  })
}

export const updateParts = (sections, treeNodesTobeAdded) => {
  _.forEach(treeNodesTobeAdded, treeNodeTobeAdded => {
    const id = treeNodeTobeAdded.id
    const idArray = id.split('_')
    idArray.pop()
    const toBeAdded = { title: treeNodeTobeAdded.title, type: treeNodeTobeAdded.type }
    idArray.splice(0, 1)
    updatePart(sections, idArray, toBeAdded)
  })
}

export const updatePart = (sections, idArray, treeNodeTobeAdded) => {
  if (_.isEmpty(idArray)) {
    if (!sections['parts']) {
      sections['parts'] = []
    }
    sections['parts'].push(treeNodeTobeAdded)
    return
  }
  _.forEach(sections, section => {
    if (section['title'] === idArray[0]) {
      idArray.splice(0, 1)
      if (_.isEmpty(idArray)) {
        if (!section['parts']) {
          section['parts'] = []
        }
        const partsArray = section['parts']
        const matchedParts = _.filter(partsArray, part => (part.title === treeNodeTobeAdded.title))
        if (_.isEmpty(matchedParts)) {
          section['parts'].push(treeNodeTobeAdded)
        }
        return
      }
      updatePart(section['sections'], idArray, treeNodeTobeAdded)
    }
  })
}

export const updateSubsystemParts = (sections, treeNodesTobeAdded, subsystemName) => {
  _.forEach(treeNodesTobeAdded, treeNodeTobeAdded => {
    const id = treeNodeTobeAdded.id
    const idArray = id.split('_')
    idArray.pop()
    const toBeAdded = { title: treeNodeTobeAdded.title, type: treeNodeTobeAdded.type }
    idArray.splice(0, 1)
    updateSubsystemPartd(sections, idArray, toBeAdded, subsystemName)
  })
}

export const updateSubsystemPartd = (sections, idArray, treeNodeTobeAdded, subsystemName) => {
  if (_.isEmpty(idArray)) {
    if (!sections['subSystemDefinition']) {
      sections['subSystemDefinition'] = { parts: [] }
    }
    sections['subSystemDefinition']['parts'].push(treeNodeTobeAdded)
    sections['subSystemDefinition']['title'] = subsystemName

    return
  }
  _.forEach(sections, section => {
    if (section['title'] === idArray[0]) {
      idArray.splice(0, 1)
      if (_.isEmpty(idArray)) {
        if (!section['subSystemDefinition']) {
          section['subSystemDefinition'] = { parts: [] }
        }
        const partsArray = section['subSystemDefinition']['parts']
        const matchedParts = _.filter(partsArray, part => (part.title === treeNodeTobeAdded.title))
        if (_.isEmpty(matchedParts)) {
          section['subSystemDefinition']['parts'].push(treeNodeTobeAdded)
          section['subSystemDefinition']['title'] = subsystemName
        }
        return
      }
      updateSubsystemPartd(section['sections'], idArray, treeNodeTobeAdded, subsystemName)
    }
  })
}

export const updateSubystemParts = (sections, treeNodesTobeAdded, subsystemName) => {
  _.forEach(treeNodesTobeAdded, treeNodeTobeAdded => {
    const id = treeNodeTobeAdded.id
    const idArray = id.split('_')
    idArray.pop()
    const toBeAdded = { title: treeNodeTobeAdded.title, type: treeNodeTobeAdded.type }
    idArray.splice(0, 1)
    updateSubsystemPart(sections, idArray, toBeAdded, subsystemName)
  })
}

export const updateSubsystemPart = (sections, idArray, treeNodeTobeAdded, subsystemName) => {
  if (_.isEmpty(idArray)) {
    if (!sections['subSystemDefinition']) {
      sections['subSystemDefinition'] = { parts: [] }
    }
    sections['subSystemDefinition']['parts'].push(treeNodeTobeAdded)
    sections['subSystemDefinition']['title'] = subsystemName
    return
  }
  _.forEach(sections, section => {
    if (section['title'] === idArray[0]) {
      idArray.splice(0, 1)
      if (_.isEmpty(idArray)) {
        if (!section['subSystemDefinition']) {
          section['subSystemDefinition'] = { parts: [] }
        }
        const partsArray = section['subSystemDefinition']['parts']
        const matchedParts = _.filter(partsArray, part => (part.title === treeNodeTobeAdded.title))
        if (_.isEmpty(matchedParts)) {
          section['subSystemDefinition']['parts'].push(treeNodeTobeAdded)
          section['subSystemDefinition']['title'] = subsystemName
        }
        return
      }
      updateSubsystemPart(section['sections'], idArray, treeNodeTobeAdded, subsystemName)
    }
  })
}

export const updatePartsForRemoval = (sections, treeNodesTobeRemoved) => {
  _.forEach(treeNodesTobeRemoved, treeNodeTobeRemoved => {
    const id = treeNodeTobeRemoved.id
    const idArray = id.split('_')
    idArray.pop()
    const toBeRemoved = { title: treeNodeTobeRemoved.title, type: treeNodeTobeRemoved.type }
    idArray.splice(0, 1)
    updatePartRemoval(sections, idArray, toBeRemoved)
  })
}

export const updatePartRemoval = (sections, idArray, treeNodeTobeRemoved) => {
  _.forEach(sections, section => {
    if (section['title'] === idArray[0]) {
      idArray.splice(0, 1)
      if (_.isEmpty(idArray)) {
        if (!section['parts']) {
          section['parts'] = []
        }
        const partsArray = section['parts']
        const remainingParts = _.filter(partsArray, part => (part.title !== treeNodeTobeRemoved.title))
        section['parts'] = remainingParts
        return
      }
      updatePartRemoval(section['sections'], idArray, treeNodeTobeRemoved)
    }
  })
}

export const updateSubsystemPartsForRemoval = (sections, treeNodesTobeRemoved) => {
  _.forEach(treeNodesTobeRemoved, treeNodeTobeRemoved => {
    const id = treeNodeTobeRemoved.id
    const idArray = id.split('_')
    idArray.pop()
    const toBeRemoved = { title: treeNodeTobeRemoved.title, type: treeNodeTobeRemoved.type }
    idArray.splice(0, 1)
    updateSubsystemPartRemoval(sections, idArray, toBeRemoved)
  })
}

export const updateSubsystemPartRemoval = (sections, idArray, treeNodeTobeRemoved) => {
  _.forEach(sections, section => {
    if (section['title'] === idArray[0]) {
      idArray.splice(0, 1)
      if (_.isEmpty(idArray)) {
        if (!section['subSystemDefinition']) {
          section['subSystemDefinition'] = { parts: [] }
        }
        const partsArray = section['subSystemDefinition']['parts']
        const remainingParts = _.filter(partsArray, part => (part.title !== treeNodeTobeRemoved.title))
        section['subSystemDefinition']['parts'] = remainingParts
        return
      }
      updateSubsystemPartRemoval(section['sections'], idArray, treeNodeTobeRemoved)
    }
  })
}

export const updatePartContent = (sections, partContent, treeNodesArray) => {
  const partName = treeNodesArray.pop()
  treeNodesArray.splice(0, 1)
  updatePartContentData(sections, treeNodesArray, partContent, partName)
}

export const updatePartContentData = (sections, treeNodesArray, partContent, partName) => {
  _.forEach(sections, section => {
    if (section['title'] === treeNodesArray[0]) {
      treeNodesArray.splice(0, 1)
      if (_.isEmpty(treeNodesArray)) {
        _.forEach(section['parts'], part => {
          if (part.title === partName) {
            if (part['type'] === 'table') {
              part['tableDef'] = partContent?.tableDef
              part['table'] = partContent?.table
            }else if (part['type'] === 'subSystemBlockDiagram') {
              part['paramsDef'] = partContent?.paramsDef
            }else if (part['type'] === 'images') {
              const orientation = partContent['orientation']
              const images = partContent['images']
              part['images'] = images
              part['orientation'] = orientation
            } else {
              part[part['type']] = partContent
            }
            return
          }
        })
        return
      }
      updatePartContentData(section['sections'], treeNodesArray, partContent, partName)
    }
  })
}

export const updateSubsystemPartContent = (sections, partContent, treeNodesArray) => {
  const partName = treeNodesArray.pop()
  treeNodesArray.splice(0, 1)
  updateSubsystemPartContentData(sections, treeNodesArray, partContent, partName)
}

export const updateSubsystemPartContentData = (sections, treeNodesArray, partContent, partName) => {
  _.forEach(sections, section => {
    if (section['title'] === treeNodesArray[0]) {
      treeNodesArray.splice(0, 1)
      if (_.isEmpty(treeNodesArray)) {
        _.forEach(section['subSystemDefinition']['parts'], part => {
          if (part.title === partName) {
            if (part['type'] === 'table') {
              part['tableDef'] = partContent?.tableDef
              part['table'] = partContent?.table
            }else if (part['type'] === 'subSystemBlockDiagram') {
              part['paramsDef'] = partContent?.paramsDef
            }else if (part['type'] === 'images') {
              const orientation = partContent['orientation']
              const images = partContent['images']
              part['images'] = images
              part['orientation'] = orientation
            } else {
              part[part['type']] = partContent
            }
            return
          }
        })
        return
      }
      updateSubsystemPartContentData(section['sections'], treeNodesArray, partContent, partName)
    }
  })
}

export const getPartContent = (sections, treeNodesArray) => {
  const partName = treeNodesArray.pop()
  treeNodesArray.splice(0, 1)
  return getPartContentData(sections, treeNodesArray, partName)
}

export const getPartContentData = (sections, treeNodesArray, partName) => {
  let returnData
  _.forEach(sections, section => {
    if (section['title'] === treeNodesArray[0]) {
      treeNodesArray.splice(0, 1)
      if (_.isEmpty(treeNodesArray)) {
        _.forEach(section['parts'], part => {
          if (part.title === partName) {
            if (part['type'] === 'table') {
              returnData = {
                tableDef: part['tableDef'],
                table: part['table']
              }
            }else if (part['type'] === 'subSystemBlockDiagram') {
              returnData = {
                paramsDef : part['paramsDef']
              }
            }else if (part['type'] === 'images') {
              returnData = part['images']
              if(part && part['images']){
                returnData = {orientation: part['orientation'], images: part['images']}
              }
            } else {
              returnData = part[part['type']]
            }
            return
          }
        })
      }
      updatePartContentData(section['sections'], treeNodesArray, partName)
    }
  })
  return returnData
}

export const getSubsystemTitle = (sections, treeNodesArray) => {
  treeNodesArray.splice(0, 1)
  return getSubsystemTitleContent(sections, treeNodesArray)
}

export const getSubsystemTitleContent = (sections, treeNodesArray) => {
  let returnData
  _.forEach(sections, section => {
    if (section['title'] === treeNodesArray[0]) {
      treeNodesArray.splice(0, 1)
      if (_.isEmpty(treeNodesArray)) {
        if (section['subSystemDefinition'] && section['subSystemDefinition']['title']) {
          returnData = section['subSystemDefinition']['title']
        }
      }
      updatePartContentData(section['sections'], treeNodesArray)
    }
  })
  return returnData
}


export const getSubsystemPartContent = (sections, treeNodesArray) => {
  const partName = treeNodesArray.pop()
  treeNodesArray.splice(0, 1)
  return getSubsystemPartContentData(sections, treeNodesArray, partName)
}

export const getSubsystemPartContentData = (sections, treeNodesArray, partName) => {
  let returnData
  _.forEach(sections, section => {
    if (section['title'] === treeNodesArray[0]) {
      treeNodesArray.splice(0, 1)
      if (_.isEmpty(treeNodesArray)) {
        if (section['subSystemDefinition'] && section['subSystemDefinition']['parts']) {
          _.forEach(section['subSystemDefinition']['parts'], part => {
            if (part.title === partName) {
              if (part['type'] === 'table') {
                returnData = {
                  tableDef: part['tableDef'],
                  table: part['table']
                }
              }else if (part['type'] === 'subSystemBlockDiagram') {
                returnData = {
                  paramsDef : part['paramsDef']
                }
              }else if (part['type'] === 'images') {
                returnData = part['images']
                if(part && part['images']){
                  returnData = {orientation: part['orientation'], images: part['images']}
                }
              } else {
                returnData = part[part['type']]
              }
              return
            }
          })
        }
      }
      updatePartContentData(section['sections'], treeNodesArray, partName)
    }
  })
  return returnData
}

export const addSectionsAndParts = (currentSection, sections, treeNodes, sectionTreeData, defaultAvailableParts, workflowObjSections) => {
  const treeNodePartsTobeAdded = []
  _.forEach(sections, section => {
    if (section.parts) {
      _.forEach(section.parts, part => {
        _.forEach(treeNodes, treeNode => {
          if (treeNode.title === section.title) {
            const node = getPartForChildNode(`${currentSection}_${section.title}`, part)
            treeNode.children.push(node)
            treeNodePartsTobeAdded.push(node)
          }
        })
      })
      updateParts(workflowObjSections, treeNodePartsTobeAdded)
      _.forEach(section.parts, part => {
        _.forEach(treeNodes, treeNode => {
          if (treeNode.title === section.title) {
            const nodeArray = `${currentSection}_${section.title}_${part.title}`.split('_')
            let content
            if (part.type === 'table') {
              content = { tableDef: part.tableDef }
            } else if (part.type === 'subSystemBlockDiagram') {
              content = { paramsDef: part.paramsDef }
            } else {
              content = part[part['type']] ? part[part['type']] : ''
            }
            updatePartContent(workflowObjSections, content, nodeArray)
            
          }
        })
      })
      updateSectionTreeDataForParts(sectionTreeData, `${currentSection}_${section.title}`, defaultAvailableParts, section.parts)
    }
    const treeNodeSubsystemsTobeAdded = []
    if (section.subSystemDefinition && section.subSystemDefinition.parts) {
      _.forEach(section.subSystemDefinition.parts, part => {
        _.forEach(treeNodes, treeNode => {
          if (treeNode.title === section.title) {
            const node = getPartForChildNode(`${currentSection}_${section.title}`, part)
            //treeNode.children.push(node)
            treeNodeSubsystemsTobeAdded.push(node)
          }
        })
      })
      updateSubsystemParts(workflowObjSections, treeNodeSubsystemsTobeAdded, section.subSystemDefinition.title)
      _.forEach(section.subSystemDefinition.parts, part => {
        _.forEach(treeNodes, treeNode => {
          if (treeNode.title === section.title) {
            const nodeArray = `${currentSection}_${section.title}_${part.title}`.split('_')
            let content
            if (part.type === 'table') {
              content = { tableDef: part.tableDef }
            } else if (part.type === 'subSystemBlockDiagram') {
              content = { paramsDef: part.paramsDef }
            } else {
              content = part[part['type']] ? part[part['type']] : ''
            }
            updateSubsystemPartContent(workflowObjSections, content, nodeArray)
            
          }
        })
      })
      updateSectionTreeDataForSubsystemParts(sectionTreeData, `${currentSection}_${section.title}`, defaultAvailableParts, section.subSystemDefinition.parts)
    }
  })
}

const getPartForChildNode = (currentPart, part) => {
  return {
    id: `${currentPart}_${part.title}`,
    title: part.title,
    type: part.type,
    tableDef: part.tableDef ? part.tableDef : '',
  }
}

const updateSectionTreeDataForParts = (sectionTreeData, currentPartToSet, availableParts, addedParts) => {
  const addedPartsTypes = _.map(addedParts, addedPart => addedPart.type)
  const remAvblParts = _.filter(availableParts, (availablePart) => {
    if (!addedPartsTypes.includes(availablePart.type)) {
      return availablePart
    }
  })
  if (!sectionTreeData[currentPartToSet] || !sectionTreeData[currentPartToSet]['availableParts'] || (_.isEmpty(sectionTreeData[currentPartToSet]['availableParts']) && _.isEmpty(sectionTreeData[currentPartToSet]['addedParts']))) {
    sectionTreeData[currentPartToSet] = {
      ...sectionTreeData[currentPartToSet],
      availableParts: remAvblParts,
      addedParts: addedParts,
    }
  }
  return sectionTreeData
}

const updateSectionTreeDataForSubsystemParts = (sectionTreeData, currentSubsystemToSet, availableSubsystems, addedSubsystems) => {
  const addedSubsystemPartsTypes = _.map(addedSubsystems, addedSubsystem => addedSubsystem.type)
  const remAvblSubsystemParts = _.filter(availableSubsystems, (availableSubsystem) => {
    if (!addedSubsystemPartsTypes.includes(availableSubsystem.type)) {
      return availableSubsystem
    }
  })
  if (!sectionTreeData[currentSubsystemToSet] || !sectionTreeData[currentSubsystemToSet]['availableSubsystems'] || (_.isEmpty(sectionTreeData[currentSubsystemToSet]['availableSubsystems']) && _.isEmpty(sectionTreeData[currentSubsystemToSet]['addedSubsystems']))) {
    sectionTreeData[currentSubsystemToSet] = {
      ...sectionTreeData[currentSubsystemToSet],
      availableSubsystems: remAvblSubsystemParts,
      addedSubsystems: addedSubsystems,
    }
  }
  return sectionTreeData
}

export const getSection = (currentSection, addSectionsToAddedSection) => {
  return {
    id: `${currentSection}_${addSectionsToAddedSection.title}`,
    title: addSectionsToAddedSection.title,
    children: [
      {
        id: `${currentSection}_${addSectionsToAddedSection.title}__addchildsection`,
        type: 'childSectionButton',
        title: 'Add Child Section',
      },
      {
        id: `${currentSection}_${addSectionsToAddedSection.title}__addchildpart`,
        type: 'childPartButton',
        title: 'Add Child Part',
      },
      {
        id: `${currentSection}_${addSectionsToAddedSection.title}__addsubsystem`,
        type: 'subsystemButton',
        title: 'Define SubSystem',
      },
    ],
  }
}

export const getSubsystem = (currentSubsystem, addSubsystemsToAddedSubsystem) => {
  return {
    id: `${currentSubsystem}_${addSubsystemsToAddedSubsystem.title}`,
    title: addSubsystemsToAddedSubsystem.title,
  }
}

// Update Part Name at any Hierarchy Level - Start

export const updatePartName = (sections, newNameForPart, treeHierarchyUntilPartArray) => {
  const partName = treeHierarchyUntilPartArray.pop()
  treeHierarchyUntilPartArray.splice(0, 1)
  updatePartNameHelper(sections, treeHierarchyUntilPartArray, newNameForPart, partName)
}

export const updatePartNameHelper = (sections, treeHierarchyUntilPartArray, newNameForPart, partName) => {
  _.forEach(sections, section => {
    if (section['title'] === treeHierarchyUntilPartArray[0]) {
      treeHierarchyUntilPartArray.splice(0, 1)
      if (_.isEmpty(treeHierarchyUntilPartArray)) {
        _.forEach(section['parts'], part => {
          if (part.title === partName) {
            part.title = newNameForPart
          }
        })
        return
      }
      updatePartNameHelper(section['sections'], treeHierarchyUntilPartArray, newNameForPart, partName)
    }
  })
}

// Update Part Name at any Hierarchy Level - End

export const updateSubsystemName = (sections, newNameForSubsystemName, treeHierarchyLevelArray) => {
  treeHierarchyLevelArray.splice(0, 1)
  updateSubsystemNameHelper(sections, treeHierarchyLevelArray, newNameForSubsystemName)
}

export const updateSubsystemNameHelper = (sections, treeHierarchyLevelArray, newNameForSubsystemName) => {
  _.forEach(sections, section => {
    if (section['title'] === treeHierarchyLevelArray[0]) {
      treeHierarchyLevelArray.splice(0, 1)
      if (_.isEmpty(treeHierarchyLevelArray)) {
        if(section['subSystemDefinition']){
          section['subSystemDefinition']['title'] = newNameForSubsystemName
        }else {
          section['subSystemDefinition'] = { parts: [], title:  newNameForSubsystemName}
        }
        return
      }
      updateSubsystemNameHelper(section['sections'], treeHierarchyLevelArray, newNameForSubsystemName)
    }
  })
}


export const updateSectionTreePartTitle = (menuTreeNodes, newNameForPart, treeHierarchyUntilPartArray, partName) => {
  if (_.isEmpty(treeHierarchyUntilPartArray)) {
    _.forEach(menuTreeNodes, menuTreeNode => {
      if (menuTreeNode.type === partName) {
        menuTreeNode.title = newNameForPart
      }
    })
  }
  _.forEach(treeHierarchyUntilPartArray, treeHierarchyUntilPart => {
    _.forEach(menuTreeNodes, menuTreeNode => {
      if (menuTreeNode.title === treeHierarchyUntilPart) {
        var index = treeHierarchyUntilPartArray.indexOf(treeHierarchyUntilPart)
        if (index !== -1) {
          treeHierarchyUntilPartArray.splice(index, 1)
        }
        updateSectionTreePartTitle(menuTreeNode.children, newNameForPart, treeHierarchyUntilPartArray, partName)
      }
    })
    
  })
}

export const updateSectionTreeDataPartTitle = (treeDataObj, newNameForPart, treeHierarchyUntilPart, partName) => {
  const treeObj = treeDataObj[treeHierarchyUntilPart]
  if (!_.isEmpty(treeObj.addedParts)) {
    _.forEach(treeObj.addedParts, part => {
      if (part.type === partName) {
        part.title = newNameForPart
      }
    })
  }
}
