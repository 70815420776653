import {
  Box, Dialog, DialogActions, DialogContent, DialogTitle,
  Stack,
} from '@mui/material'
import AvailableFields
  from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/PartSelection/TablePartDefinition/AvailableFields'
import DependentFields
  from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/PartSelection/TablePartDefinition/DependentFields'
import AvailableLookups
  from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/PartSelection/TablePartDefinition/AvailableLookups'
import KeyValue
  from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/PartSelection/TablePartDefinition/KeyValue'
import * as React from 'react'
import {
  useEffect,
  useRef,
  useState,
} from 'react'
import MDTypography from 'components/core/MDTypography'
import PartDefinition
  from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/PartSelection/PartDefinition'
import { useSelector } from 'react-redux'
import { wizardSelector } from 'layouts/admin/document-template-flow/Wizard/store/wizardSlice'
import _ from 'lodash'
import uuid from 'react-uuid'
import Table from 'components/extended/Table'
import NewLineToBr from '../../../../../../../../components/extended/NewLineToBr'
import Button from '@mui/material/Button'

export default ({ availableFields, partContent, setPartContent, availableLookups, setAvailableLookups, lookupName, tableLookupKeyValueContent, setTableLookupKeyValueContent, setLookupName, dependFieldsLookupKeyValueContent, setDependFieldsLookupKeyValueContent, tableColumnDependentsMap, tableDef, setTableDef, partObjectSelected, setContent, content, setNewContent, newContent }) => {
  const [dependentOn, setDependentOn] = useState()
  const [editLookups, setEditLookups] = useState([])
  const [dependAvailableFields, setDependAvailableFields] = useState()
  const [typeOfLookUpValues, setTypeOfLookUpValues] = useState('')
  const [typeOfPredifinedValues, setTypeOfPredifinedValues] = useState('array')
  const [availableLookupOption, setAvailableLookupOption] = useState()
  const [selectedField, setSelectedField] = useState({})
  const docTemplateFlow = useSelector(wizardSelector)
  
  const [dialogBody, setDialogBody] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('')
  const [validationColumns, setValidationColumns] = useState('')
  const [isDeleteDialog, setIsDeleteDialog] = useState(false)
  const tableChildRef = useRef()
  let transientTableDef
  let transientTableData
  useEffect(() => {
    if (docTemplateFlow.lookupDataForDependFields) {
      const { items, name } = docTemplateFlow.lookupDataForDependFields
      const rowsDataObj = getRowObjsBasedOnItems(items)
      setDependFieldsLookupKeyValueContent(rowsDataObj)
    } else {
      setDependFieldsLookupKeyValueContent([])
    }
    
  }, [docTemplateFlow.lookupDataForDependFields])
  
  useEffect(() => {
    if (docTemplateFlow.lookupDataForLookups) {
      const { items, name } = docTemplateFlow.lookupDataForLookups
      const rowsDataObj = getRowObjsBasedOnItems(items)
      setTableLookupKeyValueContent(rowsDataObj)
    } else {
      setTableLookupKeyValueContent([])
    }
    
  }, [docTemplateFlow.lookupDataForLookups])
  
  const getRowObjsBasedOnItems = (items) => {
    let rowsDataObj = []
    if (_.isArray(items) && !_.isEmpty(items)) {
      setTypeOfLookUpValues('array')
      rowsDataObj = _.map(items, item => {
        return {
          id: uuid(),
          key: '',
          value: item,
        }
      })
    } else if (_.isObject(items) && !_.isEmpty(items)) {
      setTypeOfLookUpValues('keyValue')
      _.forOwn(items, function (value, key) {
        rowsDataObj.push({
          id: uuid(),
          key: key,
          value: value,
        })
      })
    }
    return rowsDataObj
  }
  useEffect(() => {
    if (_.isEmpty(tableDef)) return
    const clonedTableDef = _.cloneDeep(tableDef)
    transientTableDef = tableDef
    setPartContent({ table: (!_.isEmpty(partContent) ? partContent.table : {}), tableDef: clonedTableDef })
  }, [tableDef])
  
  useEffect(() => {
    if (!newContent) return
    transientTableData = newContent
    setPartContent({ table: newContent, tableDef: transientTableDef ? transientTableDef : (!_.isEmpty(partContent) && !_.isEmpty(partContent.tableDef) ? partContent.tableDef : _.cloneDeep(tableDef)) })
  }, [newContent])
  
  const handleCancel = () => {
    setIsDeleteDialog(false)
    setDialogOpen(false)
    setDialogTitle('')
    setDialogBody('')
  }
  
  const handleDeleteOk = () => {
    tableChildRef.current.handleRowDelete()
  }
  
  return (
    <Stack direction='column' sx={{ justifyContent: 'center' }} spacing={2}>
      <Stack direction='column' sx={{ justifyContent: 'center' }} spacing={2}>
        <Box>
          <MDTypography p={2} variant={'h6'}>
            {partObjectSelected && partObjectSelected.title ? partObjectSelected.title : 'System Specifications'}
          </MDTypography>
        </Box>
        <PartDefinition type={'table'} setTableDef={setTableDef} setContent={setContent}
                        setNewContent={setNewContent} />
        <Stack direction='row' sx={{ justifyContent: 'center' }} spacing={2}>
          <AvailableFields setSelectedField={setSelectedField} fields={availableFields} title={'Available Fields'}
                           setDependentOn={setDependentOn} tableColumnDependentsMap={tableColumnDependentsMap}
                           setDependAvailableFields={setDependAvailableFields}
                           setAvailableLookupOption={setAvailableLookupOption} />
          <DependentFields availableFields={availableFields}
                           dependAvailableFields={dependAvailableFields}
                           title={'Table Fields'}
                           dependentOn={dependentOn}
                           availableLookups={availableLookups}
                           dependFieldsLookupKeyValueContent={dependFieldsLookupKeyValueContent}
                           setDependFieldsLookupKeyValueContent={setDependFieldsLookupKeyValueContent}
                           tableColumnDependentsMap={tableColumnDependentsMap}
                           typeOfLookUpValues={typeOfLookUpValues}
                           setAvailableLookupOption={setAvailableLookupOption}
                           availableLookupOption={availableLookupOption}
                           tableDef={tableDef}
                           setTableDef={setTableDef}
                           selectedField={selectedField}
                           setTypeOfPredifinedValues={setTypeOfPredifinedValues}
                           typeOfPredifinedValues={typeOfPredifinedValues}
                           setPartContent={setPartContent}
                           partContent={partContent}
                           newContent={newContent}
          />
          <Box style={{ width: '40%' }}>
            <AvailableLookups setEditLookups={setEditLookups} availableLookups={availableLookups}
                              setAvailableLookups={setAvailableLookups} setTypeOfLookUpValues={setTypeOfLookUpValues}
                              title={'Available Lookups'} lookupName={lookupName} setLookupName={setLookupName} />
            <KeyValue type={'lookup'} lookupName={lookupName} title={'Lookups'}
                      tableLookupKeyValueContent={tableLookupKeyValueContent}
                      setTableLookupKeyValueContent={setTableLookupKeyValueContent}
                      typeOfLookUpValues={typeOfLookUpValues}
            />
          </Box>
        </Stack>
      </Stack>
      {!_.isEmpty(tableDef) && (
        <Box style={{ width: '100%' }}>
          <Table
            ref={tableChildRef}
            content={content}
            title={partObjectSelected && partObjectSelected.title ? partObjectSelected.title : 'System Specifications'}
            tableDef={tableDef}
            setContent={setContent}
            setNewContent={setNewContent}
            setDialogBody={setDialogBody}
            setDialogTitle={setDialogTitle}
            setDialogOpen={setDialogOpen}
            setValidationColumns={setValidationColumns}
            setIsDeleteDialog={setIsDeleteDialog}
            isAdminTable={true}
          />
          <Dialog
            sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 435 } }}
            maxWidth='xs'
            open={dialogOpen}
          >
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
              <NewLineToBr>{dialogBody}</NewLineToBr>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCancel}>Cancel</Button>
              <Button onClick={handleDeleteOk}>Ok</Button>
            </DialogActions>
          </Dialog>
        </Box>
      
      )}
    
    </Stack>
  
  )
}