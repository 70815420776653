import {
  Box,
  Stack,
} from '@mui/material'
import _ from 'lodash'
import MDTypography from 'components/core/MDTypography'
import TestProcedures from './TestProcedures'
import PartDefinition
  from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/PartSelection/PartDefinition'
import * as React from 'react'
import ParamsValue
  from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/PartSelection/SystemBlockDiagramPartDefinition/ParamsValue'
import InputSelection
  from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/PartSelection/SystemBlockDiagramPartDefinition/InputSelection'
import AddHardWareUnit
  from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/PartSelection/SystemBlockDiagramPartDefinition/AddHardWareUnit'
import { useEffect } from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { wizardSelector } from 'layouts/admin/document-template-flow/Wizard/store/wizardSlice'
import { createProject } from 'layouts/dashboard/store/saga'
import { getHardwareUnits } from 'layouts/admin/document-template-flow/Wizard/store/saga'
import Button from '@mui/material/Button'

export default ({
  partContent,
  setPartContent,
  paramsDef,
  setParamsDef,
  partObjectSelected,
  setContent,
  content,
  setNewContent,
  newContent,
}) => {
  const [selectedAvailableUnit, setSelectedAvailableUnit] = React.useState('')
  const [selectedAddedUnit, setSelectedAddedUnit] = React.useState('')
  const [availableInputSectionsState, setAvailableInputSectionsState] = React.useState([])
  const [availableInputAttributes, setAvailableInputAttributes] = React.useState([])
  const [procedureView, setProcedureView] = React.useState(false)
  const dispatch = useDispatch()
  const docTemplateFlow = useSelector(wizardSelector)
  
  useEffect(() => {
    dispatch(getHardwareUnits({ actionId: undefined }))
  }, [])
  
  useEffect(() => {
    if (!_.isEmpty(docTemplateFlow.hardwareUnits)) {
      setAvailableInputSectionsState(docTemplateFlow.hardwareUnits)
      const result = _.mergeWith({}, docTemplateFlow.hardwareUnits, (objValue, srcValue) => (objValue || []).concat(...srcValue.attributes))
      const attributes = []
      _.forOwn(result, (value, key) => attributes.push(...value))
      const uniqueAttributes = _.filter(attributes, (obj, index) => {
        return index === attributes.findIndex(o => obj.label === o.label)
      })
      setAvailableInputAttributes(uniqueAttributes)
    }
  }, [docTemplateFlow.hardwareUnits])
  
  const addToAddedInputSections = () => {
    if (_.isEmpty(selectedAvailableUnit)) return
    const unit = _.find(availableInputSectionsState, { actionId: selectedAvailableUnit })
    setPartContent({ paramsDef: [unit, ...(paramsDef || [])] })
    setParamsDef([unit, ...(paramsDef || [])])
    setSelectedAvailableUnit('')
  }
  
  const removeFromAddedInputSections = () => {
    if (_.isEmpty(selectedAddedUnit)) return
    const units = _.filter(paramsDef, (item) => item.actionId !== selectedAddedUnit)
    setPartContent({ paramsDef: [...units] })
    setParamsDef([...units])
    setSelectedAddedUnit('')
  }
  
  return (
    <Stack direction='column' sx={{ justifyContent: 'center' }} spacing={2}>
      <Box>
        <MDTypography p={2} variant={'h6'}>
          {partObjectSelected && partObjectSelected.title ? partObjectSelected.title : 'Sub System Block Diagram'}
        </MDTypography>
      </Box>
      <PartDefinition />
      <Stack direction='row' sx={{ justifyContent: 'left', paddingTop: 5 }} spacing={2}>
        <InputSelection
          available={availableInputSectionsState}
          added={paramsDef}
          addToAdded={addToAddedInputSections}
          removeFromAdded={removeFromAddedInputSections}
          setSelectedAvailable={setSelectedAvailableUnit}
          setSelectedAdded={setSelectedAddedUnit}
          availableTitle={`Available Hardware Units`}
          addedTitle={`Added Hardware Units`}
        />
        {selectedAddedUnit &&
        <ParamsValue attributes={
          (_.find(paramsDef, { actionId: selectedAddedUnit }) || {}).attributes || []
        }
                     selectedAddedUnit={selectedAddedUnit}
                     setPartContent={setPartContent}
                     paramsDef={paramsDef}
                     setParamsDef={setParamsDef}
                     setProcedureView={setProcedureView}
        
        />
        }
      </Stack>
      
      <AddHardWareUnit
        setAvailableInputSectionsState={setAvailableInputSectionsState}
        availableInputSectionsState={availableInputSectionsState}
        setAvailableInputAttributes={setAvailableInputAttributes}
        availableInputAttributes={availableInputAttributes}
      />
      {procedureView && <TestProcedures
        selectedAddedUnit={selectedAddedUnit}
        partContent={partContent} />}
    </Stack>
  )
}
