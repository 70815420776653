import store from 'store'
import _ from 'lodash'

const getKeySub = path => {
  const [key, ...subPaths] = path.split('.')
  return {
    key,
    subPath: subPaths.join('.'),
  }
}

export default {
  set: (key, val) => {
    try {
      return store.set(key, val)
    } catch (e) {
    }
  },
  get: (path, df) => {
    const { key, subPath } = getKeySub(path)
    try {
      const rootVal = store.get(key)
      if(rootVal) {
        return subPath ? _.get(rootVal, subPath, df) : rootVal
      } else {
        return df
      }
    } catch (e) {
    }
  },
  remove: path => {
    const { key, subPath } = getKeySub(path)
    try {
      if(!subPath) return store.remove(key)
      const rootVal = store.get(key)
      if(rootVal) {
        _.unset(rootVal, subPath)
        store.set(key, rootVal)
      }
    } catch (e) {
    }
  },
  clearAll: () => {
    try {
      return store.clearAll()
    } catch (e) {
    }
  },
}
