import React, {
  useEffect,
  useState,
} from 'react'
import Section from 'layouts/document-management/document'
import { useSelector } from 'react-redux'
import { mainSelector } from 'layouts/dashboard/store/mainSlice'
import {
  Box,
  CardContent,
  Stack,
} from '@mui/material'
import CircularProgressStyled from 'components/extended/progress/CircularProgressStyled'
import { wizardSelector } from 'layouts/admin/document-template-flow/Wizard/store/wizardSlice'

export default ({ onStepComplete }) => {
  const dashData = useSelector(mainSelector)
  const docTemplateFlow = useSelector(wizardSelector)
  const indicatorSize = 80
  return (
    <Box sx={{justifyContent: 'center'}}>
      <Stack direction='column' sx={{ justifyContent: 'center' }} spacing={4}>
        {
          dashData.selected_document && (
            <Section isAdmin={true}/>
          )
        }
        <CardContent>
          <CircularProgressStyled
            size={indicatorSize}
            sx={{
              display: docTemplateFlow.loading ? 'block' : 'none',
              position: 'relative',
              left: '50%',
              zIndex: '9999',
              marginTop: `${-indicatorSize / 2}px`,
              marginLeft: `${-indicatorSize / 2}px`,
              background: 'transparent',
              color: 'gray',
            }}
          />
        </CardContent>
      </Stack>
    </Box>
  )
}