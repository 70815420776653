import SelectSections from './steps/SelectSections'
import SelectDocumentType from 'layouts/admin/document-template-flow/Wizard/steps/SelectDocumentType'
import SavePublish from 'layouts/admin/document-template-flow/Wizard/steps/SavePublish'
import Review from 'layouts/admin/document-template-flow/Wizard/steps/Review'

export default [
  {
    id: 'selectDocType',
    label: 'Select Document Type',
    component: SelectDocumentType,
  },
  {
    id: 'selectSections',
    label: 'Define Sections and Parts',
    component: SelectSections,
  },
  {
    id: 'review',
    label: 'Review the Document Template',
    component: Review,
  },
  {
    id: 'savePublish',
    label: 'Save / Publish /Release',
    component: SavePublish,
  },
]