import PartDefinition
  from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/PartSelection/PartDefinition'
import {
  Box,
  Stack,
  TextareaAutosize,
} from '@mui/material'
import React from 'react'
import MDTypography from 'components/core/MDTypography'
import { useSelector } from 'react-redux'
import { wizardSelector } from 'layouts/admin/document-template-flow/Wizard/store/wizardSlice'

export default ({ partContent, setPartContent, partObjectSelected, setContent, content, setNewContent, newContent}) => {
  const docTemplateFlow = useSelector(wizardSelector)
  const onChange = (e) => {
    if (!e) return
    setPartContent(e.target.value)
  }
  return (
    <Stack direction='column' sx={{ justifyContent: 'left' }} spacing={4}>
      <Box>
        <MDTypography p={2} variant={'h6'}>
          {partObjectSelected && partObjectSelected.title ? partObjectSelected.title : 'Description'}
        </MDTypography>
      </Box>
      <PartDefinition type={'text'}  setContent={setContent} setNewContent={setNewContent} setPartContent={setPartContent}/>
      <TextareaAutosize
        minRows={10}
        style={{
          width: '98%', resize: 'none',
          padding: 10, fontFamily: 'sans-serif', fontSize: 15,
        }}
        placeholder='Description...'
        onChange={onChange}
        value={partContent}
        disabled={docTemplateFlow.wizardType === 'view'}
      />
    </Stack>
  )
}