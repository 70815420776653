import {
  InputLabel,
  Stack,
} from '@mui/material'
import * as React from 'react'
import _ from 'lodash'

export default ({
  sections,
  label,
  setSelectedSections,
}) => {
  const handlePartSelect = (event) => {
    setSelectedSections(event.target.value)
  }
  const handleChangeMultiple = (event) => {
    /* let optionsData
     if(event.target && event.target.options) {
       const { options } = event.target
       optionsData = options
     } else if(event.currentTarget) {
       optionsData = event.currentTarget
     }
     const value = []
     for (let i = 0, l = optionsData.length; i < l; i += 1) {
       if(optionsData[i].selected) {
         value.push(optionsData[i].value)
       }
     }
     setSelectedSections(value)*/
  }
  return (
    <Stack direction='column' sx={{ justifyContent: 'center' }} spacing={1}>
      <InputLabel sx={{ textAlign: 'center' }}>
        {label}
      </InputLabel>
      <select
        multiple
        native
        style={{ minHeight: 300, height: 300, width: 220 }}
        //onChange={handleChangeMultiple}
        //onSelect={handleChangeMultiple}
        onClick={handleChangeMultiple}
      >
        {_.map(sections, (section, index) => (
          <>
            <option key={`option_${index}`} value={section.key}
                    style={{ fontSize: '15px', fontFamily: 'sans-serif', padding: '10px' }}
                    onClick={(event) => handlePartSelect(event)}>
              {section.label}
            </option>
          </>
        ))}
      </select>

    </Stack>

  )

}