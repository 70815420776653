import _ from 'lodash'

export const generateSectionTreeData = (document, availableSectionsData, availablePartsData) => {
  const { sections } = document
  const sectionTreeData = {}
  const addedSections = []
  const availableSections = []
  _.forEach(sections, section => {
    addedSections.push(createSection(section))
  })
  addToAvailableSections(availableSectionsData, availableSections, addedSections)
  sectionTreeData['Root'] = { availableSections, addedSections }
  _.forEach(sections, section => {
    generateSectionData(`Root_${section.title}`, section, sectionTreeData, availableSectionsData, availablePartsData)
  })
  return sectionTreeData
}

const addToAvailableSections = (availableSectionsData, availableSections, addedSections) => {
  _.forEach(availableSectionsData, availableSectionsDataValue => {
    let match = false
    _.forEach(addedSections, addedSection => {
      if (availableSectionsDataValue.title === addedSection.title) {
        match = true
      }
    })
    if (!match) {
      availableSections.push(createSection(availableSectionsDataValue))
    }
  })
}

const addToAvailableParts = (availablePartsData, availableParts, addedParts) => {
  const addedPartsTypes = _.map(addedParts, addedPart => addedPart.type)
  const remAvblParts = _.filter(availablePartsData, (availablePart) => {
    if (!addedPartsTypes.includes(availablePart.type)) {
      return availablePart
    }
  })
  _.forEach(remAvblParts, remAvblPart => {
    availableParts.push(remAvblPart)
  })
}

const addToAvailableSubsystemParts = (availablePartsData, availableSubsystems, addedSubsystems) => {
  const addedSubsystemPartsTypes = _.map(addedSubsystems, addedSubsystem => addedSubsystem.type)
  const remAvblSubsystemParts = _.filter(availablePartsData, (availableSubsystem) => {
    if (!addedSubsystemPartsTypes.includes(availableSubsystem.type)) {
      return availableSubsystem
    }
  })
  _.forEach(remAvblSubsystemParts, remAvblSubsystemPart => {
    availableSubsystems.push(remAvblSubsystemPart)
  })
}

const generateSectionsData = (parentTitle, sections, sectionTreeData, availableSectionsData, availablePartsData) => {
  _.forEach(sections, section => {
    generateSectionData(`${parentTitle}_${section.title}`, section, sectionTreeData, availableSectionsData, availablePartsData)
  })
}

export const generateSectionData = (parentTitle, currentSection, sectionTreeData, availableSectionsData, availablePartsData) => {
  const addedSections = []
  const availableSections = []
  const sectionData = {}
  if (currentSection.sections) {
    _.forEach(currentSection.sections, section => {
      addedSections.push(createSection(section))
    })
  }
  if (currentSection.parts) {
    const generatedParts = generatePartsData(currentSection.parts)
    addToAvailableParts(availablePartsData, generatedParts.availableParts, generatedParts.addedParts)
    sectionData['addedParts'] = generatedParts.addedParts
    sectionData['availableParts'] = generatedParts.availableParts
  }
  
  if (currentSection.subSystemDefinition && currentSection.subSystemDefinition.parts) {
    const generatedSubsystemParts = generateSubsystemPartsData(currentSection.subSystemDefinition.parts)
    addToAvailableSubsystemParts(availablePartsData, generatedSubsystemParts.availableSubsystems, generatedSubsystemParts.addedSubsystems)
    sectionData['addedSubsystems'] = generatedSubsystemParts.addedSubsystems
    sectionData['availableSubsystems'] = generatedSubsystemParts.availableSubsystems
  }
  
  addToAvailableSections(availableSectionsData, availableSections, addedSections)
  sectionData['addedSections'] = addedSections
  sectionData['availableSections'] = availableSections
  sectionTreeData[parentTitle] = sectionData
  if (currentSection.sections) {
    generateSectionsData(`${parentTitle}`, currentSection.sections, sectionTreeData, availableSectionsData, availablePartsData)
  }
  return sectionData
}

export const generatePartsData = (parts) => {
  const addedParts = []
  const availableParts = []
  _.forEach(parts, part => {
    addedParts.push(createPart(part))
  })
  return { availableParts, addedParts }
}

export const generateSubsystemPartsData = (parts) => {
  const addedSubsystems = []
  const availableSubsystems = []
  _.forEach(parts, part => {
    addedSubsystems.push(createPart(part))
  })
  return { availableSubsystems, addedSubsystems }
}

export const createSection = (section) => {
  return {
    title: section.title,
  }
}

export const createPart = (part) => {
  return {
    title: part.title,
    type: part.type,
    tableDef: part.tableDef ? part.tableDef : '',
  }
}

export const convertDocumentToTemplateWorkflow = (document) => {
  const { domain, subdomain, type, sections, hardwareTestsDefs } = document
  const templateWorkflow = {}
  templateWorkflow['document'] = {}
  templateWorkflow['document']['domain'] = { label: domain, value: domain }
  templateWorkflow['document']['subdomain'] = { label: subdomain, value: subdomain }
  templateWorkflow['document']['documentType'] = { label: type, value: type }
  templateWorkflow['sections'] = sections
  templateWorkflow['hardwareTestsDefs'] = hardwareTestsDefs || {}
  
  return templateWorkflow
}

export const generateSectionTree = (document) => {
  const { sections } = document
  const defaultSectionTree = {
    id: 'root',
    title: 'Root',
    children: [
      {
        id: 'Root__addchildsection',
        type: 'childSectionButton',
        title: 'Add Child Section',
      },
    ],
  }
  const sectionTree = defaultSectionTree
  _.forEach(sections, section => {
    sectionTree.children.push(generateSection(sectionTree.title, section))
  })
  return sectionTree
}

const generateSections = (parentTitle, sections, addedSections) => {
  _.forEach(sections, section => {
    addedSections.children.push(generateSection(`${parentTitle}`, section))
  })
}

const generateSection = (parentTitle, currentSection) => {
  let addedSections = addSection(parentTitle, currentSection)
  if (currentSection.sections) {
    generateSections(`${addedSections.id}`, currentSection.sections, addedSections)
  }
  if (currentSection.parts) {
    const generatedParts = generateParts(`${addedSections.id}`, currentSection.parts)
    if (!_.isEmpty(generatedParts)) {
      addedSections.children = addedSections.children.concat(generatedParts)
    }
  }
  return addedSections
}

const generateParts = (parentTitle, parts) => {
  const addedParts = []
  _.forEach(parts, part => {
    addedParts.push(addPart(parentTitle, part))
  })
  return addedParts
}

const addSection = (currentSection, addedSection) => {
  return {
    id: `${currentSection}_${addedSection.title}`,
    title: addedSection.title,
    children: [
      {
        id: `${currentSection}_${addedSection.title}__addchildsection`,
        type: 'childSectionButton',
        title: 'Add Child Section',
      },
      {
        id: `${currentSection}_${addedSection.title}__addchildpart`,
        type: 'childPartButton',
        title: 'Add Child Part',
      },
      {
        id: `${currentSection}_${addedSection.title}__addsubsystem`,
        type: 'subsystemButton',
        title: 'Define SubSystem',
      },
    ],
  }
}

const addPart = (currentPart, addedPart) => {
  return {
    id: `${currentPart}_${addedPart.title}`,
    title: addedPart.title,
    type: addedPart.type,
  }
}
