// Material Kit 2 React components
import MKBox from 'components/core/MKBox'
import BoschNavBar from '../BoschNavBar'

// Material Kit 2 React examples
// Routes
import routes from 'routes'
import DefaultNavbar from 'layouts/navigation/components/DefaultNavbar'

function TopNav () {
  return (
    <MKBox>
      <BoschNavBar routes={routes} transparent relative light center />
    </MKBox>
  )
}

export default TopNav
