export const documentData = {
  tableDef: {
    columns: [
      {
        accessor: 'id',
        Header: 'ID',
        colWidth: 60,
        isVisible: false,
      },
      {
        accessor: 'type',
        Header: 'Template Type',
        colWidth: 140,
        isVisible: true,
      },
      {
        accessor: 'subdomain',
        Header: 'Subdomain',
        colWidth: 140,
        isVisible: true,
      },
      {
        accessor: 'state',
        Header: 'Status',
        colWidth: 140,
        isVisible: true,
      },
      {
        accessor: 'owner',
        Header: 'Owner',
        colWidth: 200,
        isVisible: false,
      },
      {
        accessor: 'lastModifiedDate',
        Header: 'Last Modified Date',
        colWidth: 110,
        isVisible: true,
      },
      {
        accessor: 'version',
        Header: 'Version',
        colWidth: 110,
        isVisible: true,
      },
      {
        accessor: 'actions',
        Header: 'Actions',
        colWidth: 110,
        isVisible: true,
      },
    ],
  },
}

