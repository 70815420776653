import {
  Box,
  Stack,
  TextField,
} from '@mui/material'
import React, {
  useCallback,
  useState,
} from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import {
  searchParts,
} from 'layouts/admin/document-template-flow/Wizard/store/saga'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { wizardSelector } from 'layouts/admin/document-template-flow/Wizard/store/wizardSlice'
import { useEffect } from 'react'
import _ from 'lodash'
import { documentSelector } from 'layouts/document-management/document-store/documentSlice'
import { getDocumentPart } from 'layouts/document-management/document-store/saga'

export default ({
  type,
  setTableDef,
  setNewContent,
  setContent,
  setPartContent,
  setRowsData,
  setImageOrientation,
}) => {
  const dispatch = useDispatch()
  const docTemplateFlow = useSelector(wizardSelector)
  const mainDoc = useSelector(documentSelector)
  const [options, setOptions] = useState([])
  const [domain, setDomain] = useState()
  const [subdomain, setSubdomain] = useState()
  useEffect(() => {
    const documentType = docTemplateFlow?.templateWorkflow?.document
    setDomain(documentType?.domain)
    setSubdomain(documentType?.subdomain)
  }, [])

  useEffect(() => {
    const updatedOptions = _.map((docTemplateFlow.searchedParts),(part) => {
      return {
        label: `Project: ${part.projectName}, Document: ${part.documentName}, Section: ${part.sectionName}, Part: ${part.partName}`,
        key: `${part.projectId} ${part.documentId} ${part.sectionId} ${part.partId} `,
        documentId: part.documentId,
        partId: part.partId,
      }
    })
    setOptions(updatedOptions)
  }, [docTemplateFlow.searchedParts])

  const updateDocumentPart = () => {
    const documentPart = mainDoc.selected_part
    const sectionToDisplay = documentPart
    setContent([])
    if(type === 'table'){
      const documentPart = mainDoc.selected_part
      const sectionToDisplay = documentPart
      const { table, heading, tableDef } = sectionToDisplay || ''
      if(!_.isEmpty(table) && !_.isEmpty(tableDef)) {
        setTimeout(() => {
          setContent(table)
          //setNewContent(table)
          setTableDef(tableDef)
        },10)
      }
     // setContent(table)
      //setNewContent(table)
      //setTableDef(tableDef)
    }else if(type === 'text'){
      const { text } = sectionToDisplay || ''
      setContent(text)
      if(setPartContent){
        setPartContent(text)
      }
      setNewContent(text)
    }else if(type === 'images'){
      const { images, orientation } = sectionToDisplay || ''
      setImageOrientation(orientation)
      if(setRowsData){
        setRowsData(images)
      }

      setPartContent({'orientation': orientation, images: images})
    }
  }
  useEffect(() => {
    if(!_.isEmpty(mainDoc.selected_part)) updateDocumentPart()
  }, [mainDoc.selected_part])

  const getData = (searchTerm) => {
    dispatch(searchParts({ domain: domain.value, subdomain: subdomain.value, keyword: searchTerm, type: type }))
  }
  /*
  const onInputChange = (event, value, reason) => {
    if (value) {
      getData(value)
    } else {
      setOptions([])
    }
  }

   */

  const handleSearchParts = (searchTerm, domain, subdomain) => {
    dispatch(searchParts({ domain: domain.value, subdomain: subdomain.value, keyword: searchTerm, type: type }))
  }

  const callSearchParts = useCallback(_.debounce(handleSearchParts, 300), [])

  const onInputChange = (event, value, reason) => {
    if (value) {
      callSearchParts(value, domain, subdomain)
    } else {
      setOptions([])
    }
  }


  const getPart = (value) => {
    if(value) {
      dispatch(getDocumentPart({
        documentId: value.documentId,
        partId: value.partId,
      }))
    }
  }
  return (
    <Box sx={{ justifyContent: 'left' }}>
      <Stack direction='column' sx={{ justifyContent: 'left' }} spacing={4}>
        <Autocomplete
          id='combo-box-demo'
          options={options}
          onInputChange={onInputChange}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} label='Search existing document for prefilled data ....' variant='outlined' />
          )}
          onChange={(event, value) => {
            getPart(value)
          }}
        />
      </Stack>
    </Box>
  )
  
}