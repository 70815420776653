import React, {
  useEffect,
  useState,
} from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import MDButton from 'components/core/MDButton'
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from '@mui/material'
import makeAnimated from 'react-select/animated'
import { useNavigate } from 'react-router-dom'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { loginSelector } from '../../authentication/store/userSlice'
import { createDocument } from '../../dashboard/store/saga'
import Select from 'react-select'
import _ from 'lodash'
import { mainSelector } from '../../dashboard/store/mainSlice'
import { documentFields } from './constants/document-fields'
import CircularProgressStyled from 'components/extended/progress/CircularProgressStyled'
import { documentSelector } from 'layouts/document-management/document-store/documentSlice'
import { getPublishedDocumentTemplates } from 'layouts/document-management/document-store/saga'
import { mapProjectToDomain } from 'layouts/dashboard/project-reader'
import { getDateISOString } from 'utils/date-time'

const validationSchema = yup.object({
  tags: yup
    .string('Enter Tags')
    .required('Tags required'),

})

export default ({}) => {
  const animatedComponents = makeAnimated()
  const TYPE = 'type'
  const ID = 'docTemplateId'
  const PROJECTID = 'projectId'
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector(loginSelector)
  const dashData = useSelector(mainSelector)
  const docData = useSelector(documentSelector)
  const [documentName, setDocumentName] = useState('')

  const indicatorSize = 80
  const options = _.map(dashData.projects, project => {
    return {
      value: project.id,
      label: project.name,
    }
  })
  const projectToDomainMap = mapProjectToDomain(dashData.projects)
  const [documentTypes, setDocumentTypes] = useState([])
  let projectId = dashData?.selected_project?.id

  useEffect(() => {
    const proj = projectToDomainMap[projectId]
    const domArray = proj.split('.')
    dispatch(getPublishedDocumentTemplates({ domain: domArray[0], subdomain: domArray[1] }))
  }, [])

  useEffect(() => {
    const publishedDocumentTemplateObjs = _.map(docData.publishedDocumentTemplates, publishedDocumentTemplate => {
      const { id, type, version, publishedDate } = publishedDocumentTemplate
      const date = getDateISOString({ dateTime: publishedDate })
      return {
        label: `${type} - v ${version} - ${date}`,
        value: id,
      }
    })
    setDocumentTypes(publishedDocumentTemplateObjs)
  }, [docData.publishedDocumentTemplates])

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    isValid,
    setFieldValue,
    getValues,
    validateForm,
    handleSubmit,
  } = useFormik({
    initialValues: {
      docTemplateId: '',
      projectId: '',
      tags: '',
    },
    onSubmit: async (values) => {
      handleSave(values)
    },
    validationSchema: validationSchema,
  })

  const handleSave = (data) => {
    data['name'] = documentName
    dispatch(createDocument({ data, navigate }))
    return true
  }

  return (
    <div style={{ background: '#fefefe' }}>
      <Grid container style={{ width: '60%', margin: 'auto' }}>
        <Card>
          <CardHeader title={'Add Document'} />
          <CardContent style={{ minWidth: '500px'}}>
            <CircularProgressStyled
              size={indicatorSize}
              sx={{
                display: dashData.loading ? 'block' : 'none',
                position: 'relative',
                top: '30%',
                left: '50%',
                zIndex: '9999',
                marginTop: `${-indicatorSize / 2}px`,
                marginLeft: `${-indicatorSize / 2}px`,
                background: 'transparent',
                color: 'gray',
              }}
            />
          </CardContent>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Select
                defaultValue={() => {
                  setFieldValue(PROJECTID, dashData.selected_project.id)
                  return {
                    value: dashData.selected_project.id,
                    label: dashData.selected_project.name,
                  }
                }
                }
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 100,
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    marginBottom: 10,
                  }),
                }}
                name='Project'
                placeholder='Select Project'
                components={animatedComponents}
                options={options}
                onChange={(option) => {
                  projectId = option.value
                  setFieldValue(PROJECTID, option.value)
                }}
              />
              <Select
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 100,
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    marginBottom: 10,
                  }),
                }}
                name='Document Type'
                placeholder='Select Document Type'
                components={animatedComponents}
                options={documentTypes}
                onChange={(option) => {
                  setFieldValue(ID, option.value)
                  const label = option.label
                  const labelArray = label.split(' - ')
                  setDocumentName(labelArray[0])
                }}
              />
              {_.map(documentFields, (field) =>
                <TextField
                  key={field.name}
                  sx={{ mb: 2 }}
                  fullWidth
                  id={field.name}
                  name={field.name}
                  label={field.label}
                  value={values[field.name]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched[field.name] && Boolean(errors[field.name])}
                  helperText={touched[field.name] && errors[field.name]}
                />,
              )}

              <MDButton color='primary' disabled={_.isEmpty(touched) || !isValid} variant='contained' fullWidth
                        type='submit'>
                Submit
              </MDButton>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </div>
  )
}