import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import { createAction } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  onLoadingUserEnd,
  onLoadingUserStart,
  onLoadingUserFail, addSubSystem, removeSubSystem,
} from './subSystemSlice'

export const createSubsystem = createAction('createSubsystemSaga')

export const deleteSubsystem = createAction('deleteSubsystemSaga')

export function * createSubsystemSaga ({ payload: { data, onSuccess } }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.post, `/dms/subsystem`, data, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    yield put(addSubSystem({ subSystemName: data.subSystemName }))
    onSuccess()
  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export function * deleteSubsystemSaga ({ payload: { data, onSuccess } }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.delete, `/dms/subsystem?docId=${data.docId}&subSystemName=${data.subSystemName}`, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    yield put(removeSubSystem({ subSystemName: data.subSystemName }))
    onSuccess()
  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export default function * subSystemSaga () {
  yield takeLatest(createSubsystem.type, createSubsystemSaga)
  yield takeLatest(deleteSubsystem.type, deleteSubsystemSaga)
}