import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

export function onLoadingStart (state) {
  state.loading = true
}

export function onLoadingFail (state, { payload: error }) {
  state.loading = false
  state.error = error
  state.loaded = true
}

export function onLoadingSuccess (state) {
  state.loading = false
  state.error = null
  state.loaded = true
}

export const initialState = {
  loading: false,
  error: null,
  loaded: true,
  selected_part: undefined,
  selected_search_part: undefined,
  parts: undefined,
  fromTree: true,
  publishedDocumentTemplates: []
}

const addParts = (state, { payload: parts }) => {
  state.loading = false
  state.error = null
  state.parts = parts
  state.selected_search_part = undefined
}

const addSelectedPart = (state, { payload: part }) => {
  state.loading = false
  state.error = null
  state.selected_part = part
  state.fromTree = part ? part.fromTree : true
  state.selected_search_part = undefined
}

const addSelectedSearchPart = (state, { payload: part }) => {
  state.loading = false
  state.error = null
  state.selected_search_part = part
}

const addPublishedDocumentTemplates = (state, { payload: publishedDocumentTemplates }) => {
  state.loading = false
  state.error = null
  state.publishedDocumentTemplates = publishedDocumentTemplates
}


const deleteSelectedPart = (state, {}) => {
  state.loading = false
  state.error = null
  state.selected_part = undefined
  state.selected_search_part = undefined
}

const documentSlice = createSlice({
  name: 'documentReducer',
  initialState,
  reducers: {
    onLoadingUserStart: onLoadingStart,
    onLoadingUserEnd: (state) => {
      state.loading = false
      state.error = null
    },
    onLoadingUserFail: onLoadingFail,
    getPartsSuccess: addParts,
    getDeleteSelectedPart: deleteSelectedPart,
    getPartSuccess: addSelectedPart,
    getSearchPartSuccess: addSelectedSearchPart,
    getAddPublishedDocumentTemplatesSuccess: addPublishedDocumentTemplates,
  },
})

export const {
  onLoadingUserStart,
  onLoadingUserEnd,
  onLoadingUserFail,
  getPartsSuccess,
  getPartSuccess,
  getSearchPartSuccess,
  getDeleteSelectedPart,
  getAddPublishedDocumentTemplatesSuccess
} = documentSlice.actions
export default documentSlice.reducer

export const documentReducerSelector = (state) => state.layoutsReducer[documentSlice.name]
export const documentSelector = createSelector(documentReducerSelector,
  ({ loading, error, parts, selected_part, fromTree, publishedDocumentTemplates, selected_search_part }) => ({
    loading, error, parts, fromTree, selected_part, publishedDocumentTemplates, selected_search_part,
  }))
