import React, {
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  Box,
  Drawer,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  TextField,
} from '@mui/material'
import 'reactflow/dist/style.css'

import ReactFlow, {
  addEdge,
  Background,
  Controls,
  MiniMap,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from 'reactflow'
import _ from 'lodash'
import CircularProgressStyled from '../../progress/CircularProgressStyled'
import { useCurrentEffect } from 'use-current-effect'

import InputNode from './nodes/InputNode'
import OutputNode from './nodes/OutputNode'
import ActionNode from './nodes/ActionNode'
import SubsystemNode from './nodes/SubsystemNode'
import './css/index.css'
import InputLabel from '@mui/material/InputLabel'
import Select from 'react-select'
import MDBox from '../../../core/MDBox'
import { camelCaseToTextConvert } from 'utils/util'

const nodeTypes = {
  inputNode: InputNode,
  outputNode: OutputNode,
  subsystemNode: SubsystemNode,
  actionNode: ActionNode,
}

let _id = 1
const getId = () => `-${++_id}`

export default ({
  reactFlowWrapper,
  subSystemMap,
  reactFlowInstance,
  setReactFlowInstance,
  workflowContent,
  handleOnTreeNodeSelect,
  loading,
  setTableNodes,
  actionSet,
  nodeToDelete,
  setValidWorkflow,
  onChangeOfInputNodeInputValue,
  onChangeOfOutputNodeOutputValue,
  getInputNumberValue,
  getOutputNumberValue,
  setDialogBody,
  setDialogTitle,
  setDialogOpen,
  setIsDeleteDialog,
}) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [selectedNode, setSelectedNode] = useState()
  const [errorDialog, setErrorDialog] = useState({ dialogBody: '', dialogTitle: '', dialogOpen: false, confirmationDialog: false })
  const [hideDelete, setHideDelete] = useState(false)
  const [notifyAttributeSet, setNotifyAttributeSet] = useState(Math.random())
  const [selectedInputOutputNode, setSelectedInputOutputNode] = useState()
  const [inputNumber, setInputNumber] = useState(0)
  const [outputNumber, setOutputNumber] = useState(0)
  const { setViewport, getViewport, zoomTo, zoomOut } = useReactFlow()
  
  useEffect(() => {
    setDialogBody(errorDialog.dialogBody)
    setDialogTitle(errorDialog.dialogTitle)
    setDialogOpen(errorDialog.dialogOpen)
    setIsDeleteDialog(errorDialog.confirmationDialog)
  }, [errorDialog])
  
  useEffect(() => {
    setTableNodes(nodes)
  }, [nodes])
  
  const onChangeOfOutputParameterCount = ({ nodeId, count }) => {
    setNodes((nds) =>
      nds.map(nd => {
        if (nd.id === nodeId) {
          return {
            ...nd,
            data: {
              ...nd.data,
              executionInfo: {
                ...nd.data.executionInfo,
                outputParameters: {
                  ...nd.data.executionInfo.outputParameters,
                  outputNumber: count,
                  outputData: [],
                },
              },
              
            },
          }
        } else {
          return nd
        }
      }),
    )
  }
  
  const onChangeOfInputParameterCount = ({ nodeId, count }) => {
    setNodes((nds) =>
      nds.map(nd => {
        if (nd.id === nodeId) {
          return {
            ...nd,
            data: {
              ...nd.data,
              executionInfo: {
                ...nd.data.executionInfo,
                inputParameters: {
                  ...nd.data.executionInfo.inputParameters,
                  inputNumber: count,
                  inputData: [],
                },
              },
              
            },
          }
        } else {
          return nd
        }
      }),
    )
  }
  
  const onChangeOfHeaderText = ({ nodeId, data }) => {
    setNodes((nds) =>
      nds.map(nd => {
        if (nd.id === nodeId) {
          return {
            ...nd,
            data: {
              ...nd.data,
              executionInfo: {
                ...nd.data.executionInfo,
                headerText: data,
              },
              
            },
          }
        } else {
          return nd
        }
      }),
    )
  }
  
  const handleConnection = (data) => {
    setNodes((nds) =>
      nds.map(nd => {
        if (nd.id === data.target) {
          const datax = { ...nd.data }
          datax.connected = true
          nd.data = datax
        }
        return nd
      }),
    )
  }
  const onConnect = useCallback((params) => {
    setEdges((eds) => addEdge(params, eds))
    handleConnection(params)
  }, [])
  
  const onDragOver = useCallback((event) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }, [])
  
  const onDelete = (id) => {
    setNodes((nds) =>
      nds.filter((node) => node.id !== id))
    
    setEdges((eds) =>
      eds.filter((edge) => edge.source !== id && edge.target !== id))
  }
  
  const dropSubsystemNode = ({
    type,
    subType,
    nodeId,
    position,
  }) => {
    const attributes = (_.find(actionSet, { actionId: type }))?.attributes || []
    const paramObject = {}
    _.map(attributes, (attribute) => {
      paramObject[attribute.key] = attribute.defaultValue || ''
    })
    const executionInfo = {
      actionId: type,
      paramObject: paramObject,
      attributes: attributes,
      headerText: (subType ? camelCaseToTextConvert(subType) : 'Click To Enter Text'),
    }
    const newNode = {
      id: nodeId,
      type: 'subsystemNode',
      position,
      data: {
        connected: true,
        label: `${type} node`,
        nodeId,
        type,
        subType: (subType ? subType : ''),
        subSystemMap: subSystemMap[subType],
        handleOnTreeNodeSelect,
        onDelete,
        hideDelete,
        setSelectedNode,
        setErrorDialog,
        executionInfo,
        setSelectedInputOutputNode,
        valid: false,
      },
    }
    setNodes((nds) => nds.concat(newNode))
  }
  
  const dropActionNode = ({
    type,
    nodeId,
    position,
    displayName,
  }) => {
    setSelectedInputOutputNode()
    setSelectedNode()
    const attributes = (_.find(actionSet, { actionId: type }))?.attributes || []
    const paramObject = {}
    _.map(attributes, (attribute) => {
      paramObject[attribute.key] = attribute.defaultValue || ''
    })
    const dCount = _.filter(nodes ,
      ({ data : { executionInfo : { displayName : dName } }}) => dName && dName === displayName ).length
    paramObject['instanceId']=dCount+1
    const executionInfo = {
      actionId: type,
      paramObject: paramObject,
      attributes: attributes,
      displayName,
      headerText: `${displayName}-${(dCount+1)}`,
    }
    const newNode = {
      id: nodeId,
      type: 'actionNode',
      position,
      data: {
        connected: true,
        label: `${type} node`,
        nodeId,
        type,
        onDelete,
        hideDelete,
        setSelectedNode,
        setErrorDialog,
        executionInfo,
        valid: false,
      },
    }
    setNodes((nds) => nds.concat(newNode))
  }
  
  const dropInputNode = ({
    type,
    nodeId,
    position,
    displayName,
  }) => {
    const attributes = (_.find(actionSet, { actionId: type }))?.attributes || []
    const paramObject = {}
    _.map(attributes, (attribute) => {
      paramObject[attribute.key] = attribute.defaultValue || ''
    })
    const dCount = _.filter(nodes ,
      ({ data : { executionInfo : { displayName : dName } }}) => dName && dName === displayName ).length
    paramObject['instanceId']=dCount+1
    const executionInfo = {
      actionId: type,
      paramObject: paramObject,
      attributes: attributes,
      displayName,
      headerText: `${displayName}-${(dCount+1)}`,
      inputParameters: {
        inputNumber: 0,
        inputData: [],
      },
    }
    const newNode = {
      id: nodeId,
      type: 'inputNode',
      position,
      data: {
        connected: true,
        label: `${type} node`,
        nodeId,
        type,
        onDelete,
        hideDelete,
        setSelectedNode,
        setErrorDialog,
        setSelectedInputOutputNode,
        setInputNumber,
        executionInfo,
        valid: false,
      },
    }
    setNodes((nds) => nds.concat(newNode))
    
  }
  
  const dropOutputNode = ({
    type,
    nodeId,
    position,
    displayName,
  }) => {
    const attributes = (_.find(actionSet, { actionId: type }))?.attributes || []
    const paramObject = {}
    _.map(attributes, (attribute) => {
      paramObject[attribute.key] = attribute.defaultValue || ''
    })
    const dCount = _.filter(nodes ,
      ({ data : { executionInfo : { displayName : dName } }}) => dName && dName === displayName ).length
    paramObject['instanceId']=dCount+1
    const executionInfo = {
      actionId: type,
      paramObject: paramObject,
      attributes: attributes,
      displayName,
      headerText: `${displayName}-${(dCount+1)}`,
      inputParameters: {
        inputNumber: 0,
        inputData: [],
      },
    }
    const newNode = {
      id: nodeId,
      type: 'outputNode',
      position,
      data: {
        connected: true,
        label: `${type} node`,
        nodeId,
        type,
        onDelete,
        hideDelete,
        setSelectedNode,
        setErrorDialog,
        setSelectedInputOutputNode,
        setInputNumber,
        executionInfo,
        valid: false,
      },
    }
    setNodes((nds) => nds.concat(newNode))
  }
  
  const onDrop = (event) => {
    event.preventDefault()
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
    const type = event.dataTransfer.getData('application/reactflow')
    const elementType = event.dataTransfer.getData('application/reactflow/inputType')
    const displayName = event.dataTransfer.getData('application/reactflow/displayName')
    if (typeof type === 'undefined' || !type) {
      return
    }
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    })
    const nodeId = getId()
    if (elementType === 'inputUnit') {
      dropInputNode({ type, displayName, nodeId: `${type}-${nodeId}`, position })
    } else if (elementType === 'outputUnit') {
      dropOutputNode({ type, displayName, nodeId: `${type}-${nodeId}`, position })
    } else if (type.indexOf('subsystemNode') >= 0) {
      const subType = type.split('_').pop()
      dropSubsystemNode({ type: 'subsystemNode', subType, nodeId: `subsystemNode-${subType}-${nodeId}`, position })
    } else {
      dropActionNode({ type, displayName, nodeId: `${type}-${nodeId}`, position })
    }
    
  }
  const getUnitName = (actionId) => {
    switch(actionId) {
      case 'powerUnit' : return 'Power Unit'
      case 'commsUnit' : return 'Comm Unit'
      case 'cpuUnit' : return 'CPU Unit'
    }
  }
  useEffect(() => {
    if (_.isEmpty(nodeToDelete)) return
    const nds = _.filter(nodes, ({ data }) => data.subType === nodeToDelete)
    if (nds) _.map(nds, nd => onDelete(nd.id))
  }, [nodeToDelete])
  
  useEffect(() => {
    _.map(_.filter(nodes, { id: selectedNode?.nodeId }), entry => {
      const { data } = entry
      const paramObject = data.executionInfo?.paramObject
      _.isEmpty(_.filter(paramObject, param => _.isEmpty(param)))
        ? data.valid = true : data.valid = false
    })
    !_.isEmpty(nodes)
    && _.isEmpty(_.filter(nodes, node => node.data.valid === false))
      ? setValidWorkflow(true)
      : setValidWorkflow(false)
    
  }, [notifyAttributeSet])
  
  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        const nd = _.cloneDeep(node)
        nd.data.hideDelete = hideDelete
        return nd
      }),
    )
  }, [hideDelete])
  
  useCurrentEffect(() => {
    if (workflowContent) {
      const { workflowInstance } = workflowContent
      const cMap = {}
      if (workflowInstance) {
        let { x = 0, y = 0, zoom = 0 } = workflowInstance.viewport
        const nds = _.map(workflowInstance.nodes, node => {
          const nd = _.cloneDeep(node)
          nd.data.onDelete = onDelete
          nd.data.hideDelete = hideDelete
          nd.data.setSelectedNode = setSelectedNode
          nd.data.setErrorDialog = setErrorDialog
          if (node.type === 'inputNode') {
            nd.data.setSelectedInputOutputNode = setSelectedInputOutputNode
            nd.data.setInputNumber = setInputNumber
          }
          if (node.type === 'outputNode') {
            nd.data.setSelectedInputOutputNode = setSelectedInputOutputNode
            nd.data.setOutputNumber = setOutputNumber
          }
          if (node.type === 'subsystemNode') {
            nd.data.subSystemMap = subSystemMap[nd.data.subType]
            nd.data.handleOnTreeNodeSelect = handleOnTreeNodeSelect
          }
          if (['outputNode' , 'inputNode' , 'actionNode'].includes(node.type)
          && (!node.data.executionInfo.headerText
                || node.data.executionInfo.headerText.indexOf('-') < 0)) {
            const displayName = nd.data.executionInfo.displayName || getUnitName(nd.data.type)
            const dCount = cMap[displayName] || _.filter(workflowInstance.nodes ,
              ({ data : { executionInfo : { displayName : dName } }}) => dName && dName === displayName ).length
            nd.data.executionInfo.headerText = `${displayName}-${(dCount+1)}`
            nd.data.executionInfo.paramObject['instanceId'] = dCount+1
            nd.data.executionInfo.displayName = displayName
            if(!cMap[displayName]){
              cMap[displayName] = 1
            }else {
              cMap[displayName]  = cMap[displayName] + 1
            }
            
          }else if(node.data.executionInfo.headerText
            && node.data.executionInfo.headerText.indexOf('-') >= 0) {
            nd.data.executionInfo.paramObject['instanceId']
                    = _.last(node.data.executionInfo.headerText.split('-'))
          }
          return nd
        })
        setNodes(nds)
        _id = nds.length + 1
        setValidWorkflow(true)
        setEdges(workflowInstance.edges || [])
        setViewport({ x, y, zoom })
      }
    } else {
      setNodes([])
      setEdges([])
      setViewport({ x: 0, y: 0, zoom: 0.2 })
    }
  }, [workflowContent])
  
  const getInputOutputBars = () => {
    return _.map(_.filter(nodes, { id: selectedInputOutputNode?.nodeId }), entry => {
      const { data, type } = entry
      if (type === 'inputNode') {
        return inputNumber > 0 && (<div>
          {_.map(_.range(0, inputNumber), (current) => (
            <div>
              <TextField
                key={`input-${current}`}
                placeholder={`Enter Value`}
                name={'inputNumber'}
                variant='outlined'
                onChange={ev => {
                  setTimeout(() => data.executionInfo.inputParameters.inputData[current] = ev.target.value, 10)
                  onChangeOfInputNodeInputValue({ nodeId: data.nodeId, inputValueCurrentIndex: current, value: ev.target.value })
                }}
                fullWidth
              />
            </div>
          ))}
        </div>)
      } else if (type === 'outputNode') {
        return outputNumber > 0 && (<div>
          {_.map(_.range(0, outputNumber), (current) => (
            <div>
              <TextField
                key={`output-${current}`}
                placeholder={`Enter Value`}
                name={'outputNumber'}
                variant='outlined'
                onChange={ev => {
                  setTimeout(() => data.executionInfo.outputParameters.outputData[current] = ev.target.value, 10)
                  onChangeOfOutputNodeOutputValue({ nodeId: data.nodeId, outputValueCurrentIndex: current, value: ev.target.value })
                }}
                fullWidth
              />
            </div>
          ))}
        </div>)
      }
      
    })
  }
  
  const getInputOutputNodeData = () => {
    return _.map(_.filter(nodes, { id: selectedInputOutputNode?.nodeId }), entry => {
      const { data, type } = entry
      if (type === 'inputNode') {
        return (<><Box key='inputHeaderNodeText' component='span' sx={{ p: 1, border: '1px dashed grey' }}>
            <TextField
              placeholder={`Enter Header Text`}
              name={'inputText'}
              variant='outlined'
              onChange={ev => {
                onChangeOfHeaderText({ nodeId: data.nodeId, data: ev.target.value })
              }}
              fullWidth
            />
          </Box>
            <Box key='inputNode' component='span' sx={{ p: 1, border: '1px dashed grey' }}>
              <TextField
                placeholder={`Enter Number of Inputs`}
                name={'inputNumber'}
                variant='outlined'
                value={getInputNumberValue({ nodeId: data.nodeId })}
                onChange={ev => {
                  setInputNumber(ev.target.value)
                  onChangeOfInputParameterCount({ nodeId: data.nodeId, count: ev.target.value })
                }}
                fullWidth
              />
            </Box>
          </>
        )
      } else if (type === 'outputNode') {
        return (<><Box key='outputHeaderNodeText' component='span' sx={{ p: 1, border: '1px dashed grey' }}>
            <TextField
              placeholder={`Enter Header Text`}
              name={'outputText'}
              variant='outlined'
              onChange={ev => {
                onChangeOfHeaderText({ nodeId: data.nodeId, data: ev.target.value })
              }}
              fullWidth
            />
          </Box>
            <Box key='outputNode' component='span' sx={{ p: 1, border: '1px dashed grey' }}>
              <TextField
                placeholder={`Enter Number of Outputs`}
                name={'outputNumber'}
                value={getOutputNumberValue({ nodeId: data.nodeId })}
                variant='outlined'
                onChange={ev => {
                  setOutputNumber(ev.target.value)
                  onChangeOfOutputParameterCount({ nodeId: data.nodeId, count: ev.target.value })
                }}
                fullWidth
              />
            </Box>
          </>
        )
      } else if (type === 'subsystemNode') {
        return (<><Box key='subsystemHeaderNodeText' component='span' sx={{ p: 1, border: '1px dashed grey' }}>
            <TextField
              placeholder={`Enter New Subsystem Name`}
              name={'subsystemText'}
              variant='outlined'
              onChange={ev => {
                onChangeOfHeaderText({ nodeId: data.nodeId, data: ev.target.value })
              }}
              fullWidth
            />
          </Box>
          </>
        )
      }
    })
  }
  
  const getFields = () => {
    return _.map(_.filter(nodes, { id: selectedNode?.nodeId }), entry => {
      const { data, type } = entry
      const attributes = (_.find(actionSet, { actionId: data.type }))?.attributes || []
      const { paramObject } = data.executionInfo
      return _.map(attributes, (attr) => {
        const { options } = attr
        const setSourceOptions = []
        _.map(options, (option) => {
          setSourceOptions.push({ value: `${option}`, label: `${option}` })
        })
        return (<Box key={attr.key} component='span' sx={{ p: 1, border: '1px dashed grey' }}>
          <InputLabel>{`${attr.label} ${attr.unit}`}</InputLabel>
          {_.isEmpty(setSourceOptions) ?
            (<TextField
              placeholder={`${attr.label} ${attr.unit}`}
              name={attr.key}
              value={paramObject[attr.key]}
              variant='outlined'
              required={attr.required}
              error={(attr.required && (paramObject[attr.key] === '') ? true : false)}
              helperText={(attr.required && (paramObject[attr.key] === '') ? attr.requiredText : '')}
              onChange={ev => {
                paramObject[attr.key] = ev.target.value
                setNotifyAttributeSet(Math.random())
              }}
              fullWidth
            />)
            :
            (<><FormControl sx={{ m: 1, minWidth: 120 }}
                            error={(attr.required && (paramObject[attr.key] === '') ? true : false)}><Select
                placeholder={'Select From Allowed Values'}
                options={setSourceOptions}
                value={{ value: paramObject[attr.key], label: paramObject[attr.key] }}
                onChange={ev => {
                  paramObject[attr.key] = ev.value
                  setNotifyAttributeSet(Math.random())
                }}
              />
                <FormHelperText>{(attr.required && (paramObject[attr.key] === '') ? attr.requiredText : '')}</FormHelperText>
              </FormControl>
              </>
            )
          }
        </Box>)
      })
    })
  }
  
  return (
    <Grid container>
      <MDBox
        pl={3}
        mb={3}
        zIndex={1200}
        ref={reactFlowWrapper}
        alignItems='flex-end'
        width='100%'
        border={`3px solid`}
        borderColor={'black'}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onInit={setReactFlowInstance}
          onDrop={onDrop}
          onPaneMouseEnter={() => {
            setHideDelete(true)
          }}
          onPaneMouseLeave={() => {
            setHideDelete(false)
          }}
          onDragOver={onDragOver}
        >
          <MiniMap />
          <Controls />
          <Background />
          <CircularProgressStyled sx={{ display: loading ? 'block' : 'none' }} />
          <Drawer
            sx={{
              display: selectedNode || selectedInputOutputNode ? 'block' : 'none',
              width: 300,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: 300,
                boxSizing: 'border-box',
                mt: 1,
                mb : 2,
                height:'450px',
                right : '50px',
                top:'250px',
                overflowY : 'auto',
                border : 'groove',
              },
            }}
            variant='permanent'
            anchor='right'
          >
            <Box sx={{ mt: 0 }}>
              <Stack spacing={2}>
                {getInputOutputNodeData()}
                {getInputOutputBars()}
              </Stack>
            </Box>
            <Box sx={{ mt: 0  }}>
              <Stack spacing={2}>
                {notifyAttributeSet && getFields()}
              </Stack>
            </Box>
          </Drawer>
        </ReactFlow>
      </MDBox>
    </Grid>
  )
}