import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, {
  useEffect,
  useState,
} from 'react'
import makeStyles from '@mui/styles/makeStyles'
import MDTypography from 'components/core/MDTypography'
import _ from 'lodash'
import LookUpData
  from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/PartSelection/TablePartDefinition/AvailableLookups/LookUpData'
import Button from '@mui/material/Button'
import { deleteLookupByName } from 'layouts/admin/document-template-flow/Wizard/store/saga'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { wizardSelector } from 'layouts/admin/document-template-flow/Wizard/store/wizardSlice'

const useStyles = makeStyles(({ spacing }) => ({
  unselectButton: {
    fontFamily: 'sans-serif',
    border: '1px solid #1a192b',
    color: '#1a192b',
    '&:hover': {
      border: '1px solid #1a192b',
    },
    width: '150px',
  },
  selectButton: {
    fontFamily: 'sans-serif',
    border: '1px solid #1a192b',
    color: '#ffffff',
    '&:hover': {
      border: '1px solid #1a192b',
    },
    width: '150px',
  },
}))

export default ({ title, setEditLookups, availableLookups, setAvailableLookups, setLookupName, lookupName, setTypeOfLookUpValues }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const docTemplateFlow = useSelector(wizardSelector)
  const [selected, setSelected] = useState(0)
  const [rowsData, setRowsData] = useState([])
  const [dialogTitle, setDialogTitle] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [indexToDelete, setIndexToDelete] = useState(-1)

  useEffect(() => {
    if(!_.isEmpty(availableLookups)) {
      if(!_.isArray(availableLookups) && _.isString(availableLookups)) {
        setRowsData(JSON.parse(availableLookups))
      } else {
        setRowsData(availableLookups)
      }
    }
  }, [availableLookups])

  const addTableRows = (value) => {
    const rowsInput = {
      id: rowsData.length,
      name: value,
    }
    setRowsData([...rowsData, rowsInput])
  }

  const deleteTableRows = (index) => {
    const rows = _.cloneDeep(rowsData)
    rows.splice(indexToDelete, 1)
    setRowsData(rows)
    setAvailableLookups(rows)
    setIndexToDelete(-1)
  }

  const handleDescriptionChange = (index, e) => {
    const { name, value } = e.target
    const rowsInput = _.cloneDeep(rowsData)
    rowsInput[index]['text'] = value
    setRowsData(rowsInput)
    setAvailableLookups(rowsInput)
  }

  const handleLookupFields = (lookup) => {
    setSelected(lookup)
    setLookupName(lookup)
  }

  const handleChange = (event) => {
    setLookupName(event.target.value)
  }

  const handleLookupValueTypeChange = (event) => {
    setTypeOfLookUpValues(event.target.value)
  }

  const handleDelete = (index, lookup) => {
    setLookupName(lookup)
    setIndexToDelete(index)
    setIsDelete(true)
    setDialogTitle('Delete Lookup')
    setDialogOpen(true)
  }

  const handleCancel = () => {
    setDialogOpen(false)
  }

  const handleOk = () => {
    setDialogOpen(false)
    if(_.isEmpty(lookupName)) return
    if(isDelete) {
      if(indexToDelete < 0) return
      deleteTableRows(indexToDelete)
      setIsDelete(false)
      dispatch(deleteLookupByName({ name: lookupName }))
    } else {
      addTableRows(lookupName)
    }

  }

  return (
    <>

      <Box sx={{ width: '90%' }}>
        <Box>
          <MDTypography p={2} variant={'h6'}>
            {title}
          </MDTypography>
        </Box>
        <Box
          sx={{ width: '100%', justifyContent: 'left', minHeight: 100, maxHeight: 500}}>
        <LookUpData
          rowsData={rowsData}
          deleteTableRows={handleDelete}
          handleLookupFields={handleLookupFields}
          selected={selected}
          classes={classes}
          setDialogTitle={setDialogTitle}
          setDialogOpen={setDialogOpen}
        />
        </Box>
      </Box>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth='xs'
        open={dialogOpen}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent dividers>
          {!isDelete ? (
              <Stack direction='column' sx={{ justifyContent: 'center' }}
                     spacing={2}>
                <TextField fullWidth helperText={'Lookup Name'} onChange={handleChange} />
                <RadioGroup
                  aria-labelledby='demo-controlled-radio-buttons-group'
                  name='controlled-radio-buttons-group'
                  onChange={handleLookupValueTypeChange}
                >
                  <FormControlLabel value='array' control={<Radio />} label='List' />
                  <FormControlLabel value='keyValue' control={<Radio />} label='Key Value' />
                </RadioGroup>
              </Stack>
            )
            : <Typography>Are you sure to delete {lookupName} ? </Typography>}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleOk}>Ok</Button>
        </DialogActions>
      </Dialog>

    </>
  )
}