export const documentTypeValidation = (templateWorkFlow) => {
  const { document } = templateWorkFlow
  if (!document) return false
  const { domain, subdomain, documentType } = document
  if (!domain || !subdomain || !documentType) return false
  return true
}

export const sectionsValidation = (templateWorkFlow, sectionTreeData) => {
  return true
}