import _ from 'lodash'
import AutoComplete from 'components/extended/AutoComplete'
import { TextField } from '@mui/material'

const getColumnWidth = (name) => {
  switch (name) {
    case 'type' :
      return 110
    case 'section' :
      return 140
    case 'parameter' :
      return 150
    case 'name' :
      return 120
    case 'value' :
      return 110
    case 'lb' :
      return 85
    case 'ub' :
      return 85
    case 'unit' :
      return 100
    case 'condition' :
      return 140
    case 'remarks' :
      return 200
  }
  return 150
}

const getHwName = (hwUnits, hwUnit, instanceId) => {
  const unit = _.find(hwUnits, { hwUnit })
  if (unit) {
    return `${unit.label}-${instanceId}`
  } else {
    return '-'
  }
}
export const readColumns = (
  {
    processRowUpdate,
    updateRow,
    hwUnits,
    columnArray,
    hiddenColumns,
  },
) => {
  const columnData = []
  const columnObj = {}
  _.forEach(_.filter(columnArray, entry => entry.field !== 'hwInstanceId'), (column) => {
    columnObj[column.field] = ''
    
    if (column.predefinedValues) {
      columnData.push({
        field: column.field,
        headerName: column.headerName,
        headerClassName: 'header-style',
        width: getColumnWidth(column.field),
        editable: true,
        required: column.required,
        renderCell: (params) => {
          if (!params.hasFocus) return
          const selectedValue = { name: params.value ? params.value : '', label: params.value ? params.value : '' }
          let options = []
          const dependentField = column.dependField
          if (dependentField) {
            const currentVal = params.row[dependentField]
            if (column.predefinedValues[currentVal]) {
              options = column.predefinedValues[currentVal].map((value) => {
                return {
                  name: value,
                  label: value,
                }
              })
            }
          } else {
            options = column.predefinedValues.map((value) => {
              return {
                name: value,
                label: value,
              }
            })
          }
          return (
            <AutoComplete
              sx={{ fontSize: '10px' }}
              size='small'
              value={selectedValue}
              onValueChange={(val) => updateRow(params, val)}
              rowId={params.row.id}
              options={options}
            />
          )
        },
      })
    } else {
      columnData.push({
        field: column.field,
        headerName: column.headerName,
        headerClassName: 'header-style',
        type: column && column.validator && column.validator.type ? column.validator.type : 'string',
        width: getColumnWidth(column.field),
        editable: true,
        required: column.required,
        validator: column.validator,
        renderCell: (params) => {
          let valx = params.value
          if (column.field === 'hwUnit') {
            valx = getHwName(hwUnits, params.row['hwUnit'], params.row['hwInstanceId'])
            if (!params.hasFocus) {
              return (
                <TextField
                  variant='standard'
                  size='small'
                  InputProps={{
                    disableUnderline: true,
                    style: { fontSize: 12 },
                  }}
                  placeholder={column.field}
                  value={params.value ? valx : ''}
                />
              )
            }
            const selectedValue = {
              name: params.value ?
                getHwName(hwUnits, params.row['hwUnit'], params.row['hwInstanceId']) : '',
              label: params.value ? getHwName(hwUnits, params.row['hwUnit'], params.row['hwInstanceId'])
                : '',
            }
            const options = hwUnits.map(({ hwUnit, label, instanceId }) => {
              return {
                name: `${hwUnit}-${instanceId}`,
                label: `${label}-${instanceId}`,
              }
            })
            return (
              <AutoComplete
                sx={{ fontSize: '10px' }}
                size='small'
                value={selectedValue}
                onValueChange={(val) => updateRow(params, val)}
                rowId={params.row.id}
                options={options}
              />
            )
          }
          return (
            <TextField
              variant='standard'
              size='small'
              InputProps={{
                disableUnderline: true,
              }}
              placeholder={column.field}
              value={valx ? valx : ''}
            />
          )
        },
      })
    }
  })
  return columnData
}
