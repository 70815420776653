import { Breadcrumbs, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export default ({ domain, subdomain, project, documentName }) => {
  const navigate = useNavigate()
  return (
    <Breadcrumbs
      variant='caption'
      color='text.primary'
      sx={{
        flexDirection: 'row',
        display: 'flex',
        mb: '15px',
        alignItems: 'center',
      }}
    >
      {domain && (
        <Typography sx={{ cursor: 'pointer' }} variant='caption' color='text.primary'
                    onClick={() => {navigate(`/dashboard`)}}>
          {domain}
        </Typography>
      )}
      {subdomain && (
        <Typography sx={{ cursor: 'pointer' }} variant='caption' color='text.primary'
                    onClick={() => {navigate(`/dashboard`)}}>
          {subdomain}
        </Typography>
      )}
      {project && (
        <Typography sx={{ cursor: 'pointer' }} variant='caption' color='text.primary'
                    onClick={() => {navigate(`/dashboard`)}}>
          {project}
        </Typography>
      )}
      {documentName && (
        <Typography sx={{ cursor: 'pointer' }} variant='caption' color='text.primary'
                    onClick={() => {navigate(`/dashboard`)}}>
          {documentName}
        </Typography>
      )}
    </Breadcrumbs>
  )
}
