import MDButton from 'components/core/MDButton'
import MDBox from 'components/core/MDBox'
import React from 'react'
import {
  Box,
  Grid,
  Stack,
  TextareaAutosize,
  TextField,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from '@mui/material/Button'
import _ from 'lodash'

export default ({ rowsData,hideDescription = false, deleteTableRows, handleChange, handleDescriptionChange, handleCaptionChange }) => {
  return (
    rowsData && !_.isEmpty(rowsData) && rowsData.map((element, index) => {
      return (
        <Box sx={{ width: '100%' }}>
          <Stack direction='row' sx={{ justifyContent: 'right' }} spacing={2}>
            <Grid container spacing={2}>
              {!hideDescription && <Grid item xs={12} md={4} lg={6}>
                <TextareaAutosize
                  variant='outlined'
                  fullWidth
                  style={{ width: '100%' }}
                  minRows={6}
                  placeholder={'Description'}
                  defaultValue={element['text'] ? element['text'] : ''}
                  onChange={(e) => handleDescriptionChange(index, e)}
                />
              </Grid> }
              <Grid item xs={12} md={4} lg={1}>
                <input
                  accept='image/*'
                  type='file'
                  id={`select-image-${index}-${new Date().getTime()}`}
                  onClick={(event) => {event.target.value = ''}}
                  style={{ display: 'none' }}
                  onChange={(e) => handleChange(index, e)}
                />
                <label htmlFor={`select-image-${index}-${new Date().getTime()}`}>
                  <MDButton variant='contained' color='primary' fullWidth='100%' component='span'>
                    Upload Image
                  </MDButton>
                </label>
              </Grid>
              {rowsData[index]['image'] && (
                <>
                  <Grid item xs={12} md={4} lg={2}>
                    <MDBox mt={2} textAlign='center'>
                      <img src={element['image'] ? element['image'] : ''} width='80%' height='80%' />
                    </MDBox>

                  </Grid>
                  <Grid item xs={12} md={4} lg={2}>
                    <TextField
                      placeholder={'Caption'}
                      variant='outlined'
                      value={element['caption'] ? element['caption'] : ''}
                      onChange={(e) => handleCaptionChange(index, e)}
                      fullWidth
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={4} lg={1} ml={3}>
                <label>
                  <Button variant='outlined' size={'medium'} component='label' style={{ color: 'black' }}
                          onClick={() => (deleteTableRows(index))}>
                    <DeleteIcon />
                  </Button>
                </label>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      )
    })

  )

}