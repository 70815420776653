export const readDomains = (domains) => {
  const treeArray = []
  _.forEach(domains, (domain) => {
    treeArray.push({
      name: domain.value,
      id: domain.id,
    })
  })
  return treeArray
}
