import * as React from 'react'
import _ from 'lodash'
import MDBox from '../../../../../../../../../components/core/MDBox'
import { Stack, TextareaAutosize } from '@mui/material'
import Image from '../../ImagePartDefinition/Image'
import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import uuid from 'react-uuid'
import { useEffect, useState } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { updateTemplateWorkflowHwTestProceduresSuccess, wizardSelector } from '../../../../../store/wizardSlice'

const defaultTestParams = [
  {
    'Section': 'Hardware Function',
    'Parameter': 'Pressure',
    'Name': 'Range',
    'Lower Bound': 'rangeLow',
    'Upper Bound': 'rangeHigh',
    'Unit': 'Psi',
  },
  {
    'Section': 'Environmental',
    'Parameter': 'Pressure',
    'Name': 'Frequency / Samples',
    'Value': 'measureFreq',
    'Unit': 'Sec',
  },
  {
    'Section': 'Environmental',
    'Parameter': 'Temperature',
    'Name': 'Range',
    'Lower Bound': 'tempRangeLow',
    'Upper Bound': 'tempRangeHigh',
    'Unit': 'Deg C',
  },
  {
    'Section': 'Hardware Function',
    'Parameter': 'Temperature',
    'Name': 'Range',
    'Lower Bound': 'rangeLow',
    'Upper Bound': 'rangeHigh',
    'Unit': 'Deg C',
  },
  {
    'Section': 'Environmental',
    'Parameter': 'Pressure',
    'Name': 'Frequency / Samples',
    'Value': 'measureFreq',
    'Unit': 'Sec',
  },
  {
    'Section': 'Environmental',
    'Parameter': 'Humidity',
    'Name': 'Range',
    'Lower Bound': 'humidityRangeLow',
    'Upper Bound': 'humidityRangeHigh',
    'Unit': '% RH',
  },
  {
    'Section': 'Hardware Function',
    'Parameter': 'Radial Acceleration',
    'Name': 'Range',
    'Lower Bound': 'radialAccelLow',
    'Upper Bound': 'radialAccelHigh',
    'Unit': 'g',
  },
  {
    'Section': 'Hardware Function',
    'Parameter': 'Lateral/Tangential Acceleration',
    'Name': 'Range',
    'Lower Bound': 'lateralAccelLow',
    'Upper Bound': 'lateralAccelHigh',
    'Unit': 'g',
  },
  {
    'Section': 'Hardware Function',
    'Parameter': 'Load',
    'Name': 'Range',
    'Lower Bound': 'rangeLow',
    'Upper Bound': 'rangeHigh',
    'Unit': 'Kg',
  },
  {
    'Section': 'Environmental',
    'Parameter': 'Working Voltage',
    'Name': 'Value',
    'Value': 'workingVoltage',
    'Unit': 'V',
  },
  {
    'Section': 'Environmental',
    'Parameter': 'Max Current',
    'Name': 'Value',
    'Value': 'maxCurrent',
    'Unit': 'A',
  },
]
export default ({
  partContent,
  selectedAddedUnit,
}) => {
  
  const [rowsData, setRowsData] = useState([])
  const [procedureParams, setProcedureParams] = useState([])
  const [testParams, setTestParams] = useState(defaultTestParams)
  const [parameter, setParameter] = useState('Pressure')
  const [currentText, setCurrentText] = useState('')
  const dispatch = useDispatch()
  const {
    templateWorkflow: {
      hardwareTestsDefs:
        {
          hardwareTestParametersDefs,
          hardwareTestProceduresDefs: hardwareTestProcedures,
        },
    },
  } = useSelector(wizardSelector)
  const addTableRows = () => {
    const rowsInput = {
      id: uuid(),
      image: '',
      text: '',
      caption: '',
    }
    setRowsData([...(rowsData), rowsInput])
  }
  useEffect(() => {
    if (!partContent) return
    const { paramsDef } = partContent
    const mapObj = {}
    let allAttributes = []
    _.map(paramsDef, ({ attributes }) => {
      allAttributes = _.concat(allAttributes, attributes)
    })
    const params = _.uniqBy(allAttributes, 'parameter')
    const procParams = _.map(_.filter(params, entry => entry.parameter), entry => {
      return {
        value: entry.parameter,
        label: entry.parameter,
        unit: entry.unit,
      }
    })
    setProcedureParams(procParams)
  }, [])
  
  useEffect(() => {
    if (_.isEmpty(parameter)) return
    const sParams = _.cloneDeep(testParams)
    const knownParam = _.find(sParams, { Parameter: parameter })
    if (!knownParam) {
      const param = _.find(procedureParams, { value: parameter })
      const entry = {
        'Section': 'Hardware Function',
        'Parameter': parameter,
        'Name': 'Range',
        'Lower Bound': 'rangeLow',
        'Upper Bound': 'rangeHigh',
        'Unit': param ? param.unit : '',
      }
      setTestParams([entry, ...testParams])
    } else if (_.isEmpty(knownParam.Unit)) {
      const param = _.find(procedureParams, { value: parameter })
      knownParam.Unit = param ? param.unit : ''
      setTestParams([...sParams])
    }
  }, [parameter])
  useEffect(() => {
    if (_.isEmpty(selectedAddedUnit)) return
    const hwUnit = _.find(hardwareTestProcedures, { hwUnit: selectedAddedUnit })
    if (hwUnit) {
      const procedure = _.find(hwUnit.testProcedures, { parameter })
      if (procedure) {
        const imageRows = _.map(procedure.images, entry => {
          const { caption, image, id } = entry
          return {
            caption,
            image,
            text: '',
            id,
          }
        })
        setRowsData([...imageRows])
        setCurrentText(procedure.procedure)
        return
      }
    }
    const rowsInput = {
      id: uuid(),
      image: '',
      text: '',
      caption: '',
    }
    setRowsData([rowsInput])
    if (!_.isEmpty(hardwareTestParametersDefs)) {
      setTestParams(hardwareTestParametersDefs)
    }
    
  }, [hardwareTestProcedures, parameter, selectedAddedUnit])
  
  const handleChange = (index, e) => {
    const rowsInput = _.cloneDeep(rowsData)
    const allProcedures = _.cloneDeep(hardwareTestProcedures)
    if (!e.target.files || !e.target.files[0]) return
    const FR = new FileReader()
    FR.addEventListener('load', function (evt) {
      rowsInput[index]['image'] = (evt.target.result)
      setRowsData(rowsInput)
      if (_.isEmpty(selectedAddedUnit)) return
      const hwUnit = _.find(allProcedures, { hwUnit: selectedAddedUnit })
      if (hwUnit) {
        const procedure = _.find(hwUnit.testProcedures, { parameter })
        if (procedure) {
          procedure.images = rowsInput
          fireTemplateUpdate(allProcedures)
        } else {
          hwUnit.testProcedures.push({
            parameter,
            procedure: currentText,
            images: rowsInput,
          })
          fireTemplateUpdate(allProcedures)
        }
      } else {
        const testProcedure = {
          hwUnit: selectedAddedUnit,
          testProcedures: [{
            parameter,
            procedure: currentText,
            images: rowsInput,
          }],
        }
        allProcedures.push(testProcedure)
        fireTemplateUpdate(allProcedures)
      }
    })
    FR.readAsDataURL(e.target.files[0])
  }
  
  const handleCaption = (index, e) => {
    if (_.isEmpty(selectedAddedUnit)) return
    const rowsInput = _.cloneDeep(rowsData)
    const allProcedures = _.cloneDeep(hardwareTestProcedures)
    const { value } = e.target
    rowsInput[index]['caption'] = value
    setRowsData(rowsInput)
    const hwUnit = _.find(allProcedures, { hwUnit: selectedAddedUnit })
    if (hwUnit) {
      const procedure = _.find(hwUnit.testProcedures, { parameter })
      if (procedure) {
        procedure.images = rowsInput
        fireTemplateUpdate(allProcedures)
      } else {
        hwUnit.testProcedures.push({
          parameter,
          procedure: currentText,
          images: rowsInput,
        })
        fireTemplateUpdate(allProcedures)
      }
    } else {
      const testProcedure = {
        hwUnit: selectedAddedUnit,
        testProcedures: [{
          parameter,
          procedure: currentText,
          images: rowsInput,
        }],
      }
      allProcedures.push(testProcedure)
      fireTemplateUpdate(allProcedures)
    }
    
  }
  const fireTemplateUpdate = (testProcedures) => {
    const testSpec = {
      hardwareTestParametersDefs: testParams,
      hardwareTestProceduresDefs: testProcedures,
    }
    dispatch(updateTemplateWorkflowHwTestProceduresSuccess(testSpec))
  }
  const handleProcedureText = (e) => {
    if (_.isEmpty(selectedAddedUnit)) return
    const allProcedures = _.cloneDeep(hardwareTestProcedures)
    const { value } = e.target
    setCurrentText(value)
    const hwUnit = _.find(allProcedures, { hwUnit: selectedAddedUnit })
    if (hwUnit) {
      const procedure = _.find(hwUnit.testProcedures, { parameter })
      if (procedure) {
        procedure.procedure = value
        fireTemplateUpdate(allProcedures)
      } else {
        hwUnit.testProcedures.push({
          parameter,
          procedure: value,
          images: [...rowsData],
        })
        fireTemplateUpdate(allProcedures)
      }
    } else {
      const testProcedure = {
        hwUnit: selectedAddedUnit,
        testProcedures: [{
          parameter,
          procedure: value,
          images: [...rowsData],
        }],
      }
      allProcedures.push(testProcedure)
      fireTemplateUpdate(allProcedures)
    }
    
  }
  const deleteTableRows = (index) => {
    const rows = _.cloneDeep(rowsData)
    rows.splice(index, 1)
    setRowsData(rows)
    const allProcedures = _.cloneDeep(hardwareTestProcedures)
    const hwUnit = _.find(allProcedures, { hwUnit: selectedAddedUnit })
    if (hwUnit) {
      const procedure = _.find(hwUnit.testProcedures, { parameter })
      if (procedure) {
        procedure.images = [...rows]
        fireTemplateUpdate(allProcedures)
      } else {
        hwUnit.testProcedures.push({
          parameter,
          procedure: currentText,
          images: [...rows],
        })
        fireTemplateUpdate(allProcedures)
      }
    } else {
      const testProcedure = {
        hwUnit: selectedAddedUnit,
        testProcedures: [{
          parameter,
          procedure: currentText,
          images: [...rows],
        }],
      }
      allProcedures.push(testProcedure)
      fireTemplateUpdate(allProcedures)
    }
  }
  return (
    <MDBox>
      <Select
        styles={{
          menu: base => ({
            ...base,
            zIndex: 100,
          }),
          control: (provided, state) => ({
            ...provided,
            marginBottom: 10,
          }),
        }}
        placeholder='Select Parameter'
        options={procedureParams}
        value={{ label: parameter, value: parameter }}
        onChange={(option) => {
          setParameter(option.value)
          setCurrentText('')
        }}
      />
      <TextareaAutosize
        minRows={10}
        style={{
          width: '98%', resize: 'none',
          padding: 10, fontFamily: 'sans-serif', fontSize: 15,
        }}
        placeholder='Test Procedure Details...'
        onChange={handleProcedureText}
        value={currentText}
      />
      
      <Stack direction='column' sx={{ justifyContent: 'center' }} spacing={2}>
        <Button variant='outlined' size={'large'} component='label' style={{ color: 'black' }} color={'secondary'}
                startIcon={<AddIcon />} onClick={addTableRows}>
          Add Procedure Image
        </Button>
        <Image rowsData={rowsData}
               hideDescription={true}
               deleteTableRows={deleteTableRows}
               handleChange={handleChange}
               handleCaptionChange={handleCaption}
               handleDescriptionChange={() => {}} />
      </Stack>
    </MDBox>
  
  )
  
}