export const projectDomains = [
  { value: 'Automotive', label: 'Automotive' },
  { value: 'Industrial', label: 'Industrial' },
  { value: 'Consumer', label: 'Consumer' },
  { value: 'Energy', label: 'Energy' },
]

export const projectSubDomains = {
  Automotive: [
    { value: 'Safety', label: 'Safety' },
    { value: 'Powertrain', label: 'Powertrain' },
    { value: 'Infotainment', label: 'Infotainment' },
    { value: 'Body', label: 'Body' },
  ],
  Industrial: [
    { value: 'Manufacturing', label: 'Manufacturing' },
    { value: 'Logistics', label: 'Logistics' },
    { value: 'Monitoring', label: 'Monitoring' },
    { value: 'Surveillance', label: 'Surveillance' },
  ],
  Consumer: [
    { value: 'Home', label: 'Home' },
    { value: 'Fitness', label: 'Fitness' },
    { value: 'Gaming', label: 'Gaming' },
    { value: 'Other', label: 'Other' },
  ],
  Energy: [
    { value: 'Generation', label: 'Generation' },
    { value: 'Distribution', label: 'Distribution' },
    { value: 'Consumption', label: 'Consumption' },
    { value: 'Analytics', label: 'Analytics' },
  ],
}
