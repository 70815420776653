/**
 =========================================================
 * HWAI V1 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React from 'react'
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from 'App'

// HWAI V1 2 React Context Provider
import { MaterialUIControllerProvider } from 'context'
import { store } from './store/store'
import { sagaMiddleware } from './store/sagaMiddleware'
import userSaga from './layouts/authentication/store/saga'
import dashboardSaga from './layouts/dashboard/store/saga'
import documentSaga from './layouts/document-management/document-store/saga'
import subSystemSaga from 'layouts/document-management/subsystem-store/saga'
import wizardSaga from 'layouts/admin/document-template-flow/Wizard/store/saga'

const container = document.getElementById('app')
const root = createRoot(container)

sagaMiddleware.run(userSaga)
sagaMiddleware.run(dashboardSaga)
sagaMiddleware.run(documentSaga)
sagaMiddleware.run(subSystemSaga)
sagaMiddleware.run(wizardSaga)

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </Provider>,
)
