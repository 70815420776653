import { Stack } from '@mui/material'
import MDButton from 'components/core/MDButton'
import _ from 'lodash'
import * as React from 'react'
import SectionsList from '../SectionsList'

export default ({
  available,
  added,
  addToAdded,
  removeFromAdded,
  setSelectedAvailable,
  setSelectedAdded,
  availableTitle,
  addedTitle,
}) => {
  return (
    <Stack direction='column' sx={{ justifyContent: 'left' }} spacing={2}>
      <Stack direction='row' sx={{ justifyContent: 'left' }} spacing={1}>
        <Stack direction='column' sx={{ justifyContent: 'center' }} spacing={1}>
          <SectionsList sections={_.filter(available, (item) => !_.find(added, { key: item.key }))}
                        label={availableTitle}
                        setSelectedSections={setSelectedAvailable} />
        </Stack>
        <Stack direction='column' sx={{ justifyContent: 'center' }} spacing={1}>
          <MDButton color={'black'} variant={'outlined'} size={'small'} onClick={addToAdded}> {'>> Add >>'} </MDButton>
          <MDButton color={'black'} variant={'outlined'} size={'small'}
                    onClick={removeFromAdded}> {'<< Remove <<'} </MDButton>
        </Stack>

        <Stack direction='column' sx={{ justifyContent: 'center' }} spacing={1}>
          <SectionsList sections={added} label={addedTitle}
                        setSelectedSections={setSelectedAdded} />
        </Stack>
      </Stack>
    </Stack>
  )
}