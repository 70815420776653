import colors from 'assets/theme/base/colors'

const { text } = colors

const formLabel = {
  styleOverrides: {
    root: {
      color: text.main,
    },
  },
}

export default formLabel
