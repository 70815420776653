import React from 'react'
import {
  Box, Typography,
} from '@mui/material'

const LIGHTGRAY = '#E0E0E0'
const ORANGE = '#FFA726'
const GREEN = '#66BB6A'
const DARKGRAY = '#BDBDBD'

const stepperNoStyle = {
  fontSize: 'large',
  position: 'relative',
  left: '17px',
  top: '8px',
}
const stepperLabelStyle = {
  fontSize: 'small',
  position: 'relative',
  left: '-40px',
  top: '-25px',
  color: 'black',
}
const nodeStyle = {
  color: 'white',
  minHeight: '45px',
  minWidth: '45px',
  maxHeight: '45px',
  maxWidth: '45px',
  borderRadius: '50%',
  display: 'flex',
  padding: '5px',
  fontSize: '1rem',
  position: 'relative',
  top: '-27px',
  left: '-5px',
}
const containerStyle = {
  margin: 'auto',
  background: LIGHTGRAY,
  height: '2px',
  width: '60%',
}

const tableStyle = { width: '130%' }
const trStyle = { marginLeft: '10px' }
const tdStyle = { width: '25%', textAlign: 'left' }
const frameStyle = { width: '100%', marginTop: 2, marginBottom: 10 }

export default ({ steps, activeStep }) => {
  return (
    <Box sx={frameStyle}>
      <div style={containerStyle}>
        <table style={tableStyle}>
          <tbody>
          <tr style={trStyle}>
            {steps.map(({ id, label }, index) => {
              return (
                <td style={tdStyle} key={index}>
                  <div style={{
                    backgroundColor: activeStep === index ? ORANGE : (
                      activeStep > index ? GREEN : DARKGRAY
                    ),
                    ...nodeStyle,
                  }}>
                    <Typography style={stepperNoStyle}>{`${index + 1}`}</Typography>
                  </div>
                  <Typography style={stepperLabelStyle}>{label}</Typography>
                </td>
              )
            })}
          </tr>
          </tbody>
        </table>
      </div>
    </Box>
  )
}
