import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material'
import MDTypography from 'components/core/MDTypography'
import PartDefinition
  from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/PartSelection/PartDefinition'
import React, {
  useEffect,
  useState,
} from 'react'
import Image
  from 'layouts/admin/document-template-flow/Wizard/steps/SelectSections/PartSelection/ImagePartDefinition/Image'
import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import uuid from 'react-uuid'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { wizardSelector } from 'layouts/admin/document-template-flow/Wizard/store/wizardSlice'

export default ({ partContent, setPartContent, partObjectSelected, setContent, content, setNewContent, newContent }) => {
  const docTemplateFlow = useSelector(wizardSelector)
  const [rowsData, setRowsData] = useState([])
  const [imageOrientation, setImageOrientation] = useState('horizontal')
  useEffect(() => {
    if (!_.isEmpty(partContent)) {
      if (!_.isArray(partContent) && _.isString(partContent)) {
        const {orientation, images} = JSON.parse(partContent)
        setImageOrientation(orientation)
        setRowsData(images)
      } else {
        const {orientation, images} = partContent
        setImageOrientation(orientation)
        setRowsData(images)
      }
    }
  }, [partContent])
  
  const addTableRows = () => {
    const rowsInput = {
      id: uuid(),
      image: '',
      text: '',
      caption: '',
    }
    setRowsData([...(rowsData), rowsInput])
  }
  const deleteTableRows = (index) => {
    const rows = _.cloneDeep(rowsData)
    rows.splice(index, 1)
    setRowsData(rows)
    setPartContent({'orientation': imageOrientation, images: rows})
  }

  const handleOrientationChange = (evt) => {
    setImageOrientation(evt.target.value)
  }

  const handleChange = (index, e) => {
    const rowsInput = _.cloneDeep(rowsData)
    if (!e.target.files || !e.target.files[0]) return
    const FR = new FileReader()
    FR.addEventListener('load', function (evt) {
      rowsInput[index]['image'] = (evt.target.result)
      setRowsData(rowsInput)
      setPartContent({'orientation': imageOrientation, images: rowsInput})
    })
    FR.readAsDataURL(e.target.files[0])
  }

  const handleCaptionChange = (index, e) => {
    const { name, value } = e.target
    const rowsInput = _.cloneDeep(rowsData)
    rowsInput[index]['caption'] = value
    setRowsData(rowsInput)
    setPartContent({'orientation': imageOrientation, images: rowsInput})
  }
  
  const handleDescriptionChange = (index, e) => {
    const { name, value } = e.target
    const rowsInput = _.cloneDeep(rowsData)
    rowsInput[index]['text'] = value
    setRowsData(rowsInput)
    setPartContent({'orientation': imageOrientation, images: rowsInput})
  }
  return (
    <Stack direction='column' sx={{ justifyContent: 'center' }} spacing={4}>
      <Box>
        <MDTypography p={2} variant={'h6'}>
          {partObjectSelected && partObjectSelected.title ? partObjectSelected.title : 'Diagrams'}
        </MDTypography>
      </Box>
      <PartDefinition type={'images'} setContent={setContent}  setPartContent={setPartContent} setRowsData={setRowsData} setImageOrientation={setImageOrientation} />
      <Stack direction='row' sx={{ justifyContent: 'left' }} spacing={4}>
        <MDTypography p={1} variant={'h5'}>Orientation</MDTypography>
        <RadioGroup
          row
          value={imageOrientation}
          onChange={handleOrientationChange}
        >
          <FormControlLabel value='horizontal' control={<Radio />} label='Horizontal' />
          <FormControlLabel value='vertical' control={<Radio />} label='Vertical' />
        </RadioGroup>
      </Stack>
      {docTemplateFlow.wizardType !== 'view' && (
        <Button variant='outlined' size={'large'} component='label' style={{ color: 'black' }} color={'secondary'}
                startIcon={<AddIcon />} onClick={addTableRows}>
          Add
        </Button>
      )}
      <Stack direction='column' sx={{ justifyContent: 'center' }} spacing={2}>
        <Image rowsData={rowsData}
               deleteTableRows={deleteTableRows}
               handleChange={handleChange}
               handleCaptionChange={handleCaptionChange}
               handleDescriptionChange={handleDescriptionChange} />
      </Stack>
    </Stack>
  )
}