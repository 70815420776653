import React, { useEffect } from 'react'
import MDButton from 'components/core/MDButton'
import MDBox from 'components/core/MDBox'
import MDTypography from 'components/core/MDTypography'

export default ({ heading, content, newContent, setNewContent, setDirtyFlag }) => {
  useEffect(() => {
    setNewContent(content)
  }, [content])
  const onChange = (e) => {
    setNewContent(e)
    setDirtyFlag(true)
  }
  const readImage = (e) => {
    if(!e.target.files || !e.target.files[0]) return
    const FR = new FileReader()
    FR.addEventListener('load', function(evt) {
      setNewContent(evt.target.result)
      setDirtyFlag(true)
    })
    FR.readAsDataURL(e.target.files[0])
  }
  return (
    <>
      <MDTypography p={ 2 } variant={ 'h6' }>
        { heading }
      </MDTypography>
      <input
        accept='image/*'
        type='file'
        id='select-image'
        style={ { display: 'none' } }
        onChange={ (e) => readImage(e) }
      />
      <label htmlFor='select-image'>
        <MDButton variant='contained' color='primary' fullWidth='100%' component='span'>
          Upload Image
        </MDButton>
      </label>
      { newContent && (
        <MDBox mt={ 2 } textAlign='center'>
          <div>Image Preview:</div>
          <img src={ newContent ? newContent : '' } width='30%' height='30%' />
        </MDBox>
      ) }
    </>
  )
}
