import React, {
  useEffect,
  useState,
} from 'react'
import {
  Box,
  Grid,
} from '@mui/material'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import {
  updateTemplateWorkflowDocumentTypeSuccess,
  wizardSelector,
} from 'layouts/admin/document-template-flow/Wizard/store/wizardSlice'
import {
  getDocumentTypes,
  getDomains,
  getSubdomains,
} from 'layouts/admin/document-template-flow/Wizard/store/saga'
import _ from 'lodash'
import CircularProgressStyled from 'components/extended/progress/CircularProgressStyled'

export default ({}) => {
  const dispatch = useDispatch()
  const docTemplateFlow = useSelector(wizardSelector)
  const indicatorSize = 80
  const workflowObj = _.cloneDeep((docTemplateFlow.templateWorkflow))
  const animatedComponents = makeAnimated()
  const [domain, setDomain] = useState()
  const [domains, setDomains] = useState([])
  const [subdomain, setSubdomain] = useState()
  const [subdomains, setSubdomains] = useState([])
  const [documentType, setDocumentType] = useState()
  const [documentTypes, setDocumentTypes] = useState([])
  useEffect(() => {
    const documentType = docTemplateFlow?.templateWorkflow?.document
    setDomain(documentType?.domain)
    setSubdomain(documentType?.subdomain)
    setDocumentType(documentType?.documentType)
    dispatch(getDomains())
  }, [])
  useEffect(() => {
    setDomains(docTemplateFlow.domains)
    setSubdomains(docTemplateFlow.subdomains)
    setDocumentTypes(docTemplateFlow.documentTypes)
    
  }, [docTemplateFlow])
  return (
    <Box sx={{ flexGrow: 1, overflowY: 'auto', width: '40%', height: '300px', paddingLeft: '30%' }}>
      <CircularProgressStyled
        size={indicatorSize}
        sx={{
          display: docTemplateFlow.loading ? 'block' : 'none',
          position: 'relative',
          top: '30%',
          left: '50%',
          zIndex: '9999',
          marginTop: `${-indicatorSize / 2}px`,
          marginLeft: `${-indicatorSize / 2}px`,
          background: 'transparent',
          color: 'gray',
        }}
      />
      <Grid container alignItems={'center'}>
        <Grid item sm={12} md={12} alignItems={'center'}>
          <Select
            styles={{
              menu: base => ({
                ...base,
                zIndex: 100,
              }),
              control: (provided, state) => ({
                ...provided,
                marginBottom: 10,
              }),
            }}
            name='Select Domain'
            value={domain}
            isDisabled={docTemplateFlow.wizardType === 'view'}
            placeholder='Select Domain'
            components={animatedComponents}
            options={domains}
            onChange={(option) => {
              dispatch(getSubdomains({ domain: option.value }))
              if (!workflowObj['selectDocumentType']) {
                workflowObj['selectDocumentType'] = {}
              }
              workflowObj['document']['domain'] = option
              dispatch(updateTemplateWorkflowDocumentTypeSuccess(workflowObj.document))
              setDomain(option)
            }}
          />
        </Grid>
        <Grid item sm={12} md={12} alignItems={'center'} sx={{ paddingTop: '3%' }}>
          <Select
            styles={{
              menu: base => ({
                ...base,
                zIndex: 100,
              }),
              control: (provided, state) => ({
                ...provided,
                marginBottom: 10,
              }),
            }}
            name='Select Subdomain'
            value={subdomain}
            isDisabled={docTemplateFlow.wizardType === 'view'}
            placeholder='Select Subdomain'
            components={animatedComponents}
            options={subdomains}
            onChange={(option) => {
              dispatch(getDocumentTypes({ domain: domain.value, subdomain: option.value }))
              workflowObj['document']['subdomain'] = option
              dispatch(updateTemplateWorkflowDocumentTypeSuccess(workflowObj.document))
              setSubdomain(option)
            }}
          />
        </Grid>
        <Grid item sm={12} md={12} alignItems={'center'} sx={{ paddingTop: '3%' }}>
          <Select
            styles={{
              menu: base => ({
                ...base,
                zIndex: 100,
              }),
              control: (provided, state) => ({
                ...provided,
                marginBottom: 10,
              }),
            }}
            name='Select Document Type'
            value={documentType}
            placeholder='Select Document Type'
            isDisabled={docTemplateFlow.wizardType === 'view'}
            components={animatedComponents}
            options={documentTypes}
            onChange={(option) => {
              workflowObj['document']['documentType'] = option
              dispatch(updateTemplateWorkflowDocumentTypeSuccess(workflowObj.document))
              setDocumentType(option)
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}