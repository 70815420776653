import React from 'react'
import {
  Box,
  Stack,
} from '@mui/material'
import makeAnimated from 'react-select/animated'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import {
  clearTemplateWorkflowSuccess,
  wizardSelector,
} from 'layouts/admin/document-template-flow/Wizard/store/wizardSlice'
import _ from 'lodash'
import MDButton from 'components/core/MDButton'
import {
  createDocumentTemplate,
  publishDocumentTemplate,
} from 'layouts/admin/document-template-flow/Wizard/store/saga'
import { useNavigate } from 'react-router-dom'
import MDTypography from 'components/core/MDTypography'
import Typography from '@mui/material/Typography'

export default ({ onStepComplete }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const docTemplateFlow = useSelector(wizardSelector)
  const workflowObj = _.cloneDeep((docTemplateFlow.templateWorkflow))
  const animatedComponents = makeAnimated()
  
  const onSave = (e) => {
    if (!e) return
    const { document, sections, hardwareTestsDefs } = docTemplateFlow.templateWorkflow
    const data = {}
    data['id'] = docTemplateFlow?.createdTemplate?.id
    data['type'] = document.documentType.value
    data['domain'] = document.domain.value
    data['subdomain'] = document.subdomain.value
    data['sections'] = sections
    data['hardwareTestProcedures'] = hardwareTestsDefs
    dispatch(createDocumentTemplate({ data, navigate }))
  }
  const onPublish = (e) => {
    if (!e) return
    const { document, sections } = docTemplateFlow.templateWorkflow
    const data = {}
    data['type'] = document.documentType.value
    data['domain'] = document.domain.value
    data['subdomain'] = document.subdomain.value
    data['id'] = docTemplateFlow?.createdTemplate?.id
    dispatch(publishDocumentTemplate({ data, navigate }))
    dispatch(clearTemplateWorkflowSuccess())
  }
  
  return (
    <Box sx={{ width: '30%', justifyContent: 'center', paddingLeft: '35%' }}>
      <Stack direction='column' sx={{ justifyContent: 'center' }} spacing={4}>
        <MDButton size='large' variant={'outlined'} color={'dark'} onClick={(e) => onPublish(e)}>
          BaseLine and Publish
        </MDButton>
      </Stack>
    </Box>
  
  )
}