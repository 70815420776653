import { createAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import {
  createDocumentSuccess,
  createProjectSuccess,
  getDocumentsSuccess,
  getProjectsSuccess,
  onLoadingUserEnd,
  onLoadingUserFail,
  onLoadingUserStart,
} from './mainSlice'
import axiosWithoutInterceptors from 'axios'

const axios = axiosWithoutInterceptors
const baseUri = process.env.REACT_APP_API_BASE

export const getProjects = createAction('getProjectsSaga')

export function * getProjectsSaga () {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.get, '/dms/project', {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const projects = result.data
    yield put(getProjectsSuccess(projects))

  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export const getDocuments = createAction('getDocumentsSaga')

export function * getDocumentsSaga ({ payload }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.get, `/dms/documents?&projectId=${payload}`, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const documents = result.data
    yield put(getDocumentsSuccess({ documents, projectId: payload }))

  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export const createProject = createAction('createProjectSaga')

export function * createProjectSaga ({ payload: { data, navigate } }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.post, `/dms/project`, data, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const project = result.data
    yield put(createProjectSuccess(project))
    navigate('/dashboard')
  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export const createDocument = createAction('createDocumentSaga')

export function * createDocumentSaga ({ payload: { data, navigate } }) {
  try {
    yield put(onLoadingUserStart())
    const result = yield call(axios.post, `/dms/document`, data, {
      withCredentials: true,
    })
    yield put(onLoadingUserEnd())
    const documentRaw = result.data
    const document = _.pick(documentRaw, ['createdDate', 'id', 'name', 'owner', 'tags', 'type'])
    document.editPercentage = 10
    const { projectId } = data
    yield put(createDocumentSuccess({ document, projectId }))
    navigate('/dashboard')
  } catch (error) {
    yield put(onLoadingUserFail())
  }
}

export default function * dashboardSaga () {
  yield takeLatest(getProjects.type, getProjectsSaga)
  yield takeLatest(getDocuments.type, getDocumentsSaga)
  yield takeLatest(createProject.type, createProjectSaga)
  yield takeLatest(createDocument.type, createDocumentSaga)
}