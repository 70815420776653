import PropTypes from 'prop-types'
import Link from '@mui/material/Link'
import MDBox from 'components/core/MDBox'
import MDTypography from 'components/core/MDTypography'
import typography from 'assets/theme/base/typography'

function Footer ({ company, links }) {
  const { href, name } = company
  const { size } = typography

  const renderLinks = () =>
    links.map((link) => (
      <MDBox key={link.name} component='li' px={2} lineHeight={1}>
        <Link href={link.href} target='_blank'>
          <MDTypography variant='button' fontWeight='regular' color='text'>
            {link.name}
          </MDTypography>
        </Link>
      </MDBox>
    ))

  return <></>
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: { href: '/', name: 'HWAI' },
  links: [],
}

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
}

export default Footer
