export const validateProps = (data) => {
  const requiredFields = []
  const { attributes, paramObject, headerText } = data.executionInfo
  _.forEach(attributes, attribute => {
    const { key, required, label, requiredText } = attribute
    if(required && paramObject[key] === '') {
      requiredFields.push(`${headerText} : ${label} - ${requiredText}`)
    }
  })
  return requiredFields
}

export const checkOfMandatoryFields = (requiredFields, data) => {
  if(_.isEmpty(requiredFields)) {
    data.setErrorDialog({ dialogBody: '', dialogTitle: '', dialogOpen: false, confirmationDialog: false })
  } else {
    const erMsg = requiredFields.join('\n')
    data.setErrorDialog({ dialogBody: erMsg, dialogTitle: 'Error Message', dialogOpen: true, confirmationDialog: false })
  }
}