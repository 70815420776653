import React, { useState } from 'react'
import Wizard from 'layouts/admin/document-template-flow/Wizard'
import DashboardNavbar from 'components/core/Navbars/DashboardNavbar'
import DashboardLayout from 'components/core/LayoutContainers/DashboardLayout'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import {
  clearTemplateWorkflowSuccess,
  wizardSelector,
} from 'layouts/admin/document-template-flow/Wizard/store/wizardSlice'

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad']

export default () => {
  const [activeStep, setActiveStep] = useState(0)
  const [skipped, setSkipped] = useState(new Set())
  const [userInitStatus, setUserInitStatus] = useState({})
  const [wizardState, setWizardState] = useState(false)
  const dispatch = useDispatch()
  const docTemplateFlow = useSelector(wizardSelector)
  //dispatch(clearTemplateWorkflowSuccess())
  
  const isStepOptional = (step) => {
    return step === 1
  }
  
  const isStepSkipped = (step) => {
    return skipped.has(step)
  }
  
  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }
    
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }
  
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  
  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error('You can\'t skip a step that isn\'t optional.')
    }
    
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }
  
  const handleReset = () => {
    setActiveStep(0)
  }
  const handleWizardFinish = () => {
    setWizardState(true)
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Wizard status={userInitStatus} onFinish={handleWizardFinish} />
    </DashboardLayout>
  )
}