import Grid from '@mui/material/Grid'
import MDBox from 'components/core/MDBox'
import DashboardLayout from 'components/core/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/core/Navbars/DashboardNavbar'
import Footer from 'components/core/Footer'
import { documentData } from './documentData'
import Documents from './Documents'
import React, {
  useEffect,
  useState,
} from 'react'
import _ from 'lodash'
import Tree from 'components/extended/Tree'
import MDTypography from 'components/core/MDTypography'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Typography,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import ShareIcon from '@mui/icons-material/Share'
import DeleteIcon from '@mui/icons-material/Delete'
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { useNavigate } from 'react-router-dom'

import {
  useDispatch,
  useSelector,
} from 'react-redux'

import { getDateISOString } from 'utils/date-time'
import Button from '@mui/material/Button'
import {
  clearTemplateWorkflowSuccess,
  getAddTemplateWorkflowSuccess,
  getSelectedDomainSuccess,
  updateSectionTreeDataSuccess,
  updateTreeDataSuccess,
  updateWizardTypeSuccess,
  wizardSelector,
} from 'layouts/admin/document-template-flow/Wizard/store/wizardSlice'
import { readDomains } from 'layouts/admin/administration/domain-reader'
import {
  getAvailableParts,
  getAvailableSections,
  getDocumentsByDomain,
  getDomains,
} from 'layouts/admin/document-template-flow/Wizard/store/saga'
import { changeWorkArea, loginSelector } from 'layouts/authentication/store/userSlice'
import {
  convertDocumentToTemplateWorkflow,
  generateSectionTree,
  generateSectionTreeData,
} from 'layouts/admin/administration/buildWorkFlowTemplate'
import MDButton from '../../../components/core/MDButton'

export default () => {
  const user = useSelector(loginSelector)
  const docTemplateFlow = useSelector(wizardSelector)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([])
  const [directoryTreeFolder, setDirectoryTreeFolder] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [defaultExpandedIds, setDefaultExpandedIds] = useState([])
  const [dialogTitle, setDialogTitle] = useState('')
  const [dialogBody, setDialogBody] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [isDeleteDocumentDialog, setIsDeleteDocumentDialog] = useState(false)
  const [documentIdToDelete, setDocumentIdToDelete] = useState('')

  const indicatorSize = 80

  useEffect(() => {
    dispatch(clearTemplateWorkflowSuccess())
    dispatch(updateSectionTreeDataSuccess({}))
    dispatch(updateTreeDataSuccess(undefined))
    if(_.isEmpty(docTemplateFlow.availableSections)) {
      const { domain, subdomain, documentType } = docTemplateFlow.templateWorkflow.document
      if(domain && subdomain && documentType) {
        dispatch(getAvailableSections({ domain: domain.value, subdomain: subdomain.value, doctype: documentType.value }))
      }
    }
    if(_.isEmpty(docTemplateFlow.availableParts)) {
      dispatch(getAvailableParts())
    }
    dispatch(changeWorkArea({ workArea: 'admin' }))
    dispatch(updateWizardTypeSuccess('edit'))
  }, [])

  useEffect(() => {
    if(!user.user_id) {
      navigate('/authentication/sign-in')
    }
  }, [user])

  const handleCancel = () => {
    setDialogOpen(false)
  }

  const onSuccessfulDeleteDocument = () => {
    setDialogOpen(false)
    setIsDeleteDocumentDialog(false)
    //dispatch(getDocuments(docTemplateFlow.selected_domain.id))
  }

  const viewEditDocTemplate = (wizardType, id) => {
    dispatch(updateWizardTypeSuccess(wizardType))
    const document = _.find(docTemplateFlow.documentsByDomain, { 'id': id })
    const templateWorkflow = convertDocumentToTemplateWorkflow(document)
    const sectionTree = generateSectionTree(document)
    const sectionTreeData = generateSectionTreeData(document, docTemplateFlow.availableSections, docTemplateFlow.availableParts)
    dispatch(updateSectionTreeDataSuccess(sectionTreeData))
    dispatch(updateTreeDataSuccess(sectionTree))
    dispatch(getAddTemplateWorkflowSuccess(templateWorkflow)) //TODO
    navigate(`/create-document-template`)
  }

  const handleDeleteDocumentOk = () => {
    const document = _.find(docTemplateFlow.documentsByDomain, { 'id': documentIdToDelete })
    //dispatch(deleteDocument({ docId: document.id, onSuccess: () => onSuccessfulDeleteDocument() }))
  }

  const getRowButtons = (id, state) => (
    <MDBox width='8rem' textAlign='left' >
      {
        state !== 'published' && (
          <Fab
            p={ 2 }
            size='small'
            color='secondary'
            aria-label='edit'
            onClick={ () => viewEditDocTemplate('edit', id) }
          >
            <EditIcon fontSize={ 'medium' } />
          </Fab>
        )
      }


      {
        state === 'published' && (
          <>
            <Fab
              size='small'
              aria-label='view'
              onClick={ () => viewEditDocTemplate('view', id) }
            >
              <VisibilityOutlinedIcon fontSize={ 'medium' } />
            </Fab>
            <Fab
              size='small'
              aria-label='new'
              onClick={ () => viewEditDocTemplate('edit', id) }
            >
              <FiberNewOutlinedIcon fontSize={ 'large' } />
            </Fab>
          </>
        )
      }
      {
        state !== 'published' && (
          <Fab size='small'
            aria-label='delete'
            onClick={ () => {
              setDialogTitle('Delete Template')
              setDialogBody('Are you sure you want to this template ?')
              setDialogOpen(true)
              setIsDeleteDocumentDialog(true)
              setDocumentIdToDelete(id)
            } }
          >
            <DeleteIcon fontSize={ 'medium' } />
          </Fab>
        )
      }

    </MDBox>
  )
  useEffect(() => {
    if(_.isEmpty(docTemplateFlow.domains)) {
      dispatch(getDomains())
    }
  }, [])

  useEffect(() => {
    if(!_.isEmpty(docTemplateFlow.domains)) {
      updateDomains()
    }
    if(!_.isEmpty(docTemplateFlow.domains) && _.isEmpty(docTemplateFlow.selected_domain)) {
      const selId = _.first(docTemplateFlow.domains).id
      setSelectedIds([selId])
      handleOnTreeNodeSelect({ id: _.first(docTemplateFlow.domains).id })
    }
  }, [docTemplateFlow.domains])

  useEffect(() => {
    if(!_.isEmpty(docTemplateFlow.selected_domain)) {
      dispatch(getDocumentsByDomain({ domain: docTemplateFlow.selected_domain.id }))
    }
  }, [docTemplateFlow.selected_domain])

  useEffect(() => {
    updateDocuments()
  }, [docTemplateFlow.documentsByDomain])

  const updateDocuments = () => {
    const data = docTemplateFlow.selected_domain && docTemplateFlow.documentsByDomain ? docTemplateFlow.documentsByDomain : []
    const docMetadata = documentData
    const { tableDef } = docMetadata
    setColumns(tableDef.columns)
    const modRows = _.map(data, (row) => {
      const { id, author, type, subdomain, state, lastModifiedDate, version } = row
      return {
        id: (
          <MDTypography variant='caption' color='text' fontWeight='medium'>
            { id }
          </MDTypography>
        ),
        type: (
          <MDTypography
            variant='caption'
            color='text'
            fontWeight='medium'
            style={ {
              cursor: 'pointer',
              transition: 'color 0.7s',
            } }
            onClick={ state === 'published'
              ?() => viewEditDocTemplate('view', id)
              :() => viewEditDocTemplate('edit', id)}
          >
            { type }
          </MDTypography>
        ),
        subdomain: (
          <MDTypography variant='caption' color='text' fontWeight='medium'>
            { subdomain }
          </MDTypography>
        ),
        state: (
          <MDTypography variant='caption' color='text' fontWeight='medium'>
            { state === 'draft' ? 'Draft' : 'Published' }
          </MDTypography>
        ),
        owner: (
          <MDTypography variant='caption' color='text' fontWeight='medium'>
            { author }
          </MDTypography>
        ),
        lastModifiedDate: (
          <MDTypography variant='caption' color='text' fontWeight='medium'>
            { getDateISOString({ dateTime: lastModifiedDate, withTZ: false }) }
          </MDTypography>
        ),
        version: (
          <MDTypography variant='caption' color='text' fontWeight='medium'>
            { version }
          </MDTypography>
        ),
        actions: getRowButtons(id, state),
      }
    })
    setRows(modRows)
  }

  const updateDomains = () => {
    const domains = docTemplateFlow.domains
    const treeArray = readDomains(domains)
    const directoryTreeArray = {
      name: '',
      children: [
        {
          id: '0',
          name: 'Domains',
          children: treeArray,
        },
      ],
    }
    const defaultId = (domains && domains.length > 0 ? domains[0].id : '')
    const defaultSelectedIds = defaultId
    const defaultExpandedIds = ['0', defaultId]
    setSelectedIds([defaultSelectedIds])
    setDefaultExpandedIds(defaultExpandedIds)
    setDirectoryTreeFolder(directoryTreeArray)
  }

  const handleOnTreeNodeSelect = ({ id }) => {
    const domain = _.find(docTemplateFlow.domains, { 'id': id })
    dispatch(getSelectedDomainSuccess(domain))
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox sx={ { flexGrow: 1 } }>
        <Grid container spacing={ 1 } border={ `2px solid` }>
          <Grid item xs={ 12 } md={ 4 } lg={ 2 } border={ `1px solid` }>
            { selectedIds.length > 0 && defaultExpandedIds.length > 0 && !_.isEmpty(directoryTreeFolder) && (<Tree
              data={ directoryTreeFolder }
              selectedIds={ selectedIds }
              defaultExpandedIds={ defaultExpandedIds }
              handleOnTreeNodeSelect={ handleOnTreeNodeSelect }
            />)
            }
          </Grid>
          <Grid item xs={ 12 } md={ 8 } lg={ 10 }>
            <Documents rows={ rows } columns={ columns } />
          </Grid>
        </Grid>
        <Dialog
          sx={ { '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } } }
          maxWidth='xs'
          open={ dialogOpen }
        >
          <DialogTitle>{ dialogTitle }</DialogTitle>
          <DialogContent dividers>
            <Typography>{ dialogBody }</Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={ handleCancel }>
              Cancel
            </Button>
            { isDeleteDocumentDialog && (<Button onClick={ handleDeleteDocumentOk }>Ok</Button>) }
          </DialogActions>
        </Dialog>
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}