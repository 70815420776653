import Grid from '@mui/material/Grid'
import MDBox from 'components/core/MDBox'
import DashboardLayout from 'components/core/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/core/Navbars/DashboardNavbar'
import Footer from 'components/core/Footer'

import MDButton from '../../components/core/MDButton'
import { useNavigate } from 'react-router-dom'

function NewDocument () {
  const navigate = useNavigate()
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox sx={{ flexGrow: 1, backgroundColor: 'white' }}>
        <Grid container spacing={2}>
          <Grid item>
            <MDButton
              size={'large'}
              color='primary'
              onClick={() => {
                navigate('/section')
              }}
            >
              HRD Document
            </MDButton>
          </Grid>
          <Grid item>
            <MDButton
              size={'large'}
              color='primary'
              onClick={() => {
                navigate('/section')
              }}
            >
              {' '}
              QRS Document
            </MDButton>
          </Grid>
          <Grid item>
            <MDButton
              size={'large'}
              color='primary'
              onClick={() => {
                navigate('/section')
              }}
            >
              SSD Document
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}

export default NewDocument
