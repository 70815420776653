import { Chip, Typography } from '@mui/material'
import boschBack from 'assets/images/bosch_back.png'
import boschLogo from 'assets/images/bosch_logo.png'
import hwaiLogo from 'assets/images/hwai_logo.png'
import DefaultNavbarDropdown from '../DefaultNavbar/DefaultNavbarDropdown'
import MKBox from '../../../../components/core/MKBox'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { loginSelector } from '../../../authentication/store/userSlice'
import Avatar from '@mui/material/Avatar'
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined'
import Badge from '@mui/material/Badge'

function BoschNavBar ({ routes }) {
  const navigate = useNavigate()
  const user = useSelector(loginSelector)
  return (
    <>
      <div style={{ height: '5px', background: `url(${boschBack})` }} />
      <img style={{ cursor: 'pointer' }} onClick={() => navigate('/dashboard')} width={350} src={boschLogo} />
      <MKBox sx={{ marginTop: '-50px' }} display='flex' justifyContent='space-between' alignItems='right'>
        <MKBox
          color='inherit'
          display={{ xs: 'none', lg: 'flex' }}
          ml={'auto'}
        >
          {routes
            .filter((val) => (val.showMenu === true || val.workArea === user.work_area) && val.type !== 'divider')
            .map(({ name, icon, href, route, collapse }) => (
              <DefaultNavbarDropdown
                key={name}
                name={name}
                icon={icon}
                href={href}
                route={route}
                collapse={Boolean(collapse)}
              />
            ))
          }
          <PermIdentityOutlinedIcon fontSize={'large'} />
        </MKBox>
      </MKBox>
    
    </>
  )
}

export default BoschNavBar